import { TAddBuildingRecordAddressInfo } from 'types/pullInProcess/addBuildingRecordTypes';

export const addBuildingRecordData: TAddBuildingRecordAddressInfo = {
  addresses: [
    {
      id: '1',
      buildingNameNumber: 'Hilton',
      line1: 'line 1',
      line2: 'line 2',
      country: { name: 'United Kingdom', id: 1 },
      townCity: 'town City',
      postCode: '123',
      localAuthority: 'local authority',
    },
  ],
  existingAddresses: {
    applications: [
      {
        applicationId: '123',
        address: {
          id: '1',
          buildingNameNumber: 'jaspers',
          line1: 'line 1',
          line2: 'line 2',
          county: 'county',
          country: { name: 'Wales', id: 2 },
          townCity: 'town City',
          postCode: '123',
          localAuthority: 'local authority',
        },
      },
    ],
    pullInProcess: [
      {
        pullInProcessId: '123',
        address: {
          id: '1',
          buildingNameNumber: 'sky tower',
          line1: 'line 1',
          line2: 'line 2',
          county: 'county',
          country: { name: 'Wales', id: 1 },
          townCity: 'PULLIN town City',
          postCode: '123',
          localAuthority: 'local authority',
        },
      },
    ],
  },
};
