import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'state';

export const selectAdministrationTeamsSelectorsRoot = (state: AppState) =>
  state.administration.teams;

export const selectIsAdministrationTeamsGridLoading = createSelector(
  [selectAdministrationTeamsSelectorsRoot],
  adminTeams => adminTeams.isGridLoading
);

export const selectAdministrationTeamsGridState = createSelector(
  [selectAdministrationTeamsSelectorsRoot],
  adminTeams => adminTeams.gridState
);
