import { useMemo } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Box,
  LinearProgress,
  MenuItem,
  Collapse,
} from '@mui/material';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import {
  useAddPullInProcessContactDetailsMutation,
  useLazyGetPullInProcessContactDetailsQuery,
  useUpdatePullInProcessContactDetailsMutation,
} from 'api/pullInProcess';
import { ConfirmationModal } from 'common/components/confirmationModal';
import {
  EPullInProcessContactType,
  EPullInProcessContactTypeLanguage,
} from 'enums/EPullInProcessContactType';
import { useModalState, useLocalSnackbar } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { ContactAddressForm } from 'pages/pullInProcessPage/components/overview/contactAddressForm';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zContactDetailsFinalForm } from 'types/pullInProcess/contactDetails.zod';
import { TContactDetails } from 'types/pullInProcess/contactDetailsTypes';
import { extractErrorMessages } from 'util/ApiUtils';

export type TAddEditContactProps = {
  modalState: ReturnType<typeof useModalState<string | null>>;
  readonly?: boolean;
};
export function AddEditContact({
  modalState: { isShowing, hideModal, modalData },
  readonly,
}: TAddEditContactProps) {
  const isAddMode = modalData === null;
  const formId = 'temp';
  const formTitle = isAddMode ? 'Add' : readonly ? 'View' : 'Edit';

  return (
    <StyledDrawer anchor="right" open={isShowing}>
      <DialogTitle component="div">
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h1" component="span">
            {formTitle} contact details
          </Typography>
          <IconButton
            onClick={hideModal}
            aria-label="Close drawer"
            name="Close drawer"
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <PullInProcessContactForm
          modalData={modalData}
          formId={formId}
          hideModal={hideModal}
          readonly={readonly}
        />
      </DialogContent>
      <StyledDrawerActions>
        <Grid container justifyContent="flex-end" gap={1}>
          <Button variant="outlined" onClick={hideModal}>
            Cancel
          </Button>

          <LoadingButton variant="contained" type="submit" form={formId}>
            {isAddMode ? 'Save' : 'Update'}
          </LoadingButton>
        </Grid>
      </StyledDrawerActions>
    </StyledDrawer>
  );
}

type TPullInProcessContactFormProps = {
  modalData: string | null;
  formId: string;
  hideModal: () => void;
  readonly?: boolean;
};

export function PullInProcessContactForm({
  modalData,
  formId,
  hideModal,
  readonly,
}: TPullInProcessContactFormProps) {
  const { pullInProcessId } = usePullInProcessContext();

  const [getContactDetails] = useLazyGetPullInProcessContactDetailsQuery();

  const [postContactDetails] = useAddPullInProcessContactDetailsMutation();
  const [putContactDetails] = useUpdatePullInProcessContactDetailsMutation();

  const form = useForm<TContactDetails>({
    defaultValues: modalData
      ? async () =>
          getContactDetails({
            contactDetailsId: modalData as string,
            buildingId: pullInProcessId,
          }).unwrap()
      : async () => Promise.resolve({} as TContactDetails),
    resolver: zodResolver(zContactDetailsFinalForm),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isLoading },
    watch,
  } = form;

  const isDefaultContact = watch('defaultContact');
  const scheduleCommunication = watch('communicationStageTypeId');
  const contactTypeId = watch('contactTypeId');

  const {
    isShowing: confirmationModalIsShowing,
    hideModal: hideConfirmationModal,
    showModal: showConfirmationModal,
    modalData: confirmationModalData,
  } = useModalState<number>();

  const handleShowModal = () => {
    showConfirmationModal(scheduleCommunication);
  };

  const contactTypes = useMemo(
    () =>
      Object.entries(EPullInProcessContactTypeLanguage).sort(
        ([valA, _A], [valB, _B]) => {
          // force Other to be the bottom option
          if (valA === EPullInProcessContactType.Other.toString()) return 1;
          if (valB === EPullInProcessContactType.Other.toString()) return -1;
          return +valA - +valB;
        }
      ),
    []
  );

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onSubmit = async (formData: TContactDetails) => {
    if (confirmationModalIsShowing) hideConfirmationModal();

    const request = !modalData
      ? await postContactDetails({ buildingId: pullInProcessId, ...formData })
          .unwrap()
          .then(() => {
            createSuccessSnackbar('New Contact Added');
          })
          .catch((error: string) => {
            createErrorSnackbar(extractErrorMessages(error));
          })
          .finally(() => hideModal())
      : await putContactDetails({
          buildingId: pullInProcessId,
          contactDetailsId: modalData,
          ...formData,
        })
          .unwrap()
          .then(() => {
            createSuccessSnackbar('Contact Updated');
          })
          .catch((error: string) => {
            createErrorSnackbar(extractErrorMessages(error));
          })
          .finally(() => hideModal());

    return request;
  };

  const disableFields = !!(isLoading || readonly);

  return (
    <FormProvider {...form}>
      <form
        id={formId}
        onSubmit={handleSubmit(
          !!scheduleCommunication && !confirmationModalData
            ? handleShowModal
            : onSubmit
        )}
      >
        {isLoading ? <LinearProgress /> : <Box height={'4px'}></Box>}
        <Typography fontWeight={600} mb="1rem">
          Company details
        </Typography>
        <Controller
          control={control}
          name="companyRegistrationNumber"
          defaultValue=""
          render={({ field: fieldRest }) => (
            <TextField
              fullWidth
              {...fieldRest}
              label="Company registration number"
              error={!!errors.companyRegistrationNumber}
              helperText={errors.companyRegistrationNumber?.message}
              disabled={disableFields}
            />
          )}
        />
        <Controller
          control={control}
          name="companyName"
          defaultValue=""
          render={({ field: fieldRest }) => (
            <TextField
              fullWidth
              {...fieldRest}
              label="Company name"
              error={!!errors.companyName}
              helperText={errors.companyName?.message}
              disabled={disableFields}
            />
          )}
        />
        <Controller
          control={control}
          name="contactTypeId"
          render={({ field: { value, ...fieldRest } }) => (
            <TextField
              fullWidth
              {...fieldRest}
              value={value || ''}
              label="Contact type"
              error={!!errors.contactTypeId}
              helperText={errors.contactTypeId?.message}
              disabled={disableFields}
              select
            >
              {contactTypes.map(([value, contactText]) => (
                <MenuItem value={Number(value)} key={value}>
                  {contactText}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Collapse
          in={Number(contactTypeId) === EPullInProcessContactType.Other}
          unmountOnExit
        >
          <Controller
            control={control}
            name="otherContactType"
            defaultValue=""
            render={({ field: fieldRest }) => (
              <TextField
                fullWidth
                {...fieldRest}
                label="Please provide more information"
                error={!!errors.otherContactType}
                helperText={errors.otherContactType?.message}
                disabled={disableFields}
              />
            )}
          />
        </Collapse>
        <Controller
          control={control}
          name="defaultContact"
          defaultValue={false}
          render={({ field: fieldRest }) => (
            <FormControlLabel
              sx={{ mb: '1rem' }}
              control={
                <Switch
                  {...fieldRest}
                  checked={fieldRest.value}
                  disabled={disableFields}
                />
              }
              label="Make default contact"
            />
          )}
        />
        <Collapse in={isDefaultContact} unmountOnExit>
          <Controller
            control={control}
            name="communicationStageTypeId"
            render={({ field: fieldRest }) => (
              <TextField
                fullWidth
                {...fieldRest}
                value={fieldRest.value || ''}
                select
                label="Communication stage"
                error={!!errors.communicationStageTypeId}
                helperText={errors.communicationStageTypeId?.message}
                disabled={disableFields}
              >
                <MenuItem value={1}>Stage 1: Verification</MenuItem>
                <MenuItem value={2}>Stage 2: Engagement</MenuItem>
                <MenuItem value={3}>Stage 3: Escalation</MenuItem>
                <MenuItem value={4}>Stage 4: Enforcement</MenuItem>
              </TextField>
            )}
          />
        </Collapse>
        <Typography fontWeight={600} mb="1rem">
          Company address
        </Typography>
        <ContactAddressForm modalData={modalData} readonly={readonly} />
        <Typography fontWeight={600} mb="1rem">
          Contact details
        </Typography>
        <Box display="flex" gap="1rem">
          <Controller
            control={control}
            name="firstName"
            defaultValue=""
            render={({ field: fieldRest }) => (
              <TextField
                fullWidth
                {...fieldRest}
                label="First name"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                disabled={disableFields}
              />
            )}
          />
          <Controller
            control={control}
            name="surname"
            defaultValue=""
            render={({ field: fieldRest }) => (
              <TextField
                fullWidth
                {...fieldRest}
                label="Last name"
                error={!!errors.surname}
                helperText={errors.surname?.message}
                disabled={disableFields}
              />
            )}
          />
        </Box>
        <Controller
          control={control}
          name="emailAddress"
          defaultValue=""
          render={({ field: fieldRest }) => (
            <TextField
              fullWidth
              {...fieldRest}
              label="Email address"
              error={!!errors.emailAddress}
              helperText={errors.emailAddress?.message}
              disabled={disableFields}
            />
          )}
        />
        <Controller
          control={control}
          name="telephoneNumber"
          defaultValue=""
          render={({ field: fieldRest }) => (
            <TextField
              fullWidth
              {...fieldRest}
              label="Phone number"
              error={!!errors.telephoneNumber}
              helperText={errors.telephoneNumber?.message}
              disabled={disableFields}
            />
          )}
        />
      </form>
      <ConfirmationModal
        isShowing={confirmationModalIsShowing}
        onCancel={hideConfirmationModal}
        confirmButtonType="submit"
        formId={formId}
      >
        Clicking confirm will start automating communications to this contact,
        are you sure you want to confirm?
      </ConfirmationModal>
    </FormProvider>
  );
}
