import {
  createApi,
  fetchBaseQuery,
  TypedUseQueryHookResult,
} from '@reduxjs/toolkit/dist/query/react';
import {
  ECmsTaskGroup,
  ETaskListConfigType,
  GetAdminTasksListResponse,
  GetTaskHistoryResponse,
  GetTaskListResponseDefault,
  IAddEditTask,
  IGetOutcomesResult,
  IGetTaskTypesResult,
  ITask,
  ITaskStats,
  TaskListConfig,
} from 'types/tasks/CmsTaskTypes';
import { QueryTags } from 'util/ApiUtils';
import { handleFileDownloadFromWithinRtkQuery } from 'util/FileUtils';
import {
  customPrepareHeaders,
  getCsvUrlSearchParamFromQueryData,
  getUrlSearchParamFromQueryData,
  IGetCsvUrlSearchParamFromQueryDataParams,
  IGetUrlSearchParamFromQueryDataParams,
} from 'util/QueryUtils';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/task`;
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: customPrepareHeaders,
  credentials: 'include',
});

export const taskApi = createApi({
  reducerPath: 'taskApi',
  baseQuery,
  tagTypes: [QueryTags.Tasks, QueryTags.Notes],
  endpoints: builder => ({
    addTask: builder.mutation<void, IAddEditTask>({
      invalidatesTags: [QueryTags.Tasks],
      query: payload => ({
        url: payload.taskGroup === ECmsTaskGroup.Application ? `${process.env.REACT_APP_API_URL}/api/applicationtask` : `${process.env.REACT_APP_API_URL}/api/pullintask`,
        method: 'POST',
        body: payload,
      }),
    }),
    editTask: builder.mutation<void, { taskId: string; task: IAddEditTask }>({
      invalidatesTags: [QueryTags.Tasks],
      query: payload => ({
        url: `${payload.taskId}`,
        method: 'PUT',
        body: payload.task,
      }),
    }),
    getTaskTypes: builder.query<IGetTaskTypesResult, void>({
      query: () => `type`,
    }),
    getTaskCompletionOptions: builder.query<IGetOutcomesResult, void>({
      query: () => `outcome`,
    }),
    getTaskStats: builder.query<ITaskStats, void>({
      providesTags: [QueryTags.Tasks],
      query: () => `stats`,
      keepUnusedDataFor: 0,
    }),
    getTask: builder.query<ITask, string>({
      providesTags: [QueryTags.Tasks],
      query: taskId => `/${taskId}`,
    }),
    getAdminTasks: builder.query<GetAdminTasksListResponse, TaskListConfig>({
      providesTags: [QueryTags.Tasks],
      query: config => getQueryForTaskListConfig(config),
      keepUnusedDataFor: 0,
    }),
    getCurrentUserTasks: builder.query<
      GetTaskListResponseDefault,
      TaskListConfig
    >({
      providesTags: [QueryTags.Tasks],
      query: config => getQueryForTaskListConfig(config),
      keepUnusedDataFor: 0,
    }),
    getApplicationTasks: builder.query<
      GetTaskListResponseDefault,
      TaskListConfig
    >({
      providesTags: [QueryTags.Tasks],
      query: config => getQueryForTaskListConfig(config),
      keepUnusedDataFor: 0,
    }),
    getPullinTasks: builder.query<
      GetTaskListResponseDefault,
      TaskListConfig
    >(
      {
      providesTags: [QueryTags.Tasks],
      query: config => getQueryForTaskListConfig(config),
      keepUnusedDataFor: 0,
    }),
    getAdminTasksCsv: builder.query<null, TaskListConfig>({
      queryFn: async (config, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: getQueryForTaskListConfig(config, true),
          responseHandler: response => response.blob(),
        });

        if (result.error) {
          return { error: result.error };
        }

        return handleFileDownloadFromWithinRtkQuery(
          `Task List ${new Date().toLocaleDateString('en-GB')}.csv`,
          result.data as Blob
        );
      },
    }),
    getCurrentUserTasksCsv: builder.query<null, TaskListConfig>({
      queryFn: async (config, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: getQueryForTaskListConfig(config, true),
          responseHandler: response => response.blob(),
        });

        if (result.error) {
          return { error: result.error };
        }

        return handleFileDownloadFromWithinRtkQuery(
          `My Task List ${new Date().toLocaleDateString('en-GB')}.csv`,
          result.data as Blob
        );
      },
    }),
    getApplicationTasksCsv: builder.query<null, TaskListConfig>({
      queryFn: async (config, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: getQueryForTaskListConfig(config, true),
          responseHandler: response => response.blob(),
        });

        if (result.error) {
          return { error: result.error };
        }

        return handleFileDownloadFromWithinRtkQuery(
          `Application Task List ${new Date().toLocaleDateString('en-GB')}.csv`,
          result.data as Blob
        );
      },
    }),
    getTaskHistory: builder.query<
      GetTaskHistoryResponse,
      string
    >({
      providesTags: [QueryTags.Tasks],
      query: taskId => `/${taskId}/history`,
    }),
  }),
});

const getQueryForTaskListConfig = (
  config: TaskListConfig,
  csvDownload: boolean = false
) => {
  const { query } = config;

  const csvQuery: IGetCsvUrlSearchParamFromQueryDataParams = {
    sortBy: query.sortBy.toString(),
    sortDirection: query.sortDirection.toString(),
    searchValue: query.searchValue,
  };

  const gridQuery: IGetUrlSearchParamFromQueryDataParams = {
    ...csvQuery,
    skip: query.skip,
    take: query.take,
  };

  const queryParamsStr = csvDownload
    ? getCsvUrlSearchParamFromQueryData(csvQuery)
    : getUrlSearchParamFromQueryData(gridQuery);

  let resourcePath = '';

  switch (config.type) {
    case ETaskListConfigType.AdminTasks:
      const { query: adminTasksQuery } = config;
      if (adminTasksQuery.assigneeId.length > 0) {
        for (const assigneeId of adminTasksQuery.assigneeId) {
          queryParamsStr.append('assigneeId', `${assigneeId}`);
        }
      }

      if (adminTasksQuery.status.length > 0) {
        for (const status of adminTasksQuery.status) {
          queryParamsStr.append('status', `${status}`);
        }
      }

      if (adminTasksQuery.team.length > 0) {
        for (const team of adminTasksQuery.team) {
          queryParamsStr.append('teamId', `${team}`);
        }
      }

      if (adminTasksQuery.taskType.length > 0) {
        for (const taskType of adminTasksQuery.taskType) {
          queryParamsStr.append('taskTypeId', `${taskType}`);
        }
      }

      if (adminTasksQuery.startDate) {
        queryParamsStr.append('startDate', `${adminTasksQuery.startDate}`);
      }

      if (adminTasksQuery.endDate) {
        queryParamsStr.append('endDate', `${adminTasksQuery.endDate}`);
      }

      resourcePath = csvDownload ? 'task.csv' : 'task';
      break;

    case ETaskListConfigType.CurrentUserTasks:
      const { query: currentUserTasksQuery } = config;
      if (currentUserTasksQuery.status.length > 0) {
        for (const status of currentUserTasksQuery.status) {
          queryParamsStr.append('status', `${status}`);
        }
      }

      queryParamsStr.append(
        'showCreatedByMe',
        `${currentUserTasksQuery.showCreatedByMe}`
      );

      if (currentUserTasksQuery.viewType) {
        queryParamsStr.append('viewType', `${currentUserTasksQuery.viewType}`);
      }

      resourcePath = csvDownload ? 'profile/task.csv' : 'profile/task';
      break;

    case ETaskListConfigType.ApplicationTasks:
    case ETaskListConfigType.PullinTasks:
      const { query: applicationTasksQuery } = config;
      if (applicationTasksQuery.status.length > 0) {
        for (const status of applicationTasksQuery.status) {
          queryParamsStr.append('status', `${status}`);
        }
      }

      if (applicationTasksQuery.team.length > 0) {
        for (const team of applicationTasksQuery.team) {
          queryParamsStr.append('teamId', `${team}`);
        }
      }

      const area = `${config.type === ETaskListConfigType.ApplicationTasks ? "application" : "pullin"}`;

      resourcePath = csvDownload
        ? `${area}/${config.id}/task.csv`
        : `${area}/${config.id}/task`;
      break;
  }
  return `${
    process.env.REACT_APP_API_URL
  }/api/${resourcePath}?${queryParamsStr.toString()}`;
};

export const {
  useAddTaskMutation,
  useEditTaskMutation,
  useGetTaskQuery,
  useGetTaskTypesQuery,
  useGetTaskCompletionOptionsQuery,
  useGetTaskStatsQuery,
  useGetAdminTasksQuery,
  useGetCurrentUserTasksQuery,
  useGetApplicationTasksQuery,
  useGetPullinTasksQuery,
  useLazyGetAdminTasksCsvQuery,
  useLazyGetCurrentUserTasksCsvQuery,
  useLazyGetApplicationTasksCsvQuery,
  useGetTaskHistoryQuery,
} = taskApi;

export type GetCurrentUserTasksQueryResult = TypedUseQueryHookResult<
  GetTaskListResponseDefault,
  TaskListConfig,
  typeof baseQuery
>;

export type GetAdminTasksQueryResult = TypedUseQueryHookResult<
  GetAdminTasksListResponse,
  TaskListConfig,
  typeof baseQuery
>;

export type GetApplicationTasksQueryResult = TypedUseQueryHookResult<
  GetTaskListResponseDefault,
  TaskListConfig,
  typeof baseQuery
>;
