import { useEffect } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { addDays } from 'date-fns';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useGetTeamListQuery
} from 'api/administration/teams';
import { useAddTaskMutation, useEditTaskMutation } from 'api/task';
import { Loading } from 'common/components/loading';
import { AssigneeField, DueDateField } from 'common/components/task/common';
import {
  useTaskCompletionOptions,
  useTaskTypeUtils,
} from 'common/components/task/utils';
import { useLocalSnackbar } from 'hooks';
import { useAppDispatch } from 'state';
import { closeDrawer, useTaskDrawerState } from 'state/slices/task';
import {
  ECmsTaskGroup,
  ECmsTaskStatus,
  ECmsTaskStatusLanguage,
  IAddEditTask,
} from 'types/tasks/CmsTaskTypes';
import { extractErrorMessages } from 'util/ApiUtils';
import { getDateOnlyIsoString } from 'util/DateUtils';

interface IAddEditTaskFormProps {
  formId: string;
  task: IAddEditTask;
  mutation:
    | { type: 'Add'; fn: ReturnType<typeof useAddTaskMutation>[0] }
    | { type: 'Edit'; fn: ReturnType<typeof useEditTaskMutation>[0] };
}

const isDateInThePast = (dateToCheck: Date) => {
  const today = new Date();
  // Set hours to 0,0,0,0 so it doesn't take time into consideration
  today.setHours(0, 0, 0, 0);

  return dateToCheck < today;
};

export const AddEditTaskForm = ({
  formId,
  task,
  mutation,
}: IAddEditTaskFormProps) => {
  const dispatch = useAppDispatch();
  const { taskGroup, taskIdToEdit } = useTaskDrawerState();
  const form = useForm<IAddEditTask>({
    defaultValues: task,
  });
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { defaultValues },
  } = form;
  const formTeamIdValue = watch('assignedToTeamId');

  const {
    data: teamsData,
    isLoading: teamsLoading,
    isError: teamsIsError,
    error: teamsError,
  } = useGetTeamListQuery();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  // errors
  useEffect(() => {
    if (teamsIsError && teamsError)
      createErrorSnackbar('An error occurred while fetching Team data.');
  }, [teamsIsError, teamsError, createErrorSnackbar]);

  const onSubmit = async (formData: IAddEditTask) => {
    const { type, fn } = mutation;

    formData.taskGroup = taskGroup;

    try {
      const functionCall =
        type === 'Add'
          ? fn(formData)
          : type === 'Edit' && taskIdToEdit
          ? fn({ taskId: taskIdToEdit, task: formData })
          : undefined;

      if (functionCall === undefined) {
        createErrorSnackbar('Error api function call could not be made');
        return;
      }

      if (isDateInThePast(new Date(formData.dueDate))) {
        createErrorSnackbar("Due date can't be in the past");
        return;
      }

      await functionCall
        .unwrap()
        .then(_ => {
          dispatch(closeDrawer());
          reset();
          createSuccessSnackbar(!taskIdToEdit ? `Task added` : 'Task updated');
        })
        .catch(error => {
          createErrorSnackbar(extractErrorMessages(error));
        });
    } catch (err) {
      createErrorSnackbar(
        !taskIdToEdit ? `Failed to add task` : `Failed to update task`
      );
    }
  };

  const [selectedTypeId, selectedSubtypeId, selectedTopicId, status] = watch([
    'taskTypeId',
    'taskSubtypeId',
    'topicId',
    'status',
  ]);

  const {
    taskTypeOptions,
    getTaskTypeName,
    taskSubtypeOptions,
    taskTopicOptions,
    getTopicSla,
    isSubTaskTypeDueDateEditable,
  } = useTaskTypeUtils(selectedTypeId, selectedSubtypeId, selectedTopicId);

  const resetTopic = () => {
    setValue('topicId', '');
  };

  const resetSubtypeAndTopic = () => {
    setValue('taskSubtypeId', '');
    resetTopic();
  };

  const { taskCompletionOptions } = useTaskCompletionOptions();
  const isTaskCompleted = status === ECmsTaskStatus.Done;

  const isDueDateEditable =
    typeof selectedTypeId === 'number' &&
    typeof selectedSubtypeId === 'number' &&
    isSubTaskTypeDueDateEditable(selectedTypeId, selectedSubtypeId);

  // outcomeId is not required when selected task type is Escalations
  const isOutcomeIdRequired =
    typeof selectedTypeId === 'number' &&
    getTaskTypeName(selectedTypeId).startsWith('Escalation')
      ? false
      : true;

  return teamsLoading ? (
      <Loading isOpen />
    ) : (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%' }}
        id={formId}
      >
        <Controller
          control={control}
          name="description"
          rules={{
            required: 'Description is required',
            maxLength: {
              value: 1000,
              message: '1000 characters is the maximum allowed.',
            },
          }}
          render={({ field, formState: { errors } }) => (
            <TextField
              {...field}
              multiline
              disabled={mutation.type === 'Edit'}
              fullWidth
              rows={5}
              label="Description"
              error={!!errors.description}
              helperText={errors.description?.message}
            />
          )}
        />

        {taskTypeOptions.length > 0 ? (
          <Controller
            control={control}
            name="taskTypeId"
            rules={{ required: 'Task Type is required' }}
            render={({ field, formState: { errors } }) => (
              <TextField
                {...field}
                select
                fullWidth
                label="Task Type"
                error={Boolean(errors.taskTypeId)}
                helperText={errors.taskTypeId?.message}
                onChange={e => {
                  setValue('taskTypeId', parseInt(e.target.value));
                  resetSubtypeAndTopic();
                }}
              >
                {taskTypeOptions
                  .filter(
                    v => (v.taskGroup === taskGroup && (v.active || defaultValues?.taskTypeId === v.value))
                  )
                  .map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
        ) : null}

        {taskSubtypeOptions.length > 0 ? (
          <Controller
            control={control}
            name="taskSubtypeId"
            rules={{ required: 'Task Sub Type is required' }}
            render={({ field, formState: { errors } }) => (
              <TextField
                {...field}
                select
                fullWidth
                label="Task Sub Type"
                error={!!errors.taskSubtypeId}
                helperText={errors.taskSubtypeId?.message}
                onChange={e => {
                  setValue('taskSubtypeId', parseInt(e.target.value));
                  resetTopic();
                }}
              >
                {taskSubtypeOptions
                  .filter(
                    v => v.active || defaultValues?.taskSubtypeId === v.value
                  )
                  .map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
        ) : null}

        {taskTopicOptions.length > 0 ? (
          <Controller
            control={control}
            name="topicId"
            rules={{ required: 'Topic is required' }}
            render={({ field, formState: { errors } }) => (
              <TextField
                {...field}
                select
                fullWidth
                label="Topic"
                error={!!errors.topicId}
                helperText={errors.topicId?.message}
                onChange={e => {
                  const parsedTopicId = parseInt(e.target.value);
                  setValue('topicId', parsedTopicId);

                  // Check if we have an sla too
                  // If so, update the dueDate field
                  const topicSlaDays = getTopicSla(parsedTopicId);
                  if (topicSlaDays > 0) {
                    setValue(
                      'dueDate',
                      getDateOnlyIsoString(addDays(new Date(), topicSlaDays))
                    );
                  }
                }}
                SelectProps={{
                  renderValue: selected => (
                    <Box whiteSpace={'normal'}>
                      {taskTopicOptions.find(
                        taskTopicOption =>
                          taskTopicOption.value ===
                          (selected as number).toString()
                      )?.label ?? 'Could not get option'}
                    </Box>
                  ),
                }}
              >
                {taskTopicOptions
                  .filter(v => v.active || defaultValues?.topicId === v.value)
                  .map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
        ) : null}

        <Controller
          control={control}
          name="applicationRef"
          rules={{ required: taskGroup === ECmsTaskGroup.Application ? 'Application Reference is required' : 'Pull In Reference is required' }}
          render={({ field, formState: { errors } }) => (
            <TextField
              {...field}
              fullWidth
              disabled={mutation.type === 'Edit'}
              label={taskGroup === ECmsTaskGroup.Application ? "Application Reference" : "Pull In Reference"}
              error={!!errors.applicationRef}
              helperText={errors.applicationRef?.message}
            />
          )}
        />

        <Typography variant="h2" mb={2}>
          Assigned
        </Typography>

        <Controller
            control={control}
            name={'assignedToTeamId'}
            rules={{ required: 'Team is required' }}
            render={({ field, fieldState }) => (
              <>
                <TextField
                  {...field}
                  select
                  fullWidth
                  label="Team"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onChange={e => {
                    setValue('assignedToTeamId', parseInt(e.target.value));
                    setValue('assigneeUserId', undefined);
                  }}
                >
                  {teamsData?.filter(team => team.canTasksBeAssignedToThisTeam === true)
                    .map(team => {
                    return (
                      <MenuItem
                        key={team.id}
                        value={team.id}
                      >
                        {team.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </>
            )}
          />

        <AssigneeField isRequired={false} selectedTeam={formTeamIdValue} />

        <DueDateField isDisabled={!isDueDateEditable} />

        <Typography variant="h2" mb={2}>
          Status
        </Typography>
        <Controller
          control={control}
          name="status"
          rules={{ required: 'Status is required' }}
          render={({ fieldState, field: { onChange, ...fieldProps } }) => {
            return (
              <FormControl>
                <ToggleButtonGroup
                  {...fieldProps}
                  value={fieldProps.value.toString()}
                  onChange={(_, val) => {
                    if (val !== null) {
                      if (typeof val === 'string') {
                        onChange(parseInt(val));
                      }
                    }
                  }}
                  exclusive
                  sx={{ mb: 3 }}
                >
                  {Object.entries(ECmsTaskStatusLanguage).map(
                    ([key, value]) => (
                      <ToggleButton key={key} value={key}>
                        {value}
                      </ToggleButton>
                    )
                  )}
                </ToggleButtonGroup>
                {fieldState.error?.message ? (
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
            );
          }}
        />

        {isTaskCompleted ? (
          <>
            <Typography variant="h2" mb={2}>
              Task Completed Details
            </Typography>
            {taskCompletionOptions.length > 0 && isOutcomeIdRequired ? (
              <Controller
                control={control}
                name="outcomeId"
                rules={{ required: 'Outcome Reason is required' }}
                render={({ field, formState: { errors } }) => (
                  <TextField
                    {...field}
                    value={field.value ?? ''}
                    select
                    fullWidth
                    label="Outcome Reason"
                    error={!!errors.outcomeId}
                    helperText={errors.outcomeId?.message}
                  >
                    {taskCompletionOptions.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            ) : null}
            <Controller
              control={control}
              name="notes"
              rules={{ required: 'Outcome Notes are required' }}
              render={({ field, formState: { errors } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={5}
                  label="Notes"
                  error={!!errors.notes}
                  helperText={errors.notes?.message}
                />
              )}
            />
          </>
        ) : null}
      </form>
    </FormProvider>
  );
};
