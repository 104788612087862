import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { z, ZodType } from 'zod';
import { useUpdatePullInBuildingResponsiveFlagMutation } from 'api/pullInProcess';
import { useLocalSnackbar } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { StyledDrawerActions } from 'styles/globalStyles/drawer';

interface IPullInUnresponsiveFlagModalProps {
  onClose: () => void;
}

export function PullInUnresponsiveFlagModal({
  onClose,
}: IPullInUnresponsiveFlagModalProps) {
  return (
    <Dialog open fullWidth>
      <DialogTitle>
        Are you sure you want to mark this building as unresponsive?
      </DialogTitle>
      <DialogContent>
        <PullInUnresponsiveFlagModalForm onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
}

interface IPullInUnresponsiveFlagModalFormFormProps {
  onClose: () => void;
}

export const PullInUnresponsiveFlagModalForm = ({
  onClose,
}: IPullInUnresponsiveFlagModalFormFormProps) => {
  const form = useForm<{ comment: string }>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zPullInResponsiveFlagSchema),
  });
  const { handleSubmit, control, reset } = form;

  const { pullInProcessId } = usePullInProcessContext();
  const [updatePullInBuildingResponsiveFlag, { isLoading }] =
    useUpdatePullInBuildingResponsiveFlagMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onSubmit = async (formData: { comment: string }) => {
    try {
      await updatePullInBuildingResponsiveFlag({
        buildingId: pullInProcessId,
        comment: formData.comment,
      })
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Unresponsive flag updated successfully`);
        })
        .catch(err => {
          createErrorSnackbar(`Failed to update Unresponsive flag`);
        });
    } catch (error) {
      createErrorSnackbar(`An unexpected error occurred`);
    }
    onClose();
  };

  return (
    <form
      noValidate
      onSubmit={event => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
      style={{ width: '100%' }}
    >
      <DialogContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="comment"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  required
                  label="Comments"
                  helperText={fieldState.error?.message}
                  multiline
                  autoComplete="off"
                  inputProps={{ maxLength: 1000 }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <StyledDrawerActions>
        <Grid container justifyContent="flex-end" gap={1}>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <LoadingButton variant="contained" type="submit" disabled={isLoading}>
            Confirm
          </LoadingButton>
        </Grid>
      </StyledDrawerActions>
    </form>
  );
};

export const zPullInResponsiveFlagSchema: ZodType<{ comment: string }> =
  z.object({
    comment: z
      .string()
      .nonempty('Comments is required')
      .max(1000, '1000 characters is the maximum allowed.'),
  });
