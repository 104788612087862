import { Grid, Typography } from '@mui/material';

export const PermissionDeniedToTab = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: '50vh', textAlign: 'center' }}
    >
      <Typography variant="h4" color="error">
        You do not have the correct permissions to view this tab.
      </Typography>
    </Grid>
  );
};
