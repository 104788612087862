import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, DialogContent, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { z, ZodType } from 'zod';
import { useUpdatePullInBuildingResponsiveFlagMutation } from 'api/pullInProcess';
import { useLocalSnackbar } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { StyledDialog, StyledDialogTitle } from 'styles/globalStyles/dialog';
import { StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IDeletePullInUnresponsiveFlag } from 'types/pullInProcess/pullInProcessTypes';
import { nameof } from 'util/formUtils';

interface IDeletePullInUnresponsiveFlagModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const getName = (fieldName: keyof IDeletePullInUnresponsiveFlag) =>
  nameof<IDeletePullInUnresponsiveFlag>(fieldName);

export function DeletePullInUnresponsiveFlagModal({
  onConfirm,
  onCancel,
}: IDeletePullInUnresponsiveFlagModalProps) {
  const { pullInProcessId } = usePullInProcessContext();

  const form = useForm<IDeletePullInUnresponsiveFlag>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zdeletePullInUnresponsiveFlagSchema),
  });
  const { handleSubmit, control, reset } = form;
  const [updatePullInBuildingResponsiveFlag, { isLoading }] =
    useUpdatePullInBuildingResponsiveFlagMutation();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onSubmit = async (formData: IDeletePullInUnresponsiveFlag) => {
    try {
      await updatePullInBuildingResponsiveFlag({
        buildingId: pullInProcessId,
        comment: formData.comment,
      })
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Unresponsive flag updated successfully`);
        })
        .catch(err => {
          createErrorSnackbar(`Failed to update Unresponsive flag`);
        });
    } catch (error) {
      createErrorSnackbar(`An unexpected error occurred`);
    }

    onConfirm();
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>Remove Unresponsive Flag</StyledDialogTitle>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={getName('comment')}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    required
                    label="Comments"
                    helperText={fieldState.error?.message}
                    multiline
                    autoComplete="off"
                    inputProps={{ maxLength: 1000 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onCancel();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              Remove Flag
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </StyledDialog>
  );
}

export const zdeletePullInUnresponsiveFlagSchema: ZodType<
  Pick<IDeletePullInUnresponsiveFlag, 'comment'>
> = z.object({
  comment: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});
