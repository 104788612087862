import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { FormSkeleton } from 'common/components/skeletons';
import { IFile } from 'common/types/File';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { IPaymentRequestClosingChecks } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';

export const ClosingPaymentRequestChecksSectionInfoPanelReports = ({
  fileData,
  isLoading,
}: {
  fileData: IPaymentRequestClosingChecks | undefined;
  isLoading: boolean;
}) => {
  return (
    <>
      {isLoading && <FormSkeleton />}
      {fileData?.buildingRegulatoryApprovalFiles && (
        <InnerCard title="Building regulatory approval">
          {
            <FileDownloadCard
              fileItems={fileData.buildingRegulatoryApprovalFiles}
            />
          }
        </InnerCard>
      )}
      {fileData?.practicalCompletionCertificateFiles && (
        <InnerCard title="Practical completion certificate">
          <FileDownloadCard
            fileItems={fileData.practicalCompletionCertificateFiles}
          />
        </InnerCard>
      )}

      {fileData?.exitFraewFiles && (
        <InnerCard title="Fire Risk Assessment">
          <FileDownloadCard fileItems={fileData.exitFraewFiles} />
        </InnerCard>
      )}
      {fileData?.leaseholderAndResidentEvidenceFiles && (
        <InnerCard title="Leaseholder and resident evidence">
          <FileDownloadCard
            fileItems={fileData.leaseholderAndResidentEvidenceFiles}
          />
        </InnerCard>
      )}
    </>
  );
};

const InnerCard = ({
  title,
  children,
}: {
  title?: string;
  children: ReactNode;
}) => {
  return (
    <Box pt={2} p={2} mb={2} bgcolor={'grey.100'}>
      {title && (
        <Typography variant="h3" fontWeight="600">
          {title}
        </Typography>
      )}
      <>{children}</>
    </Box>
  );
};

const FileDownloadCard = ({ fileItems }: { fileItems: Array<IFile> }) => (
  <>
    {fileItems.map(item => (
      <ApplicationFileDownloadWrapper
        key={item.id}
        fileName={item.name}
        fileId={item.id}
        fileWrapperStyle={{
          backgroundColor: '#FAFAFA',
          marginTop: '1rem',
        }}
      />
    ))}
  </>
);
