import { Fragment, useState } from 'react';
import { Box } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPaymentRequestCostEvidenceDocumentListQuery } from 'api/application/paymentRequestApi';
import { useModalState } from 'hooks';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import {
  PaymentRequestCostEvidenceDocumentList,
  UploadDocumentDialog,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/sections/PaymentRequestCostEvidenceSection';

export const PaymentRequestCostEvidenceSection = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();
  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
      }
    : skipToken;
  const { data, refetch } =
    useGetPaymentRequestCostEvidenceDocumentListQuery(request);

  const [selectedDocumentType, setSelectedDocumentType] = useState<
    'costReport' | 'invoice'
  >('costReport');

  const {
    isShowing: isUploadDocumentDialogShowing,
    showModal: showUploadDocumentDialog,
    hideModal: hideUploadDocumentDialog,
  } = useModalState();

  return (
    <Fragment>
      <PaymentRequestCostEvidenceDocumentList
        title="Cost Report"
        documents={data?.costReports ?? []}
        docType="costReport"
        onUploadClick={() => {
          setSelectedDocumentType('costReport');
          showUploadDocumentDialog();
        }}
        refetch={refetch}
      />

      <Box sx={{ mt: 2 }}>
        <PaymentRequestCostEvidenceDocumentList
          title="Invoices"
          documents={data?.invoices ?? []}
          docType="invoice"
          onUploadClick={() => {
            setSelectedDocumentType('invoice');
            showUploadDocumentDialog();
          }}
          refetch={refetch}
        />
      </Box>

      <Box sx={{ mt: 2 }}></Box>

      {isUploadDocumentDialogShowing && (
        <UploadDocumentDialog
          documentType={selectedDocumentType}
          onConfirm={() => {
            hideUploadDocumentDialog();
            refetch();
          }}
          onCancel={hideUploadDocumentDialog}
        />
      )}
    </Fragment>
  );
};
