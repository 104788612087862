import { Box, Grid } from '@mui/material';
import { useLazyGetVendorPaymentDetailsFileQuery } from 'api/application/paymentApi';
import { FileDownload } from 'common/components/fileDownload';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { Intro } from 'pages/applicationPage/content/payments/common';

export const PaymentPdfSection = () => {
  const { applicationId, applicationReference } = useApplicationContext();
  const { createErrorSnackbar } = useLocalSnackbar();
  const [trigger, result] = useLazyGetVendorPaymentDetailsFileQuery();

  const handleClick = () => {
    if (!applicationId || !applicationReference) {
      createErrorSnackbar(
        'Could not get file. applicationId or applicationReference is empty'
      );
      return;
    }

    trigger({ applicationId, fileName: applicationReference });
  };

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        borderLeft: '3px solid black',
        borderLeftColor: 'black',
        borderRadius: 1,
        p: 3,
      }}
    >
      <Grid container flexDirection={'column'} rowGap={2}>
        <Box>
          <Intro
            heading="Download and send account set up details"
            subHeading="Download the vendor set-up details and send the document to Homes England"
          />
        </Box>
        <Box>
          <FileDownload
            isError={result.isError}
            error={result.error}
            isLoading={result.isLoading || result.isFetching}
            displayValue={'Vendor Set-up Details'}
            handleClick={handleClick}
            fileWrapperStyle={{ my: 0 }}
            fileName='Vendor Set-up Details'
          />
        </Box>
      </Grid>
    </Box>
  );
};
