import { useReducer } from 'react';
import { useGetPullinTasksQuery } from 'api/task';
import { ESortDirection } from 'enums/ESortDirection';
import { ETeam } from 'enums/ETeam';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context/pullInProcessContext';
import {
  ECmsTaskStatus,
  ECmsTaskView,
  ETaskListConfigType,
  ITaskListPaginatedQueryDefault,
} from 'types/tasks/CmsTaskTypes';

interface ITaskListStateDefault {
  gridState: ITaskListPaginatedQueryDefault;
}

const useInitialState = (): ITaskListStateDefault => {
  return {
    gridState: {
      skip: 0,
      take: 25,
      searchValue: null,
      sortBy: 'dueDate',
      sortDirection: ESortDirection.Asc,
      status: [ECmsTaskStatus.InProgress, ECmsTaskStatus.NotStarted],
      team: [ETeam.DaviesOps, ETeam.HomesEnglandOperations],
      showCreatedByMe: true,
      viewType: ECmsTaskView.Tasks,
    },
  };
};

type ActionType = {
  type: 'gridStateChanged';
  payload: Partial<ITaskListPaginatedQueryDefault>;
};

const reducer = (
  state: ReturnType<typeof useInitialState>,
  action: ActionType
) => {
  switch (action.type) {
    case 'gridStateChanged':
      return { gridState: { ...state.gridState, ...action.payload } };

    default:
      throw new Error('invalid action.type');
  }
};

export const useApplicationTaskListGridState = () => {
  const initialState = useInitialState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const gridStateChanged = (newGridState: ActionType['payload']) => {
    dispatch({ type: 'gridStateChanged', payload: newGridState });
  };

  return { state, dispatch, gridStateChanged };
};

export const useGridQueryAndState = () => {
  const { pullInProcessId } = usePullInProcessContext();
  const { state, gridStateChanged } = useApplicationTaskListGridState();

  const query = useGetPullinTasksQuery({
    type: ETaskListConfigType.PullinTasks,
    query: state.gridState,
    id: pullInProcessId
  });
  return { query, state: state, gridStateChanged };
};

export const usePullinTasksGridQueryAndState = () => {
  const { pullInProcessId } = usePullInProcessContext();
  const { state, gridStateChanged } = useApplicationTaskListGridState();

  const query = useGetPullinTasksQuery({
    type: ETaskListConfigType.PullinTasks,
    query: state.gridState,
    id: pullInProcessId,
  });

  return { query, state: state, gridStateChanged };
};
