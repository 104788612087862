import { EPermissionGroup } from 'enums/EPermissionGroup';
import { IPermission } from 'types/administration/AdministrationPermissionsTypes';

export const administrationPermissionsData: Array<IPermission> = [
  {
    name: 'tasklist.view-all',
    description: 'User can access Task Management',
    permissionGroup: EPermissionGroup.TaskManagement,
    granted: false,
  },
  {
    name: 'tasklist.view-own',
    description: 'User can only see their tasks',
    permissionGroup: EPermissionGroup.TaskManagement,
    granted: false,
  },
  {
    name: 'tasklist.view-own-teams',
    description: 'User can view own team tasks only',
    permissionGroup: EPermissionGroup.TaskManagement,
    granted: false,
  },
  {
    name: 'applications.view',
    description: 'User can access Applications',
    permissionGroup: EPermissionGroup.ApplicationGeneral,
    granted: false,
  },
  {
    name: 'applications.intervention.process',
    description: 'User can update Intervention Category',
    permissionGroup: EPermissionGroup.ApplicationGeneral,
    granted: false,
  },
  {
    name: 'payment-release.edit',
    description: 'User can approve a payment',
    permissionGroup: EPermissionGroup.ApplicationGeneral,
    granted: false,
  },
  {
    name: 'payment-release.reject',
    description: 'User can reject a payment',
    permissionGroup: EPermissionGroup.ApplicationGeneral,
    granted: false,
  },
  {
    name: 'liveproject.payments.view',
    description: 'User can view payments',
    permissionGroup: EPermissionGroup.ApplicationLiveProject,
    granted: false,
  },
  {
    name: 'liveproject.payments.process',
    description: 'User can process payments',
    permissionGroup: EPermissionGroup.ApplicationLiveProject,
    granted: false,
  }
];
