import { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Divider, Grid, Tabs } from '@mui/material';
import { CustomTab } from 'common/components/customTab';
import { CustomTabPanel } from 'common/components/customTabPanel';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { useCurrentUserPermissions } from 'hooks';
import { PermissionDeniedToTab } from 'pages/applicationPage/common/components';
import { BuildingDetailsTabPanel } from 'pages/pullInProcessPage/components/buildingDetailsPanel';
import { PullInProcessCommunications } from 'pages/pullInProcessPage/components/communications/pullInProcessCommunications';
import { PullInProcessDocuments } from 'pages/pullInProcessPage/components/documents/pullInProcessDocuments';
import { PullInStatus } from 'pages/pullInProcessPage/components/pullInStatus';
import { PullinTaskList } from 'pages/pullInProcessPage/components/pullinTaskLists/PullinTaskList';

export const StyledRoundBorderBox = styled(RoundBorderBox)({
  '& > div:not(:last-child)': {
    borderBottom: '1px solid rgba(0,0,0,0.12)',
  },
  '& > div': {
    padding: '1rem',
  },
  borderRadius: '8px',
});

const pullInProcessKeys = [
  'buildingDetails',
  'tasks',
  'communications',
  'documents',
  'support',
] as const;
type PullInProcessKey = (typeof pullInProcessKeys)[number];

export function PullInProcessPageLayout() {
  const [selectedTab, setSelectedTab] =
    useState<PullInProcessKey>('buildingDetails');

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasPullinprocessBuildingdetailsView = doesUserHaveSinglePermission(
    'pullinprocess.buildingdetails.view'
  );
  const hasPullinprocessDocumentsView = doesUserHaveSinglePermission(
    'pullinprocess.documents.view'
  );
  const hasPullinprocessCommunicationsView = doesUserHaveSinglePermission(
    'pullinprocess.communications.view'
  );
  const hasPullinprocessTasklistView = doesUserHaveSinglePermission(
    'pullinprocess.tasklist.view'
  );

  const pullInProcessTabs: Record<
    PullInProcessKey,
    { title: string; content: ReactNode; hidden?: boolean }
  > = {
    buildingDetails: {
      title: 'Building Details',
      content: hasPullinprocessBuildingdetailsView ? (
        <BuildingDetailsTabPanel />
      ) : (
        <PermissionDeniedToTab />
      ),
    },
    tasks: {
      title: 'Task List',
      content: hasPullinprocessTasklistView ? (
        <PullinTaskList />
      ) : (
        <PermissionDeniedToTab />
      ),
    },
    documents: {
      title: 'Documents',
      content: hasPullinprocessDocumentsView ? (
        <PullInProcessDocuments />
      ) : (
        <PermissionDeniedToTab />
      ),
    },
    communications: {
      title: 'Communications',
      content: hasPullinprocessCommunicationsView ? (
        <PullInProcessCommunications />
      ) : (
        <PermissionDeniedToTab />
      ),
    },
    support: {
      title: 'Support',
      content: <div>Support</div>, // Future work
      hidden: true,
    },
  } as const;

  return (
    <Grid container spacing="1.5rem" mt="1rem">
      <nav>
        <Tabs
          onChange={(_, value) => setSelectedTab(value)}
          sx={{
            pl: '1.5rem',
            width: '100%',
          }}
          value={selectedTab}
        >
          {pullInProcessKeys.map(key =>
            pullInProcessTabs[key].hidden ? null : (
              <CustomTab
                key={key}
                label={pullInProcessTabs[key].title}
                currentSelectedTab={selectedTab}
                value={key}
              />
            )
          )}
        </Tabs>
      </nav>
      <Divider sx={{ m: '-1px -1rem 0 -1rem', width: '110%' }} />

      <Grid item xs={12}>
        <Box width="100%">
          <PullInStatus />
          <CustomTabPanel value={selectedTab} currentSelectedTab={selectedTab}>
            {pullInProcessTabs[selectedTab].content}
          </CustomTabPanel>
        </Box>
      </Grid>
    </Grid>
  );
}
