import { LinearProgress, Stack, Typography } from '@mui/material';
import { useVariationApproveMutation } from 'api/application/variationApi';
import {
  AlertForQueryErrorOrNull,
  WarningAlert,
} from 'common/components/alerts';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { useLocalSnackbar, useModalState } from 'hooks';
import { EVariationStatus } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import {
  useApplicationAndVariationIdOrNull,
  useVariationOverview,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import { extractErrorMessages } from 'util/ApiUtils';

export const useApproveModal = () => {
  const { isShowing, showModal, hideModal } = useModalState();

  const renderApproveModal = () => {
    return (
      <ApproveConfirmationModal isShowing={isShowing} handleClose={hideModal} />
    );
  };

  return {
    showApproveModal: showModal,
    hideApproveModal: hideModal,
    renderApproveModal,
  };
};

const ApproveConfirmationModal = ({
  isShowing,
  handleClose,
}: {
  isShowing: boolean;
  handleClose: () => void;
}) => {
  const queryParams = useApplicationAndVariationIdOrNull();
  const [approve, approveStatus] = useVariationApproveMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();
  const { isVariancePercentageGreaterThanThreshold } = useVariationOverview();

  const handleClick = () => {
    if (!queryParams) {
      createErrorSnackbar('Could not get variation id');
      return;
    }

    approve({
      ...queryParams,
      status: EVariationStatus.Approved,
    })
      .unwrap()
      .then(_ => {
        createSuccessSnackbar(
          'The variation request has been successfully approved'
        );
        handleClose();
      })
      .catch(error => {
        createErrorSnackbar(extractErrorMessages(error));
      });
  };

  return (
    <ConfirmationModal
      isShowing={isShowing}
      confirmButtonText="Approve Variation"
      cancelButtonText="Cancel"
      onConfirm={handleClick}
      onCancel={handleClose}
      dialogActionsSx={{ justifyContent: 'center', mb: 2 }}
      areButtonsLoading={approveStatus.isLoading}
    >
      {approveStatus.isLoading ? <LinearProgress /> : null}

      <Stack gap={2}>
        <Typography pt={1} fontWeight={600} textAlign={'center'}>
          Are you sure you want to approve this variation request?
        </Typography>

        {isVariancePercentageGreaterThanThreshold ? (
          <WarningAlert alertText="Please note, the variance is greater than the allowed threshold. Are you sure you want to proceed?" />
        ) : null}

        <AlertForQueryErrorOrNull
          isError={approveStatus.isError}
          error={approveStatus.error}
        />
      </Stack>
    </ConfirmationModal>
  );
};
