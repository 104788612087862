import { administrationApi } from 'api/administration';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IUsersGridListItem,
  IUser,
  IGetUsersListQuery,
  IUserItem,
  IAddEditUserForm,
} from 'types/administration/AdministrationUsersTypes';
import { QueryTags } from 'util/ApiUtils';
import { getUrlSearchParamFromQueryData } from 'util/QueryUtils';

const getPaginatedUsersQueryString = (query: IGetUsersListQuery) => {
  const queryParamsStr = getUrlSearchParamFromQueryData({
    skip: query.skip,
    take: query.take,
    sortBy: query.sortBy.toString(),
    sortDirection: query.sortDirection.toString(),
    searchValue: query.searchValue,
  });

  if (query.roleId.length > 0) {
    for (const roleId of query.roleId) {
      queryParamsStr.append('role', roleId);
    }
  }

  if (query.organisationId.length > 0) {
    for (const organisationId of query.organisationId) {
      queryParamsStr.append('organisation', `${organisationId}`);
    }
  }

  return `/users?${queryParamsStr.toString()}`;
};

const usersEndpoints = administrationApi.injectEndpoints({
  endpoints: builder => ({
    getPaginatedUsersRolesList: builder.query<
      IPaginationResponse<IUsersGridListItem>,
      IGetUsersListQuery
    >({
      providesTags: [QueryTags.Users],
      query: query => {
        return getPaginatedUsersQueryString(query);
      },
    }),
    getUserList: builder.query<IUserItem[], void>({
      providesTags: [QueryTags.Users],
      query: _ => '/user/list',
    }),
    getUserTeamsList: builder.query<IUserItem[], void>({
      providesTags: [QueryTags.Users],
      query: _ => '/user/teamlist',
    }),
    getUser: builder.query<IUser, string>({
      providesTags: [QueryTags.User],
      query: query => `/user/${query}`,
    }),
    addUser: builder.mutation<void, IAddEditUserForm>({
      invalidatesTags: [QueryTags.Users],
      query: payload => ({
        url: `/user`,
        method: 'POST',
        body: payload,
      }),
    }),
    editUser: builder.mutation<void, IAddEditUserForm>({
      invalidatesTags: [QueryTags.Users],
      query: payload => ({
        url: `/user/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    toggleUserBlock: builder.mutation({
      invalidatesTags: [QueryTags.User, QueryTags.Users],
      query: params => ({
        url: `/user/${params.id}/block`,
        method: 'PUT',
        body: params.payload,
      }),
    }),
    resetUserPassword: builder.mutation<void, string>({
      query: id => ({
        url: `/user/${id}/requestUpdatePassword`,
        method: 'POST',
      }),
    }),
    deleteUser: builder.mutation<void, string>({
      invalidatesTags: [QueryTags.Users],
      query: id => ({
        url: `/user/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPaginatedUsersRolesListQuery,
  useGetUserQuery,
  useGetUserListQuery,
  useGetUserTeamsListQuery,
  useLazyGetUserQuery,
  useAddUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useToggleUserBlockMutation,
  useResetUserPasswordMutation,
} = usersEndpoints;
