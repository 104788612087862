import { Grid } from '@mui/material';
import { Breadcrumbs } from 'common/components/breadCrumb/breadCrumb';
import { IBreadcrumbItem } from 'common/components/breadCrumb/breadCrumbBuilder';
import { MainContentContainer } from 'common/components/mainContentContainer';
import { CurrentUserTasksDataGrid } from 'pages/currentUserTasksPage';

const _crumbs: readonly IBreadcrumbItem[] = [
  { text: 'Tasks', url: undefined },
] as const;

export const CurrentUserTasksPage = () => {
  return (
    <MainContentContainer>
        <>
          <Breadcrumbs crumbs={_crumbs} />

          <Grid item xs={12}>
            <CurrentUserTasksDataGrid />
          </Grid>
        </>
    </MainContentContainer>
  );
};
