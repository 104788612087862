import { Box, Button, FormHelperText } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useAddPaymentRequestCostEvidenceCostReportDocumentMutation,
  useAddPaymentRequestCostEvidenceInvoiceDocumentMutation,
} from 'api/application/paymentRequestApi';
import { useUploadFileMutation } from 'api/file';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { IFileUpload } from 'common/types/File';
import { useLocalSnackbar } from 'hooks';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import {
  IAddPaymentRequestCostEvidenceCostReportDocument,
  IAddPaymentRequestCostEvidenceInvoiceDocument,
  IUploadDocumentDialogProps,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { setServerSideFormErrors } from 'util/formUtils';

export function UploadDocumentDialog({
  documentType,
  onConfirm,
  onCancel,
}: IUploadDocumentDialogProps) {
  const [uploadFile, uploadStatus] = useUploadFileMutation();
  const [addCostReportDocument] =
    useAddPaymentRequestCostEvidenceCostReportDocumentMutation();
  const [addInvoiceDocument] =
    useAddPaymentRequestCostEvidenceInvoiceDocumentMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();
  const isUploading = uploadStatus.isLoading;

  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  const form = useForm<IAddPaymentRequestCostEvidenceCostReportDocument>();
  const { handleSubmit, control, watch } = form;

  const onSubmit = async (formData: any) => {
    try {
      formData.applicationId = applicationId;
      formData.paymentRequestId = selectedItem?.id;
      formData =
        documentType === 'costReport'
          ? (formData as IAddPaymentRequestCostEvidenceCostReportDocument)
          : (formData as IAddPaymentRequestCostEvidenceInvoiceDocument);

      const apiCall =
        documentType === 'costReport'
          ? addCostReportDocument
          : addInvoiceDocument;
      await apiCall(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Successfully document uploaded`);
          onConfirm();
        })
        .catch(error => {
          if (error?.data?.generalError?.errorMessage) {
            createErrorSnackbar(error.data.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to upload document`);
    }
  };

  const handleOnFileDelete = async () => {
    form.setValue(`fileId`, '');
  };

  const handleOnFileUpload = async (file: File) => {
    const fileFormData = new FormData();
    fileFormData.append('file', file);

    try {
      let fileUpload: IFileUpload = {
        file: file,
        uploadSection:
          documentType === 'costReport'
            ? 'PaymentRequestCostReport'
            : 'PaymentRequestInvoice',
      };

      await uploadFile(fileUpload)
        .unwrap()
        .then(payload => {
          form.setValue(`fileId`, payload.id);
          createSuccessSnackbar(`File uploaded successfully`);
        })
        .catch(error => {
          createErrorSnackbar(error.data.generalError.errorMessage);
        });
    } catch (err) {
      createErrorSnackbar(`Failed to upload file`);
    }
  };
  const watchFileId = watch('fileId');

  return (
    <StyledDialog open fullWidth maxWidth="sm">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledDialogTitle>Upload a Document</StyledDialogTitle>
          <StyledDialogContent>
            <Box
              sx={{
                border: '2px dashed #ccc',
                borderRadius: '8px',
                textAlign: 'center',
                padding: '20px',
                cursor: 'pointer',
                background: '#f9f9f9',
              }}
            >
              <Controller
                control={control}
                rules={{ required: 'Document is required' }}
                name="fileId"
                render={fieldProps => {
                  return (
                    <>
                      <FileUploadInputSelect
                        isUploading={isUploading}
                        id="file"
                        accept=".pdf, .docx, .xlsx, .csv"
                        label={
                          documentType === 'costReport'
                            ? 'Click to upload or drag and drop (max 20MB)'
                            : 'Click to upload or drag and drop (max 100MB)'
                        }
                        inputName="fileId"
                        onFileUpload={file => handleOnFileUpload(file)}
                        onFileDelete={handleOnFileDelete}
                        uploaded={uploadStatus.isSuccess}
                      />

                      {fieldProps.fieldState.error ? (
                        <Box mt={-2} mb={3}>
                          <FormHelperText error>
                            {fieldProps.fieldState.error.message}
                          </FormHelperText>
                        </Box>
                      ) : null}
                    </>
                  );
                }}
              />
            </Box>
          </StyledDialogContent>
          <StyledDialogActions>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isUploading || !watchFileId}
            >
              Upload Document
            </Button>
          </StyledDialogActions>
        </form>
      </FormProvider>
    </StyledDialog>
  );
}
