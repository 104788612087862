import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import List from '@mui/material/List';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  LeftHandNavItem,
  NavItemConfig,
} from 'common/components/leftHandNav';
import { useCurrentUserPermissions } from 'hooks';
import { routePaths } from 'routing/IndexRoutes';
import { selectExpanded } from 'state/selectors/navigationBarSelectors';
import { UserPermissions } from 'types/UserPermissions';

export const LeftHandNav = () => {
  const navBarExpanded = useSelector(selectExpanded);
  const navigate = useNavigate();
  const { canUserSeeRoute } = useCanUserSeeRoute();

  return (
    <nav>
      <Drawer variant="permanent" open={navBarExpanded}>
        <List sx={{ paddingTop: '64px', zIndex: 99 }}>
          {leftNavItems.map(leftNavItem =>
            canUserSeeRoute(leftNavItem.permissions) ? (
              <LeftHandNavItem
                key={leftNavItem.name}
                leftNavItem={leftNavItem}
                navBarExpanded={navBarExpanded}
                navigate={navigate}
              />
            ) : null
          )}
        </List>
      </Drawer>
    </nav>
  );
};

const leftNavItems: readonly NavItemConfig[] = [
  {
    name: 'Home',
    iconDefinition: solid('house-chimney'),
    href: routePaths.home,
  },
  {
    name: 'Tasklist',
    iconDefinition: solid('list-check'),
    href: routePaths.adminTasks,
    permissions: ['tasklist.view-all'],
  },
  {
    name: 'Applications',
    iconDefinition: solid('building-circle-check'),
    href: routePaths.applications,
    permissions: ['applications.view'],
  },
  {
    name: 'PullInProcess',
    iconDefinition: solid('code-pull-request'),
    href: routePaths.pullInProcesses,
    permissions: ['pullinprocess.view'],
  },
  {
    name: 'Admin',
    iconDefinition: solid('user-gear'),
    href: `${routePaths.admin}/1`,
    permissions: ['admin.access'],
  },
] as const;

const useCanUserSeeRoute = () => {
  const { doesUserHaveSomeOfThesePermissions } = useCurrentUserPermissions();

  // Return true if user is allowed to see the route
  // Return false if user is not allowed to see the route
  const canUserSeeRoute = (
    permissions: readonly UserPermissions[] | undefined
  ) => {
    if (!permissions || permissions.length <= 0) {
      // There are no permissions set on the route
      // Therefore allow the user to see the route
      return true;
    }

    // There are some permissions set on the route
    // Check the user has at least one of them
    return doesUserHaveSomeOfThesePermissions(permissions);
  };

  return { canUserSeeRoute };
};
