import { useCurrentUserPermissions } from 'hooks';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import { PaymentRequestCostsSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestCostsSection';

export const PaymentRequestCosts = ({
  paymentRequestTitle = '',
  isFinalPayment,
  readOnly,
}: {
  paymentRequestTitle: string;
  isFinalPayment: boolean;
  readOnly: boolean;
}) => {
  const { selectedItem } = useApplicationLiveProjectContext();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();

  const hasLiveprojectPaymentsProcess = doesUserHaveSinglePermission(
    'liveproject.payments.process'
  );

  const hasLiveprojectFinalpaymentProcess = doesUserHaveSinglePermission(
    'liveproject.finalpayment.process'
  );

  return (
    <PaymentRequestCostsSection
      paymentRequestId={selectedItem?.id}
      paymentRequestTitle={paymentRequestTitle}
      isFinalPayment={isFinalPayment}
      readOnly={readOnly}
      hasProcessPermssion={
        (!isFinalPayment && hasLiveprojectPaymentsProcess) ||
        (isFinalPayment && hasLiveprojectFinalpaymentProcess)
      }
    />
  );
};
