import {
  EProjectPrepItemStatus,
  EProgressReportItemType,
  IGetProjectPrepListResponse,
  IProjectPrepOverview,
  IProjectPrepProgressReport,
} from 'pages/applicationPage/content/projectPrep/ProjectPrepTypes';
import { getGuid } from 'util/AppUtils';

export const projectPrepOverviewData: IProjectPrepOverview = {
  csspAssigned: true,
  openSupportRequests: 10,
  stageStartDate: '2023-07-08',
  totalSupportCost: 132423,
  totalSupportRequests: 20,
};

export const projectPrepListViewData: IGetProjectPrepListResponse = {
  results: [
    {
      id: getGuid(),
      status: EProjectPrepItemStatus.Rejected,
      title: 'Version 1.0',
      subTitle: '',
      type: EProgressReportItemType.WorkPackage,
      dueDate: '2023-09-27',
    },
    {
      id: getGuid(),
      status: EProjectPrepItemStatus.InProgress,
      title: 'Latest Progress Report',
      subTitle: 'August Report',
      type: EProgressReportItemType.MonthlyReport,
      dueDate: '2023-08-05',
      startDate: '2023-08-03',
      lastModifiedBy: 'John Haidt',
      lastModifiedDate: '2023-08-03',
    },
    {
      id: getGuid(),
      status: EProjectPrepItemStatus.InProgress,
      title: 'Works Package Deed',
      subTitle: 'Works package deed',
      type: EProgressReportItemType.WorkPackageDeed,
      dueDate: '2023-08-05',
      startDate: '2023-08-03',
      lastModifiedBy: 'Jeremy Springer',
      lastModifiedDate: '2023-08-03',
    },
    {
      id: getGuid(),
      status: EProjectPrepItemStatus.Complete,
      title: 'Previous Progress Report',
      subTitle: 'July Report',
      type: EProgressReportItemType.MonthlyReport,
      startDate: '2023-07-15',
      lastModifiedBy: 'John Haidt',
      lastModifiedDate: '2023-07-28',
      submittedDate: '2023-09-28',
      completedDate: '2023-09-29',
    },
  ],
};

export const progressReportData: IProjectPrepProgressReport = {
  dateDue: '2023-07-25',
  dateOpened: '2023-07-15',
  lastUpdated: '2023-07-23',
  completedDate: '2023-07-24',
  status: EProjectPrepItemStatus.Complete,
  sectionTitle: 'Primary Report',
  sectionSubTitle: 'August Report',
  questions: [
    {
      title:
        'Have you informed leaseholder of progress made on the application?',
      type: 'boolean',
      value: false,
    },
    {
      title: 'Have you appointed a lead designer?',
      type: 'boolean',
      value: false,
    },
    {
      title: `What is the reason you haven't appointed a lead designer?`,
      type: 'string',
      value: 'Example reason',
    },
    {
      title: 'Summarise your risks and blockers',
      type: 'string',
      value: 'Example risks and blockers',
    },
    {
      title: ' Forecast start on site date',
      type: 'string',
      value: '07/2023',
    },
    {
      title: `What are some files you have uploaded?`,
      type: 'file-array',
      value: [
        {
          fileName: 'this jazzy file.pdf',
          fileUri: 'https://path.to.file.pdf',
        },
        {
          fileName: 'this jazzy file2.pdf',
          fileUri: 'https://path.to.file.pdf',
        },
      ],
    },
    {
      title: `Example table`,
      type: 'table',
      value: {
        columnHeaders: ['cola', 'colb'],
        rows: [
          ['vala1', 'valb1'],
          ['vala2', 'valb2'],
          ['vala3', 'valb3'],
        ],
      },
    },
  ],
};
