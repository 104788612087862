import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCurrentUserPermissions } from 'hooks';
import { AdministrationTeamsFilters } from 'pages/administrationPage/components/administrationTeamsTab/components/AdministrationTeamsFilters';
import { AdministrationTeamsGrid } from 'pages/administrationPage/components/administrationTeamsTab/components/AdministrationTeamsGrid';

export function AdministrationTeamsTab() {

  const navigate = useNavigate();
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasViewPermission = doesUserHaveSinglePermission('admin.team-management.view');
  const hasEditPermission = doesUserHaveSinglePermission('admin.team.edit');

  const handleMenuItemClick = () => {
      navigate(`/admin/addEditTeam`);
  };

  return (
    <>
      <Grid container>
        {hasViewPermission && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            { hasEditPermission && 
              <Button
                variant="contained"
                onClick={handleMenuItemClick}
                startIcon={<FontAwesomeIcon icon={faAdd} />}
              >
                Add Team
              </Button> }
          </Grid>
        )}
        <Grid item xs={12}>
          <AdministrationTeamsFilters />
        </Grid>
        <Grid item xs={12}>
          <AdministrationTeamsGrid />
        </Grid>
      </Grid>
    </>
  );
}
