import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid } from '@mui/material';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { useCurrentUserPermissions } from 'hooks';
import { CostScheduleCSVButton } from 'pages/applicationPage/content/liveProject/common/CostSchedulingCSVButton';
import {
  useVariationReferrals,
  useVariationOverview,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';
import {
  DaviesUserButtonsProps,
  _formId,
  useCanFormBeApproved,
  useHomesEnglandReferralLogic,
  useRejectLogic,
  useSaveLogic,
  useThirdPartyReferralLogic,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/sections/VariationChecksSection/utils';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';

export const ActionButtons = ({
  handleSave,
  saveStatus,
}: DaviesUserButtonsProps) => {
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasLiveProjectVariationsProcess = doesUserHaveSinglePermission(
    'liveproject.variations.process'
  );
  const hasLiveprojectVariationsApprove = doesUserHaveSinglePermission(
    'liveproject.variations.approve'
  );
  const hasLiveprojectVariationsReject = doesUserHaveSinglePermission(
    'liveproject.variations.reject'
  );
  const hasLiveprojectVariationsApproveAbovethreshold =
    doesUserHaveSinglePermission(
      'liveproject.variations.approve.belowthreshold'
    );
  const hasApplicationsReferProcess = doesUserHaveSinglePermission(
    'applications.refer.process'
  );
  const hasApplicationsReferHe = doesUserHaveSinglePermission(
    'applications.refer.he'
  );

  const { isVariancePercentageGreaterThanThreshold } = useVariationOverview();
  const { hasOpenThirdPartyReferral } = useVariationReferrals();

  const canFormBeApproved = useCanFormBeApproved();
  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Variation,
  });
  const thirdPartyReferral = useThirdPartyReferralLogic();
  const homesEnglandReferral = useHomesEnglandReferralLogic();
  const saveLogic = useSaveLogic(handleSave);
  const rejectLogic = useRejectLogic();

  return (
    <Grid container spacing={1}>
      <Grid xs={12} item>
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          bgcolor="grey.100"
        >
          {/* REFER */}
          <Button
            variant="outlined"
            size="small"
            onClick={thirdPartyReferral.handleReferToThirdPartyClick}
            disabled={
              saveStatus.isLoading ||
              isOnHoldForThisType ||
              !hasApplicationsReferProcess
            }
          >
            Refer
          </Button>
          {thirdPartyReferral.thirdPartyReferralDrawer.renderReferDrawer()}

          {/* REFER TO HE */}
          {hasApplicationsReferHe && (
            <Button
              variant="outlined"
              size="small"
              onClick={homesEnglandReferral.handleReferToHeClick}
              disabled={
                saveStatus.isLoading ||
                isOnHoldForThisType ||
                !hasLiveProjectVariationsProcess
              }
            >
              Refer to HE
            </Button>
          )}

          {homesEnglandReferral.referToHeModal.renderReferToHomesEnglandModal({
            handleConfirmClick:
              homesEnglandReferral.handleReferToHeConfirmClick,
            referStatus: {
              isLoading: homesEnglandReferral.referToHeStatus.isLoading,
              isError: homesEnglandReferral.referToHeStatus.isError,
              error: homesEnglandReferral.referToHeStatus.error,
            },
          })}

          <CostScheduleCSVButton />

          {/* ON / OFF HOLD */}
          <OnOffHold
            holdType={EOnHoldType.Variation}
            isDisabled={!hasLiveProjectVariationsProcess}
          />

          <Box gap={2} display="flex">
            {/* REJECT */}
            <LoadingButton
              variant="outlined"
              size="small"
              onClick={rejectLogic.handleRejectClick}
              loading={saveStatus.isLoading}
              disabled={
                isOnHoldForThisType ||
                !hasApplicationsReferHe ||
                !hasLiveProjectVariationsProcess ||
                !hasLiveprojectVariationsReject
              }
            >
              Reject
            </LoadingButton>
            {rejectLogic.rejectModal.renderRejectModal()}

            {/* SAVE */}
            <LoadingButton
              variant="outlined"
              size="small"
              onClick={saveLogic.handleSaveClick}
              loading={saveStatus.isLoading}
              disabled={isOnHoldForThisType || !hasLiveProjectVariationsProcess}
            >
              Save
            </LoadingButton>

            {/* APPROVE VARIATION */}
            <LoadingButton
              type="submit"
              variant="contained"
              size="small"
              form={_formId}
              disabled={
                hasOpenThirdPartyReferral ||
                isOnHoldForThisType ||
                !canFormBeApproved ||         
                (!hasLiveprojectVariationsApprove &&
                  !hasLiveprojectVariationsApproveAbovethreshold) ||
                (isVariancePercentageGreaterThanThreshold &&
                  hasLiveprojectVariationsApprove &&
                  hasLiveprojectVariationsApproveAbovethreshold) ||
                (isVariancePercentageGreaterThanThreshold &&
                  !hasLiveprojectVariationsApprove &&
                  hasLiveprojectVariationsApproveAbovethreshold) ||
                (!isVariancePercentageGreaterThanThreshold &&
                  !hasLiveprojectVariationsApproveAbovethreshold &&
                  !hasLiveprojectVariationsApprove)
              }
              loading={saveStatus.isLoading}
            >
              Approve Variation
            </LoadingButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
