import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useGetApplicationGrantFundingCategoriesQuery } from 'api/application';
import {
  useGetAdditionalPaymentRecommendationDetailsQuery,
  useGetPaymentRecommendationDetailsQuery,
  useGetVendorAccountSetupSummaryQuery,
} from 'api/application/paymentApi';
import { useCurrentUserPermissions, useLocalSnackbar } from 'hooks';
import {
  ApplicationItemGroupNavigator,
  ColumnContainer,
  SectionProgress,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  EPaymentSection,
  EVendorAccountSetupStatus,
  PaymentsSectionOutlet,
} from 'pages/applicationPage/content/payments';
import {
  ApplicationPaymentsContextProvider,
  useApplicationPaymentsContext,
} from 'pages/applicationPage/content/payments/context';

export const Payments = () => {
  return (
    <ApplicationPaymentsContextProvider>
      <PaymentsMain />
    </ApplicationPaymentsContextProvider>
  );
};

const usePaymentCategories = () => {
  const { applicationId } = useApplicationContext();
  const { data: vendorAccountSummaryData } =
    useGetVendorAccountSetupSummaryQuery(applicationId);

  const { data: paymentRecommendationDetailsData } =
    useGetPaymentRecommendationDetailsQuery(applicationId);

  const { doesUserHaveSomeOfThesePermissions, doesUserHaveSinglePermission } =
    useCurrentUserPermissions();
  const hasPaymentPermission = doesUserHaveSinglePermission(
    'applications.payments'
  );

  return [
    {
      categoryTitle: 'Payments',
      detailItems: [
        {
          id: EPaymentSection.VendorSetup,
          sectionName: 'Vendor Set up Details',
          isComplete:
            vendorAccountSummaryData?.status ===
              EVendorAccountSetupStatus.Complete ?? false,
          isDisplayed: hasPaymentPermission,
          isRequired: true,
        },
        {
          id: EPaymentSection.RecommendPayment,
          sectionName: 'Recommend Payment',
          isComplete:
            paymentRecommendationDetailsData?.recommendationSent ?? false,
          isDisplayed:
            doesUserHaveSomeOfThesePermissions([
              'payment-recommend.view',
              'payment-recommend.edit',
            ]) && paymentRecommendationDetailsData?.ptfsSupportRequired,
          isRequired: paymentRecommendationDetailsData?.ptfsSupportRequired,
        },
        {
          id: EPaymentSection.ReleasePayment,
          sectionName: 'Release Payment',
          isComplete: paymentRecommendationDetailsData?.isApproved ?? false,
          isDisplayed:
            doesUserHaveSomeOfThesePermissions([
              'payment-release.view',
              'payment-release.edit',
            ]) && paymentRecommendationDetailsData?.ptfsSupportRequired,
          isRequired: paymentRecommendationDetailsData?.ptfsSupportRequired,
        },
      ],
    },
  ];
};

const useAdditionalPaymentCategories = () => {
  const { applicationId } = useApplicationContext();

  const { data: additionalPaymentRecommendationDetailsData } =
    useGetAdditionalPaymentRecommendationDetailsQuery(applicationId);

  const { doesUserHaveSomeOfThesePermissions } = useCurrentUserPermissions();

  return [
    {
      categoryTitle: 'Additional Payment',
      detailItems: [
        {
          id: EPaymentSection.AdditionalRecommendPayment,
          sectionName: 'Recommend Additional Payment',
          isComplete:
            additionalPaymentRecommendationDetailsData?.recommendationSent ??
            false,
          isDisplayed:
            doesUserHaveSomeOfThesePermissions([
              'payment-recommend.view',
              'payment-recommend.edit',
            ]) &&
            additionalPaymentRecommendationDetailsData?.ptfsSupportRequired,
          isRequired:
            additionalPaymentRecommendationDetailsData?.ptfsSupportRequired,
        },
        {
          id: EPaymentSection.AdditionalReleasePayment,
          sectionName: 'Release Additional Payment',
          isComplete:
            additionalPaymentRecommendationDetailsData?.isApproved ?? false,
          isDisplayed:
            doesUserHaveSomeOfThesePermissions([
              'payment-release.view',
              'payment-release.edit',
            ]) &&
            additionalPaymentRecommendationDetailsData?.ptfsSupportRequired,
          isRequired:
            additionalPaymentRecommendationDetailsData?.ptfsSupportRequired,
        },
      ],
    },
  ];
};

const useIsGfaComplete = () => {
  const { applicationId } = useApplicationContext();
  const { data: gfaCategoryData } =
    useGetApplicationGrantFundingCategoriesQuery(applicationId);
  const isGfaComplete =
    gfaCategoryData?.categories.every(m =>
      m.detailItems.every(di => di.isComplete)
    ) ?? false;
  return { isGfaComplete };
};

const useIsPaymentDetailItemEnabled = () => {
  const { isGfaComplete } = useIsGfaComplete();

  const isPaymentDetailItemEnabled = (
    detailItems: ReturnType<typeof usePaymentCategories>[number]['detailItems'],
    currentIndex: number
  ) => {
    // if we are handling the first detail item, then it's always enabled as there are no dependencies
    if (currentIndex === 0) {
      return true;
    }

    // If we are handling a subsequent item, check previous item is complete
    // If it's complete, this item is enabled
    // If it's not complete, this item is not enabled
    if (detailItems[currentIndex - 1].isComplete && isGfaComplete) {
      return true;
    }

    return false;
  };

  return { isPaymentDetailItemEnabled };
};

const useIsAdditionalPaymentDetailItemEnabled = () => {
  const isAdditionalPaymentDetailItemEnabled = (
    detailItems: ReturnType<
      typeof useAdditionalPaymentCategories
    >[number]['detailItems'],
    currentIndex: number
  ) => {
    // if we are handling the first detail item, then it's always enabled as there are no dependencies
    if (currentIndex === 0) {
      return true;
    }

    // If we are handling a subsequent item, check previous item is complete
    // If it's complete, this item is enabled
    // If it's not complete, this item is not enabled
    if (detailItems[currentIndex - 1].isComplete) {
      return true;
    }

    return false;
  };

  return { isAdditionalPaymentDetailItemEnabled };
};

const PaymentsMain = () => {
  const { isScheduleOfWorksSubmitted } = useApplicationContext();
  const { selectedItemId, setSelectedItemId } = useApplicationPaymentsContext();

  const paymentCategories = usePaymentCategories();
  const additionalPaymentCategories = useAdditionalPaymentCategories();

  const { isPaymentDetailItemEnabled } = useIsPaymentDetailItemEnabled();
  const { isAdditionalPaymentDetailItemEnabled } =
    useIsAdditionalPaymentDetailItemEnabled();

  const paymentStepsCompleted = paymentCategories.reduce<number>(
    (counter, category) => {
      return (
        counter + category.detailItems.filter(item => item.isComplete).length
      );
    },
    0
  );

  const paymentStepsRequired = paymentCategories.reduce<number>(
    (counter, category) => {
      return (
        counter + category.detailItems.filter(item => item.isRequired).length
      );
    },
    0
  );

  const additionalPaymentStepsCompleted =
    additionalPaymentCategories.reduce<number>((counter, category) => {
      return (
        counter + category.detailItems.filter(item => item.isComplete).length
      );
    }, 0);

  const additionalPaymentStepsRequired =
    additionalPaymentCategories.reduce<number>((counter, category) => {
      return (
        counter + category.detailItems.filter(item => item.isRequired).length
      );
    }, 0);

  const { isGfaComplete } = useIsGfaComplete();

  const isPaymentSectionComplete = paymentCategories
    .find(x => x.categoryTitle === 'Payments')
    ?.detailItems.find(x => x.sectionName === 'Release Payment')?.isComplete;

  const isAddtionalPaymentSectionComplete = additionalPaymentCategories
    .find(x => x.categoryTitle === 'Additional Payment')
    ?.detailItems.find(
      x => x.sectionName === 'Release Additional Payment'
    )?.isComplete;

  const { createInfoSnackbar } = useLocalSnackbar();

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={3.5}>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <SectionProgress
              totalSteps={paymentStepsRequired}
              currentStep={paymentStepsCompleted}
            />
          </ColumnContainer>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <Box p={2}>
              <ApplicationItemGroupNavigator
                itemGroups={paymentCategories.map((category, categoryIndex) => {
                  return {
                    title: category.categoryTitle,
                    isEnabled: true,
                    items: category.detailItems
                      .filter(detailItem => detailItem.isDisplayed)
                      .map((detailItem, detailIndex) => {
                        const sectionEnabled = isPaymentDetailItemEnabled(
                          category.detailItems,
                          detailIndex
                        );

                        return {
                          title: detailItem.sectionName,
                          isSelected: selectedItemId === detailItem.id,
                          isComplete: detailItem.isComplete,
                          onClick: () => {
                            if (sectionEnabled) {
                              setSelectedItemId(detailItem.id);
                            } else {
                              const isPreviousSectionComplete =
                                category.detailItems[detailIndex - 1]
                                  ?.isComplete;
                              const incompleteSectionNames = [
                                !isGfaComplete ? 'the Grant Funding Tab' : null,
                                !isPreviousSectionComplete
                                  ? 'the ' +
                                    (category.detailItems[detailIndex - 1]
                                      ?.sectionName ?? 'previous') +
                                    ' section'
                                  : null,
                              ]
                                .filter(Boolean)
                                .join(' and ');
                              createInfoSnackbar(
                                `You must complete the ${incompleteSectionNames} before you can enter this section`,
                                { preventDuplicate: true }
                              );
                            }
                          },
                        };
                      }),
                  };
                })}
              />
            </Box>
          </ColumnContainer>

          {isPaymentSectionComplete &&
            (!isScheduleOfWorksSubmitted ||
              isAddtionalPaymentSectionComplete) && (
              <>
                <ColumnContainer sx={{ marginBottom: '10px' }}>
                  <SectionProgress
                    totalSteps={additionalPaymentStepsRequired}
                    currentStep={additionalPaymentStepsCompleted}
                  />
                </ColumnContainer>
                <ColumnContainer sx={{ marginBottom: '10px' }}>
                  <Box p={2}>
                    <ApplicationItemGroupNavigator
                      itemGroups={additionalPaymentCategories.map(
                        (category, categoryIndex) => {
                          return {
                            title: category.categoryTitle,
                            isEnabled: true,
                            items: category.detailItems
                              .filter(detailItem => detailItem.isDisplayed)
                              .map((detailItem, detailIndex) => {
                                const sectionEnabled =
                                  isAdditionalPaymentDetailItemEnabled(
                                    category.detailItems,
                                    detailIndex
                                  );

                                return {
                                  title: detailItem.sectionName,
                                  isSelected: selectedItemId === detailItem.id,
                                  isComplete: detailItem.isComplete,
                                  onClick: () => {
                                    if (sectionEnabled) {
                                      setSelectedItemId(detailItem.id);
                                    } else {
                                      const isPreviousSectionComplete =
                                        category.detailItems[detailIndex - 1]
                                          ?.isComplete;
                                      const incompleteSectionNames = [
                                        !isPreviousSectionComplete
                                          ? 'the ' +
                                            (category.detailItems[
                                              detailIndex - 1
                                            ]?.sectionName ?? 'previous') +
                                            ' section'
                                          : null,
                                      ]
                                        .filter(Boolean)
                                        .join(' and ');
                                      createInfoSnackbar(
                                        `You must complete the ${incompleteSectionNames} before you can enter this section`,
                                        { preventDuplicate: true }
                                      );
                                    }
                                  },
                                };
                              }),
                          };
                        }
                      )}
                    />
                  </Box>
                </ColumnContainer>
              </>
            )}
        </Grid>
        <Grid item xs={8.5}>
          <ColumnContainer>
            <PaymentsSectionOutlet />
          </ColumnContainer>
        </Grid>
      </Grid>
    </>
  );
};
