import { Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetScheduleOfWorksOverviewQuery } from 'api/application/scheduleOfWorksApi';
import { useCurrentUserPermissions } from 'hooks';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import { PaymentRequestCostsSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection';

export const LatestPaymentRequestSection = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();

  const hasLiveprojectScheduleofworksProcess = doesUserHaveSinglePermission(
    'liveproject.scheduleofworks.process'
  );

  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        scheduleOfWorksId: selectedItem.id,
      }
    : skipToken;

  const { data } = useGetScheduleOfWorksOverviewQuery(request);

  if (!data?.latestPaymentRequestId || data?.latestPaymentRequestId === "00000000-0000-0000-0000-000000000000") {
    return (
      <Typography variant="h2" color={'grey.700'}>
        No payment requests have been made
      </Typography>
    );
  }

  return (
    <PaymentRequestCostsSection
      paymentRequestId={data?.latestPaymentRequestId}
      paymentRequestTitle={'Schedule Of Works'}
      isFinalPayment={data?.isFinalPayment ? data?.isFinalPayment : false}
      readOnly={true}
      hasProcessPermssion={hasLiveprojectScheduleofworksProcess}
    />
  );
};
