import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { z, ZodType } from 'zod';
import {
  useGetRagRatingsQuery,
  useUpdatePullInRagRatingMutation,
} from 'api/pullInProcess';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IPullInRagRating } from 'types/pullInProcess/pullInProcessTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IPullInRagRating) =>
  nameof<IPullInRagRating>(fieldName);

interface IPullInRagRatingModalProps {
  onClose: () => void;
}

export function PullInRagRatingModal({ onClose }: IPullInRagRatingModalProps) {
  return (
    <Dialog open fullWidth>
      <DialogTitle>RAG Rating</DialogTitle>
      <DialogContent>
        <PullInRagRatingModalForm onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
}

interface IPullInRagRatingModalFormProps {
  onClose: () => void;
}

export const PullInRagRatingModalForm = ({
  onClose,
}: IPullInRagRatingModalFormProps) => {
  const { pullInProcessId } = usePullInProcessContext();

  const { data: ragRatings, isLoading: ragRatingsLoading } =
    useGetRagRatingsQuery();

  const [updatePullInRagRating, updatePullInRagRatingResult] =
    useUpdatePullInRagRatingMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IPullInRagRating>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zRagRatingSchema),
  });
  const { handleSubmit, control, reset } = form;

  const onSubmit = async (formData: IPullInRagRating) => {
    try {
      await updatePullInRagRating({
        buildingId: pullInProcessId,
        ratingId: formData.ratingId ?? null,
        comment: formData.comment,
      })
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`RAG rating updated successfully`);
          onClose();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit RAG rating category`);
    }
  };

  return ragRatingsLoading ? (
    <Loading isOpen />
  ) : (
    <form
      noValidate
      onSubmit={event => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
      style={{ width: '100%' }}
    >
      <DialogContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" color={'grey.700'}>
              RAG Rating
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="ratingId"
              defaultValue={null}
              render={({
                field: { onChange, value, ...fieldRest },
                fieldState,
              }) => (
                <Autocomplete
                  options={[{ id: null, name: '' }, ...(ragRatings ?? [])]}
                  getOptionLabel={option => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  value={ragRatings?.find(r => r.id === value) || null}
                  onChange={(_, newValue) => {
                    onChange(newValue ? newValue.id : null);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      {...fieldRest}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={getName('comment')}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  required
                  label="Comments"
                  helperText={fieldState.error?.message}
                  multiline
                  autoComplete="off"
                  inputProps={{ maxLength: 1000 }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <StyledDrawerActions>
        <Grid container justifyContent="flex-end" gap={1}>
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={updatePullInRagRatingResult.isLoading}
          >
            Update RAG Rating
          </LoadingButton>
        </Grid>
      </StyledDrawerActions>
    </form>
  );
};

export const zRagRatingSchema: ZodType<
  Pick<IPullInRagRating, 'ratingId' | 'comment'>
> = z.object({
  ratingId: z.number().nullable(),
  comment: z
    .string()
    .nonempty('Comments is required')
    .max(1000, '1000 characters is the maximum allowed.'),
});
