import { useCallback } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { GridSearchField } from 'common/components/gridSearchField';
import { useAppDispatch } from 'state';
import { selectAdministrationTeamsGridState, selectIsAdministrationTeamsGridLoading } from 'state/selectors/administration/administrationTeamsSelectors';
import { gridStateChanged } from 'state/slices/administration/administrationTeamsReducer';
import {
  StyledFilter,
  StyledFiltersGridLeft,
} from 'styles/globalStyles/filterStyles';

export function AdministrationTeamsFilters() {
  const dispatch = useAppDispatch();

  const administrationTeamsGridState = useSelector(
    selectAdministrationTeamsGridState
  );

  const isAdministrationTeamsGridLoading = useSelector(
    selectIsAdministrationTeamsGridLoading
  );

  const handleSearchCallback = useCallback(
    (searchValue: string) => {
      dispatch(
        gridStateChanged({
          searchValue,
          skip: 0,
        })
      );
    },
    [dispatch]
  );

  return (
    <Grid container pt={4}>
      <StyledFiltersGridLeft item xs={8}>
        <StyledFilter>
          <GridSearchField
            isLoading={isAdministrationTeamsGridLoading}
            defaultValue={administrationTeamsGridState.searchValue}
            handleSearch={handleSearchCallback}
          />
        </StyledFilter>
      </StyledFiltersGridLeft>
    </Grid>
  );
}
