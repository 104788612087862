import { Box, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetScheduleOfWorksOverviewQuery } from 'api/application/scheduleOfWorksApi';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { ColumnContainer } from 'pages/applicationPage/common/components';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import { ScheduleOfWorksKpiSection } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/common/ScheduleOfWorksKpiSection';
import { KeyDatesCard } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/ScheduleOfWorksOverviewSection/KeyDatesCard';
import { WorksContractCard } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/ScheduleOfWorksOverviewSection/WorksContractCard';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';

export const ScheduleOfWorksOverviewSection = () => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        scheduleOfWorksId: selectedItem.id,
      }
    : skipToken;

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.ScheduleOfWorks,
  });

  const { data } = useGetScheduleOfWorksOverviewQuery(request);

  return (
    <>
      <Box>{ isOnHoldForThisType && <OnHoldBanner holdType={EOnHoldType.ScheduleOfWorks} /> }</Box>
      <ScheduleOfWorksKpiSection />
      <ColumnContainer
        sx={{
          padding: 0,
          border: '1px solid lightgray',
          borderRadius: 2,
          mt: 4,
        }}
      >
        <Box sx={{ padding: 2, borderBottom: '1px solid lightgray' }}>
          <Typography variant="h3" fontWeight={800} fontSize="1.3em">
            Overview
          </Typography>
        </Box>
        <Box sx={{ padding: 3 }}>
          <Box
            mb={2}
            sx={{
              width: '100%',
              padding: 2,
              paddingTop: 3,
              borderRadius: 1,
            }}
          >
            <Box>
              <Box sx={{ display: 'flex' }}>
                {data?.endDate && data.startDate && (
                  <KeyDatesCard
                    data={{
                      startDate: data.startDate,
                      endDate: data.endDate,
                    }}
                    projectDuration={data.projectDurationInMonths}
                    title="Project dates"
                  />
                )}
                <WorksContractCard
                  data={data?.worksContractDocuments}
                  title="Works contract"
                />
               
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: 'flex' }}>
                <WorksContractCard
                  data={data?.buildingControlDocuments}
                  title="Building control approval"
                />
              <WorksContractCard
                  data={data?.leaseholderAgreementDocuments}
                  title="Leaseholder agreement"
                />

                </Box>
              </Box>
          </Box>
        </Box>
      </ColumnContainer>
    </>
  );
};
