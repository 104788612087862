import { useState } from 'react';
import {
  faPhone,
  faEnvelope,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useCreateSupportTicketMutation } from 'api/application/supportApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect';
import { useCurrentUserPermissions, useFileUpload, useLocalSnackbar } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  ESupportTicketContactMethod,
  ESupportTicketType,
} from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { AddSupportTicketApplicationSummary } from 'pages/applicationPage/content/support/sections';
import {
  useReferSupportRequestModal,
  useResolveSupportRequestModal,
} from 'pages/applicationPage/content/support/sections/SupportTicketSection/common';
import {
  StyledLargeDrawer,
  StyledDrawerActions,
} from 'styles/globalStyles/largeDrawer';

export interface IAddSupportRequest {
  id?: string | null;
  description: string;
  contactMethod: ESupportTicketContactMethod;
  doesApplicantHaveCapacityGap: boolean;
  evidenceFileId: string;
}

interface IAddSupportTicketDrawerProps {
  onClose: () => void;
}

const _formId = 'support-request-form';

export function AddSupportTicketDrawer({
  onClose,
}: IAddSupportTicketDrawerProps) {
  const { applicationId } = useApplicationContext();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();
  const form = useForm<IAddSupportRequest>();
  const { handleSubmit, reset, getValues, watch, control, trigger } = form;
  const [createSupportRequest, createSupportRequestStatus] =
    useCreateSupportTicketMutation();
  const [evidenceFileName, setEvidenceFileName] = useState('');
  const [evidenceFileInvalid, setEvidenceFileInvalid] = useState(false);
  const { showReferModal, renderReferModal } = useReferSupportRequestModal();
  const { showResolveModal, renderResolveModal } =
    useResolveSupportRequestModal();

    const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
      const hasSupportReferralsRefer = doesUserHaveSinglePermission(
        'support.referrals.refer'
      );

  const showInvalidSnackbar = () =>
    createErrorSnackbar('Please complete all required fields and try again', {
      preventDuplicate: true,
    });

  const isFormValid = async () => {
    const isValid = await trigger();

    if (!isValid) {
      return false;
    }

    const formData = getValues();
    if (
      formData.contactMethod === ESupportTicketContactMethod.Email &&
      !formData.evidenceFileId
    ) {
      setEvidenceFileInvalid(true);
      return false;
    }

    return true;
  };

  const getFormData = () => {
    const formData = getValues();
    const data = {
      ...formData,
      supportTicketType: ESupportTicketType.ProgressReport,
      applicationId,
    };
    return data;
  };

  const saveForm = async () => {
    if (!(await isFormValid())) {
      return false;
    }

    const data = getFormData();
    await createSupportRequest(data)
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Support request saved');
        onClose();
      });
  };

  const supportType = watch('contactMethod');
  const evidenceFileId = watch('evidenceFileId');

  const handleOnDeleteFileUpload = async () => {
    form.setValue('evidenceFileId', '');
    setEvidenceFileName('');
  };

  const { uploadFile, uploadFileStatus } = useFileUpload();

  const handleOnFileUpload = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'SupportTicket',
      onSuccess: payload => {
        form.setValue('evidenceFileId', payload.id);
        setEvidenceFileName(payload.name);
      },
    });
  };

  const handleResolveClick = async () => {
    if (!(await isFormValid())) {
      showInvalidSnackbar();
      return;
    }

    showResolveModal({
      config: {
        type: 'newTicket',
        newTicketData: getFormData(),
        onSuccess: () => {
          reset();
          onClose();
        },
      },
    });
  };

  const handleReferClick = async () => {
    if (!(await isFormValid())) {
      showInvalidSnackbar();
      return;
    }

    showReferModal({
      config: {
        type: 'newTicket',
        newTicketData: getFormData(),
        onSuccess: () => {
          reset();
          onClose();
        },
      },
    });
  };

  return (
    <>
      <StyledLargeDrawer
        anchor="right"
        open
        onClose={() => {
          reset();
          onClose();
        }}
      >
        <Box pb={0}>
          <FormProvider {...form}>
            <form
              id={_formId}
              onSubmit={handleSubmit(saveForm)}
              style={{ width: '100%' }}
            >
              <DialogContent>
                <Grid container columnSpacing={3}>
                  <Grid item xs={6} gap={1}>
                    <Typography
                      variant="h3"
                      marginBottom={2}
                      padding={2}
                      fontWeight={900}
                      fontSize={'1.2em'}
                    >
                      Applicant requested support
                    </Typography>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          backgroundColor: 'grey.50',
                          padding: 3,
                          borderRadius: '5px',
                          marginBottom: 2,
                        }}
                      >
                        <Typography
                          variant="h3"
                          marginBottom={3}
                          fontWeight={600}
                          fontSize="0.9em"
                        >
                          How did you contact the applicant?
                        </Typography>
                        <Controller
                          control={control}
                          rules={{ required: 'Required' }}
                          name={'contactMethod'}
                          render={({
                            fieldState,
                            field: { onChange, ...fieldProps },
                          }) => (
                            <FormControl>
                              <ToggleButtonGroup
                                {...fieldProps}
                                onChange={(_, val) => {
                                  if (val !== null) {
                                    onChange(val);
                                  }
                                }}
                                exclusive
                              >
                                <ToggleButton
                                  value={ESupportTicketContactMethod.Phone}
                                >
                                  <FontAwesomeIcon
                                    icon={faPhone}
                                    style={{ marginRight: '10px' }}
                                  />
                                  Phone
                                </ToggleButton>
                                <ToggleButton
                                  value={ESupportTicketContactMethod.Email}
                                >
                                  <FontAwesomeIcon
                                    icon={faEnvelope}
                                    style={{ marginRight: '10px' }}
                                  />
                                  Email
                                </ToggleButton>
                              </ToggleButtonGroup>
                              {fieldState.error?.message ? (
                                <FormHelperText error>
                                  {fieldState.error?.message}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          )}
                        />
                      </Box>
                    </Grid>

                    {!!supportType ? (
                      <>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              backgroundColor: 'grey.50',
                              padding: 3,
                              borderRadius: '5px',
                              marginBottom: 2,
                            }}
                          >
                            <Typography
                              variant="h3"
                              marginBottom={3}
                              fontWeight={600}
                              fontSize="0.9em"
                            >
                              Does the applicant have a knowledge gap, resource
                              issue or inexperience?
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              rules={{
                                validate: value =>
                                  value === true ||
                                  value === false ||
                                  'Required',
                              }}
                              name={'doesApplicantHaveCapacityGap'}
                              render={({
                                fieldState,
                                field: { onChange, ...fieldProps },
                              }) => (
                                <FormControl>
                                  <ToggleButtonGroup
                                    {...fieldProps}
                                    onChange={(_, val) => {
                                      if (val !== null) {
                                        onChange(val);
                                      }
                                    }}
                                    exclusive
                                  >
                                    <ToggleButton value={true}>
                                      Yes
                                    </ToggleButton>
                                    <ToggleButton value={false}>
                                      No
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                  {fieldState.error?.message ? (
                                    <FormHelperText error>
                                      {fieldState.error?.message}
                                    </FormHelperText>
                                  ) : null}
                                </FormControl>
                              )}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Box
                            sx={{
                              backgroundColor: 'grey.50',
                              padding: 3,
                              borderRadius: '5px',
                              marginBottom: 2,
                            }}
                          >
                            <Typography
                              variant="h3"
                              marginBottom={3}
                              fontWeight={600}
                              fontSize="0.9em"
                            >
                              Provide futher explanation
                            </Typography>
                            <Controller
                              control={control}
                              name={'description'}
                              rules={{ required: 'Required' }}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  multiline
                                  fullWidth
                                  error={!!fieldState.error}
                                  rows={3}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          </Box>
                        </Grid>

                        {supportType === ESupportTicketContactMethod.Email ? (
                          <Grid item mb={2}>
                            <Typography
                              variant="h3"
                              fontWeight={600}
                              fontSize="1em"
                            >
                              Upload evidence of communication
                            </Typography>
                            {!!evidenceFileId ? (
                              <Box display="flex" flexDirection="row">
                                <ApplicationFileDownloadWrapper
                                  fileName={evidenceFileName ?? 'Download'}
                                  fileId={evidenceFileId}
                                />

                                <IconButton
                                  size="small"
                                  onClick={handleOnDeleteFileUpload}
                                  aria-label="Delete File"
                                  name="Delete File"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </IconButton>
                              </Box>
                            ) : (
                              <>
                                <FileUploadInputSelect
                                  id="supportEvidenceFile"
                                  accept=".pdf"
                                  inputName="supportEvidenceFileId"
                                  onFileUpload={file =>
                                    handleOnFileUpload(file)
                                  }
                                  onFileDelete={() =>
                                    handleOnDeleteFileUpload()
                                  }
                                  isUploading={uploadFileStatus.isLoading}
                                  uploaded={uploadFileStatus.isSuccess}
                                />
                                {evidenceFileInvalid && (
                                  <FormHelperText error>
                                    Evidence file is required.
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          </Grid>
                        ) : null}
                      </>
                    ) : null}

                    <AlertForQueryErrorOrNull
                      isError={createSupportRequestStatus.isError}
                      error={createSupportRequestStatus.error}
                    />
                  </Grid>
                  <Grid item xs={6} gap={1}>
                    <AddSupportTicketApplicationSummary onClose={onClose} />
                  </Grid>
                </Grid>
              </DialogContent>
              <StyledDrawerActions></StyledDrawerActions>
            </form>
          </FormProvider>
          <Grid container>
            <Grid xs={6} item>
              <Box
                display="flex"
                justifyContent="flex-start"
                p={3}
                bgcolor="grey.100"
              >
                <Box gap={2} display="flex">
                  <Button
                    disabled={
                      !supportType ||
                      uploadFileStatus.isLoading ||
                      createSupportRequestStatus.isLoading ||
                      !hasSupportReferralsRefer
                    }
                    sx={{ color: 'black' }}
                    onClick={() => {
                      handleResolveClick();
                    }}
                    variant="outlined"
                  >
                    Resolve support
                  </Button>

                  <Button
                    disabled={
                      !supportType ||
                      uploadFileStatus.isLoading ||
                      createSupportRequestStatus.isLoading ||
                      !hasSupportReferralsRefer
                    }
                    sx={{ color: 'black' }}
                    variant="outlined"
                    type="submit"
                    onClick={() => {
                      handleReferClick();
                    }}
                  >
                    Refer request
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid xs={6} item>
              <Box
                display="flex"
                justifyContent="flex-end"
                p={3}
                bgcolor="grey.100"
              >
                <Box gap={2} display="flex">
                  <LoadingButton
                    loading={createSupportRequestStatus.isLoading}
                    disabled={!supportType || uploadFileStatus.isLoading || !hasSupportReferralsRefer}
                    type="submit"
                    form={_formId}
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </StyledLargeDrawer>
      {renderReferModal()}
      {renderResolveModal()}
    </>
  );
}
