import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Chip
} from '@mui/material';
import { SortDirection } from '@mui/material/TableCell';
import { useSelector } from 'react-redux';
import { useGetPaginatedOrgsListQuery } from 'api/administration/organisations';
import { Loading } from 'common/components/loading';
import { NoResultsFoundText } from 'common/components/noResultsfoundText/NoResultsFoundText';
import { ESortDirection } from 'enums/ESortDirection';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { AddEditOrgDrawer } from 'pages/administrationPage/components/administrationOrgsTab/components/addEditOrgDrawer/AddEditOrgDrawer';
import { DeleteOrganisationDialog } from 'pages/administrationPage/components/administrationOrgsTab/components/deleteOrgDialog/DeleteOrgDialog';
import { EAdminOrgsMenuType } from 'pages/administrationPage/components/administrationOrgsTab/types/EAdminOrgsMenuType';
import { useAppDispatch } from 'state';
import {
  selectAdministrationOrgsGridState,
  selectIsAdministrationOrgsGridLoading,
} from 'state/selectors/administration/administrationOrgsSelectors';
import { gridStateChanged } from 'state/slices/administration/administrationOrganisationsReducer';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { IOrgsGridListItem } from 'types/administration/AdministrationOrganisationTypes';

export function AdministrationOrgsGrid() {
  const dispatch = useAppDispatch();
  const administrationOrgsGridState = useSelector(
    selectAdministrationOrgsGridState
  );
  const orgsListQuery = useGetPaginatedOrgsListQuery(
    administrationOrgsGridState
  );

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const userHasEditPermission = doesUserHaveSinglePermission(
    'admin.organisation.edit'
  );
  const userHasDeletePermission = doesUserHaveSinglePermission(
    'admin.organisation.delete'
  );
  const userCanEditOrDelete = userHasEditPermission || userHasDeletePermission;

  const { skip, take, sortBy, sortDirection } = administrationOrgsGridState;
  const isAdministrationOrgsGridLoading = useSelector(
    selectIsAdministrationOrgsGridLoading
  );

  const {
    isShowing: isAddEditOrgDrawerShowing,
    showModal: showAddEditOrgDrawer,
    hideModal: hideAddEditOrgDrawer,
  } = useModalState();

  const {
    isShowing: isDeleteOrgDialogShowing,
    showModal: showDeleteOrgDialog,
    hideModal: hideDeleteOrgDialog,
  } = useModalState();

  const [selectedRowOrgId, setSelectedRowOrgId] = useState<string | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const noResultsFound =
    orgsListQuery.data?.count === 0 && !!orgsListQuery.originalArgs?.searchValue;

  const handleClose = (menuType: EAdminOrgsMenuType) => {
    switch (menuType) {
      case EAdminOrgsMenuType.ViewEditUser:
        showAddEditOrgDrawer(true);
        break;
      case EAdminOrgsMenuType.DeleteUser:
        showDeleteOrgDialog(true);
        break;
    }

    setAnchorEl(null);
  };

  const onEllipsisClick = (
    rowId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedRowOrgId(rowId);
    setAnchorEl(event.currentTarget);
  };

  const currentPage = skip / take;

  const sortHandler = (property: keyof IOrgsGridListItem): void => {
    dispatch(
      gridStateChanged({
        sortBy: property,
        sortDirection:
          sortDirection === ESortDirection.Asc
            ? ESortDirection.Desc
            : ESortDirection.Asc,
      })
    );
  };

  const getAriaSortDirection = (
    property: keyof IOrgsGridListItem,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getCurrentSortDirection = (
    property: keyof IOrgsGridListItem,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderTableCell = (
    property: keyof IOrgsGridListItem,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          active={sortBy === property}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => sortHandler(property)}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
    );
  };

  return (
    <>
      <Loading isOpen={isAdministrationOrgsGridLoading} />
      <NoResultsFoundText show={noResultsFound} />
      <StyledGenericTable data-testid="Administration-Organisations-Table">
        <TableHead>
          <TableRow>
            {getHeaderTableCell('name', 'Organisation')}
            {getHeaderTableCell('teams', 'Team view')}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {orgsListQuery.data?.results?.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>
                {
                  row.teams.map(t => 
                    <Chip key={t.id} size="small" sx={{ mx: 1, my: 1 }} label={t.name} />
                    )
                
                }
              </TableCell>

              <TableCell align="right">
                {userCanEditOrDelete && (
                  <>
                    <IconButton
                      aria-label="edit"
                      name="edit"
                      onClick={event => onEllipsisClick(row.id, event)}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                    {selectedRowOrgId === row.id ? (
                      <Menu
                        id={`admin-user-menu-${row.id}`}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        { userHasEditPermission && 
                          <MenuItem
                            onClick={() =>
                              handleClose(EAdminOrgsMenuType.ViewEditUser)
                            }
                          >
                            View/Edit Organisation
                          </MenuItem>
                        }
                        
                        { userHasDeletePermission && 
                        <MenuItem
                          onClick={() =>
                            handleClose(EAdminOrgsMenuType.DeleteUser)
                          }
                        >
                          Delete Organisation
                        </MenuItem>
                        }
                      </Menu>
                    ) : null}
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={orgsListQuery.data?.count || 0}
              rowsPerPage={take}
              rowsPerPageOptions={[take]}
              page={currentPage}
              onPageChange={(e, newPage) => {
                const newSkip = newPage * take;
                dispatch(gridStateChanged({ skip: newSkip }));
              }}
              onRowsPerPageChange={e => {
                dispatch(
                  gridStateChanged({
                    take: +e.target.value,
                  })
                );
              }}
            />
          </TableRow>
        </TableFooter>
      </StyledGenericTable>

      {isAddEditOrgDrawerShowing && selectedRowOrgId && (
        <AddEditOrgDrawer
          organisationId={+selectedRowOrgId}
          onSuccess={() => {
            setSelectedRowOrgId(null);
            hideAddEditOrgDrawer();
          }}
          onClose={() => {
            setSelectedRowOrgId(null);
            hideAddEditOrgDrawer();
          }}
        />
      )}

      <DeleteOrganisationDialog
        isShowing={isDeleteOrgDialogShowing}
        selectedId={selectedRowOrgId}
        onClose={() => {
          setSelectedRowOrgId(null);
          hideDeleteOrgDialog();
          orgsListQuery.refetch();
        }}
        onCancel={() => {
          setSelectedRowOrgId(null);
          hideDeleteOrgDialog();
        }}
      />


    </>
  );
}
