import { useEffect, useMemo, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAddTeamMutation,
  useEditTeamMutation,
  useLazyGetTeamQuery,
  useLazyGetTeamRulesQuery,
} from 'api/administration/teams';
import { useGetCostCentreListQuery } from 'api/application';
import { Breadcrumbs } from 'common/components/breadCrumb/breadCrumb';
import { IBreadcrumbItem } from 'common/components/breadCrumb/breadCrumbBuilder';
import { Loading } from 'common/components/loading';
import { MainContentContainer } from 'common/components/mainContentContainer';
import { useLocalSnackbar } from 'hooks';
import { CustomToggle } from 'pages/administrationPage/components/administrationTeamsTab/components/CustomToggle';
import { EAdministrationTabType } from 'pages/administrationPage/types/EAdministrationTabType';
import { ITeamDetails } from 'types/administration/AdministrationTeamsTypes';
import { extractErrorMessages } from 'util/ApiUtils';
import { nameof } from 'util/formUtils';

export function AddEditTeamPage() {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const { createErrorSnackbar, createSuccessSnackbar } = useLocalSnackbar();

  const selectLocalAuthorityHelpText = 'Please select at least one Local Authority';

  // required calls
  const [
    getTeam,
    {
      isError: existingIsError,
      error: existingError,
      data: existingData,
      isLoading: existingIsLoading,
    },
  ] = useLazyGetTeamQuery();
  const [
    getRules,
    {
      data: rulesData,
      isLoading: rulesIsLoading,
      isError: rulesIsError,
      error: rulesError,
    },
  ] = useLazyGetTeamRulesQuery();
  const {
    data: ccData,
    isError: ccIsError,
    error: ccError,
    isLoading: ccIsLoading,
  } = useGetCostCentreListQuery();
  const [addTeamPage, { isLoading: addTeamIsLoading }] = useAddTeamMutation();
  const [editTeamPage, { isLoading: editTeamIsLoading }] = useEditTeamMutation();

  

  // if is Edit then retrieve data
  useEffect(() => {
    if (teamId) {
      getTeam(teamId);
    }
  }, [getTeam, teamId]);

  
  // if not Edit then setup Add
  useEffect(() => {
    if (!teamId) {
      getRules();
    }
  }, [teamId, getRules]);

  const defaultData = useMemo<ITeamDetails>(
    () => ({
      teamId: 0,
      name: '',
      containsAllLocalAuthoritesCostCentres: false,
      canTasksBeAssignedToThisTeam: false,
      localAuthoritiesCostCentres: [],
      rules: [],
    }),
    []
  );
  const [data, setData] = useState<ITeamDetails>(defaultData);

  const form = useForm<ITeamDetails>({
    defaultValues: defaultData,
    mode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isDirty, isValid },
  } = form;
  const showLocalAuthorities = watch('containsAllLocalAuthoritesCostCentres');

  // once we have existing data reset form data
  useEffect(() => {
    if (teamId && existingData && ccData) {
      setData(existingData);
      reset(existingData);
    }
  }, [existingData, ccData, reset, teamId]);

  //no existing data then reset form data
  useEffect(() => {
    if (!teamId && rulesData && ccData) {
      const newTeamDetails = {
        ...defaultData,
        rules: rulesData.map(rule => ({ ...rule, granted: false })),
      };
      setData(newTeamDetails);
      reset(newTeamDetails);
    }
  }, [rulesData, ccData, reset, teamId, defaultData]);

  const onSubmit = (formData: ITeamDetails) => {
    if (teamId) {
      handleEditTeam(formData);
    } else {
      handleAddTeam(formData);
    }
  };

  const handleAddTeam = (formData: ITeamDetails) => {
    addTeamPage(formData)
      .unwrap()
      .then(_ => {
        createSuccessSnackbar('Successfully added new team');
        navigate('/admin/' + EAdministrationTabType.Teams);
      })
      .catch(error => {
        createErrorSnackbar(extractErrorMessages(error));
      });
  };

  const handleEditTeam = (formData: ITeamDetails) => {
    editTeamPage(formData)
      .unwrap()
      .then(_ => {
        createSuccessSnackbar('Successfully edited team');
        navigate('/admin/' + EAdministrationTabType.Teams);
      })
      .catch(error => {
        createErrorSnackbar(extractErrorMessages(error));
      });
  };

  // error messages
  useEffect(() => {
    if (existingIsError && existingError)
      createErrorSnackbar('An error occurred while fetching Team data.');
  }, [existingIsError, existingError, createErrorSnackbar]);

  useEffect(() => {
    if (ccIsError && ccError)
      createErrorSnackbar('An error occurred while fetching Cost Centre data.');
  }, [ccIsError, ccError, createErrorSnackbar]);

  useEffect(() => {
    if (rulesIsError && rulesError)
      createErrorSnackbar('An error occurred while fetching rules.');
  }, [createErrorSnackbar, rulesIsError, rulesError]);

  const getName = (fieldName: keyof ITeamDetails) =>
    nameof<ITeamDetails>(fieldName);

  return (
    <MainContentContainer>
      <Breadcrumbs
        crumbs={
          [
            { text: 'Admin', url: '/admin' },
            {
              text: teamId ? 'Edit Team' : 'Add Team',
              url: undefined,
            },
          ] as IBreadcrumbItem[]
        }
      />

      <Grid container justifyContent="flex-start" alignItems="center">
        <Grid item>
          <IconButton
            sx={{ marginLeft: 0 }}
            aria-label="Back"
            name="Back"
            color="primary"
            onClick={() => {
              navigate('/admin/' + EAdministrationTabType.Teams);
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h1"> {teamId ? 'Edit' : 'Add'} Team</Typography>
        </Grid>
      </Grid>
      {(existingIsLoading ||
        rulesIsLoading ||
        ccIsLoading ||
        addTeamIsLoading ||
        editTeamIsLoading) && <Loading isOpen={true} />}
      {data && (
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid container sx={{ paddingTop: '2rem' }} columnSpacing={8}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h2" mb={3}>
                      Detail
                    </Typography>
                    {/* Name */}
                    <Controller
                      defaultValue={''}
                      name={getName('name')}
                      rules={{ required: 'Please fill out this field' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          error={!!fieldState.error}
                          required
                          label="Name"
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    {errors.name && <p>{errors.name.message}</p>}
                  </Grid>
                  {/* Tasks */}
                  <CustomToggle
                    key={1}
                    controllerName={'canTasksBeAssignedToThisTeam'}
                    description={'Tasks can be assigned to this team'}
                  />

                  <Grid item xs={12}>
                    <Typography variant="h2" mb={3}>
                      Local Authorities
                    </Typography>
                    <Grid container>
                      {/* Include Local Authorities */}
                      <CustomToggle
                        key={1}
                        controllerName={'containsAllLocalAuthoritesCostCentres'}
                        description={'Include all Local Authorities'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {!showLocalAuthorities && (
                        <Controller
                          control={control}
                          name="localAuthoritiesCostCentres"
                          rules={{
                            validate: value => 
                              showLocalAuthorities || 
                              (value && value.length > 0) || 
                              selectLocalAuthorityHelpText
                          }}
                          render={({
                            fieldState,
                            field: { onChange, value, ...fieldProps },
                          }) => (
                            <Autocomplete
                              {...fieldProps}
                              onChange={(_, val) => {
                                const selectedAuthorities = val.map(id => ({
                                  id: id,
                                  name:
                                    ccData?.find(o => o.id === id)?.name || '',
                                }));
                                onChange(selectedAuthorities);
                              }}
                              value={value ? value.map(item => item.id) : []}
                              options={ccData?.map(({ id }) => id) || []}
                              getOptionLabel={opt =>
                                ccData?.find(o => o.id === opt)?.name || ''
                              }
                              multiple
                              renderInput={params => {
                                return (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    error={!!fieldState.error}
                                    label="Select Local Authorities"
                                    helperText={fieldState.error?.message || 
                                      (!value || value.length === 0 ? selectLocalAuthorityHelpText : "")}
                                  />
                                );
                              }}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h2" mb={3}>
                  Rules
                </Typography>

                {/* Rules */}
                <Grid container mb={1}>
                  {data.rules.map((rule, index) => (
                    <CustomToggle
                      key={index}
                      controllerName={`rules.${index}.granted`}
                      description={rule.description}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{ my: 3, mx: -5 }} />

            <Grid
              container
              justifyContent="flex-end"
              sx={{ paddingRight: '2rem' }}
              gap={1}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  navigate('/admin/' + EAdministrationTabType.Teams);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || !isDirty}
              >
                {teamId ? 'Update' : 'Add'} Team
              </Button>
            </Grid>
          </form>
        </FormProvider>
      )}
    </MainContentContainer>
  );
}
