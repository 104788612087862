import { useEffect } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Controller, useFormContext } from 'react-hook-form';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EReferralType } from 'enums/EReferralType';
import { useDebounce, useFileUpload } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { BuildingHeightChecksCompleteNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/FRAEWChecks/BuildingHeightChecksCompleteNotificationBanner';
import { ReferApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplicationNotificationBanner';
import { RejectApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/RejectApplicationNotificationBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context/ApplicationEligibilityContext';
import { IApplicationFRAEWChecks } from 'types/applications/ApplicationEligibilityTypes';
import { nameof } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationFRAEWChecks) =>
  nameof<IApplicationFRAEWChecks>(fieldName);

interface IFRAEWBuildingHeightProps {
  data?: IApplicationFRAEWChecks;
  readOnly?: boolean;
  onSubmit: (rejectTriggered: boolean) => void;
}

export const FRAEWBuildingHeight = ({
  data,
  readOnly,
  onSubmit,
}: IFRAEWBuildingHeightProps) => {
  const { onReferralQuestion, onRejectedQuestion, rejectedQuestion } =
    useApplicationEligibilityContext();

  const { watch, setValue } = useFormContext<IApplicationFRAEWChecks>();

  const { uploadFile, uploadFileStatus } = useFileUpload();

  const [
    watchBuildingHeightDocumentFileId,
    watchSubmittedBuildingHeight,
    watchDoesBuildingHeightMatchFRAEWReport,
    watchValidReasonForIncorrectBuildingHeight,
    watchBuildingHeight,
    watchIsBuildingHeightCorrect,
  ] = watch([
    'buildingHeightDocumentFileId',
    'submittedBuildingHeight',
    'doesBuildingHeightMatchFRAEWReport',
    'validReasonForIncorrectBuildingHeight',
    'buildingHeight',
    'isBuildingHeightCorrect',
  ]);

  const uploadBuildingHeightFile = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'EligibilityFRAEW',
      onSuccess: payload => {
        setValue(`buildingHeightDocumentFileId`, payload.id);
      },
    });
  };

  const deleteBuildingHeightFile = async () => {
    setValue(`buildingHeightDocumentFileId`, '');
  };

  const handleReferralQuestion = (
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => {
    onReferralQuestion(
      EEligibilitySection.Fraew,
      question,
      referralType,
      refer
    );
  };

  useEffect(() => {
    onRejectedQuestion(
      watchSubmittedBuildingHeight < 11
        ? EEligibilityQuestion.FRAEW_SubmittedBuildingHeight
        : null
    );
  }, [onRejectedQuestion, watchSubmittedBuildingHeight]);

  const debouncedValue = useDebounce(watchBuildingHeight, 500);
  useEffect(() => {
    onRejectedQuestion(
      debouncedValue !== undefined && debouncedValue < 11
        ? EEligibilityQuestion.FRAEW_BuildingHeight
        : null
    );
  }, [debouncedValue, onRejectedQuestion]);

  return (
    <Grid container spacing={1}>
      <Grid xs={9} item>
        <Grid container spacing={1}>
          <Box p={2}>
            <Grid xs={12} item>
              <Typography variant="h2" fontWeight={600}>
                Building Height
              </Typography>
            </Grid>
            <Grid xs={12} item pb={2}>
              <Typography variant="body1">
                The building height in the FRAEW summary is{' '}
                <strong>{data?.submittedBuildingHeight} metres</strong>. Please
                check this is in the FRAEW report and confirm whether its
                correct
              </Typography>
            </Grid>
            <Box p={2}>
              <Grid xs={12} item>
                <Grid item mb={2}>
                  <Typography variant="h3" fontWeight={600} fontSize="1em">
                    Check the building height matches the FRAEW report?
                  </Typography>
                  <Controller
                    defaultValue={''}
                    name={getName('doesBuildingHeightMatchFRAEWReport')}
                    render={({
                      fieldState,
                      field: { onChange, ...fieldProps },
                    }) => (
                      <FormControl>
                        <ToggleButtonGroup
                          {...fieldProps}
                          onChange={(_, val) => {
                            if (val !== null) {
                              onChange(val);
                            }
                            handleReferralQuestion(
                              EEligibilityQuestion.FRAEW_BuildingHeightMatchFRAEWReport,
                              EReferralType.HomesEngland,
                              val === false
                            );
                            if (val) {
                              setValue(
                                `validReasonForIncorrectBuildingHeight`,
                                undefined
                              );
                            }
                          }}
                          exclusive
                          disabled={readOnly}
                        >
                          <ToggleButton data-automation-id="DoesBuildingHeightMatchFRAEWReport_Pass" value={true}>Pass</ToggleButton>
                          <ToggleButton data-automation-id="DoesBuildingHeightMatchFRAEWReport_Fail" value={false}>Fail</ToggleButton>
                        </ToggleButtonGroup>
                        {fieldState.error?.message ? (
                          <FormHelperText error>
                            {fieldState.error?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              {watchDoesBuildingHeightMatchFRAEWReport &&
                watchSubmittedBuildingHeight < 11 && (
                  <RejectApplicationNotificationBanner
                    question={
                      EEligibilityQuestion.FRAEW_BuildingHeightMatchFRAEWReport
                    }
                    onClick={() => onSubmit(true)}
                    disabled={readOnly}
                  />
                )}
              {watchDoesBuildingHeightMatchFRAEWReport === false && (
                <>
                  <ReferApplicationNotificationBanner
                    question={
                      EEligibilityQuestion.FRAEW_BuildingHeightMatchFRAEWReport
                    }
                    referralType={EReferralType.Applicant}
                  />
                  <Grid item mb={2}>
                    <Typography variant="h3" fontWeight={600} fontSize="1em">
                      Contact applicant to confirm building height
                    </Typography>
                    <Controller
                      defaultValue={''}
                      name={getName('applicantConfirmedBuildingHeight')}
                      render={({
                        fieldState,
                        field: { onChange, value, ...fieldProps },
                      }) => (
                        <FormControl>
                          <FormControlLabel
                            data-automation-id="ApplicantConfirmedBuildingHeight"
                            control={
                              <Checkbox
                                {...fieldProps}
                                checked={value}
                                onChange={(_, val) => {
                                  if (val !== null) onChange(val);
                                }}
                                disabled={readOnly}
                              />
                            }
                            label="I have contacted the applicant"
                          />
                          {fieldState.error?.message ? (
                            <FormHelperText error>
                              {fieldState.error?.message}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Grid item mb={2}>
                      <Typography variant="h3" fontWeight={600} fontSize="1em">
                        Has the applicant provided a valid reason for incorrect
                        building height?
                      </Typography>
                      <Controller
                        defaultValue={''}
                        name={getName('validReasonForIncorrectBuildingHeight')}
                        render={({
                          fieldState,
                          field: { onChange, ...fieldProps },
                        }) => (
                          <FormControl>
                            <ToggleButtonGroup
                              {...fieldProps}
                              onChange={(_, val) => {
                                if (val !== null) {
                                  onChange(val);
                                }
                                handleReferralQuestion(
                                  EEligibilityQuestion.FRAEW_ValidReasonForIncorrectBuildingHeight,
                                  EReferralType.HomesEngland,
                                  true
                                );
                              }}
                              exclusive
                              disabled={readOnly}
                            >
                              <ToggleButton data-automation-id="ValidReasonForIncorrectBuildingHeight_Pass" value={true}>Pass</ToggleButton>
                              <ToggleButton data-automation-id="ValidReasonForIncorrectBuildingHeight_Fail" value={false}>Fail</ToggleButton>
                            </ToggleButtonGroup>
                            {fieldState.error?.message ? (
                              <FormHelperText error>
                                {fieldState.error?.message}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {watchValidReasonForIncorrectBuildingHeight && (
                    <>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Provide Reason
                        </Typography>
                        <Controller
                          name={getName('reasonForIncorrectBuildingHeight')}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              data-automation-id="ReasonForIncorrectBuildingHeight" 
                              fullWidth
                              error={!!fieldState.error}
                              required
                              label="Reason For Incorrect Building Height"
                              helperText={fieldState.error?.message}
                              multiline
                              disabled={readOnly}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item mb={2} data-automation-id="BuildingHeightDocument" >
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Upload documents
                        </Typography>
                        {readOnly ||
                        (watchBuildingHeightDocumentFileId !== undefined &&
                          watchBuildingHeightDocumentFileId ===
                            data?.buildingHeightDocument?.id) ? (
                          <Box display="flex" flexDirection="row">
                            <ApplicationFileDownloadWrapper
                              fileName={
                                data?.buildingHeightDocument?.name ?? ''
                              }
                              fileId={data?.buildingHeightDocument?.id ?? ''}
                            />
                            {!readOnly && (
                              <IconButton
                                size="small"
                                onClick={deleteBuildingHeightFile}
                                aria-label="Delete File"
                                name="Delete File"
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </IconButton>
                            )}
                          </Box>
                        ) : (
                          <>
                            <FileUploadInputSelect
                              id="buildingHeightDocument"
                              accept=".pdf"
                              inputName="buildingHeightDocumentFileId"
                              onFileUpload={file =>
                                uploadBuildingHeightFile(file)
                              }
                              onFileDelete={deleteBuildingHeightFile}
                              isUploading={uploadFileStatus.isLoading}
                              uploaded={uploadFileStatus.isSuccess}
                            />
                            {watchBuildingHeightDocumentFileId && (
                              <FormHelperText error>
                                Building height document is required.
                              </FormHelperText>
                            )}
                          </>
                        )}
                      </Grid>
                      <ReferApplicationNotificationBanner
                        question={
                          EEligibilityQuestion.FRAEW_ValidReasonForIncorrectBuildingHeight
                        }
                        referralType={EReferralType.HomesEngland}
                      />
                    </>
                  )}
                  {watchValidReasonForIncorrectBuildingHeight === false && (
                    <>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                          width="35rem"
                        >
                          What is the building height in the FRAEW report?
                        </Typography>
                        <Typography variant="h3" fontSize="1em">
                          Provide the answers in metres
                        </Typography>
                        <Controller
                          name={getName('buildingHeight')}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              data-automation-id="BuildingHeight"
                              type="number"
                              fullWidth
                              error={!!fieldState.error}
                              required
                              label="Building Height"
                              helperText={fieldState.error?.message}
                              style={{ width: '12rem' }}
                              disabled={
                                readOnly &&
                                rejectedQuestion !==
                                  EEligibilityQuestion.FRAEW_BuildingHeight
                              }
                            />
                          )}
                        />
                        <strong style={{ paddingLeft: '1rem' }}>Metres</strong>
                      </Grid>
                      {watchBuildingHeight !== undefined &&
                        watchBuildingHeight < 11 && (
                          <RejectApplicationNotificationBanner
                            question={EEligibilityQuestion.FRAEW_BuildingHeight}
                            onClick={() => onSubmit(true)}
                            disabled={readOnly}
                          />
                        )}
                      {watchBuildingHeight !== undefined &&
                        watchBuildingHeight >= 11 &&
                        watchBuildingHeight < 13 && (
                          <>
                            <Grid item mb={2}>
                              <Typography
                                variant="h3"
                                fontWeight={600}
                                fontSize="1em"
                              >
                                Have you checked the building height and are
                                satisfied that the height is correct?
                              </Typography>
                              <Typography variant="h3" fontSize="1em">
                                Use Google height checker or similar tool
                              </Typography>
                              <Controller
                                defaultValue={''}
                                name={getName('isBuildingHeightCorrect')}
                                render={({
                                  fieldState,
                                  field: { onChange, ...fieldProps },
                                }) => (
                                  <FormControl>
                                    <ToggleButtonGroup
                                      {...fieldProps}
                                      onChange={(_, val) => {
                                        if (val !== null) {
                                          onChange(val);
                                        }
                                        handleReferralQuestion(
                                          EEligibilityQuestion.FRAEW_IsBuildingHeightCorrect,
                                          EReferralType.HomesEngland,
                                          !val
                                        );
                                      }}
                                      exclusive
                                      disabled={readOnly}
                                    >
                                      <ToggleButton data-automation-id="IsBuildingHeightCorrect_Pass" value={true}>
                                        Pass
                                      </ToggleButton>
                                      <ToggleButton data-automation-id="IsBuildingHeightCorrect_Fail" value={false}>
                                        Fail
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                    {fieldState.error?.message ? (
                                      <FormHelperText error>
                                        {fieldState.error?.message}
                                      </FormHelperText>
                                    ) : null}
                                  </FormControl>
                                )}
                              />
                            </Grid>
                            {watchIsBuildingHeightCorrect && (
                              <BuildingHeightChecksCompleteNotificationBanner />
                            )}
                            {watchIsBuildingHeightCorrect === false && (
                              <ReferApplicationNotificationBanner
                                question={
                                  EEligibilityQuestion.FRAEW_IsBuildingHeightCorrect
                                }
                                referralType={EReferralType.HomesEngland}
                              />
                            )}
                          </>
                        )}
                      <ReferApplicationNotificationBanner
                        question={
                          EEligibilityQuestion.FRAEW_ValidReasonForIncorrectBuildingHeight
                        }
                        referralType={EReferralType.HomesEngland}
                      />
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid xs={3} item></Grid>
    </Grid>
  );
};
