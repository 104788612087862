import { ReactNode } from 'react';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { ErrorAlert } from 'common/components/alerts';
import { Loading } from 'common/components/loading';
import { useCurrentUserPermissions } from 'hooks';
import { PermissionDeniedToTab } from 'pages/applicationPage/common/components';
import {
  EApplicationGrantFundingSectionType,
  EApplicationGrantFundingSelectedItemIdType,
} from 'pages/applicationPage/content/applicationGrantFunding/ApplicationGrantFundingTypes';
import { GrantFundingSectionHeader } from 'pages/applicationPage/content/applicationGrantFunding/components/GrantFundingSectionHeader';
import { GrantFundingCounterSignedDocumentationChecksForm } from 'pages/applicationPage/content/applicationGrantFunding/components/counterSignedDocumentation/GrantFundingCounterSignedDocumentationChecksForm';
import { GrantFundingDetailsChecksForm } from 'pages/applicationPage/content/applicationGrantFunding/components/details/GrantFundingDetailsChecksForm';
import { GrantFundingSignedDocumentationChecksForm } from 'pages/applicationPage/content/applicationGrantFunding/components/signedDocumentation/GrantFundingSignedDocumentationChecksForm';
import { useApplicationGrantFundingContext } from 'pages/applicationPage/content/applicationGrantFunding/context';
import { getSectionId } from 'pages/applicationPage/content/applicationGrantFunding/utils';
import { IApplicationGrantFundingItem } from 'types/applications/ApplicationGrantFundingTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const ApplicationGrantFundingSectionOutlet = () => {
  const {
    selectedItemId,
    selectedItem,
    applicationGrantFundingCategoriesQuery,
  } = useApplicationGrantFundingContext();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasApplicationsBankdetailsView = doesUserHaveSinglePermission(
    'applications.bank-details.view'
  );

  if (!selectedItemId && applicationGrantFundingCategoriesQuery.isLoading) {
    return <Loading isOpen />;
  }

  if (!selectedItem) {
    return (
      <ErrorAlert msg={`Could not find item with id: ${selectedItemId}`} />
    );
  }

  const renderContent = () => (
    <>
      <Header
        title={selectedItem.sectionName}
        lastUpdated={selectedItem.lastUpdated ?? ''}
        isComplete={selectedItem.isComplete}
      />
      <Divider />
      <Form item={selectedItem} />
    </>
  );

  if (
    selectedItemId ===
      EApplicationGrantFundingSelectedItemIdType.CheckGrantFundingDetails &&
    !hasApplicationsBankdetailsView
  ) {
    return <PermissionDeniedToTab />;
  }
  if (
    selectedItemId ===
      EApplicationGrantFundingSelectedItemIdType.UploadSignedDocs &&
    !hasApplicationsBankdetailsView
  ) {
    return <PermissionDeniedToTab />;
  }
  return renderContent();
};

const Header = ({
  title,
  lastUpdated,
  isComplete,
}: {
  title: string;
  lastUpdated: string;
  isComplete: boolean;
}) => {
  return (
    <GrantFundingSectionHeader
      title={title}
      rightContent={
        <>
          {lastUpdated !== '' && (
            <SubHeaderItem
              label="Last Updated"
              value={getUkDateStringFromJsonDateString(lastUpdated)}
            />
          )}
          <Chip
            label={isComplete ? 'Complete' : 'Not Started'}
            color={isComplete ? 'success' : 'warning'}
            variant={isComplete ? 'filled' : 'outlined'}
            data-automation-id="chip_is_complete"
          />
        </>
      }
    />
  );
};

const SubHeaderItem = ({
  label,
  value,
}: {
  label: string;
  value: ReactNode;
}) => {
  return (
    <Box display="flex" flexDirection="row" gap={2} alignItems="center">
      <Typography variant="body1" color="grey.700" fontSize="0.9em">
        {label}
      </Typography>

      {typeof value === 'string' ? (
        <Typography variant="body1" fontWeight={600} fontSize="0.9em">
          {value}
        </Typography>
      ) : (
        value
      )}
    </Box>
  );
};

type GrantFundingItemConfig = {
  type: EApplicationGrantFundingSectionType;
  content: ReactNode;
};
const items: GrantFundingItemConfig[] = [
  {
    type: EApplicationGrantFundingSectionType.CheckGrantFundingDetails,
    content: <GrantFundingDetailsChecksForm />,
  },
  {
    type: EApplicationGrantFundingSectionType.UploadSignedDocs,
    content: <GrantFundingSignedDocumentationChecksForm />,
  },
  {
    type: EApplicationGrantFundingSectionType.UploadCounterSignedGrantFunding,
    content: <GrantFundingCounterSignedDocumentationChecksForm />,
  },
];

const Form = ({ item }: { item: IApplicationGrantFundingItem }) => {
  const sectionId = getSectionId(item.sectionName);
  return (
    <>
      {items.map(item => (
        <Box
          key={item.type}
          sx={{ display: sectionId === item.type ? 'block' : 'none' }}
        >
          {item.content}
        </Box>
      ))}
    </>
  );
};
