import { ReactNode, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { DateTimeAndUserRow } from 'common/components/dateTimeAndUserRow';
import { useReferToThirdPartyDrawer } from 'common/components/referrals/thirdPartyReferrals';
import { useThirdPartyReferralQuery } from 'common/components/referrals/thirdPartyReferrals/hooks';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useCurrentUserPermissions, useLocalSnackbar, useModalState } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import {
  EReferralStatus,
  EReferralType,
  EThirdPartyReferrerTypeLanguage,
  EReferralRecordType,
} from 'types/applications/ReferralTypes';
import { getGbpAmount, getUkDateStringFromJsonDateString } from 'util/AppUtils';

type ViewReferralHookParams =
  | {
      referralRecordType: EReferralRecordType.WorksPackage;
    }
  | {
      referralRecordType: EReferralRecordType.PaymentRequest;
      paymentRequestId: string;
    }
  | {
      referralRecordType: EReferralRecordType.Variation;
      variationId: string;
    };

interface IViewReferralConfig {
  referralId: string;
  referralType: EReferralType | undefined;
}

export const useViewReferralToThirdPartyDrawer = (
  params: ViewReferralHookParams
) => {
  const { createErrorSnackbar } = useLocalSnackbar();
  const {
    isShowing: isReferDrawerShowing,
    showModal,
    hideModal,
  } = useModalState();

  const [referralConfig, setReferralConfig] =
    useState<IViewReferralConfig | null>(null);

  const showViewReferralDrawer = (referralConfig: IViewReferralConfig) => {
    setReferralConfig(referralConfig);
    showModal();
  };

  const hideViewReferralDrawer = () => {
    setReferralConfig(null);
    hideModal();
  };

  const { query: tpReferralQuery } = useThirdPartyReferralQuery(
    referralConfig?.referralId ?? '',
    referralConfig?.referralType,
    params
  );

  const { isLoading, isError, error, data, isSuccess } = tpReferralQuery;

  const referToThirdPartyDrawer = useReferToThirdPartyDrawer();

  const handleEditClick = () => {
    if (!data) {
      createErrorSnackbar(
        'Could not get referral data. Please try again later.'
      );
      return;
    }

    if (data.status === EReferralStatus.Complete) {
      createErrorSnackbar('Status is complete. Not possible to edit.');
      return;
    }

    const getShowConfig = (): Parameters<
      typeof referToThirdPartyDrawer.showReferDrawer
    >[0] => {
      const base = {
        referralType: 'editReferral',
        referral: data,
      } as const;
      switch (params.referralRecordType) {
        case EReferralRecordType.WorksPackage:
          return {
            ...base,
            referralRecordType: EReferralRecordType.WorksPackage,
          };
        case EReferralRecordType.PaymentRequest:
          return {
            ...base,
            referralRecordType: EReferralRecordType.PaymentRequest,
            paymentRequestId: params.paymentRequestId,
          };
        case EReferralRecordType.Variation:
          return {
            ...base,
            referralRecordType: EReferralRecordType.Variation,
            variationId: params.variationId,
          };
      }
    };

    referToThirdPartyDrawer.showReferDrawer(getShowConfig());
    hideViewReferralDrawer();
  };
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();

  const renderViewReferralDrawer = () => {
    
    const hasApplicationsReferProcess = doesUserHaveSinglePermission(
      'applications.refer.process'
    );

    return (
      <>
        <StyledDrawer anchor="right" open={isReferDrawerShowing}>
          <Box>
            <DialogTitle component="div">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h1" component="span">
                  View Referral
                </Typography>
                <IconButton
                  onClick={hideModal}
                  aria-label="Close Drawer"
                  name="Close Drawer"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <AlertForQueryErrorOrNull isError={isError} error={error} />

              {isLoading ? (
                <Box py={2}>
                  <FormSkeleton />
                </Box>
              ) : null}

              {isSuccess ? (
                <Stack rowGap={3}>
                  <SummarySection title="Referral Details">
                    <Grid container>
                      <Grid item xs={6}>
                        <DataItem
                          label="Start Date"
                          value={getUkDateStringFromJsonDateString(
                            data.startDate
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DataItem
                          label="End Date"
                          value={getUkDateStringFromJsonDateString(
                            data.endDate
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Divider />

                    <DataItem
                      label="Cost (£)"
                      value={getGbpAmount({
                        value: data.cost,
                        showCurrencySymbol: false,
                      })}
                    />
                    <Divider />

                    <DataItem label="Description" value={data.description} />
                  </SummarySection>

                  <SummarySection title="Company Details">
                    <DataItem label="Company" value={data.companyName} />
                    <Divider />

                    <DataItem
                      label="Type"
                      value={
                        EThirdPartyReferrerTypeLanguage[data.thirdPartyType]
                      }
                    />
                    <Divider />

                    <Grid container>
                      <Grid item xs={6}>
                        <DataItem label="Email" value={data.companyEmail} />
                      </Grid>
                      <Grid item xs={6}>
                        <DataItem label="Phone" value={data.companyPhone} />
                      </Grid>
                    </Grid>
                  </SummarySection>

                  {data.response ? (
                    <SummarySection title="Response">
                      <DataItem
                        label="Response code"
                        value={data.response ?? '-'}
                      />

                      <DataItem
                        label="Provide further explanation"
                        value={data.responseComments ?? '-'}
                      />

                      {data.responseFile ? (
                        <ApplicationFileDownloadWrapper
                          fileName={`Evidence of communication`}
                          fileId={data.responseFile.id}
                        />
                      ) : null}

                      <DateTimeAndUserRow
                        timestamp={data.dateResponded ?? '-'}
                        personName={data.responseUser ?? '-'}
                      />
                    </SummarySection>
                  ) : null}
                </Stack>
              ) : null}
            </DialogContent>
            <StyledDrawerActions>
              <Grid container justifyContent="flex-end" gap={1}>
                <Button variant="outlined" onClick={hideViewReferralDrawer}>
                  Close
                </Button>
                {data &&
                data.status !== EReferralStatus.Complete &&
                hasApplicationsReferProcess ? (
                  <Button variant="contained" onClick={handleEditClick}>
                    Edit
                  </Button>
                ) : null}
              </Grid>
            </StyledDrawerActions>
          </Box>
        </StyledDrawer>

        {referToThirdPartyDrawer.renderReferDrawer()}
      </>
    );
  };

  const SummarySection = ({
    title,
    children,
    sx,
  }: {
    title: string;
    children: ReactNode;
    sx?: SxProps;
  }) => {
    return (
      <RoundBorderBox sx={{ p: 3, ...sx }}>
        <Typography variant="body1" fontWeight={600} fontSize={'1.1em'} mb={2}>
          {title}
        </Typography>

        <Stack gap={2}>{children}</Stack>
      </RoundBorderBox>
    );
  };

  const DataItem = ({
    label,
    value,
    sx,
  }: {
    label: string;
    value: string;
    sx?: SxProps;
  }) => {
    return (
      <Box sx={sx}>
        <Typography variant="body1" color={'grey.500'}>
          {label}
        </Typography>
        <Typography
          variant="body1"
          sx={{ overflowWrap: 'anywhere' }}
          fontWeight={600}
        >
          {value}
        </Typography>
      </Box>
    );
  };

  return {
    showViewReferralDrawer,
    renderViewReferralDrawer,
  };
};
