import { faClock } from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useGetLiveProjectReportListQuery } from 'api/application/liveProjectApi';
import {
  HandleLoadingOrError,
  LabelText,
  ListViewIcon,
} from 'common/components/listView';
import { useCurrentUserPermissions } from 'hooks/authHooks/useCurrentUserPermissions';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  ELiveProjectItemType,
  ILiveProjectReportItem,
} from 'pages/applicationPage/content/liveProject/LiveProjectTypes';
import { LiveProjectItemChip } from 'pages/applicationPage/content/liveProject/common';
import {
  PaymentRequestCard,
  VariationCard,
} from 'pages/applicationPage/content/liveProject/sections/OverviewSection';
import { ClosingPaymentRequestCard } from 'pages/applicationPage/content/liveProject/sections/OverviewSection/ClosingPaymentRequestCard';
import { ListViewBox } from 'pages/applicationPage/content/liveProject/sections/OverviewSection/common';
import { getPrettyDateStringFromJsonDateString } from 'util/AppUtils';

export const ListView = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, isError, error, data } =
    useGetLiveProjectReportListQuery(applicationId);

  return (
    <>
      <HandleLoadingOrError
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
      {isSuccess ? <ListViewMain data={data.results} /> : null}
    </>
  );
};

const ListViewMain = ({ data }: { data: ILiveProjectReportItem[] }) => {
  return (
    <Stack rowGap={2}>
      {data && data.map(item => <ListViewCard key={item.id} item={item} />)}
    </Stack>
  );
};

const ListViewCard = ({ item }: { item: ILiveProjectReportItem }) => {
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();

  const hasLiveprojectScheduleofworksView = doesUserHaveSinglePermission(
    'liveproject.scheduleofworks.view'
  );

  const hasLiveprojectPaymentsView = doesUserHaveSinglePermission(
    'liveproject.payments.view'
  );

  const hasLiveprojectVariationsView = doesUserHaveSinglePermission(
    'liveproject.variations.view'
  );

  const hasLiveprojectFinalpaymentView = doesUserHaveSinglePermission(
    'liveproject.finalpayment.view'
  );

  switch (item.type) {
    case ELiveProjectItemType.ScheduleOfWorks:
      return hasLiveprojectScheduleofworksView ? (
        <ScheduleOfWorksCard item={item} />
      ) : null;
    case ELiveProjectItemType.PaymentRequest:
      return hasLiveprojectPaymentsView ? (
        <PaymentRequestCard item={item} />
      ) : null;

    case ELiveProjectItemType.Variation:
      return hasLiveprojectVariationsView ? (
        <VariationCard item={item} />
      ) : null;

    case ELiveProjectItemType.ClosingPaymentRequest:
      return hasLiveprojectFinalpaymentView ? (
        <ClosingPaymentRequestCard item={item} />
      ) : null;

    default:
      return null;
  }
};

const ScheduleOfWorksCard = ({ item }: { item: ILiveProjectReportItem }) => {
  return (
    <ListViewBox
      itemId={item.id}
      itemType={item.type}
      sx={{ p: 2, bgcolor: 'grey.100' }}
    >
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={6}>
          <Typography variant="h2" fontSize={'1.1em'} fontWeight={700} mb={3}>
            {item.title}
          </Typography>
          <Typography variant="h3" fontSize={'1em'} fontWeight={600}>
            {item.subTitle}
          </Typography>
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
          <Box>
            <Box mb={1} textAlign={'right'}>
              <LiveProjectItemChip status={item.status} />
            </Box>
            <Box mb={1} textAlign={'left'}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <LabelText value={'Submitted'} isBold />
                <ListViewIcon icon={faClock} />
                <LabelText
                  value={
                    item?.submittedDate
                      ? getPrettyDateStringFromJsonDateString(
                          item.submittedDate
                        )
                      : '-'
                  }
                />
              </Stack>
            </Box>
            <Box mb={1} textAlign={'left'}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <LabelText value={'Completed'} isBold />
                <ListViewIcon icon={faClock} />
                <LabelText
                  value={
                    item?.completedDate
                      ? getPrettyDateStringFromJsonDateString(
                          item.completedDate
                        )
                      : '-'
                  }
                />
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ListViewBox>
  );
};
