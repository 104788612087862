import { createContext, ReactNode, useContext } from 'react';
import { useGetCurrentUserQuery } from 'api/profile/profile';
import { FormSkeleton } from 'common/components/skeletons';
import { useGridQueryAndState } from 'pages/pullInProcessPage/components/pullinTaskLists/utils';

interface IPullinTasksGridContext {
  query: ReturnType<typeof useGridQueryAndState>['query'];
  state: ReturnType<typeof useGridQueryAndState>['state'];
  gridStateChanged: ReturnType<typeof useGridQueryAndState>['gridStateChanged'];
}

const PullinTasksGridContext = createContext<
  IPullinTasksGridContext | undefined
>(undefined);

interface IPullinTasksGridContextProviderProps {
  children: ReactNode;
}

export const PullinTasksGridContextProvider = ({
  children,
}: IPullinTasksGridContextProviderProps) => {
  const { isLoading, isSuccess } = useGetCurrentUserQuery();
  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isSuccess) {
    return (
      <PullinTasksGridContextProviderMain>
        {children}
      </PullinTasksGridContextProviderMain>
    );
  }

  return null;
};

export const PullinTasksGridContextProviderMain = ({
  children,
}: IPullinTasksGridContextProviderProps) => {
  const value = useGridQueryAndState();
  return (
    <PullinTasksGridContext.Provider value={value}>
      {children}
    </PullinTasksGridContext.Provider>
  );
};

export const usePullinTasksGridContext = () => {
  const context = useContext(PullinTasksGridContext);
  if (context === undefined) {
    throw new Error(
      'usePullinTasksGridContext must be used within an PullinTasksGridContextProvider'
    );
  }
  return context;
};
