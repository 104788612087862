import { Fragment, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  LinearProgress,
  Menu,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ErrorAlert } from 'common/components/alerts';
import {
  EScheduleOfWorksReferralLanguage,
  EScheduleOfWorksReferralStatus,
} from 'enums/EScheduleOfWorksReferralStatus';
import {
  useCurrentUserPermissions,
  useEllipsisMenu,
  useModalState,
} from 'hooks';
import { useScheduleOfWorksContext } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/context/ScheduleOfWorksContext';
import { ScheduleOfWorksCompleteReferralModal } from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/sections/Referrals/ScheduleOfWorksCompleteReferralModal';
import { StyledGenericTable as Table } from 'styles/globalStyles/tables';
import { extractErrorMessages } from 'util/ApiUtils';
import { getMenuItemConfig, getMenuItems } from 'util/AppUtils';

export enum EReferralsMenuType {
  Complete,
}

export const ScheduleOfWorksReferralsGrid = () => {
  const { scheduleOfWorksReferralsQuery } = useScheduleOfWorksContext();

  if (scheduleOfWorksReferralsQuery.isLoading) {
    return <LinearProgress />;
  }

  if (scheduleOfWorksReferralsQuery.isSuccess) {
    return <ReferralTable data={scheduleOfWorksReferralsQuery.data} />;
  }

  if (scheduleOfWorksReferralsQuery.isError) {
    return (
      <ErrorAlert
        msg={extractErrorMessages(scheduleOfWorksReferralsQuery.error)}
      />
    );
  }

  return null;
};

const ReferralTable = ({
  data,
}: {
  data: ReturnType<
    typeof useScheduleOfWorksContext
  >['scheduleOfWorksReferralsQuery']['data'];
}) => {
  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const [referralIdToComplete, setReferralIdToComplete] = useState<
    string | null
  >(null);
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasApplicationsReferProcess = doesUserHaveSinglePermission(
    'applications.refer.process'
  );

  const { isShowing, showModal, hideModal } = useModalState();

  const handleModalClose = () => {
    setReferralIdToComplete(null);
    hideModal();
  };

  const handleMenuItemClick = (menuType: EReferralsMenuType) => {
    switch (menuType) {
      case EReferralsMenuType.Complete:
        setReferralIdToComplete(selectedId);
        showModal();
        break;
    }

    handleMenuClose();
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <Table
        sx={{
          '& tr.completion-reason td': {
            borderTop: 0,
            pt: 0,
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Reason</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.results?.map(referral => (
            <Fragment key={referral.id}>
              <TableRow>
                <TableCell>{referral.reason}</TableCell>
                <TableCell>
                  {EScheduleOfWorksReferralLanguage[referral.status]}
                </TableCell>
                <TableCell aria-label="Actions">
                  {referral.status !==
                  EScheduleOfWorksReferralStatus.Complete ? (
                    <>
                      <IconButton
                        onClick={event =>
                          handleEllipsisClick(referral.id, event)
                        }
                        aria-label="Actions"
                        name="Actions"
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                      {selectedId === referral.id ? (
                        <Menu
                          id={`referrals-menu-${referral.id}`}
                          anchorEl={ellipsisMenuAnchor}
                          open={Boolean(ellipsisMenuAnchor)}
                          onClose={handleMenuItemClick}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          {getMenuItems(
                            [
                              getMenuItemConfig(
                                'Complete',
                                EReferralsMenuType.Complete,
                                true,
                                !hasApplicationsReferProcess
                              ),
                            ],
                            handleMenuItemClick
                          )}
                        </Menu>
                      ) : null}
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
              {referral.status === EScheduleOfWorksReferralStatus.Complete ? (
                <TableRow className="completion-reason">
                  <TableCell colSpan={6}>
                    <Box
                      sx={{
                        p: 2,
                        backgroundColor: 'grey.100',
                        borderRadius: 2,
                        borderColor: 'grey.300',
                        borderWidth: 1,
                        borderStyle: 'solid',
                      }}
                    >
                      <Typography
                        variant="caption"
                        color={'grey.600'}
                        fontWeight={900}
                        fontSize="0.85em"
                      >
                        Completion Reason
                      </Typography>
                      <br />
                      {referral.completeReason}
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null}
            </Fragment>
          ))}
        </TableBody>
      </Table>

      {isShowing && referralIdToComplete ? (
        <ScheduleOfWorksCompleteReferralModal
          referralId={referralIdToComplete}
          onConfirm={handleModalClose}
          onCancel={handleModalClose}
        />
      ) : null}
    </>
  );
};
