import { useState } from 'react';
import { Box, Grid, Tabs, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs } from 'common/components/breadCrumb/breadCrumb';
import { IBreadcrumbItem } from 'common/components/breadCrumb/breadCrumbBuilder';
import { CustomTab } from 'common/components/customTab';
import { CustomTabPanel } from 'common/components/customTabPanel';
import { MainContentContainer } from 'common/components/mainContentContainer';
import { PageTitle } from 'common/components/pageTitle';
import { useCurrentUserPermissions } from 'hooks';
import { AdministrationAssessorPanelListTab } from 'pages/administrationPage/components/administrationAssessorPanelListTab/AdministrationAssessorPanelListTab';
import { AdministrationBenchmarkFiguresTab } from 'pages/administrationPage/components/administrationBenchmarkFiguresTab/AdministrationBenchmarkFiguresTab';
import { AdministrationOrgsTab } from 'pages/administrationPage/components/administrationOrgsTab/AdministrationOrgsTab';
import { AdministrationPTFSTab } from 'pages/administrationPage/components/administrationPTFSTab/AdministrationPTFSTab';
import { AdministrationRolesTab } from 'pages/administrationPage/components/administrationRolesTab/AdministrationRolesTab';
import { AdministrationSystemNotificationsTab } from 'pages/administrationPage/components/administrationSystemNotificationsTab/AdministrationSystemNotificationsTab';
import { AdministrationTeamsTab } from 'pages/administrationPage/components/administrationTeamsTab/AdministrationTeamsTab';
import { AdministrationUsersTab } from 'pages/administrationPage/components/administrationUsersTab/AdministrationUsersTab';
import { EAdministrationTabType } from 'pages/administrationPage/types/EAdministrationTabType';

const _crumbs: readonly IBreadcrumbItem[] = [
  { text: 'Admin', url: undefined },
] as const;

export function AdministrationPage() {
  const theme = useTheme();
  const { tab } = useParams();
  const navigate = useNavigate();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const canViewAssessorPanelList = doesUserHaveSinglePermission(
    'admin.fraew-assessor-list'
  );
  const canViewBenchmarkFigures = doesUserHaveSinglePermission(
    'admin.benchmark-figures.view'
  );
  const canViewPtfsFigures = doesUserHaveSinglePermission(
    'admin.ptfs.view'
  );
  const canViewTeamManagement = doesUserHaveSinglePermission(
    'admin.team-management.view'
  );
  const canViewOrganisationManagement = doesUserHaveSinglePermission(
    'admin.organisation-management.view'
  );

  const [selectedTab, setSelectedTab] = useState<EAdministrationTabType>(() => {
    if (!tab) return EAdministrationTabType.Teams;
    
    switch (tab) {
      case EAdministrationTabType.Roles:
        return EAdministrationTabType.Roles;
      case EAdministrationTabType.PTFS:
        return EAdministrationTabType.PTFS;
      case EAdministrationTabType.SystemNotifications:
        return EAdministrationTabType.SystemNotifications;
      case EAdministrationTabType.AssessorPanelList:
        return EAdministrationTabType.AssessorPanelList;
      case EAdministrationTabType.BenchmarkFigures:
        return EAdministrationTabType.BenchmarkFigures;
      case EAdministrationTabType.Organisation:
        return EAdministrationTabType.Organisation;
      case EAdministrationTabType.Teams:
        return EAdministrationTabType.Teams;
      default:
        return EAdministrationTabType.Teams;
    }
  });

  const handleChange = (newValue: string) => {
    navigate(('/admin/' + newValue) as EAdministrationTabType);
    setSelectedTab(newValue as EAdministrationTabType);
  };

  return (
    <MainContentContainer>
      <Breadcrumbs crumbs={_crumbs} />

      <PageTitle title="Admin" />

      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <nav>
            <Tabs
              onChange={(_, value) => {
                handleChange(value);
              }}
              aria-label="Administration Tabs"
              value={selectedTab}
            >
              {canViewTeamManagement && (
                <CustomTab
                  label="Team Management"
                  value={EAdministrationTabType.Teams}
                  currentSelectedTab={selectedTab}
                />
              )}
              {canViewOrganisationManagement && (
                <CustomTab
                  label="Organisation Management"
                  value={EAdministrationTabType.Organisation}
                  currentSelectedTab={selectedTab}
                />
              )}
              <CustomTab
                label="Role Management"
                value={EAdministrationTabType.Roles}
                currentSelectedTab={selectedTab}
              />
              <CustomTab
                label="Users"
                value={EAdministrationTabType.Users}
                currentSelectedTab={selectedTab}
              />
              {canViewPtfsFigures && (
                <CustomTab
                  label="PTFS Benchmark Figure"
                  value={EAdministrationTabType.PTFS}
                  currentSelectedTab={selectedTab}
                />
              )}
              <CustomTab
                label="System Notifications"
                value={EAdministrationTabType.SystemNotifications}
                currentSelectedTab={selectedTab}
              />
              {canViewAssessorPanelList && (
                <CustomTab
                  label="Assessor Panel List"
                  value={EAdministrationTabType.AssessorPanelList}
                  currentSelectedTab={selectedTab}
                />
              )}
              {canViewBenchmarkFigures && (
                <CustomTab
                  label="Benchmark Figures"
                  value={EAdministrationTabType.BenchmarkFigures}
                  currentSelectedTab={selectedTab}
                />
              )}
            </Tabs>
          </nav>
        </Box>
        <Box sx={{ borderTop: 1, borderColor: theme.palette.divider }}>
          {canViewTeamManagement ? (<CustomTabPanel
            currentSelectedTab={selectedTab}
            value={EAdministrationTabType.Teams}
            sx={{ p: 3 }}
          >
            <AdministrationTeamsTab />
          </CustomTabPanel>
          ) : null}
          <CustomTabPanel
            currentSelectedTab={selectedTab}
            value={EAdministrationTabType.Organisation}
            sx={{ p: 3 }}
          >
            <AdministrationOrgsTab />
          </CustomTabPanel>
          <CustomTabPanel
            currentSelectedTab={selectedTab}
            value={EAdministrationTabType.Users}
            sx={{ p: 3 }}
          >
            <AdministrationUsersTab />
          </CustomTabPanel>
          <CustomTabPanel
            currentSelectedTab={selectedTab}
            value={EAdministrationTabType.Roles}
            sx={{ p: 3 }}
          >
            <AdministrationRolesTab />
          </CustomTabPanel>
          {canViewPtfsFigures ? (
            <CustomTabPanel
              currentSelectedTab={selectedTab}
              value={EAdministrationTabType.PTFS}
              sx={{ p: 3 }}
            >
              <AdministrationPTFSTab />
            </CustomTabPanel>
          ) : null}
          <CustomTabPanel
            currentSelectedTab={selectedTab}
            value={EAdministrationTabType.SystemNotifications}
            sx={{ p: 3 }}
          >
            <AdministrationSystemNotificationsTab />
          </CustomTabPanel>
          {canViewAssessorPanelList ? (
            <CustomTabPanel
              currentSelectedTab={selectedTab}
              value={EAdministrationTabType.AssessorPanelList}
              sx={{ p: 3 }}
            >
              <AdministrationAssessorPanelListTab />
            </CustomTabPanel>
          ) : null}
          {canViewBenchmarkFigures ? (
            <CustomTabPanel
              currentSelectedTab={selectedTab}
              value={EAdministrationTabType.BenchmarkFigures}
              sx={{ p: 3 }}
            >
              <AdministrationBenchmarkFiguresTab />
            </CustomTabPanel>
          ) : null}
        </Box>
      </Grid>
    </MainContentContainer>
  );
}
