import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useGetVendorAccountSetupSummaryQuery,
  useGetVendorPaymentDetailsQuery,
  useUpdateVendorInfoSentStatusMutation,
} from 'api/application/paymentApi';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { FormSkeleton } from 'common/components/skeletons';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  EVendorAccountSetupStatus,
  IVendorInfoSentStatusForm,
} from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';
import { Intro } from 'pages/applicationPage/content/payments/common';
import { useApplicationPaymentsContext } from 'pages/applicationPage/content/payments/context';

export const VendorAccountSentConfirmationSection = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, data, isError, error } =
    useGetVendorPaymentDetailsQuery(applicationId);

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isSuccess) {
    const initialData: IVendorInfoSentStatusForm = {
      detailsSentToHomesEngland: data.detailsSentToHomesEngland ?? false,
      detailsSentEvidenceFile: data.detailsSentEvidenceFile,
      detailsSentEvidenceFileId: data.detailsSentEvidenceFile?.id ?? '',
    };

    return (
      <VendorAccountSentConfirmationSectionMain initialFormData={initialData} />
    );
  }

  return <AlertForQueryErrorOrNull isError={isError} error={error} />;
};

const _fileInputName = 'detailsSentEvidenceFile';

const VendorAccountSentConfirmationSectionMain = ({
  initialFormData,
}: {
  initialFormData: IVendorInfoSentStatusForm;
}) => {
  const { applicationId } = useApplicationContext();
  const { readOnly } = useApplicationPaymentsContext();

  const { data: vendorAccountSummaryData } =
    useGetVendorAccountSetupSummaryQuery(applicationId);

  const { data: vendorInfoSentServerData } =
    useGetVendorPaymentDetailsQuery(applicationId);

  const isSectionReadOnly =
    readOnly ||
    vendorAccountSummaryData?.status !== EVendorAccountSetupStatus.NotStarted ||
    (vendorInfoSentServerData?.detailsSentToHomesEngland ?? false);

  const [updateStatus, updateStatusResult] =
    useUpdateVendorInfoSentStatusMutation();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const { uploadFile, uploadFileStatus } = useFileUpload();

  const form = useForm<IVendorInfoSentStatusForm>({
    defaultValues: initialFormData,
  });
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, isSubmitting },
  } = form;

  const [watchVendorInfoSentLocal, watchVendorInfoSentEvidenceFile] = watch([
    'detailsSentToHomesEngland',
    'detailsSentEvidenceFile',
  ]);

  const onSubmit = async () => {
    if (readOnly) {
      createErrorSnackbar(`You don't have permission`);
      return;
    }
    const formData = getValues();

    await updateStatus({
      ...formData,
      applicationId,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Status updated');
        reset({}, { keepValues: true });
      });
  };

  const handleOnFileUpload = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'VendorInfoSent',
      onSuccess: payload => {
        form.setValue(`${_fileInputName}Id`, payload.id);
        form.setValue(_fileInputName, payload);
      },
    });
  };

  const handleOnDeleteFileUpload = async () => {
    form.setValue(`${_fileInputName}Id`, '');
    form.setValue(_fileInputName, null);
  };

  return (
    <FormProvider {...form}>
      <LeavePageGuard
        blockNavigationIf={isDirty && !isSubmitting}
        message="Are you sure you want to leave? Any unsaved changes to the Vendor Creation section will be lost."
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <AlertForChangedData isDirty={isDirty} sx={{ mx: 0, mb: 4 }} />

        <Box>
          <Box>
            <Intro heading="Confirm that the 'Vendor Set up Details' has been sent to Homes England" />
          </Box>

          <Box bgcolor="grey.100" p={2} mb={3}>
            <Controller
              control={control}
              name={'detailsSentToHomesEngland'}
              render={({ field: { onChange, value, ...fieldProps } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...fieldProps}
                      checked={value}
                      onChange={(_, val) => {
                        if (val !== null) {
                          onChange(val);
                        }
                      }}
                      disabled={isSectionReadOnly}
                      data-automation-id="checkbox_vendor_setup_sent"
                    />
                  }
                  label="I have sent the Vendor Set up Details"
                />
              )}
            />
          </Box>
        </Box>

        {watchVendorInfoSentLocal ? (
          <Controller
            control={control}
            name={'detailsSentEvidenceFileId'}
            rules={{
              required: 'Evidence that the file has been sent is required.',
            }}
            render={props => {
              return (
                <Box>
                  <Intro
                    heading={
                      props.field.value
                        ? 'Evidence of file being sent'
                        : 'Upload evidence'
                    }
                  />
                  {props.field.value &&
                  watchVendorInfoSentEvidenceFile?.id &&
                  watchVendorInfoSentEvidenceFile?.id ===
                    vendorInfoSentServerData?.detailsSentEvidenceFile?.id ? (
                    <Box display="flex" flexDirection="row">
                      <ApplicationFileDownloadWrapper
                        fileName={watchVendorInfoSentEvidenceFile?.name ?? ''}
                        fileId={watchVendorInfoSentEvidenceFile?.id ?? ''}
                        fileWrapperStyle={{ mb: 0 }}
                      />
                      {!isSectionReadOnly ? (
                        <IconButton
                          size="small"
                          onClick={() => handleOnDeleteFileUpload()}
                          aria-label="Delete File"
                          name="Delete File"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      ) : null}
                    </Box>
                  ) : (
                    <>
                      {!isSectionReadOnly ? (
                        <>
                          <FileUploadInputSelect
                            id={_fileInputName}
                            inputName={_fileInputName}
                            label="Click to upload or drag and drop file (max. 30MB)"
                            accept=".pdf,.docx,.xlsx,.msg,.eml,.jpg,.jpeg,.png"
                            onFileUpload={file => handleOnFileUpload(file)}
                            onFileDelete={() => handleOnDeleteFileUpload()}
                            isUploading={uploadFileStatus.isLoading}
                            uploaded={uploadFileStatus.isSuccess}
                          />

                          {props.fieldState.error && (
                            <FormHelperText error>
                              {props.fieldState.error.message}
                            </FormHelperText>
                          )}
                        </>
                      ) : null}
                    </>
                  )}
                </Box>
              );
            }}
          />
        ) : null}

        <AlertForQueryErrorOrNull
          isError={updateStatusResult.isError}
          error={updateStatusResult.error}
        />

        {!isSectionReadOnly && (
          <Box
            display="flex"
            justifyContent={'flex-end'}
            gap={2}
            p={3}
            bgcolor="grey.100"
            mt={2}
          >
            <Button variant="outlined" onClick={() => reset()} data-automation-id="button_vendor_setup_cancel">
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={updateStatusResult.isLoading}
              data-automation-id="button_vendor_setup_confirm"
            >
              Confirm Details
            </LoadingButton>
          </Box>
        )}
      </form>
    </FormProvider>
  );
};
