import { useCallback } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { GridSearchField } from 'common/components/gridSearchField';
import { useAppDispatch } from 'state';
import {
  selectAdministrationOrgsGridState,
  selectIsAdministrationOrgsGridLoading,
} from 'state/selectors/administration/administrationOrgsSelectors';
import { gridStateChanged } from 'state/slices/administration/administrationOrganisationsReducer';
import {
  StyledFilter,
  StyledFiltersGridLeft
} from 'styles/globalStyles/filterStyles';

export function AdministrationOrgsFilters() {
  const dispatch = useAppDispatch();

  const administrationOrgsGridState = useSelector(
    selectAdministrationOrgsGridState
  );

  const isAdministrationOrgsGridLoading = useSelector(
    selectIsAdministrationOrgsGridLoading
  );

  const handleSearchCallback = useCallback(
    (searchValue: string) => {
      dispatch(
        gridStateChanged({
          searchValue,
          skip: 0,
        })
      );
    },
    [dispatch]
  );

  return (
    <Grid container pt={4}>
      <StyledFiltersGridLeft item xs={8}>
        <StyledFilter>
          <GridSearchField
            isLoading={isAdministrationOrgsGridLoading}
            defaultValue={administrationOrgsGridState.searchValue}
            handleSearch={handleSearchCallback}
          />
        </StyledFilter>
      </StyledFiltersGridLeft>
    </Grid>
  );
}
