import { Button, Typography } from '@mui/material';
import { useReferToGLAMutation } from 'api/application';
import { useLocalSnackbar } from 'hooks';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IReferToGLADialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function ReferToGLADialog({
  onConfirm,
  onCancel,
}: IReferToGLADialogProps) {
  const { applicationId } = useApplicationEligibilityContext();

  const [referToGLA] = useReferToGLAMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onReferToGLA = async () => {
    try {
      await referToGLA(applicationId);
      createSuccessSnackbar('Successfully referred application to GLA');
      onConfirm();
    } catch (err) {
      createErrorSnackbar(`Failed to refer application to GLA`);
    }
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        Are you sure you want to refer this application to the GLA for the
        Building Safety Fund?
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>
          Please check that this building is above 18m and the local authority
          is in Greater London
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button data-automation-id="ReferToGLA_No" variant="outlined" onClick={onCancel}>
          No
        </Button>
        <Button data-automation-id="ReferToGLA_Yes" variant="contained" onClick={onReferToGLA}>
          Yes
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
