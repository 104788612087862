import { useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Box } from '@mui/system';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useGetVendorAccountSetupSummaryQuery,
  useUpdateVendorPaymentDetailsApprovalStatusMutation,
} from 'api/application/paymentApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect';
import { FormSkeleton } from 'common/components/skeletons';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ReferApplicationHomesEnglandNotificationBanner } from 'pages/applicationPage/content/applicationGrantFunding/components';
import {
  EVendorAccountSetupStatus,
  IVendorPaymentDetailsApprovalForm,
} from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';
import { Intro } from 'pages/applicationPage/content/payments/common';
import { useApplicationPaymentsContext } from 'pages/applicationPage/content/payments/context';

export const VendorAccountApprovalSection = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, data, isError, error } =
    useGetVendorAccountSetupSummaryQuery(applicationId);

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isSuccess) {
    const initialData: IVendorPaymentDetailsApprovalForm = {
      paymentDetailsApproved: data.paymentDetailsApproved ?? '',
      paymentApprovalReason: '',
      paymentApprovalEvidenceFile: null,
      paymentApprovalEvidenceFileId: '',
    };

    return <VendorAccountApprovalSectionMain initialFormData={initialData} />;
  }

  return <AlertForQueryErrorOrNull isError={isError} error={error} />;
};

const _fileInputName = 'paymentApprovalEvidenceFile';

const VendorAccountApprovalSectionMain = ({
  initialFormData,
}: {
  initialFormData: IVendorPaymentDetailsApprovalForm;
}) => {
  const { applicationId } = useApplicationContext();
  const { readOnly } = useApplicationPaymentsContext();

  const { data: vendorAccountSummaryData } =
    useGetVendorAccountSetupSummaryQuery(applicationId);

  const isSectionStatusComplete =
    vendorAccountSummaryData?.status === EVendorAccountSetupStatus.Complete;

  const isSectionReadOnly = readOnly || isSectionStatusComplete;

  const [updateApprovalStatus, { isError, error, isLoading }] =
    useUpdateVendorPaymentDetailsApprovalStatusMutation();

  const form = useForm<IVendorPaymentDetailsApprovalForm>({
    defaultValues: initialFormData,
  });
  const { control, handleSubmit, reset, watch, getValues, setError } = form;

  const [watchPaymentDetailsApproved, watchPaymentApprovalEvidenceFile] = watch(
    ['paymentDetailsApproved', 'paymentApprovalEvidenceFile']
  );

  const [
    paymentApprovalEvidenceFileInvalid,
    setPaymentApprovalEvidenceFileInvalid,
  ] = useState<boolean>(false);

  const { uploadFile, uploadFileStatus } = useFileUpload();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } = useLocalSnackbar();

  const validForm = (formData: IVendorPaymentDetailsApprovalForm) => {
    let valid = true;
    setPaymentApprovalEvidenceFileInvalid(false);

    if (
      formData.paymentApprovalEvidenceFileId === undefined ||
      formData.paymentApprovalEvidenceFileId === ''
    ) {
      setPaymentApprovalEvidenceFileInvalid(true);
      valid = false;
    }

    if (!valid) {
      createWarningSnackbar(
        'Please correct any form validation errors shown, and then try again.'
      );
    }

    return valid;
  };

  const onSubmit = async () => {
    if (readOnly) {
      createErrorSnackbar(`You don't have permission`);
      return;
    }
    const formData = getValues();

    // Only validate if paymentDetailsApproved has a value i.e. has been set to true or false
    if (formData.paymentDetailsApproved === '') {
      setError('paymentDetailsApproved', {
        message: 'Please choose an option',
      });
      return false;
    }

    if (validForm(formData)) {
      await updateApprovalStatus({
        applicationId,
        approved: formData.paymentDetailsApproved,
        reason: formData.paymentApprovalReason,
        approvalEvidenceFileId: formData.paymentApprovalEvidenceFileId,
      })
        .unwrap()
        .then(() => {
          createSuccessSnackbar('Approval status updated');
          reset({
            ...initialFormData,
            paymentDetailsApproved: formData.paymentDetailsApproved,
          });
        });
    }
  };

  const handleOnFileUpload = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'VendorPaymentDetailsApproval',
      onSuccess: payload => {
        form.setValue(`${_fileInputName}Id`, payload.id);
        form.setValue(`paymentApprovalEvidenceFile`, payload);
      },
    });
  };

  const handleOnDeleteFileUpload = async () => {
    form.setValue(`${_fileInputName}Id`, '');
    form.setValue(`paymentApprovalEvidenceFile`, null);
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={3}>
            <Box>
              <Intro heading="Have the payment details been approved by Homes England?" />
              <Controller
                defaultValue={initialFormData.paymentDetailsApproved}
                control={control}
                name="paymentDetailsApproved"
                render={({
                  fieldState,
                  field: { onChange, ...fieldProps },
                }) => (
                  <FormControl>
                    <ToggleButtonGroup
                      {...fieldProps}
                      onChange={(_, val) => {
                        if (val !== null) {
                          onChange(val);
                        }
                      }}
                      exclusive
                      disabled={isSectionReadOnly}
                    >
                      <ToggleButton value={true} data-automation-id="toggle-button-payment-approved-yes">Yes</ToggleButton>
                      <ToggleButton value={false} data-automation-id="toggle-button-payment-approved-no">No</ToggleButton>
                    </ToggleButtonGroup>

                    {fieldState.error ? (
                      <FormHelperText error>
                        {fieldState.error.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            </Box>
            {watchPaymentDetailsApproved === false && (
              <ReferApplicationHomesEnglandNotificationBanner />
            )}
            {watchPaymentDetailsApproved !== '' && !isSectionStatusComplete ? (
              <>
                <Controller
                  control={control}
                  name={'paymentApprovalReason'}
                  rules={{
                    validate: {
                      required: value => {
                        if (
                          !value &&
                          getValues('paymentDetailsApproved') === false
                        ) {
                          return 'Reason is required';
                        }

                        return true;
                      },
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!fieldState.error}
                      label="Provide Reason"
                      data-automation-id="input-payment-approval-reason"
                      helperText={fieldState.error?.message}
                      multiline
                      rows={4}
                      disabled={isSectionReadOnly}
                      sx={{ mb: 0 }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="paymentApprovalEvidenceFileId"
                  rules={{
                    validate: {
                      required: value => {
                        if (
                          !value &&
                          getValues('paymentDetailsApproved') === true
                        ) {
                          return 'An evidence file is required if setting approval to Yes';
                        }

                        return true;
                      },
                    },
                  }}
                  render={props => {
                    return (
                      <Box>
                        <Intro heading="Upload evidence" />

                        {props.field.value &&
                        watchPaymentApprovalEvidenceFile?.id &&
                        watchPaymentApprovalEvidenceFile?.id ===
                          initialFormData?.paymentApprovalEvidenceFileId ? (
                          <Box display="flex" flexDirection="row">
                            <ApplicationFileDownloadWrapper
                              fileName={
                                watchPaymentApprovalEvidenceFile?.name ?? ''
                              }
                              fileId={
                                watchPaymentApprovalEvidenceFile?.id ?? ''
                              }
                            />
                            <IconButton
                              size="small"
                              onClick={() => handleOnDeleteFileUpload()}
                              aria-label="Delete File"
                              name="Delete File"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          </Box>
                        ) : (
                          <>
                            {!isSectionReadOnly ? (
                              <>
                                <FileUploadInputSelect
                                  id={_fileInputName}
                                  inputName={_fileInputName}
                                  label="Click to upload or drag and drop file (max. 30MB)"
                                  accept=".pdf,.docx,.xlsx,.msg,.eml,.jpg,.jpeg,.png"
                                  onFileUpload={file =>
                                    handleOnFileUpload(file)
                                  }
                                  onFileDelete={() =>
                                    handleOnDeleteFileUpload()
                                  }
                                  isUploading={uploadFileStatus.isLoading}
                                  uploaded={uploadFileStatus.isSuccess}
                                />

                                {paymentApprovalEvidenceFileInvalid && (
                                  <FormHelperText error>
                                    Evidence file is required.
                                  </FormHelperText>
                                )}
                              </>
                            ) : null}
                          </>
                        )}
                      </Box>
                    );
                  }}
                />
                {isError && <FormHelperText error>{error}</FormHelperText>}
                {!isSectionReadOnly && (
                  <Box
                    display="flex"
                    justifyContent={'flex-end'}
                    gap={2}
                    p={3}
                    bgcolor="grey.100"
                  >
                    <Button variant="outlined" onClick={() => reset()} data-automation-id="button-payment-approval-cancel">
                      Cancel
                    </Button>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isLoading}
                      disabled={uploadFileStatus.isLoading}
                      data-automation-id="button-payment-approval-confirm"
                    >
                      Confirm Details
                    </LoadingButton>
                  </Box>
                )}
              </>
            ) : null}
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};