import { Box, Grid, Typography } from '@mui/material';
import { useGetUkBankAccountDetailsQuery } from 'api/application';
import { EQuestionType } from 'enums/EQuestionType';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ApplicationDetailsAnswerEditedIndicator } from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsAnswerEditedIndicator';
import { ApplicationInfoPanelContextMenu } from 'pages/applicationPage/content/applicationDetails/components/ApplicationInfoPanelContextMenu';
import { extractErrorMessages } from 'util/ApiUtils';

type BankDetailsProps = {
  largeTitle?: boolean;
};

export const BankDetails = ({ largeTitle = false }: BankDetailsProps) => {
  const { createErrorSnackbar } = useLocalSnackbar();
  const { applicationId } = useApplicationContext();
  const { isLoading, data, isError, error } =
    useGetUkBankAccountDetailsQuery(applicationId);

  const inlineData: [string, string][] = [
    ['Sortcode', data?.sortCode ?? ''],
    ['Account Number', data?.accountNumber ?? ''],
    ['Account Name', data?.accountName ?? ''],
    ['Bank Name', data?.bankName ?? ''],
    ['Bank Branch', data?.branchName ?? ''],
  ];

  const PaymentDetailsSectionInline = ({
    title,
    value,
  }: {
    title: string;
    value: string;
  }) => {
    return (
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="body1" fontSize={'0.95em'}>
            {title}:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" fontWeight={600}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const DataTitle = ({ title }: { title: string }) => {
    return (
      <Typography
        variant="body1"
        color="grey.700"
        fontWeight={'600'}
        fontSize={'0.95em'}
      >
        {title}
      </Typography>
    );
  };

  return (
    <>
      {isError && createErrorSnackbar(extractErrorMessages(error))}
      {isLoading && <div>Loading...</div>}
      {data && (
        <>
          <Box>
            <Grid container gap={1} justifyContent={'space-between'}>
              {largeTitle ? (
                <Grid item>
                  <Typography variant="h2">Bank Details</Typography>
                </Grid>
              ) : (
                <DataTitle title="Bank Details" />
              )}
              <Grid item gap={1}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2px',
                  }}
                >
                  <ApplicationDetailsAnswerEditedIndicator
                    questionType={EQuestionType.UkBankAccountDetails}
                  />
                  <ApplicationInfoPanelContextMenu
                    questionType={EQuestionType.UkBankAccountDetails}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          {inlineData.map(([title, value], i) => (
            <PaymentDetailsSectionInline key={i} title={title} value={value} />
          ))}
        </>
      )}
    </>
  );
};
