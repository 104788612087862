import {
  Box,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { v4 as uuidv4 } from 'uuid';
import { useGetPaymentRequestCostEvidenceDocumentHistoryQuery } from 'api/application/paymentRequestApi';
import { Loading } from 'common/components/loading';
import { IFile } from 'common/types/File';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import { IViewPaymentRequestCostEvidenceAuditHistoryDialogProps } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { getUkDateTimeStringFromJsonDateString } from 'util/AppUtils';

export function ViewPaymentRequestCostEvidenceAuditHistoryDialog({
  questionType,
  onClose,
}: IViewPaymentRequestCostEvidenceAuditHistoryDialogProps) {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
        questionType: questionType,
      }
    : skipToken;

  const { data, isLoading } =
    useGetPaymentRequestCostEvidenceDocumentHistoryQuery(request);

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDialog open fullWidth maxWidth="lg">
      <StyledDialogTitle sx={{ textAlign: 'left' }}>History</StyledDialogTitle>
      <StyledDialogContent>
        {data && data.length > 0 ? (
          <StyledGenericTable>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Original Value</TableCell>
                <TableCell>New Value</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Date/Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(item => {
                return (
                  <TableRow key={uuidv4()}>
                    <TableCell>{item.field}</TableCell>
                    <TableCell>
                      {!item.isFile ? (
                        item.oldValue
                      ) : item.oldValue ? (
                        <ApplicationFileDownloadWrapper
                          fileName={(item.oldValue as IFile).name}
                          fileId={(item.oldValue as IFile).id}
                        />
                      ) : (
                        <></>
                      )}
                    </TableCell>
                    <TableCell>
                      {!item.isFile ? (
                        item.newValue
                      ) : item.newValue ? (
                        <ApplicationFileDownloadWrapper
                          fileName={(item.newValue as IFile).name}
                          fileId={(item.newValue as IFile).id}
                        />
                      ) : (
                        <></>
                      )}
                    </TableCell>
                    <TableCell>{item.changedByUser}</TableCell>
                    <TableCell>
                      {getUkDateTimeStringFromJsonDateString(
                        item.dateChanged.toString()
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledGenericTable>
        ) : (
          <NoAuditHistoryComponent />
        )}
      </StyledDialogContent>
      <StyledDialogActions
        sx={{ justifyContent: 'right', paddingRight: '2rem' }}
      >
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}

export function NoAuditHistoryComponent() {
  const theme = useTheme();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={2}
      pl={0}
      borderColor={theme.palette.divider}
    >
      <Box padding="5px">
        <Typography variant="h1" component="span">
          There has been no changes made to this question
        </Typography>
      </Box>
    </Grid>
  );
}
