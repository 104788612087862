import { forwardRef, useImperativeHandle } from 'react';
import { EQuestionType } from 'enums/EQuestionType';
import { useModalState } from 'hooks';
import { EditAddressDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditAddressDrawer';
import { EditAlternateFundingRoutesDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditAlternateFundingRoutesDrawer';
import { EditAnyNonResidentialUnitsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditAnyNonResidentialUnitsDrawer';
import { EditApplicationFileDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditApplicationFileDrawer';
import { EditAssessmentTypeDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditAssessmentTypeDrawer';
import { EditBankAccountVerificationContactDetailsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditBankAccountVerificationContactDetailsDrawer';
import { EditBsrCodeDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditBsrCodeDrawer';
import { EditBsrRegistrationCodeDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditBsrRegistrationCodeDrawer';
import { EditBuildingHeightDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditBuildingHeightDrawer';
import { EditBuildingLocalAuthorityDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditBuildingLocalAuthorityDrawer';
import { EditBuildingNameDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditBuildingNameDrawer';
import { EditBuildingNumberOfStoreysDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditBuildingNumberOfStoreysDrawer';
import { EditCaveatsOrLimitationsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditCaveatsOrLimitationsDrawer';
import { EditCompanyDetailsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditCompanyDetailsDrawer';
import { EditContactDetailsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditContactDetailsDrawer';
import { EditCostEffectiveCourseOfActionDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditCostEffectiveCourseOfActionDrawer';
import { EditEvidenceForAuthorisationDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditEvidenceForAuthorisationDrawer';
import { EditEvidenceForChiefExecutiveDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditEvidenceForChiefExecutiveDrawer';
import { EditEvidenceForS151OfficerDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditEvidenceForS151Officer';
import { EditFraewCladdingSystemTypesDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewCladdingSystemTypesDrawer';
import { EditFraewCostDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewCostDrawer';
import { EditFraewCurrentEvacuationStrategyDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewCurrentEvacuationStrategyDrawer';
import { EditFraewExternalManagementPoliciesDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewExternalManagementPoliciesDrawer';
import { EditFraewExternalWorksDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewExternalWorksDrawer';
import { EditFraewFireRescueServiceAccessRestrictionsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewFireRescueServiceAccessRestrictionsDrawer';
import { EditFraewInstructionDateDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewInstructionDateDrawer';
import { EditFraewInterimMeasuresDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewInterimMeasuresDrawer';
import { EditFraewInternalWorksDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewInternalWorksDrawer';
import { EditFraewNumberOfStairwellsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewNumberOfStairwellsDrawer';
import { EditFraewPeerReviewerDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewPeerReviewerDrawer';
import { EditFraewReportAuthorDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewReportAuthorDrawer';
import { EditFraewSurveyCompanyDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewSurveyCompanyDrawer';
import { EditFraewSurveyDateDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditFraewSurveyDateDrawer';
import { EditGfaCounterSignedDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditGfaCounterSignedDrawer';
import { EditLeaseholderEngagementDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditLeaseholderEngagementDrawer';
import { EditLifeSafetyRiskAssessmentDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditLifeSafetyRiskAssessmentDrawer';
import { EditNumberOfResidentialUnitsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditNumberOfResidentialUnitsDrawer';
import { EditNumberOfStoreysDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditNumberOfStoreysDrawer';
import { EditOriginalDeveloperDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditOriginalDeveloperDrawer';
import { EditOtherOptionsConsideredDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditOtherOptionsConsideredDrawer';
import { EditPartOfDevelopmentDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditPartOfDevelopmentDrawer';
import { EditProjectMilestonesDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditProjectMilestonesDrawer';
import { EditPtfsAmountDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditPtfsAmountDrawer';
import { EditPtfsRequiredDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditPtfsRequiredDrawer';
import { EditRecommendCladdingDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditRecommendCladdingDrawer';
import { EditRecommendedInterimMeasuresDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditRecommendedInterimMeasuresDrawer';
import { EditRiskSafetyMitigationDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditRiskSafetyMitigationDrawer';
import { EditTotalCladdingAreaDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditTotalCladdingAreaDrawer';
import { EditUkBankAccountDetailsDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditUkBankAccountDetailsDrawer';
import { EditVatNumberDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditVatNumberDrawer';
import { EditWhoseBankAccountDrawer } from 'pages/applicationPage/content/applicationDetails/dialogs/EditWhoseBankAccountDrawer';

export interface IApplicationDetailsEditDialogs {
  displayEditUkBankAccountDetailsDrawer: () => void;
  displayEditBankAccountVerificationContactDetailsDrawer: () => void;
  displayEditPtfsRequiredDrawer: () => void;
  displayEditPtfsAmountDrawer: () => void;
  displayEditVatNumberDrawer: () => void;
  displayEditWhoseBankAccountDrawer: () => void;
  displayEditAddressDrawer: () => void;
  displayEditCompanyDetailsDrawer: () => void;
  displayEditContactDetailsDrawer: () => void;
  displayEditBuildingHeightDrawer: () => void;
  displayEditNumberOfStoreysDrawer: () => void;
  displayEditTotalCladdingAreaDrawer: () => void;
  displayEditLifeSafetyRiskAssessmentDrawer: () => void;
  displayEditAssessmentTypeDrawer: () => void;
  displayEditRecommendCladdingDrawer: () => void;
  displayEditApplicationFileDrawer: () => void;
  displayViewQuestionAuditHistoryDialog: () => void;
  displayEditBsrRegistrationCodeDrawer: () => void;
  displayEditBuildingNameDrawer: () => void;
  displayEditFraewSurveyCompanyDrawer: () => void;
  displayEditFraewSurveyDateDrawer: () => void;
  displayEditFraewInstructionDateDrawer: () => void;
  displayEditFraewReportAuthorDrawer: () => void;
  displayEditFraewPeerReviewerDrawer: () => void;
  displayEditFraewCostDrawer: () => void;
  displayEditProjectMilestonesDrawer: () => void;
  displayEditFraewInterimMeasuresDrawer: () => void;
  displayEditFraewCurrentEvacuationStrategyDrawer: () => void;
  displayEditFraewNumberOfStairwellsDrawer: () => void;
  displayEditFraewExternalManagementPoliciesDrawer: () => void;
  displayEditFraewFireRescueServiceAccessRestrictionsDrawer: () => void;
  displayEditBsrCodeDrawer: () => void;
  displayEditOriginalDeveloperDrawer: () => void;
  displayEditNumberOfResidentialUnitsDrawer: () => void;
  displayEditAnyNonResidentialUnitsDrawer: () => void;
  displayEditPartOfDevelopmentDrawer: () => void;
  displayEditBuildingNumberOfStoreysDrawer: () => void;
  displayEditAlternateFundingRoutesDrawer: () => void;
  displayEditRiskSafetyMitigationDrawer: () => void;
  displayEditCostEffectiveCourseOfActionDrawer: () => void;
  displayEditOtherOptionsConsideredDrawer: () => void;
  displayEditCaveatsOrLimitationsDrawer: () => void;
  displayEditRecommendedInterimMeasuresDrawer: () => void;
  displayEditLeaseholderEngagementDrawer: () => void;
  displayFraewInternalWorksDrawer: () => void;
  displayFraewExternalWorksDrawer: () => void;
  displayFraewCladdingSystemTypesDrawer: () => void;
  displayEditEvidenceForAuthoristionDrawer: () => void;
  displayEditEvidenceForChiefExecutiveDrawer: () => void;
  displayEditEvidenceForS151OfficerDrawer: () => void;
  displayEditGfaCounterSignedDrawer: () => void;
  displayEditBuildingLocalAuthorityDrawer: () => void;
}

export interface IApplicationDetailsEditDialogProps {
  questionType?: EQuestionType;
  currentValue?: string;
}

export const ApplicationDetailsEditDialogs = forwardRef(
  (props: IApplicationDetailsEditDialogProps, ref) => {
    const {
      isShowing: isEditUkBankAccountDetailsDrawerShowing,
      showModal: showEditUkBankAccountDetailsDrawer,
      hideModal: hideEditUkBankAccountDetailsDrawer,
    } = useModalState();

    const {
      isShowing: isEditBankAccountVerificationContactDetailsDrawerShowing,
      showModal: showEditBankAccountVerificationContactDetailsDrawer,
      hideModal: hideEditBankAccountVerificationContactDetailsDrawer,
    } = useModalState();

    const {
      isShowing: isEditPtfsRequiredDrawerShowing,
      showModal: showEditPtfsRequiredDrawer,
      hideModal: hideEditPtfsRequiredDrawer,
    } = useModalState();

    const {
      isShowing: isEditPtfsAmountDrawerShowing,
      showModal: showEditPtfsAmountDrawer,
      hideModal: hideEditPtfsAmountDrawer,
    } = useModalState();

    const {
      isShowing: isEditVatNumberDrawerShowing,
      showModal: showEditVatNumberDrawer,
      hideModal: hideEditVatNumberDrawer,
    } = useModalState();

    const {
      isShowing: isEditWhoseBankAccountDrawerShowing,
      showModal: showEditWhoseBankAccountDrawer,
      hideModal: hideEditWhoseBankAccountDrawer,
    } = useModalState();

    const {
      isShowing: isEditAddressDrawerShowing,
      showModal: showEditAddressDrawer,
      hideModal: hideEditAddressDrawer,
    } = useModalState();

    const {
      isShowing: isEditCompanyDetailsDrawerShowing,
      showModal: showEditCompanyDetailsDrawer,
      hideModal: hideEditCompanyDetailsDrawer,
    } = useModalState();

    const {
      isShowing: isEditContactDetailsDrawerShowing,
      showModal: showEditContactDetailsDrawer,
      hideModal: hideEditContactDetailsDrawer,
    } = useModalState();

    const {
      isShowing: isEditBuildingHeightDrawerShowing,
      showModal: showEditBuildingHeightDrawer,
      hideModal: hideEditBuildingHeightDrawer,
    } = useModalState();

    const {
      isShowing: isEditNumberOfStoreysDrawerShowing,
      showModal: showEditNumberOfStoreysDrawer,
      hideModal: hideEditNumberOfStoreysDrawer,
    } = useModalState();

    const {
      isShowing: isEditTotalCladdingAreaDrawerShowing,
      showModal: showEditTotalCladdingAreaDrawer,
      hideModal: hideEditTotalCladdingAreaDrawer,
    } = useModalState();

    const {
      isShowing: isEditLifeSafetyRiskAssessmentDrawerShowing,
      showModal: showEditLifeSafetyRiskAssessmentDrawer,
      hideModal: hideEditLifeSafetyRiskAssessmentDrawer,
    } = useModalState();

    const {
      isShowing: isEditAssessmentTypeDrawerShowing,
      showModal: showEditAssessmentTypeDrawer,
      hideModal: hideEditAssessmentTypeDrawer,
    } = useModalState();

    const {
      isShowing: isEditRecommendCladdingDrawerShowing,
      showModal: showEditRecommendCladdingDrawer,
      hideModal: hideEditRecommendCladdingDrawer,
    } = useModalState();

    const {
      isShowing: isEditApplicationFileDrawerShowing,
      showModal: showEditApplicationFileDrawer,
      hideModal: hideEditApplicationFileDrawer,
    } = useModalState();

    const {
      isShowing: isEditBsrRegistrationCodeDrawerShowing,
      showModal: showEditBsrRegistrationCodeDrawer,
      hideModal: hideEditBsrRegistrationCodeDrawer,
    } = useModalState();

    const {
      isShowing: isEditBuildingNameDrawerShowing,
      showModal: showEditBuildingNameDrawer,
      hideModal: hideEditBuildingNameDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewSurveyCompanyDrawerShowing,
      showModal: showEditFraewSurveyCompanyDrawer,
      hideModal: hideEditFraewSurveyCompanyDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewSurveyDateDrawerShowing,
      showModal: showEditFraewSurveyDateDrawer,
      hideModal: hideEditFraewSurveyDateDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewInstructionDateDrawerShowing,
      showModal: showEditFraewInstructionDateDrawer,
      hideModal: hideEditFraewInstructionDateDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewReportAuthorDrawerShowing,
      showModal: showEditFraewReportAuthorDrawer,
      hideModal: hideEditFraewReportAuthorDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewPeerReviewerDrawerShowing,
      showModal: showEditFraewPeerReviewerDrawer,
      hideModal: hideEditFraewPeerReviewerDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewCostDrawerShowing,
      showModal: showEditFraewCostDrawer,
      hideModal: hideEditFraewCostDrawer,
    } = useModalState();

    const {
      isShowing: isEditProjectMilestonesDrawerShowing,
      showModal: showEditProjectMilestonesDrawer,
      hideModal: hideEditProjectMilestonesDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewInterimMeasuresDrawerShowing,
      showModal: showEditFraewInterimMeasuresDrawer,
      hideModal: hideEditFraewInterimMeasuresDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewCurrentEvacuationStrategyDrawerShowing,
      showModal: showEditFraewCurrentEvacuationStrategyDrawer,
      hideModal: hideEditFraewCurrentEvacuationStrategyDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewNumberOfStairwellsDrawerShowing,
      showModal: showEditFraewNumberOfStairwellsDrawer,
      hideModal: hideEditFraewNumberOfStairwellsDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewExternalManagementPoliciesDrawerShowing,
      showModal: showEditFraewExternalManagementPoliciesDrawer,
      hideModal: hideEditFraewExternalManagementPoliciesDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewFireRescueServiceAccessRestrictionsDrawer,
      showModal: showEditFraewFireRescueServiceAccessRestrictionsDrawer,
      hideModal: hideEditFraewFireRescueServiceAccessRestrictionsDrawer,
    } = useModalState();

    const {
      isShowing: isEditBsrCodeDrawerShowing,
      showModal: showEditBsrCodeDrawer,
      hideModal: hideEditBsrCodeDrawer,
    } = useModalState();

    const {
      isShowing: isEditOriginalDeveloperDrawerShowing,
      showModal: showEditOriginalDeveloperDrawer,
      hideModal: hideEditOriginalDeveloperDrawer,
    } = useModalState();

    const {
      isShowing: isEditNumberOfResidentialUnitsDrawerShowing,
      showModal: showEditNumberOfResidentialUnitsDrawer,
      hideModal: hideEditNumberOfResidentialUnitsDrawer,
    } = useModalState();

    const {
      isShowing: isEditAnyNonResidentialUnitsDrawerShowing,
      showModal: showEditAnyNonResidentialUnitsDrawer,
      hideModal: hideEditAnyNonResidentialUnitsDrawer,
    } = useModalState();

    const {
      isShowing: isEditPartOfDevelopmentDrawerShowing,
      showModal: showEditPartOfDevelopmentDrawer,
      hideModal: hideEditPartOfDevelopmentDrawer,
    } = useModalState();

    const {
      isShowing: isEditBuildingNumberOfStoreysDrawerShowing,
      showModal: showEditBuildingNumberOfStoreysDrawer,
      hideModal: hideEditBuildingNumberOfStoreysDrawer,
    } = useModalState();

    const {
      isShowing: isEditAlternateFundingRoutesDrawerShowing,
      showModal: showEditAlternateFundingRoutesDrawer,
      hideModal: hideEditAlternateFundingRoutesDrawer,
    } = useModalState();

    const {
      isShowing: isEditRiskSafetyMitigationDrawerShowing,
      showModal: showEditRiskSafetyMitigationDrawer,
      hideModal: hideEditRiskSafetyMitigationDrawer,
    } = useModalState();

    const {
      isShowing: isEditCostEffectiveCourseOfActionDrawerShowing,
      showModal: showEditCostEffectiveCourseOfActionDrawer,
      hideModal: hideEditCostEffectiveCourseOfActionDrawer,
    } = useModalState();

    const {
      isShowing: isEditOtherOptionsConsideredDrawerShowing,
      showModal: showEditOtherOptionsConsideredDrawer,
      hideModal: hideEditOtherOptionsConsideredDrawer,
    } = useModalState();

    const {
      isShowing: isEditCaveatsOrLimitationsDrawerShowing,
      showModal: showEditCaveatsOrLimitationsDrawer,
      hideModal: hideEditCaveatsOrLimitationsDrawer,
    } = useModalState();

    const {
      isShowing: isEditRecommendedInterimMeasuresShowing,
      showModal: showEditRecommendedInterimMeasuresDrawer,
      hideModal: hideEditRecommendedInterimMeasuresDrawer,
    } = useModalState();

    const {
      isShowing: isEditLeaseholderEngagementDrawerShowing,
      showModal: showEditLeaseholderEngagementDrawer,
      hideModal: hideEditLeaseholderEngagementDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewInternalWorksDrawerShowing,
      showModal: showEditFraewInternalWorksDrawer,
      hideModal: hideEditFraewInternalWorksDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewExternalWorksDrawerShowing,
      showModal: showEditFraewExternalWorksDrawer,
      hideModal: hideEditFraewExternalWorksDrawer,
    } = useModalState();

    const {
      isShowing: isEditFraewFraewCladdingSystemTypesDrawerShowing,
      showModal: showEditFraewCladdingSystemTypesDrawer,
      hideModal: hideEditFraewCladdingSystemTypesDrawer,
    } = useModalState();

    const {
      isShowing: isEditEvidenceForAuthorisationDrawerShowing,
      showModal: showEditEvidenceForAuthorisationDrawer,
      hideModal: hideEditEvidenceForAuthorisationDrawer,
    } = useModalState();

    const {
      isShowing: isEditEvidenceForChiefExecutiveDrawerShowing,
      showModal: showEditEvidenceForChiefExecutiveDrawer,
      hideModal: hideEditEvidenceForChiefExecutiveDrawer,
    } = useModalState();

    const {
      isShowing: isEditEvidenceForS151OfficerDrawerShowing,
      showModal: showEditEvidenceForS151OfficerDrawer,
      hideModal: hideEditEvidenceForS151OfficerDrawer,
    } = useModalState();

    const {
      isShowing: isEditGfaCounterSignedDrawerShowing,
      showModal: showEditGfaCounterSignedDrawer,
      hideModal: hideEditGfaCounterSignedDrawer,
    } = useModalState();

    const {
      isShowing: isEditBuildingLocalAuthorityDrawerShowing,
      showModal: showEditBuildingLocalAuthorityDrawer,
      hideModal: hideEditBuildingLocalAuthorityDrawer,
    } = useModalState();

    useImperativeHandle(ref, () => ({
      displayEditUkBankAccountDetailsDrawer() {
        showEditUkBankAccountDetailsDrawer();
      },
      displayEditBankAccountVerificationContactDetailsDrawer() {
        showEditBankAccountVerificationContactDetailsDrawer();
      },
      displayEditPtfsRequiredDrawer() {
        showEditPtfsRequiredDrawer();
      },
      displayEditPtfsAmountDrawer() {
        showEditPtfsAmountDrawer();
      },
      displayEditVatNumberDrawer() {
        showEditVatNumberDrawer();
      },
      displayEditWhoseBankAccountDrawer() {
        showEditWhoseBankAccountDrawer();
      },
      displayEditAddressDrawer() {
        showEditAddressDrawer();
      },
      displayEditCompanyDetailsDrawer() {
        showEditCompanyDetailsDrawer();
      },
      displayEditContactDetailsDrawer() {
        showEditContactDetailsDrawer();
      },
      displayEditBuildingHeightDrawer() {
        showEditBuildingHeightDrawer();
      },
      displayEditNumberOfStoreysDrawer() {
        showEditNumberOfStoreysDrawer();
      },
      displayEditTotalCladdingAreaDrawer() {
        showEditTotalCladdingAreaDrawer();
      },
      displayEditLifeSafetyRiskAssessmentDrawer() {
        showEditLifeSafetyRiskAssessmentDrawer();
      },
      displayEditAssessmentTypeDrawer() {
        showEditAssessmentTypeDrawer();
      },
      displayEditRecommendCladdingDrawer() {
        showEditRecommendCladdingDrawer();
      },
      displayEditApplicationFileDrawer() {
        showEditApplicationFileDrawer();
      },
      displayEditBsrRegistrationCodeDrawer() {
        showEditBsrRegistrationCodeDrawer();
      },
      displayEditBuildingNameDrawer() {
        showEditBuildingNameDrawer();
      },
      displayEditFraewSurveyCompanyDrawer() {
        showEditFraewSurveyCompanyDrawer();
      },
      displayEditFraewSurveyDateDrawer() {
        showEditFraewSurveyDateDrawer();
      },
      displayEditFraewInstructionDateDrawer() {
        showEditFraewInstructionDateDrawer();
      },
      displayEditFraewReportAuthorDrawer() {
        showEditFraewReportAuthorDrawer();
      },
      displayEditFraewPeerReviewerDrawer() {
        showEditFraewPeerReviewerDrawer();
      },
      displayEditFraewCostDrawer() {
        showEditFraewCostDrawer();
      },
      displayEditProjectMilestonesDrawer() {
        showEditProjectMilestonesDrawer();
      },
      displayEditFraewInterimMeasuresDrawer() {
        showEditFraewInterimMeasuresDrawer();
      },
      displayEditFraewCurrentEvacuationStrategyDrawer() {
        showEditFraewCurrentEvacuationStrategyDrawer();
      },
      displayEditFraewNumberOfStairwellsDrawer() {
        showEditFraewNumberOfStairwellsDrawer();
      },
      displayEditFraewExternalManagementPoliciesDrawer() {
        showEditFraewExternalManagementPoliciesDrawer();
      },
      displayEditFraewFireRescueServiceAccessRestrictionsDrawer() {
        showEditFraewFireRescueServiceAccessRestrictionsDrawer();
      },
      displayEditBsrCodeDrawer() {
        showEditBsrCodeDrawer();
      },
      displayEditOriginalDeveloperDrawer() {
        showEditOriginalDeveloperDrawer();
      },
      displayEditNumberOfResidentialUnitsDrawer() {
        showEditNumberOfResidentialUnitsDrawer();
      },
      displayEditAnyNonResidentialUnitsDrawer() {
        showEditAnyNonResidentialUnitsDrawer();
      },
      displayEditPartOfDevelopmentDrawer() {
        showEditPartOfDevelopmentDrawer();
      },
      displayEditBuildingNumberOfStoreysDrawer() {
        showEditBuildingNumberOfStoreysDrawer();
      },
      displayEditAlternateFundingRoutesDrawer() {
        showEditAlternateFundingRoutesDrawer();
      },
      displayEditRiskSafetyMitigationDrawer() {
        showEditRiskSafetyMitigationDrawer();
      },
      displayEditCostEffectiveCourseOfActionDrawer() {
        showEditCostEffectiveCourseOfActionDrawer();
      },
      displayEditOtherOptionsConsideredDrawer() {
        showEditOtherOptionsConsideredDrawer();
      },
      displayEditCaveatsOrLimitationsDrawer() {
        showEditCaveatsOrLimitationsDrawer();
      },
      displayEditRecommendedInterimMeasuresDrawer() {
        showEditRecommendedInterimMeasuresDrawer();
      },
      displayEditLeaseholderEngagementDrawer() {
        showEditLeaseholderEngagementDrawer();
      },
      displayFraewInternalWorksDrawer() {
        showEditFraewInternalWorksDrawer();
      },
      displayFraewExternalWorksDrawer() {
        showEditFraewExternalWorksDrawer();
      },
      displayFraewCladdingSystemTypesDrawer() {
        showEditFraewCladdingSystemTypesDrawer();
      },
      displayEditEvidenceForAuthoristionDrawer() {
        showEditEvidenceForAuthorisationDrawer();
      },
      displayEditEvidenceForChiefExecutiveDrawer() {
        showEditEvidenceForChiefExecutiveDrawer();
      },
      displayEditEvidenceForS151OfficerDrawer() {
        showEditEvidenceForS151OfficerDrawer();
      },
      displayEditGfaCounterSignedDrawer() {
        showEditGfaCounterSignedDrawer();
      },
      displayEditBuildingLocalAuthorityDrawer() {
        showEditBuildingLocalAuthorityDrawer();
      }
    }));

    return (
      <>
        {isEditUkBankAccountDetailsDrawerShowing && (
          <EditUkBankAccountDetailsDrawer
            onSuccess={() => {
              hideEditUkBankAccountDetailsDrawer();
            }}
            onClose={() => {
              hideEditUkBankAccountDetailsDrawer();
            }}
          />
        )}

        {isEditBankAccountVerificationContactDetailsDrawerShowing && (
          <EditBankAccountVerificationContactDetailsDrawer
            onSuccess={() => {
              hideEditBankAccountVerificationContactDetailsDrawer();
            }}
            onClose={() => {
              hideEditBankAccountVerificationContactDetailsDrawer();
            }}
          />
        )}

        {isEditPtfsRequiredDrawerShowing && (
          <EditPtfsRequiredDrawer
            onSuccess={() => {
              hideEditPtfsRequiredDrawer();
            }}
            onClose={() => {
              hideEditPtfsRequiredDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditPtfsAmountDrawerShowing && (
          <EditPtfsAmountDrawer
            onSuccess={() => {
              hideEditPtfsAmountDrawer();
            }}
            onClose={() => {
              hideEditPtfsAmountDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditVatNumberDrawerShowing && (
          <EditVatNumberDrawer
            onSuccess={() => {
              hideEditVatNumberDrawer();
            }}
            onClose={() => {
              hideEditVatNumberDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditWhoseBankAccountDrawerShowing && (
          <EditWhoseBankAccountDrawer
            onSuccess={() => {
              hideEditWhoseBankAccountDrawer();
            }}
            onClose={() => {
              hideEditWhoseBankAccountDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditAddressDrawerShowing && props.questionType && (
          <EditAddressDrawer
            questionType={props.questionType}
            onSuccess={() => {
              hideEditAddressDrawer();
            }}
            onClose={() => {
              hideEditAddressDrawer();
            }}
          />
        )}

        {isEditCompanyDetailsDrawerShowing && props.questionType && (
          <EditCompanyDetailsDrawer
            questionType={props.questionType}
            onSuccess={() => {
              hideEditCompanyDetailsDrawer();
            }}
            onClose={() => {
              hideEditCompanyDetailsDrawer();
            }}
          />
        )}

        {isEditContactDetailsDrawerShowing && props.questionType && (
          <EditContactDetailsDrawer
            questionType={props.questionType}
            onSuccess={() => {
              hideEditContactDetailsDrawer();
            }}
            onClose={() => {
              hideEditContactDetailsDrawer();
            }}
          />
        )}

        {isEditBuildingHeightDrawerShowing && (
          <EditBuildingHeightDrawer
            onSuccess={() => {
              hideEditBuildingHeightDrawer();
            }}
            onClose={() => {
              hideEditBuildingHeightDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditNumberOfStoreysDrawerShowing && (
          <EditNumberOfStoreysDrawer
            onSuccess={() => {
              hideEditNumberOfStoreysDrawer();
            }}
            onClose={() => {
              hideEditNumberOfStoreysDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditTotalCladdingAreaDrawerShowing && (
          <EditTotalCladdingAreaDrawer
            onSuccess={() => {
              hideEditTotalCladdingAreaDrawer();
            }}
            onClose={() => {
              hideEditTotalCladdingAreaDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditLifeSafetyRiskAssessmentDrawerShowing && (
          <EditLifeSafetyRiskAssessmentDrawer
            onSuccess={() => {
              hideEditLifeSafetyRiskAssessmentDrawer();
            }}
            onClose={() => {
              hideEditLifeSafetyRiskAssessmentDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditAssessmentTypeDrawerShowing && (
          <EditAssessmentTypeDrawer
            onSuccess={() => {
              hideEditAssessmentTypeDrawer();
            }}
            onClose={() => {
              hideEditAssessmentTypeDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditRecommendCladdingDrawerShowing && (
          <EditRecommendCladdingDrawer
            onSuccess={() => {
              hideEditRecommendCladdingDrawer();
            }}
            onClose={() => {
              hideEditRecommendCladdingDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditApplicationFileDrawerShowing && props.questionType && (
          <EditApplicationFileDrawer
            questionType={props.questionType}
            onSuccess={() => {
              hideEditApplicationFileDrawer();
            }}
            onClose={() => {
              hideEditApplicationFileDrawer();
            }}
          />
        )}

        {isEditBsrRegistrationCodeDrawerShowing && (
          <EditBsrRegistrationCodeDrawer
            onSuccess={() => {
              hideEditBsrRegistrationCodeDrawer();
            }}
            onClose={() => {
              hideEditBsrRegistrationCodeDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditBuildingNameDrawerShowing && (
          <EditBuildingNameDrawer
            onSuccess={() => {
              hideEditBuildingNameDrawer();
            }}
            onClose={() => {
              hideEditBuildingNameDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewSurveyCompanyDrawerShowing && (
          <EditFraewSurveyCompanyDrawer
            onSuccess={() => {
              hideEditFraewSurveyCompanyDrawer();
            }}
            onClose={() => {
              hideEditFraewSurveyCompanyDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewSurveyDateDrawerShowing && (
          <EditFraewSurveyDateDrawer
            onSuccess={() => {
              hideEditFraewSurveyDateDrawer();
            }}
            onClose={() => {
              hideEditFraewSurveyDateDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewInstructionDateDrawerShowing && (
          <EditFraewInstructionDateDrawer
            onSuccess={() => {
              hideEditFraewInstructionDateDrawer();
            }}
            onClose={() => {
              hideEditFraewInstructionDateDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewReportAuthorDrawerShowing && (
          <EditFraewReportAuthorDrawer
            onSuccess={() => {
              hideEditFraewReportAuthorDrawer();
            }}
            onClose={() => {
              hideEditFraewReportAuthorDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewPeerReviewerDrawerShowing && (
          <EditFraewPeerReviewerDrawer
            onSuccess={() => {
              hideEditFraewPeerReviewerDrawer();
            }}
            onClose={() => {
              hideEditFraewPeerReviewerDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditFraewCostDrawerShowing && (
          <EditFraewCostDrawer
            onSuccess={() => {
              hideEditFraewCostDrawer();
            }}
            onClose={() => {
              hideEditFraewCostDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}

        {isEditProjectMilestonesDrawerShowing && (
          <EditProjectMilestonesDrawer
            onSuccess={() => {
              hideEditProjectMilestonesDrawer();
            }}
            onClose={() => {
              hideEditProjectMilestonesDrawer();
            }}
          />
        )}

        {isEditFraewInterimMeasuresDrawerShowing ? (
          <EditFraewInterimMeasuresDrawer
            onSuccess={hideEditFraewInterimMeasuresDrawer}
            onClose={hideEditFraewInterimMeasuresDrawer}
          />
        ) : null}

        {isEditFraewCurrentEvacuationStrategyDrawerShowing ? (
          <EditFraewCurrentEvacuationStrategyDrawer
            onSuccess={hideEditFraewCurrentEvacuationStrategyDrawer}
            onClose={hideEditFraewCurrentEvacuationStrategyDrawer}
          />
        ) : null}

        {isEditFraewNumberOfStairwellsDrawerShowing ? (
          <EditFraewNumberOfStairwellsDrawer
            onSuccess={hideEditFraewNumberOfStairwellsDrawer}
            onClose={hideEditFraewNumberOfStairwellsDrawer}
          />
        ) : null}

        {isEditFraewExternalManagementPoliciesDrawerShowing ? (
          <EditFraewExternalManagementPoliciesDrawer
            onSuccess={hideEditFraewExternalManagementPoliciesDrawer}
            onClose={hideEditFraewExternalManagementPoliciesDrawer}
          />
        ) : null}

        {isEditFraewFireRescueServiceAccessRestrictionsDrawer ? (
          <EditFraewFireRescueServiceAccessRestrictionsDrawer
            onSuccess={hideEditFraewFireRescueServiceAccessRestrictionsDrawer}
            onClose={hideEditFraewFireRescueServiceAccessRestrictionsDrawer}
          />
        ) : null}

        {isEditBsrCodeDrawerShowing ? (
          <EditBsrCodeDrawer
            onSuccess={hideEditBsrCodeDrawer}
            onClose={hideEditBsrCodeDrawer}
          />
        ) : null}

        {isEditOriginalDeveloperDrawerShowing ? (
          <EditOriginalDeveloperDrawer
            onSuccess={hideEditOriginalDeveloperDrawer}
            onClose={hideEditOriginalDeveloperDrawer}
          />
        ) : null}

        {isEditNumberOfResidentialUnitsDrawerShowing ? (
          <EditNumberOfResidentialUnitsDrawer
            onSuccess={hideEditNumberOfResidentialUnitsDrawer}
            onClose={hideEditNumberOfResidentialUnitsDrawer}
          />
        ) : null}

        {isEditAnyNonResidentialUnitsDrawerShowing ? (
          <EditAnyNonResidentialUnitsDrawer
            onSuccess={hideEditAnyNonResidentialUnitsDrawer}
            onClose={hideEditAnyNonResidentialUnitsDrawer}
          />
        ) : null}

        {isEditPartOfDevelopmentDrawerShowing ? (
          <EditPartOfDevelopmentDrawer
            onSuccess={hideEditPartOfDevelopmentDrawer}
            onClose={hideEditPartOfDevelopmentDrawer}
          />
        ) : null}

        {isEditBuildingNumberOfStoreysDrawerShowing ? (
          <EditBuildingNumberOfStoreysDrawer
            onSuccess={hideEditBuildingNumberOfStoreysDrawer}
            onClose={hideEditBuildingNumberOfStoreysDrawer}
          />
        ) : null}

        {isEditAlternateFundingRoutesDrawerShowing ? (
          <EditAlternateFundingRoutesDrawer
            onSuccess={hideEditAlternateFundingRoutesDrawer}
            onClose={hideEditAlternateFundingRoutesDrawer}
          />
        ) : null}

        {isEditRiskSafetyMitigationDrawerShowing ? (
          <EditRiskSafetyMitigationDrawer
            onSuccess={hideEditRiskSafetyMitigationDrawer}
            onClose={hideEditRiskSafetyMitigationDrawer}
          />
        ) : null}

        {isEditCostEffectiveCourseOfActionDrawerShowing ? (
          <EditCostEffectiveCourseOfActionDrawer
            onSuccess={hideEditCostEffectiveCourseOfActionDrawer}
            onClose={hideEditCostEffectiveCourseOfActionDrawer}
            currentValue={props.currentValue}
          />
        ) : null}

        {isEditOtherOptionsConsideredDrawerShowing ? (
          <EditOtherOptionsConsideredDrawer
            onSuccess={hideEditOtherOptionsConsideredDrawer}
            onClose={hideEditOtherOptionsConsideredDrawer}
            currentValue={props.currentValue}
          />
        ) : null}

        {isEditCaveatsOrLimitationsDrawerShowing ? (
          <EditCaveatsOrLimitationsDrawer
            onSuccess={hideEditCaveatsOrLimitationsDrawer}
            onClose={hideEditCaveatsOrLimitationsDrawer}
            currentValue={props.currentValue}
          />
        ) : null}

        {isEditRecommendedInterimMeasuresShowing ? (
          <EditRecommendedInterimMeasuresDrawer
            onSuccess={hideEditRecommendedInterimMeasuresDrawer}
            onClose={hideEditRecommendedInterimMeasuresDrawer}
          />
        ) : null}

        {isEditLeaseholderEngagementDrawerShowing ? (
          <EditLeaseholderEngagementDrawer
            onSuccess={hideEditLeaseholderEngagementDrawer}
            onClose={hideEditLeaseholderEngagementDrawer}
          />
        ) : null}

        {isEditFraewInternalWorksDrawerShowing ? (
          <EditFraewInternalWorksDrawer
            onSuccess={hideEditFraewInternalWorksDrawer}
            onClose={hideEditFraewInternalWorksDrawer}
          />
        ) : null}

        {isEditFraewExternalWorksDrawerShowing ? (
          <EditFraewExternalWorksDrawer
            onSuccess={hideEditFraewExternalWorksDrawer}
            onClose={hideEditFraewExternalWorksDrawer}
          />
        ) : null}

        {isEditFraewFraewCladdingSystemTypesDrawerShowing ? (
          <EditFraewCladdingSystemTypesDrawer
            onSuccess={hideEditFraewCladdingSystemTypesDrawer}
            onClose={hideEditFraewCladdingSystemTypesDrawer}
          />
        ) : null}

        {isEditEvidenceForAuthorisationDrawerShowing ? (
          <EditEvidenceForAuthorisationDrawer
            onSuccess={hideEditEvidenceForAuthorisationDrawer}
            onClose={hideEditEvidenceForAuthorisationDrawer}
          />
        ) : null}

        {isEditEvidenceForChiefExecutiveDrawerShowing ? (
          <EditEvidenceForChiefExecutiveDrawer
            onSuccess={hideEditEvidenceForChiefExecutiveDrawer}
            onClose={hideEditEvidenceForChiefExecutiveDrawer}
          />
        ) : null}

        {isEditEvidenceForS151OfficerDrawerShowing ? (
          <EditEvidenceForS151OfficerDrawer
            onSuccess={hideEditEvidenceForS151OfficerDrawer}
            onClose={hideEditEvidenceForS151OfficerDrawer}
          />
        ) : null}

        {isEditGfaCounterSignedDrawerShowing ? (
          <EditGfaCounterSignedDrawer
            onSuccess={hideEditGfaCounterSignedDrawer}
            onClose={hideEditGfaCounterSignedDrawer}
          />
        ) : null}

        {isEditBuildingLocalAuthorityDrawerShowing && (
          <EditBuildingLocalAuthorityDrawer
            onSuccess={() => {
              hideEditBuildingLocalAuthorityDrawer();
            }}
            onClose={() => {
              hideEditBuildingLocalAuthorityDrawer();
            }}
            currentValue={props.currentValue ? props.currentValue : '-'}
          />
        )}
      </>
    );
  }
);
