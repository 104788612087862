import { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Stack,
  SxProps,
  Typography,
  Chip,
} from '@mui/material';
import { ETeamRole } from 'enums/ETeamRole';
import { IProjectTeam } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { ProjectTeamValue } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ProjectTeamSection';

export const ProjectTeamAccordionInfo = ({
  data,
}: {
  data: IProjectTeam[];
}) => {
  return (
    <Box mt={2}>
      {data.map((item, i) => {
        return <ProjectTeamAccordion key={i} item={item} />;
      })}
    </Box>
  );
};

export const ProjectTeamAccordion = ({ item }: { item: IProjectTeam }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function getSectionItems() {
    var items = [
      ['Primary contact number', item.primaryContactNumber],
      ['Email', item.emailAddress],
      [
        'Have you formally signed a contract with this person?',
        item.contractSigned ? 'Yes' : 'No',
      ],
      [
        'Are appropriate levels of professional indemnity insurance and limits of liability, for the risks associated and in accordance with industry best practice, in place?',
        item.appropriateLevelsOfProfessionalIndemnity ? 'Yes' : 'No',
      ],
    ];

    const rnlopi = item.reasonNoLevelsOfProfessionalIndemnity;
    items.push([
      'Reason provided for not having appropriate levels of professional indemnity insurance and limits of liability, for the risk associated and in accordance with industry best practice.',
      rnlopi === null || rnlopi === undefined ? '-' : rnlopi,
    ]);

    if (item.teamRole === ETeamRole.LeadContractor) {
      
      const hcc = item.hasChasCertification;
      items.push([
        'Has your lead contractor obtained CHAS Elite certification (Common Assessment Standard)?',
        hcc === null || hcc === undefined ? '-' : hcc ? 'Yes' : 'No',
      ]);

      const isutccs = item.isSignedUpToConsiderateConstructorScheme;
      items.push([
        'Is your lead contractor signed up to the Considerate Constructors Scheme?',
        isutccs === null || isutccs === undefined || isutccs === '' ? '-' : isutccs,
      ]);

      const rnsutccs = item.reasonNotSignedUpToConsiderateConstructorScheme;
      items.push([
        'Tell us why your lead contractor is not signed up to the Considerate Constructors Scheme',
        rnsutccs === null || rnsutccs === undefined || rnsutccs === '' ? '-' : rnsutccs,
      ]);
    }

    items.push([
      'Was this ' +
        item.role +
        '  involved in the original installation of cladding on this building?',
      item.fireRiskSafetyEngineerInvolved ? 'Yes' : 'No',
    ]);

    const riii = item.reasonInvolvedInInstallation;
    items.push([
      "Explanation of this team member's involvement in the original installation of the building cladding.",
      riii === null || riii === undefined || riii === '' ? '-' : riii,
    ]);

    return items;
  }

  return (
    <Accordion expanded={isExpanded}>
      <AccordionSummary
        sx={{
          pointerEvents: 'none',
          '& .MuiAccordionSummary-content': {
            minWidth: 0,
          },
        }}
      >
        <Grid container alignItems="center" columnSpacing={2} wrap="nowrap">
          <Grid
            item
            flexBasis={'30px'}
            sx={isExpanded ? { alignSelf: 'flex-start' } : null}
          >
            <IconButton
              onClick={e => {
                setIsExpanded(isExpandedVal => !isExpandedVal);
              }}
              sx={{
                pointerEvents: 'auto',
                flexGrow: 1,
                background: 'grey.100',
                p: 1,
                m: 0,
                padding: 0,
              }}
            >
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <ProjectTeamValue>{item.role}</ProjectTeamValue>
          </Grid>
          <Grid item xs={3}>
            <ProjectTeamValue>
              {item.name}
              {item.isGrantCertifyingOfficer ? (
                <ProjectChip label="GCO" />
              ) : null}
            </ProjectTeamValue>
          </Grid>
          <Grid item xs={3}>
            <ProjectTeamValue>{item.companyName}</ProjectTeamValue>
          </Grid>
          <Grid item xs={3}>
            <ProjectTeamValue>
              {item.companyRegistration}
              {item.isNew ? <ProjectChip label="New" /> : null}
            </ProjectTeamValue>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Stack
          rowGap={2}
          sx={{
            borderWidth: 0,
            borderTopWidth: 1,
            borderColor: 'grey.300',
            borderStyle: 'solid',
          }}
        >
          <Section items={getSectionItems()} sx={{ bgcolor: 'grey.100' }} />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const ProjectChip = ({ label }: { label: string }) => (
  <Chip label={label} size="small" sx={{ ml: 1 }} />
);

const Section = ({ items, sx }: { items: string[][]; sx?: SxProps }) => {
  return (
    <Box sx={sx}>
      <Box sx={{ px: 7, py: 3 }}>
        <Stack rowGap={1}>
          {items.map(([label, value], n) => (
            <Row key={n} label={label} value={value} />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

const Row = ({ label, value }: { label: string; value: string }) => {
  return (
    <Grid container mt={2}>
      <Grid item xs={5}>
        <Typography variant="body1" fontWeight={'600'} fontSize={'0.9em'}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography
          variant="body1"
          paddingLeft={4}
          fontSize={'0.9em'}
          color="grey.800"
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};
