import { useState } from 'react';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { ReferToRemediationAdviserDrawer } from 'pages/applicationPage/content/support/sections/SupportTicketSection/common/ReferToRemediationAdviserDrawer';

export function ReferToRemediationAdviserContextMenu() {
  const theme = useTheme();

  const {
    isShowing: isReferToRemediationAdviserDrawerShowing,
    showModal: showReferToRemediationAdviserDrawer,
    hideModal: hideReferToRemediationAdviserDrawer,
  } = useModalState();

  const [contextMenuAnchorEl, setContextMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleShowContextMenu = (anchorEl: HTMLElement | null) => {
    setContextMenuAnchorEl(anchorEl);
  };

  const handleCloseContextMenu = () => {
    setContextMenuAnchorEl(null);
  };

  const handleContextMenuButtonClick = () => {
    showReferToRemediationAdviserDrawer();
    setContextMenuAnchorEl(null);
  };

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
    const hasSupportReferralsProcess = doesUserHaveSinglePermission(
      'support.referrals.process'
    );

  return (
    <>
      <Box flexGrow={1}>
        <IconButton
          onClick={e => {
            handleShowContextMenu(e.currentTarget);
          }}
          sx={{
            borderRadius: 0,
            background: theme.palette.grey[100],
            p: 1,
            m: 0,
          }}
          aria-label="Details menu"
          name="Details menu"
        >
          <FontAwesomeIcon icon={faEllipsis} size="xs" fixedWidth />
        </IconButton>
      </Box>

      {Boolean(contextMenuAnchorEl) ? (
        <Menu
          anchorEl={contextMenuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(contextMenuAnchorEl)}
          onClose={() => handleCloseContextMenu()}
        >
          <MenuItem
            onClick={() => {
              handleCloseContextMenu();
              handleContextMenuButtonClick();
            }}
            aria-label="Edit"
            disabled={!hasSupportReferralsProcess}
          >
            Edit
          </MenuItem>
        </Menu>
      ) : null}

      {isReferToRemediationAdviserDrawerShowing && (
        <ReferToRemediationAdviserDrawer
          editMode={true}
          isShowingRemediationAdviserDrawer={
            isReferToRemediationAdviserDrawerShowing
          }
          hideRemediationAdviserDrawer={hideReferToRemediationAdviserDrawer}
        />
      )}
    </>
  );
}
