import { Button, Typography } from '@mui/material';
import { useConfirmPEPSanctionsCompleteMutation } from 'api/application';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IConfirmSectionCompleteDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmSectionCompleteDialog({
  onConfirm,
  onCancel,
}: IConfirmSectionCompleteDialogProps) {
  const { applicationId } = useApplicationContext();
  const [confirmPEPSanctionsComplete] =
    useConfirmPEPSanctionsCompleteMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onConfirmPEPSanctionsComplete = async () => {
    await confirmPEPSanctionsComplete(applicationId)
      .unwrap()
      .then(payload => {
        createSuccessSnackbar(
          'PEP/Sanctions Successfully Confirmed As Complete'
        );
        onConfirm();
      })
      .catch(error => {
        createErrorSnackbar(
          `An unexpected error occured while confirming PEP/Sanctions as complete: ${error.data.generalError.errorMessage}`
        );
      });
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        {`Are you sure you want to confirm the PEP/Sanctions as complete?`}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>
          You will not be able to undo this action once completed.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button data-automation-id="PEPSanctionChecks_Cancel" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button data-automation-id="PEPSanctionChecks_Confirm" variant="contained" onClick={onConfirmPEPSanctionsComplete}>
          Confirm Section Complete
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
