import { rest } from 'msw';
import { 
  administrationOrganisationData, 
  organisationUsersData, 
  administrationOrganisationsForAdminData, 
  administrationEditOrganisationData } from 'mocks/data/administration/administrationOrganisationData';

export const administrationOrganisationHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/organisation/list`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(administrationOrganisationData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/organisation/:organisationId/users`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(organisationUsersData));
      }
      return res(ctx.status(200), ctx.json(organisationUsersData));
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/organisations`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(administrationOrganisationsForAdminData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/organisation/:organisationId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200), ctx.json(administrationEditOrganisationData));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/admin/organisation/:organisationId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.post(
    `${process.env.REACT_APP_API_URL}/api/admin/organisation`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
  rest.put(
    `${process.env.REACT_APP_API_URL}/api/admin/organisation/:organisationId`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
        return res(ctx.status(200));
      }
      return req.passthrough();
    }
  ),
];
