import { Button } from '@mui/material';
import {
  useArchivePaymentRequestCostReportDocumentMutation,
  useArchivePaymentRequestInvoiceDocumentMutation,
} from 'api/application/paymentRequestApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import {
  IArchivePaymentRequestCostEvidenceDocumentDialog,
  IArchivePaymentRequestCostReportDocument,
  IArchivePaymentRequestInvoiceDocument,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

export function ArchivePaymentRequestCostEvidenceDocumentDialog({
  documentId,
  documentType,
  onConfirm,
  onCancel,
}: IArchivePaymentRequestCostEvidenceDocumentDialog) {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  const [archivePaymentRequestCostReportDocument] =
    useArchivePaymentRequestCostReportDocumentMutation();

  const [archivePaymentRequestInvoiceDocument] =
    useArchivePaymentRequestInvoiceDocumentMutation();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onArchiveDocument = async () => {
    const archivePayload =
      documentType === 'costReport'
        ? ({
            applicationId: applicationId,
            paymentRequestId: selectedItem?.id,
            fileId: documentId,
          } as IArchivePaymentRequestCostReportDocument)
        : ({
            applicationId: applicationId,
            paymentRequestId: selectedItem?.id,
            fileId: documentId,
          } as IArchivePaymentRequestInvoiceDocument);

    try {
      if (documentType === 'costReport') {
        await archivePaymentRequestCostReportDocument(archivePayload).unwrap();
      } else {
        await archivePaymentRequestInvoiceDocument(archivePayload).unwrap();
      }
      createSuccessSnackbar('Successfully archived document');
      onConfirm();
    } catch (err) {
      createErrorSnackbar(`Failed to archive document`);
    }
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        {`Are you sure you wish to archive this file?`}
      </StyledDialogTitle>
      <StyledDialogActions>
        <Button aria-label="cancel" variant="outlined" onClick={onCancel}>
          No
        </Button>
        <Button
          aria-label="Archive File"
          variant="contained"
          onClick={onArchiveDocument}
        >
          Yes
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
