import { ReactNode, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Typography,
  Chip,
  Divider,
  useTheme,
  Grid,
  Tabs,
  styled,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPullInProcessSummaryQuery } from 'api/pullInProcess';
import { CustomTab } from 'common/components/customTab';
import { CustomTabPanel } from 'common/components/customTabPanel';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import {
  EPullInProcessStatus,
  EPullInProcessStatusLanguage,
} from 'enums/EPullInProcessStatus';
import { useModalState } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { BuildingDetailsForm } from 'pages/pullInProcessPage/components/buildingDetails/buildingDetailsForm';
import { ContactsPanel } from 'pages/pullInProcessPage/components/overview/contactsPanel';
import { OverviewSummaryPanel } from 'pages/pullInProcessPage/components/overview/overviewSummaryPanel';
import { StyledDrawer } from 'styles/globalStyles/drawer';

const StyledCustomTab = styled(CustomTab)({
  backgroundColor: '#f5f5f5',
  borderRadius: '3px',
  border: '2px solid rgba(0,0,0,.12)',
  transition: 'all 150ms linear',
  padding: '12px',
  maxWidth: '100%',
  fontSize: '0.9rem',
  alignItems: 'flex-start',

  '&:hover': {
    backgroundColor: 'white',
  },

  '&:focus-visible': {
    border: '2px solid #212121',
  },
  '&.Mui-selected': {
    border: '2px solid #212121',
  },
});

type BoxWrapperProps = {
  render: JSX.Element;
};

function BoxWrapper({ render }: BoxWrapperProps) {
  return <Box mt="0.5rem">{render}</Box>;
}

export function BuildingDetailsPanel({
  onEditClick,
}: {
  onEditClick: () => void;
}) {
  const { pullInProcessId } = usePullInProcessContext();
  const { data } = useGetPullInProcessSummaryQuery(
    pullInProcessId ?? skipToken
  );
  const { palette } = useTheme();
  return (
    <RoundBorderBox borderRadius="8px" p="1rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="h3" variant="h3" fontWeight="600" m={0}>
          Building Details
        </Typography>
        <Button variant="outlined" onClick={onEditClick}>
          Edit
        </Button>
      </Box>
      <BoxWrapper render={<Typography color="GrayText">Stage</Typography>} />
      <BoxWrapper render={<Chip size="small" label={data?.stage ?? ''} />} />
      <BoxWrapper render={<Divider />} />
      <BoxWrapper render={<Typography color="GrayText">Status</Typography>} />
      <BoxWrapper
        render={
          <Chip
            size="small"
            label={
              data?.status ? EPullInProcessStatusLanguage[data?.status] : ''
            }
            sx={{
              backgroundColor:
                data?.status === EPullInProcessStatus.NotEligible
                  ? palette.error.light
                  : palette.chipPrimary?.light,
            }}
          />
        }
      />
      <BoxWrapper render={<Divider />} />
      <BoxWrapper
        render={<Typography color="GrayText">Unique ID</Typography>}
      />
      <BoxWrapper render={<Typography>{data?.uniqueId ?? ''}</Typography>} />
      {data?.status === EPullInProcessStatus.ProgressedToApplication &&
      data?.progressedToApplicationReference ? (
        <>
          <BoxWrapper
            render={
              <Typography color="GrayText">Linked Application</Typography>
            }
          />
          <BoxWrapper
            render={
              <Typography>{data.progressedToApplicationReference}</Typography>
            }
          />
        </>
      ) : null}
    </RoundBorderBox>
  );
}

const overviewTabKeys = ['buildingDetails', 'contactDetails'] as const;
type OverviewTabKey = (typeof overviewTabKeys)[number];

export function BuildingDetailsTabPanel() {
  const [selectedTab, setSelectedTab] =
    useState<OverviewTabKey>('buildingDetails');

  const { isShowing, showModal, hideModal } = useModalState();

  const overviewTabs: Record<
    OverviewTabKey,
    { label: string; component: ReactNode }
  > = {
    buildingDetails: {
      label: 'Building Details',
      component: <OverviewSummaryPanel onEditClick={showModal} />,
    },
    contactDetails: {
      label: 'Contact Details',
      component: <ContactsPanel />,
    },
  };
  return (
    <Grid container spacing={'1rem'}>
      <Grid item xs={3} display="flex" flexDirection="column" gap="20px">
        <RoundBorderBox borderRadius="8px">
          <Tabs
            onChange={(_, newValue: OverviewTabKey) =>
              void setSelectedTab(newValue)
            }
            sx={{
              padding: '1rem',
              width: '100%',
              '& .MuiTabs-flexContainer': { gap: '1rem' },
            }}
            value={selectedTab}
            orientation="vertical"
          >
            {overviewTabKeys.map(key => (
              <StyledCustomTab
                key={key}
                currentSelectedTab={selectedTab}
                value={key}
                label={overviewTabs[key].label}
              />
            ))}
          </Tabs>
        </RoundBorderBox>
      </Grid>
      <Grid item xs={9}>
        <Box width="100%">
          <CustomTabPanel value={selectedTab} currentSelectedTab={selectedTab}>
            {overviewTabs[selectedTab].component}
          </CustomTabPanel>
        </Box>
      </Grid>
      <StyledDrawer anchor="right" open={isShowing}>
        <DialogTitle component="div">
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h1" component="span">
              Building Details
            </Typography>
            <IconButton
              onClick={hideModal}
              aria-label="Close drawer"
              name="Close drawer"
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <BuildingDetailsForm
            formId={'buildingsDetailForm'}
            onClose={hideModal}
          />
        </DialogContent>
      </StyledDrawer>
    </Grid>
  );
}
