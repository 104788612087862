import { ZodType, z } from 'zod';
import { TAddBuildingRecord } from 'types/pullInProcess/addBuildingRecordTypes';

export const zAddBuildingRecordAddressSchema: ZodType<
  Omit<TAddBuildingRecord, 'localAuthority' | 'sourceOfInformation' | 'sourceOfInformationOther'>
> = z.object({
  address: z.object({
    id: z.string().optional(),
    buildingNameNumber: z.string().optional(),
    postCode: z.string().min(1, 'Postcode is required'),
    line1: z.string().optional(),
    line2: z.string().optional(),
    townCity: z.string().optional(),
    county: z.string().optional(),
    country: z
      .object({
        name: z.string(),
        id: z.number(),
      })
      .optional()
  }),
  buildingHeightTypeId: z.number().optional(),
});

export const zAddBuildingRecordSourceInformationSchema: ZodType<
  Pick<TAddBuildingRecord, 'localAuthority' | 'sourceOfInformation' | 'sourceOfInformationOther'>
> = z
  .object({
    localAuthority: z.object(
      {
        id: z.string(),
        name: z.string()
      },
      {
        required_error: 'Local authority is required',
        invalid_type_error: 'Local authority is required',
      }
    ),
    sourceOfInformation: z.object(
      {
        id: z.number(),
        name: z.string(),
        hasFreeText: z.boolean(),
      },
      {
        required_error: 'Source information is required',
        invalid_type_error: 'Source information is required',
      }
    ),
    sourceOfInformationOther: z.string().optional(),
  })
  .refine(
    schema => {
      return schema.sourceOfInformation?.hasFreeText
        ? schema.sourceOfInformationOther !== ''
        : true;
    },
    {
      message: 'Other source information is required',
      path: ['sourceOfInformationOther'],
    }
  );

export const zAddBuildingRecordSchema: ZodType<TAddBuildingRecord> =
  z.intersection(
    zAddBuildingRecordAddressSchema,
    zAddBuildingRecordSourceInformationSchema
  );
