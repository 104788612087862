import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@mui/material';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { AdministrationOrgsFilters } from 'pages/administrationPage/components/administrationOrgsTab/components/AdministrationOrgsFilters';
import { AdministrationOrgsGrid } from 'pages/administrationPage/components/administrationOrgsTab/components/AdministrationOrgsGrid';
import { AddEditOrgDrawer } from 'pages/administrationPage/components/administrationOrgsTab/components/addEditOrgDrawer/AddEditOrgDrawer';

export function AdministrationOrgsTab() {
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasEditPermission = doesUserHaveSinglePermission(
    'admin.organisation.edit'
  );

  const {
    isShowing: isAddEditOrgDrawerShowing,
    showModal: showAddEditOrgDrawer,
    hideModal: hideAddEditOrgDrawer,
  } = useModalState();

  return (
    <>
      <Grid container>
        {hasEditPermission && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              onClick={showAddEditOrgDrawer}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
            >
              Add Organisation
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <AdministrationOrgsFilters />
        </Grid>
        <Grid item xs={12}>
          <AdministrationOrgsGrid />
        </Grid>
      </Grid>
       {isAddEditOrgDrawerShowing && (
              <AddEditOrgDrawer
                onSuccess={() => {
                  hideAddEditOrgDrawer();
                }}
                onClose={() => {
                  hideAddEditOrgDrawer();
                }}
              />
            )}
    </>
  );
}
