import { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { useCurrentUserPermissions } from 'hooks';
import {
  EReplacementCladdingTypeLanguage,
  IGetCladdingSectionResponse,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import { useEditCladdingDrawer } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/CladdingSection';
import {
  FraewDetailsSection,
  Section,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/CladdingSection/common';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';

export const CladdingSystemsList = ({
  data,
}: {
  data: IGetCladdingSectionResponse;
}) => {
  const { renderEditDrawer, showEditCladdingDrawer } = useEditCladdingDrawer();

  const handleEditClick = (
    claddingId: string,
    claddingSystemNumber: number
  ) => {
    showEditCladdingDrawer({
      claddingId,
      claddingSystemNumber,
    });
  };

  return (
    <Box mt={2}>
      {data.claddingSystemReplacementDetails.map((item, i) => {
        return (
          <CladdingSystemAccordion
            key={item.workPackageCostsScheduleCladdingSystemsId}
            item={item}
            claddingSystemNumber={i + 1}
            handleEditClick={() =>
              handleEditClick(
                item.workPackageCostsScheduleCladdingSystemsId,
                i + 1
              )
            }
          />
        );
      })}
      {renderEditDrawer()}
    </Box>
  );
};

export const CladdingSystemAccordion = ({
  item,
  claddingSystemNumber,
  handleEditClick,
}: {
  item: IGetCladdingSectionResponse['claddingSystemReplacementDetails'][number];
  claddingSystemNumber: number;
  handleEditClick: () => void;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { readOnly } = useWorksPackageContext();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasWorkpackageCladdingEdit = doesUserHaveSinglePermission(
    'workpackage.cladding.edit'
  );
  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.WorksPackage,
  });

  return (
    <Accordion expanded={isExpanded}>
      <AccordionSummary
        sx={{
          pointerEvents: 'none',
          '& .MuiAccordionSummary-content': {
            minWidth: 0,
          },
        }}
      >
        <Grid container alignItems="center" columnSpacing={2} wrap="nowrap">
          <Grid
            item
            flexBasis={'30px'}
            sx={isExpanded ? { alignSelf: 'flex-start' } : null}
          >
            <IconButton
              onClick={e => {
                setIsExpanded(isExpandedVal => !isExpandedVal);
              }}
              sx={{
                pointerEvents: 'auto',
                flexGrow: 1,
                background: 'grey.100',
                p: 1,
                m: 0,
                padding: 0,
              }}
            >
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <Typography fontSize={'0.9em'} fontWeight={600}>
              Cladding System {claddingSystemNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Chip
              label={EReplacementCladdingTypeLanguage[item.remediationType]}
            />
          </Grid>
          <Grid item flexBasis={'30px'}>
            {!readOnly && !isOnHoldForThisType ? (
              <Button
                size="small"
                variant="outlined"
                onClick={handleEditClick}
                sx={{
                  pointerEvents: 'auto',
                  m: 0,
                  height: 'unset',
                  py: 0.25,
                  px: 0,
                }}
                disabled={!hasWorkpackageCladdingEdit}
              >
                Edit
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Stack
          rowGap={2}
          sx={{
            borderWidth: 0,
            borderTopWidth: 1,
            borderColor: 'grey.300',
            borderStyle: 'solid',
          }}
        >
          <FraewDetailsSection item={item} />
          <Section
            title="What is replacing this cladding system?"
            items={[
              ['Cladding Type', item.replacingCladdingSystemType],
              ['Cladding Manufacturer', item.replacingCladdingManufacturer],
              ['Insulation Material', item.replacingInsulationType],
              ['Insulation Manufacturer', item.replacingInsulationManufacturer],
            ]}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
