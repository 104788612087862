import { Button, TextField } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useRejectConfirmEligibilityChecksMutation } from 'api/application';
import { useLocalSnackbar } from 'hooks/useLocalSnackbar';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { IApplicationRejectEligibilityChecks } from 'types/applications/ApplicationEligibilityTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationRejectEligibilityChecks) =>
  nameof<IApplicationRejectEligibilityChecks>(fieldName);

interface IRejectApplicationDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function RejectApplicationDialog({
  onConfirm,
  onCancel,
}: IRejectApplicationDialogProps) {
  const { applicationId } = useApplicationContext();
  const [rejectConfirmEligibilityChecks] =
    useRejectConfirmEligibilityChecksMutation();
  const { createSuccessSnackbar, createWarningSnackbar, createErrorSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationRejectEligibilityChecks>();
  const { handleSubmit } = form;

  const onSubmit = async (formData: IApplicationRejectEligibilityChecks) => {
    let applicationRejectEligibilityChecks: IApplicationRejectEligibilityChecks =
      {
        applicationId: applicationId,
        rejectionReason: formData.rejectionReason,
      };

    await rejectConfirmEligibilityChecks(applicationRejectEligibilityChecks)
      .unwrap()
      .then(payload => {
        createSuccessSnackbar('Application rejected');
        onConfirm();
      })
      .catch(error => {
        if (error.data.propertyErrors) {
          setServerSideFormErrors(form, error.data);
          createWarningSnackbar(
            'Please correct any form validation errors shown, and then try again.'
          );
        } else {
          createErrorSnackbar(
            `An unexpected error occured while rejecting application: ${error}`
          );
        }
      });
  };

  return (
    <StyledDialog open>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledDialogTitle>Reject Application</StyledDialogTitle>
          <StyledDialogContent>
            <Controller
              name={getName('rejectionReason')}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!fieldState.error}
                  required
                  multiline
                  rows={4}
                  label="Reason For Rejection"
                  helperText={fieldState.error?.message}
                  style={{ width: '34rem' }}
                />
              )}
            />
          </StyledDialogContent>
          <StyledDialogActions>
            <Button data-automation-id="ReferApplication_Cancel" variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button data-automation-id="ReferApplication_Reject" variant="contained" type="submit">
              Reject Application
            </Button>
          </StyledDialogActions>
        </form>
      </FormProvider>
    </StyledDialog>
  );
}
