export enum EApplicationGrantFundingSectionType {
  CheckGrantFundingDetails = 'details',
  UploadSignedDocs = 'docs',
  UploadCounterSignedGrantFunding = 'counter',
}


// These SelectedItemIdTypes are generated in the frontend from GrantFundingApi.ts
// They are used in the ApplicationGrantFundingSectionOutlet.tsx to determine which section to display
export enum EApplicationGrantFundingSelectedItemIdType {
  CheckGrantFundingDetails = 'CheckGFADetails',
  UploadSignedDocs = 'Uploadsigneddocs',
  UploadCounterSignedGrantFunding = 'UploadcountersignedGFA',
}
