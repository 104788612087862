import { rest } from 'msw';
import {
} from 'mocks/data/administration/administrationUsersData';
import { 
  administrationTeamsData, 
  administrationTeamData, 
  administrationRulesDefault, 
  administrationTeamList } from 'mocks/data/administration/administrationTeamsData';

export const administrationTeamsHandlers = [
  rest.get(
    `${process.env.REACT_APP_API_URL}/api/admin/teams`,
    (req, res, ctx) => {
      if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {

        return res(ctx.status(200), ctx.json(administrationTeamsData));
      }
      return req.passthrough();
    }
  ),
  rest.get(
      `${process.env.REACT_APP_API_URL}/api/admin/team/:teamId`,
      (req, res, ctx) => {
        if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
          return res(ctx.status(200), ctx.json(administrationTeamData));
        }
        return res(ctx.status(200), ctx.json(administrationTeamData));
      }
    ),
    rest.get(
      `${process.env.REACT_APP_API_URL}/api/admin/teamrules`,
      (req, res, ctx) => {
        if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
          return res(ctx.status(200), ctx.json(administrationRulesDefault));
        }
        return res(ctx.status(200), ctx.json(administrationRulesDefault));
      }
    ),
    rest.get(
      `${process.env.REACT_APP_API_URL}/api/admin/teamlist`,
      (req, res, ctx) => {
        if (process.env.REACT_APP_IS_STRICT_MOCKS === 'yes') {
          return res(ctx.status(200), ctx.json(administrationTeamList));
        }
        return res(ctx.status(200), ctx.json(administrationTeamList));
      }
    ),
];
