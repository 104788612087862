import { Grid, Typography } from '@mui/material';
import { useGetGrantFundingSummaryQuery } from 'api/application';
import { BankDetails } from 'common/components/bankDetails';
import { useCurrentUserPermissions } from 'hooks';
import {
  ColumnContainer,
  DetailItem,
} from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const GrantFundingCounterSignedDocumentationChecksInfoPanel = () => {
  const { applicationId } = useApplicationContext();

  const query = useGetGrantFundingSummaryQuery(applicationId);

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasApplicationsBankdetailsView = doesUserHaveSinglePermission(
    'applications.bank-details.view'
  );

  return (
    <>
      <ColumnContainer margin="10px">
        <Typography variant="h2" padding="16px">
          Secretariat Details
        </Typography>
        {query.data ? (
          <>
            <DetailItem
              label="Secretariat Email"
              value={
                query.data.secretarialEmail ? query.data.secretarialEmail : '-'
              }
            />
          </>
        ) : null}
      </ColumnContainer>
      {hasApplicationsBankdetailsView && (
        <ColumnContainer margin="10px">
          <Grid
            container
            gap={1}
            justifyContent={'space-between'}
            padding={'16px'}
          >
            <Grid item>
              <BankDetails largeTitle={true} />
            </Grid>
          </Grid>
        </ColumnContainer>
      )}
    </>
  );
};
