import { Button, Typography } from '@mui/material';
import {
  useDeleteOrganisationMutation
} from 'api/administration/organisations';
import { useLocalSnackbar } from 'hooks';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IDeleteOrganisationDialogProps {
  isShowing: boolean;
  selectedId: string | null;
  onClose: () => void;
  onCancel: () => void;
}

export function DeleteOrganisationDialog({
  isShowing,
  selectedId,
  onClose,
  onCancel,
}: IDeleteOrganisationDialogProps) {

  const [deleteOrg] = useDeleteOrganisationMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onDelete = async () => {
    try {
      await deleteOrg(selectedId ?? '')
              .unwrap()
              .then(() => {
                createSuccessSnackbar('Successfully deleted organisation');
                onClose();
              })
              .catch(error => {
                if (error?.data?.generalError) {
                  createErrorSnackbar(error.data.generalError.errorMessage);
                }
                else {
                  createErrorSnackbar(`Failed to delete organisation`);
                }
              });

    } catch (err) {
      createErrorSnackbar(`Failed to delete organisation`);
    }
  };

  return (
    <StyledDialog open={isShowing}>
      <StyledDialogTitle>
        {`Are you sure you want to delete this organisation?`}
      </StyledDialogTitle>
      <StyledDialogContent>
        <Typography>
          You will not be able to undo this action once completed.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDelete}>
          Delete organisation
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}