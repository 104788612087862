import { applicationApi } from 'api/application/applicationApi';
import {
  EVariationType,
  IApproveVariationParams,
  IRejectVariationParams,
  IEditVariationReasonMutationParams,
  IEditVariationScopeMutationParams,
  IEditVariationThirdPartyContributionMutationParams,
  IEditVariationTimescaleMutationParams,
  IEditVariationTypesMutationParams,
  IGetVariationCostsScheduleResponse,
  IGetVariationCsvParams,
  IReferVariationToHomesEnglandParams,
  ISaveVariationChecksParams,
  IVariationOverview,
  IVariationQueryParams,
  UpdateVariationValuesParams,
  VariationkChecksForm,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import {
  IIneligibleForm,
  IInstallationForm,
  IOtherForm,
  IPreliminariesForm,
  IRemovalForm,
} from 'types/applications/ApplicationCostScheduleTypes';
import { QueryTags } from 'util/ApiUtils';
import { handleFileDownloadFromWithinRtkQuery } from 'util/FileUtils';

export const variationEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getVariationOverview: builder.query<
      IVariationOverview,
      IVariationQueryParams
    >({
      providesTags: [QueryTags.ApplicationVariationOverview],
      query: ({ applicationId, variationId }) =>
        `${applicationId}/variation/${variationId}/overview`,
      keepUnusedDataFor: 0,
    }),

    getVariationCostsSchedule: builder.query<
      IGetVariationCostsScheduleResponse,
      IVariationQueryParams
    >({
      providesTags: [QueryTags.ApplicationVariationCostSchedule],
      query: ({ applicationId, variationId }) =>
        `${applicationId}/variation/${variationId}/costschedule`,
    }),

    getVariationCostsScheduleCsv: builder.query<null, IGetVariationCsvParams>({
      queryFn: async (
        { applicationId, variationId, fileName },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `${applicationId}/variation/${variationId}/costschedule/csv`,
          responseHandler: response => response.blob(),
        });

        if (result.error) {
          return { error: result.error };
        }

        return handleFileDownloadFromWithinRtkQuery(
          fileName,
          result.data as Blob
        );
      },
    }),

    saveVariationRemovalValues: builder.mutation<
      void,
      UpdateVariationValuesParams<IRemovalForm>
    >({
      invalidatesTags: [
        QueryTags.ApplicationVariationCostSchedule,
        QueryTags.ApplicationVariationOverview,
      ],
      query: ({ formData, applicationId, variationId }) => {
        return {
          url: `${applicationId}/variation/${variationId}/costSchedule/unsafeCladdingRemoval`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    saveVariationInstallationValues: builder.mutation<
      void,
      UpdateVariationValuesParams<IInstallationForm>
    >({
      invalidatesTags: [
        QueryTags.ApplicationVariationCostSchedule,
        QueryTags.ApplicationVariationOverview,
      ],
      query: ({ formData, applicationId, variationId }) => {
        return {
          url: `${applicationId}/variation/${variationId}/costSchedule/newCladdingInstallation`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    saveVariationPreliminariesValues: builder.mutation<
      void,
      UpdateVariationValuesParams<IPreliminariesForm>
    >({
      invalidatesTags: [
        QueryTags.ApplicationVariationCostSchedule,
        QueryTags.ApplicationVariationOverview,
      ],
      query: ({ formData, applicationId, variationId }) => {
        return {
          url: `${applicationId}/variation/${variationId}/costSchedule/preliminariesAndOverheads`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    saveVariationOtherCostsValues: builder.mutation<
      void,
      UpdateVariationValuesParams<IOtherForm>
    >({
      invalidatesTags: [
        QueryTags.ApplicationVariationCostSchedule,
        QueryTags.ApplicationVariationOverview,
      ],
      query: ({ formData, applicationId, variationId }) => {
        return {
          url: `${applicationId}/variation/${variationId}/costSchedule/otherCosts`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    saveVariationIneligibleValues: builder.mutation<
      void,
      UpdateVariationValuesParams<IIneligibleForm>
    >({
      invalidatesTags: [
        QueryTags.ApplicationVariationCostSchedule,
        QueryTags.ApplicationVariationOverview,
      ],
      query: ({ formData, applicationId, variationId }) => {
        return {
          url: `${applicationId}/variation/${variationId}/costSchedule/ineligibleCosts`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    getVariationChecks: builder.query<
      Partial<VariationkChecksForm>,
      IVariationQueryParams
    >({
      providesTags: [QueryTags.ApplicationVariationChecks],
      query: ({ applicationId, variationId }) =>
        `${applicationId}/variation/${variationId}/checks`,
    }),

    saveVariationChecksFields: builder.mutation<
      void,
      ISaveVariationChecksParams
    >({
      invalidatesTags: [
        QueryTags.ApplicationVariationChecks,
        QueryTags.ApplicationStatus,
      ],
      query: ({ formData, applicationId, variationId }) => {
        return {
          url: `${applicationId}/variation/${variationId}/checks`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    variationApprove: builder.mutation<
      void,
      IApproveVariationParams
    >({
      invalidatesTags: [
        QueryTags.ApplicationVariationCostSchedule,
        QueryTags.ApplicationVariationOverview,
        QueryTags.ApplicationVariationChecks,
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationPaymentRequestOverview,
        QueryTags.ApplicationClosingPaymentRequestOverview,
        QueryTags.ApplicationLiveProjectOverview,
        QueryTags.ApplicationLiveProjectList,
      ],
      query: ({ applicationId, variationId, ...params }) => {
        return {
          url: `${applicationId}/variation/${variationId}/approvevariation`,
          method: 'PUT',
          body: params,
        };
      },
    }),

    variationReject: builder.mutation<
      void,
      IRejectVariationParams
    >({
      invalidatesTags: [
        QueryTags.ApplicationVariationCostSchedule,
        QueryTags.ApplicationVariationOverview,
        QueryTags.ApplicationVariationChecks,
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationPaymentRequestOverview,
        QueryTags.ApplicationClosingPaymentRequestOverview,
        QueryTags.ApplicationLiveProjectOverview,
        QueryTags.ApplicationLiveProjectList,
      ],
      query: ({ applicationId, variationId, ...params }) => {
        return {
          url: `${applicationId}/variation/${variationId}/rejectvariation`,
          method: 'PUT',
          body: params,
        };
      },
    }),

    referVariationToHomesEngland: builder.mutation<
      void,
      IReferVariationToHomesEnglandParams
    >({
      invalidatesTags: [
        QueryTags.ApplicationVariationReferralList,
        QueryTags.ApplicationStatus,
      ],
      query: ({ applicationId, variationId, ...body }) => {
        return {
          url: `${applicationId}/variation/${variationId}/referToHomesEngland`,
          method: 'PUT',
          body,
        };
      },
    }),

    updateVariationTypes: builder.mutation<
      void,
      IEditVariationTypesMutationParams
    >({
      invalidatesTags: [QueryTags.ApplicationVariationOverview],
      query: ({ applicationId, variationId, formData }) => {
        return {
          url: `${applicationId}/variation/${variationId}/type`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    updateVariationScope: builder.mutation<
      void,
      IEditVariationScopeMutationParams
    >({
      invalidatesTags: [QueryTags.ApplicationVariationOverview],
      query: ({ applicationId, variationId, formData }) => {
        return {
          url: `${applicationId}/variation/${variationId}/type/${EVariationType.Scope}`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    updateVariationTimescale: builder.mutation<
      void,
      IEditVariationTimescaleMutationParams
    >({
      invalidatesTags: [QueryTags.ApplicationVariationOverview],
      query: ({ applicationId, variationId, formData }) => {
        return {
          url: `${applicationId}/variation/${variationId}/type/${EVariationType.Timescale}`,
          method: 'PUT',
          body: formData,
        };
      },
    }),

    updateVariationThirdPartyContribution: builder.mutation<
    void,
    IEditVariationThirdPartyContributionMutationParams
  >({
    invalidatesTags: [QueryTags.ApplicationVariationOverview],
    query: ({ applicationId, variationId, formData }) => {
      return {
        url: `${applicationId}/variation/${variationId}/type/${EVariationType.ThirdPartyContribution}`,
        method: 'PUT',
        body: formData,
      };
    },
  }),

    updateVariationReason: builder.mutation<
      void,
      IEditVariationReasonMutationParams
    >({
      invalidatesTags: [QueryTags.ApplicationVariationOverview],
      query: ({ applicationId, variationId, formData }) => {
        return {
          url: `${applicationId}/variation/${variationId}/reason`,
          method: 'PUT',
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useGetVariationOverviewQuery,
  useGetVariationCostsScheduleQuery,
  useLazyGetVariationCostsScheduleCsvQuery,
  useSaveVariationPreliminariesValuesMutation,
  useSaveVariationOtherCostsValuesMutation,
  useSaveVariationInstallationValuesMutation,
  useSaveVariationRemovalValuesMutation,
  useSaveVariationIneligibleValuesMutation,
  useGetVariationChecksQuery,
  useSaveVariationChecksFieldsMutation,
  useVariationApproveMutation,
  useVariationRejectMutation,
  useReferVariationToHomesEnglandMutation,
  useUpdateVariationTypesMutation,
  useUpdateVariationScopeMutation,
  useUpdateVariationTimescaleMutation,
  useUpdateVariationThirdPartyContributionMutation,
  useUpdateVariationReasonMutation,
} = variationEndpoints;
