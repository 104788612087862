import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'state';

export const selectAdministrationOrgsSelectorsRoot = (state: AppState) =>
  state.administration.organisations;

export const selectIsAdministrationOrgsGridLoading = createSelector(
  [selectAdministrationOrgsSelectorsRoot],
  adminOrgs => adminOrgs.isGridLoading
);

export const selectAdministrationOrgsGridState = createSelector(
  [selectAdministrationOrgsSelectorsRoot],
  adminOrgs => adminOrgs.gridState
);
