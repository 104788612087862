import { Fragment, useState } from 'react';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SortDirection,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  useGetEligibilityPepSanctionDirectorsQuery,
  useSetDirectorStatusMutation,
} from 'api/application';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { GridSearchField } from 'common/components/gridSearchField';
import { Loading } from 'common/components/loading';
import { NoResultsFoundText } from 'common/components/noResultsfoundText/NoResultsFoundText';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { ESortDirection } from 'enums/ESortDirection';
import { useLocalSnackbar, useModalState } from 'hooks';
import {
  AddEditDirectorDrawer,
  ConfirmSectionCompleteDialog,
  DeleteDirectorDialog,
  EDirectorsMenuType,
  RejectDirectorDialog,
} from 'pages/applicationPage/content/applicationEligibility/components/PEPSanctionsChecks';
import { usePepSanctionsGridState } from 'pages/applicationPage/content/applicationEligibility/components/PEPSanctionsChecks/hooks';
import { ReferApplicationButton } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplication';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import {
  IDirectorStatus,
  IPEPSanctionsDirectorsListItem,
} from 'types/applications/ApplicationEligibilityTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const PEPSanctionsChecksGrid = () => {
  const { applicationId, readOnly } = useApplicationEligibilityContext();

  const {
    state: { gridState },
    dispatch,
    gridStateChanged,
  } = usePepSanctionsGridState();

  const { skip, take, sortBy, sortDirection, searchValue } = gridState;

  const params = !applicationId
    ? skipToken
    : {
        applicationId,
        queryParams: gridState,
      };
  const query = useGetEligibilityPepSanctionDirectorsQuery(params);
  const [setDirectorStatus] = useSetDirectorStatusMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const {
    isShowing: isAddEditDirectorDrawerShowing,
    showModal: showAddEditDirectorDrawer,
    hideModal: hideAddEditDirectorDrawer,
  } = useModalState();

  const {
    isShowing: isDeleteDirectorDialogShowing,
    showModal: showDeleteDirectorDialog,
    hideModal: hideDeleteDirectorDialog,
  } = useModalState();

  const {
    isShowing: isConfirmSectionCompleteDialogShowing,
    showModal: showConfirmSectionCompleteDialog,
    hideModal: hideConfirmSectionCompleteDialog,
  } = useModalState();

  const {
    isShowing: isRejectDirectorDialogShowing,
    showModal: showRejectDirectorDialog,
    hideModal: hideRejectDirectorDialog,
  } = useModalState();

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Eligibility,
  });

  const [selectedRowDirectorId, setSelectedRowDirectorId] = useState<
    string | null
  >(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isEllipsisMenuOpen = Boolean(anchorEl);

  const noResultsFound = !!searchValue && query.data?.count === 0;

  const handleClose = (menuType: EDirectorsMenuType) => {
    switch (menuType) {
      case EDirectorsMenuType.Edit:
        showAddEditDirectorDrawer(true);
        break;
      case EDirectorsMenuType.Delete:
        showDeleteDirectorDialog(true);
        break;
    }

    setAnchorEl(null);
  };

  const onEllipsisClick = (
    rowId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedRowDirectorId(rowId);
    setAnchorEl(event.currentTarget);
  };

  const currentPage = skip / take;

  const sortHandler = (
    property: keyof IPEPSanctionsDirectorsListItem
  ): void => {
    dispatch({
      type: 'gridStateChanged',
      payload: {
        sortBy: property,
        sortDirection:
          sortDirection === ESortDirection.Asc
            ? ESortDirection.Desc
            : ESortDirection.Asc,
      },
    });
  };

  const getAriaSortDirection = (
    property: keyof IPEPSanctionsDirectorsListItem,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getCurrentSortDirection = (
    property: keyof IPEPSanctionsDirectorsListItem,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderTableCell = (
    property: keyof IPEPSanctionsDirectorsListItem,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          active={sortBy === property}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => sortHandler(property)}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
    );
  };

  const handleSearchCallback = (searchValue: string) => {
    gridStateChanged({ searchValue, skip: 0 });
  };

  const onPassFailClick = async (rowId: string, passed: boolean) => {
    if (passed) {
      const directorStatus: IDirectorStatus = {
        id: rowId,
        applicationId: applicationId,
        isPassed: true,
      };

      await setDirectorStatus(directorStatus)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar('Successfully set director status to passed');
        })
        .catch(error => {
          createErrorSnackbar(
            `An unexpected error occured while updating the status: ${error}`
          );
        });
    } else {
      setSelectedRowDirectorId(rowId);
      // Show reject director dialog
      showRejectDirectorDialog(true);
      setAnchorEl(null);
    }
  };

  const numberOfPassedDirectors = query.data?.results
    ? query.data?.results.filter(item => item.isPassed).length
    : 0;

  return (
    <>
      {isOnHoldForThisType && (
        <OnHoldBanner holdType={EOnHoldType.Eligibility} />
      )}

      <Box display="flex" justifyContent="space-between" p={2}>
        <Box>
          <Typography variant="h2">Company Directors</Typography>
        </Box>
        <Box display="flex" gap={1} justifyContent="flex-end">
          <GridSearchField
            data-automation-id="DirectorSearch"
            isLoading={query.isLoading}
            defaultValue={searchValue}
            handleSearch={handleSearchCallback}
          />
          {!readOnly && (
            <Button
              data-automation-id="AddDirector"
              variant="contained"
              onClick={showAddEditDirectorDrawer}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              disabled={isOnHoldForThisType}
            >
              Add Director
            </Button>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        gap={2}
        justifyContent="flex-start"
        p={2}
        alignItems={'center'}
      >
        <Typography variant="body1" fontWeight={800}>
          Click here to navigate to PEP/Sanctions checks
        </Typography>
        <Button
          variant="contained"
          href="https://secure.veriphy.co.uk"
          target="_blank"
        >
          Veriphy
        </Button>
      </Box>
      <Grid container spacing={1} p={3}>
        <Grid xs={12} item>
          <Loading isOpen={query.isLoading} />
          <NoResultsFoundText show={noResultsFound} />
          <Grid item xs={12}></Grid>
          <StyledGenericTable data-testid="EligibilityPepSanctionsDirectorsTable">
            <TableHead>
              <TableRow>
                {getHeaderTableCell('name', 'Name')}
                {getHeaderTableCell('address', 'Address')}
                {getHeaderTableCell('dateOfBirth', 'Date of birth')}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {query.data?.results?.map(row => (
                <Fragment key={'Subrow_1_' + row.id}>
                  <TableRow>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>
                      {getUkDateStringFromJsonDateString(row.dateOfBirth)}
                    </TableCell>
                    <TableCell>
                      <Box display="flex" gap={0.5}>
                        <ToggleButtonGroup
                          onChange={(_, val) => onPassFailClick(row.id, val)}
                          exclusive
                          disabled={readOnly || isOnHoldForThisType}
                          value={row.isPassed}
                        >
                          <ToggleButton data-automation-id="Director_Pass" value={true}>Passed</ToggleButton>
                          <ToggleButton data-automation-id="Director_Fail" value={false}>Failed</ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </TableCell>
                    <TableCell align="right" aria-label="Actions">
                      {!readOnly && !isOnHoldForThisType ? (
                        <>
                          <IconButton
                            data-automation-id="Director_Menu"
                            aria-label="edit"
                            name="edit"
                            onClick={event => onEllipsisClick(row.id, event)}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                          <Menu
                            id={`director-menu-${row.id}`}
                            anchorEl={anchorEl}
                            open={isEllipsisMenuOpen}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem
                              data-automation-id="Director_Edit"
                              onClick={() =>
                                handleClose(EDirectorsMenuType.Edit)
                              }
                            >
                              Edit director
                            </MenuItem>
                            <MenuItem
                              data-automation-id="Director_Delete"
                              onClick={() =>
                                handleClose(EDirectorsMenuType.Delete)
                              }
                            >
                              Delete director
                            </MenuItem>
                          </Menu>
                        </>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  {!row.isPassed && (
                    <TableRow key={'Subrow_2_' + row.id}>
                      <TableCell colSpan={5} style={{ borderTop: 'none' }}>
                        {row.failureReason}
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={query.data?.count || 0}
                  rowsPerPage={take}
                  rowsPerPageOptions={[take]}
                  page={currentPage}
                  onPageChange={(e, newPage) => {
                    const newSkip = newPage * take;
                    gridStateChanged({ skip: newSkip });
                  }}
                  onRowsPerPageChange={e => {
                    gridStateChanged({ take: +e.target.value });
                  }}
                />
              </TableRow>
            </TableFooter>
          </StyledGenericTable>
          {isAddEditDirectorDrawerShowing && (
            <AddEditDirectorDrawer
              directorId={selectedRowDirectorId}
              onSuccess={() => {
                hideAddEditDirectorDrawer();
                query.refetch();
              }}
              onClose={() => {
                hideAddEditDirectorDrawer();
              }}
            />
          )}

          {isDeleteDirectorDialogShowing && selectedRowDirectorId && (
            <DeleteDirectorDialog
              directorId={selectedRowDirectorId}
              onConfirm={() => {
                setSelectedRowDirectorId(null);
                hideDeleteDirectorDialog();
                query.refetch();
              }}
              onCancel={() => {
                setSelectedRowDirectorId(null);
                hideDeleteDirectorDialog();
              }}
            />
          )}

          {isRejectDirectorDialogShowing && selectedRowDirectorId && (
            <RejectDirectorDialog
              directorId={selectedRowDirectorId}
              onConfirm={() => {
                setSelectedRowDirectorId(null);
                hideRejectDirectorDialog();
                query.refetch();
              }}
              onCancel={() => {
                setSelectedRowDirectorId(null);
                hideRejectDirectorDialog();
              }}
            />
          )}
        </Grid>
      </Grid>

      {!readOnly && (
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <Box
              display="flex"
              justifyContent="space-between"
              p={3}
              bgcolor="grey.100"
            >
              <ReferApplicationButton
                sectionType={EEligibilitySection.PepSanctions}
                disabled={isOnHoldForThisType}
              />
              <OnOffHold holdType={EOnHoldType.Eligibility} />
              <Button
                data-automation-id="PEPSanctionChecks_ConfirmSectionComplete"
                variant="contained"
                disabled={numberOfPassedDirectors === 0 || isOnHoldForThisType}
                onClick={showConfirmSectionCompleteDialog}
              >
                Confirm Section Complete
              </Button>
              {isConfirmSectionCompleteDialogShowing && (
                <ConfirmSectionCompleteDialog
                  onConfirm={() => {
                    hideConfirmSectionCompleteDialog();
                  }}
                  onCancel={() => {
                    hideConfirmSectionCompleteDialog();
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};
