import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { teamsEndpoints } from 'api/administration/teams';
import { ESortDirection } from 'enums/ESortDirection';
import { IGetTeamsListQuery, ITeamsGridListItem } from 'types/administration/AdministrationTeamsTypes';
import { nameof } from 'util/formUtils';

export const administrationSortByTypes = [
  nameof<ITeamsGridListItem>('name')
] as const;
export type AdministrationSortByTypes =
  (typeof administrationSortByTypes)[number];

export interface IAdministrationTeamsState {
  isGridLoading: boolean;
  gridState: IGetTeamsListQuery;
}

const initialState: IAdministrationTeamsState = {
  isGridLoading: true,
  gridState: {
    skip: 0,
    take: 25,
    name: [],
    searchValue: null,
    sortBy: nameof<ITeamsGridListItem>('name'),
    sortDirection: ESortDirection.Asc,
  },
};

const administrationTeamsSlice = createSlice({
  name: 'administrationTeams',
  initialState,
  reducers: {
    gridStateChanged: (
      state,
      action: PayloadAction<Partial<IGetTeamsListQuery>>
    ) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
    userLeftAdministrationTeamsPage: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        teamsEndpoints.endpoints.getPaginatedTeamsList.matchFulfilled,
        state => {
          state.isGridLoading = false;
        }
      )
      .addMatcher(
        teamsEndpoints.endpoints.getPaginatedTeamsList.matchRejected,
        state => {
          state.isGridLoading = false;
        }
      );
  },
});

export const administrationTeamsReducer = administrationTeamsSlice.reducer;
export const { gridStateChanged, userLeftAdministrationTeamsPage } =
  administrationTeamsSlice.actions;
