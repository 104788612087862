import { administrationApi } from 'api/administration';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IOrganisation,
  IOrganisationUser, 
  IGetOrgsListQuery, 
  IOrgsGridListItem,
  IOrganisationForEdit
} from 'types/administration/AdministrationOrganisationTypes';
import { QueryTags } from 'util/ApiUtils';
import { getUrlSearchParamFromQueryData } from 'util/QueryUtils';


const getPaginatedOrgsQueryString = (query: IGetOrgsListQuery) => {
  const queryParamsStr = getUrlSearchParamFromQueryData({
    skip: query.skip,
    take: query.take,
    sortBy: query.sortBy.toString(),
    sortDirection: query.sortDirection.toString(),
    searchValue: query.searchValue,
  });

  return `/organisations?${queryParamsStr.toString()}`;
};

export const organisationEndpoints = administrationApi.injectEndpoints({
  endpoints: builder => ({
    getOrganisations: builder.query<Array<IOrganisation>, void>({
      query: () => `${process.env.REACT_APP_API_URL}/api/organisation/list`,
    }),
    getOrganisationUsers: builder.query<Array<IOrganisationUser>, number>({
      query: organisationId =>
        `${process.env.REACT_APP_API_URL}/api/organisation/${organisationId}/users`,
    }),
    getPaginatedOrgsList: builder.query<IPaginationResponse<IOrgsGridListItem>,IGetOrgsListQuery>({
          providesTags: [QueryTags.Organisations],
          query: query => {
            return getPaginatedOrgsQueryString(query);
          },
    }),
    getOrganisation: builder.query<IOrganisationForEdit, number>({
      providesTags: [QueryTags.Organisation],
      query: query => `${process.env.REACT_APP_API_URL}/api/admin/organisation/${query}`,
    }),
    addOrganisation: builder.mutation<any, IOrganisationForEdit>({
      invalidatesTags: [QueryTags.Organisations],
      query: payload => ({
        url: `${process.env.REACT_APP_API_URL}/api/admin/organisation`,
        method: 'POST',
        body: payload,
      }),
    }),
    editOrganisation: builder.mutation<any, IOrganisationForEdit>({
      invalidatesTags: [QueryTags.Organisations],
      query: payload => ({
        url: `${process.env.REACT_APP_API_URL}/api/admin/organisation/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteOrganisation: builder.mutation<void, string>({
      invalidatesTags: [QueryTags.Organisations],
      query: payload => ({
        url: `/organisation/${payload}`,
        method: 'DELETE',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { 
  useGetOrganisationsQuery, 
  useGetOrganisationUsersQuery, 
  useGetPaginatedOrgsListQuery,
  useLazyGetOrganisationQuery,
  useAddOrganisationMutation,
  useEditOrganisationMutation, 
  useDeleteOrganisationMutation
} = organisationEndpoints;
