import { useCallback } from 'react';
import { Box, Grid, TextField, MenuItem } from '@mui/material';
import { GridSearchField } from 'common/components/gridSearchField';
import { TeamFilterSelect } from 'common/components/teams';
import { ESortDirection } from 'enums/ESortDirection';
import { useAdminTasksGridContext, FilterButton } from 'pages/adminTasksPage';
import {
  adminTaskListSortByOptions,
  IAdminTaskListItem,
} from 'types/tasks/CmsTaskTypes';

export const AdminTasksDataGridNavigation = () => {
  const {
    query,
    state: { gridState },
    gridStateChanged,
  } = useAdminTasksGridContext();

  const handleSearchCallback = useCallback(
    (searchValue: string) => {
      gridStateChanged({ searchValue, skip: 0 });
    },
    [gridStateChanged]
  );

  const sortOptions = Object.entries(adminTaskListSortByOptions).map(
    ([key, value]) => {
      return { value: key, label: value };
    }
  );

  return (
    <Box p={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <GridSearchField
            isLoading={query.isLoading}
            defaultValue={gridState.searchValue}
            handleSearch={handleSearchCallback}
          />
        </Grid>
        <Grid container justifyContent="flex-end" flex={1} columnGap={1}>
          <Grid item>
            <TeamFilterSelect
              gridState={gridState}
              gridStateChanged={gridStateChanged}
            />
            <TextField
              name="sort"
              label="Sort"
              select
              SelectProps={{
                sx: { '& .MuiSelect-select': { p: '12.5px 12.5px 10px' } },
              }}
              sx={{ minWidth: '215px', p: 0, marginLeft: '1rem' }}
              value={gridState.sortBy}
              onChange={e => {
                gridStateChanged({
                  sortBy: e.target.value as keyof IAdminTaskListItem,
                  sortDirection: ESortDirection.Asc,
                });
              }}
            >
              {sortOptions.map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <FilterButton />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
