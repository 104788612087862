import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEditLocalAuthorityMutation } from 'api/application/applicationEditApi';
import { useGetPullInProcessesLocalAuthoritiesQuery } from 'api/pullInProcess';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { zLocalAuthoritySchema } from 'types/applications/ApplicationEdit.zod';
import { ILocalAuthorityForm } from 'types/applications/ApplicationEditTypes';
import { setServerSideFormErrors } from 'util/formUtils';

interface IEditBuildingLocalAuthorityDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
  currentValue: string;
}

export function EditBuildingLocalAuthorityDrawer({
  onClose,
  onSuccess,
  currentValue,
}: IEditBuildingLocalAuthorityDrawerProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1">Edit Local Authority</Typography>
          <Typography variant="h3">{currentValue}</Typography>
        </DialogTitle>
        <EditBuildingLocalAuthorityForm onSuccess={onSuccess} onClose={onClose} />
      </Box>
    </StyledDrawer>
  );
}

interface IEditBuildingLocalAuthorityFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const EditBuildingLocalAuthorityForm = ({
  onClose,
  onSuccess,
}: IEditBuildingLocalAuthorityFormProps) => {
  const { applicationId } = useApplicationContext();

  const [editLocalAuthority, editLocalAuthorityResult] =
    useEditLocalAuthorityMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const {
    data: localAuthoritiesData,
    isSuccess: localAuthoritiesLoadedSuccessfully,
    isError: localAuthoritiesLoadFailed,
  } = useGetPullInProcessesLocalAuthoritiesQuery();

  const form = useForm<ILocalAuthorityForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zLocalAuthoritySchema),
  });
  const { handleSubmit, reset, control } = form;

  const onSubmit = async (formData: ILocalAuthorityForm) => {
    try {
      formData.applicationId = applicationId;
      await editLocalAuthority(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Local Authority updated successfully`);
          onSuccess();
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit Local Authority`);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        style={{ width: '100%' }}
      >
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
             

            {localAuthoritiesLoadedSuccessfully && (
                      <>
                        <Controller
                          control={control}
                          name="localAuthority"
                          defaultValue={undefined}
                          render={({ fieldState, field: { onChange, ...fieldRest } }) => {
                            return (
                              <Autocomplete
                                options={localAuthoritiesData}
                                getOptionLabel={option => option.name}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value?.id
                                }
                                onChange={(_, value) => {
                                  onChange(value);
                                }}
                                renderOption={(props, option) => {
                                  return (
                                    <li {...props} key={option.id}>
                                      {option.name}
                                    </li>
                                  );
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    {...fieldRest}
                                    label="Local Authority (required)"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                  />
                                )}
                              />
                            );
                          }}
                        />
                      </>
                    )}

                    {
                      localAuthoritiesLoadFailed && ( 
                      <Typography color="error" sx={{ mb: '2rem' }}>
                        Failed to load local authorities. You will not be able to complete this action. Please try again later
                      </Typography>)
                    }



            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue=""
                name="changeReason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Comment"
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={editLocalAuthorityResult.isLoading}
            >
              Update Answers
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};
