import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Stack,
} from '@mui/material';
import { useGetProjectPlanQuery } from 'api/application/worksPackageApi';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';

export const ProjectPlanCard = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, data } = useGetProjectPlanQuery(applicationId);

  return (
    <>
      <RoundBorderBox sx={{ mt: 5 }}>
        <Box
          bgcolor={'grey.100'}
          sx={{ padding: 2, borderBottom: '1px solid lightgray' }}
        >
          <Typography variant="h3" fontWeight={800} fontSize="1.3em">
            Project Plan
          </Typography>
        </Box>

        <Box sx={{ padding: 4 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="body1" fontWeight={600}>
              Do you have an agreed Project Plan setting out the delivery
              milestones and underlying activities/timescales?
            </Typography>
            <Typography variant="body1" fontWeight={600}>
              {data?.hasProjectPlan === true
                ? 'Yes'
                : data?.hasProjectPlan === false
                ? 'No'
                : '-'}
            </Typography>
          </Grid>
        </Box>
        <Divider />

        {isLoading ? <FormSkeleton sx={{ m: 2 }} /> : null}

        {Array.isArray(data?.projectPlanFiles) &&
        data?.projectPlanFiles.some(file => file) ? (
          <Box>
            <Grid container spacing={2} p={2}>
              {data?.projectPlanFiles.map((file, index) => (
                <Grid item xs={12} sm={6} key={file.id}>
                  <Card variant="outlined">
                    <CardContent
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 1,
                        '&:last-child': { pb: 0 },
                      }}
                    >
                      <Stack spacing={1} sx={{ flexGrow: 1 }}>
                        <ApplicationFileDownloadWrapper
                          fileName={file.name}
                          fileId={file.id}
                          fileWrapperStyle={{
                            marginTop: '1rem',
                            border: 'none',
                          }}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Divider />
          </Box>
        ) : null}
      </RoundBorderBox>
    </>
  );
};
