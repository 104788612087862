import { ReactNode } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import {
  useEditPaymentRequestCostsMutation,
  useGetPaymentRequestCostsQuery,
  useGetPaymentRequestOverviewQuery,
} from 'api/application/paymentRequestApi';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { Loading } from 'common/components/loading';
import { useCurrentUserPermissions, useLocalSnackbar, useModalState } from 'hooks';
import { ColumnContainer } from 'pages/applicationPage/common/components';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context';
import {
  IPaymentRequestCosts,
  IPaymentRequestRequest,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { PaymentRequestCostsCurrentPaymentsSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestCostsCurrentPaymentsSection';
import { PaymentRequestCostsFinalPaymentSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestCostsFinalPaymentSection';
import { PaymentRequestCostsFooterSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestCostsFooterSection';
import { PaymentRequestCostsHeaderSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestCostsHeaderSection';
import { PaymentRequestCostsMonthlyCostsSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestCostsMonthlyCostsSection';
import { PaymentRequestCostsPaymentsPaidToDateSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestCostsPaymentsPaidToDateSection';
import { PaymentRequestSaveModal } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestCostsSection/PaymentRequestSaveModal';
import { PaymentRequestKpiSection } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/common/PaymentRequestKpiSection';
import { setServerSideFormErrors } from 'util/formUtils';

export const PaymentRequestCostsSection = ({
  paymentRequestId,
  paymentRequestTitle = '',
  isFinalPayment,
  readOnly,
  hasProcessPermssion,
}: {
  paymentRequestId :string | undefined;
  paymentRequestTitle: string;
  isFinalPayment: boolean;
  readOnly: boolean;
  hasProcessPermssion: boolean;
}) => {
  const { applicationId } = useApplicationLiveProjectContext();

  const request =
    !applicationId || !paymentRequestId
      ? skipToken
      : { applicationId, paymentRequestId: paymentRequestId };

  const query = useGetPaymentRequestCostsQuery(request);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const currentData = {
      ...data,
    };

    return (
      <>
        <PaymentRequestKpiSection paymentRequestId={paymentRequestId} />

        {data?.rejectedPayments && data.rejectedPayments.length > 0 && (
          <PaymentsPaidToDateContainer>
            <PaymentRequestCostsPaymentsPaidToDateSection
              paymentsPaidToDate={data.rejectedPayments}
              paymentTitle="Missed and Rejected Payments"
            />
          </PaymentsPaidToDateContainer>
        )}
        <PaymentsPaidToDateContainer>
          <PaymentRequestCostsPaymentsPaidToDateSection
            paymentsPaidToDate={data?.grantPaidToDate}
            paymentTitle="Grant paid to date"
          />
        </PaymentsPaidToDateContainer>
        <PaymentRequestCostsFormMain
          paymentRequestTitle={paymentRequestTitle}
          currentData={currentData}
          isFinalPayment={isFinalPayment}
          readOnly={readOnly}
          hasProcessPermssion={hasProcessPermssion}
          paymentRequestId={paymentRequestId}
        />
      </>
    );
  }

  return null;
};

const PaymentRequestCostsFormMain = ({
  paymentRequestId,
  paymentRequestTitle = '',
  currentData,
  isFinalPayment,
  readOnly,
  hasProcessPermssion,
}: {
  paymentRequestId :string | undefined
  currentData: IPaymentRequestCosts;
  paymentRequestTitle: string;
  isFinalPayment: boolean;
  readOnly: boolean;
  hasProcessPermssion: boolean;
}) => {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasLiveprojectPaymentsProcess = doesUserHaveSinglePermission(
    'liveproject.payments.process'
  );
  const hasLiveprojectFinalpaymentProcess = doesUserHaveSinglePermission(
    'liveproject.finalpayment.process'
  );

  const {
    isShowing: isSavePaymentRequestModalShowing,
    showModal: showSavePaymentRequestModal,
    hideModal: hideSavePaymentRequestModal,
  } = useModalState();

  const request: IPaymentRequestRequest = {
    applicationId: applicationId,
    paymentRequestId: paymentRequestId ?? '',
  };

  const { data } = useGetPaymentRequestOverviewQuery(request);

  const [editPaymentRequestCosts, editPaymentRequestCostsStatus] =
    useEditPaymentRequestCostsMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IPaymentRequestCosts>({
    defaultValues: currentData,
  });

  const scheduledCurrentPaymentsTotals = currentData.currentPayments
    ?.filter(item => item.scheduledCost !== undefined)
    .reduce((sum, current) => sum + current.scheduledCost, 0);

  const scheduledMonthlyCostsTotals = currentData.monthlyCosts
    ?.filter(item => item.scheduledCost !== undefined)
    .reduce((sum, current) => sum + current.scheduledCost, 0);

  const scheduledFinalCostsTotals =
    currentData.finalPayment !== undefined
      ? currentData.finalPayment?.scheduledCost
      : 0;

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isSubmitting },
    getValues,
  } = form;

  const { fields: currentPaymentsFields } = useFieldArray({
    control,
    name: 'currentPayments',
    keyName: 'key',
  });

  const monthlyCostsFields = useFieldArray({
    control,
    name: 'monthlyCosts',
  }).fields;

  const watchMonthlyCosts = useWatch({
    name: 'monthlyCosts',
    control,
  });
  const watchCurrentPayments = useWatch({
    name: 'currentPayments',
    control,
  });
  const watchFinalPaymentConfirmedCost = useWatch({
    name: 'finalPayment.confirmedCost',
    control,
  });

  const totalPaidToDate =
    currentData.grantPaidToDate
      ?.filter(
        item =>
          item.amount !== undefined &&
          item.amount.toString() !== ''
      )
      .reduce(
        (sum, current) => sum + parseFloat(current.amount.toString() ?? '0'),
        0
      ) ?? 0;

  const totalConfiremdCurrentPaymentsCosts =
    watchCurrentPayments
      ?.filter(
        item =>
          item.confirmedCost !== undefined &&
          item.confirmedCost.toString() !== ''
      )
      .reduce(
        (sum, current) =>
          sum + parseFloat(current.confirmedCost?.toString() ?? '0'),
        0
      ) ?? 0;

  const totalConfiremdMonthlyCosts =
    watchMonthlyCosts
      ?.filter(
        item =>
          item.confirmedCost !== undefined &&
          item.confirmedCost.toString() !== ''
      )
      .reduce(
        (sum, current) =>
          sum + parseFloat(current.confirmedCost?.toString() ?? '0'),
        0
      ) ?? 0;

  const totalCosts =
    totalConfiremdMonthlyCosts +
    totalConfiremdCurrentPaymentsCosts +
    parseFloat(watchFinalPaymentConfirmedCost?.toString() ?? '0') +
    totalPaidToDate;

  const saveCosts = async () => {
    const formData = getValues();
    try {
      formData.id = applicationId;
      formData.paymentRequestId = selectedItem?.id;
      await editPaymentRequestCosts(formData)
        .unwrap()
        .then(_payload => {
          createSuccessSnackbar(`Payment Request Costs updated successfully`);
          reset({}, { keepValues: true });
        })
        .catch(error => {
          if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to edit Payment Request costs`);
    } finally {
      hideSavePaymentRequestModal();
    }
  };

  const onCostsSubmit = async () => {
    const formData = getValues();

    let currentPaymentsChanged =
      formData.currentPayments &&
      formData.currentPayments.filter(
        item =>
          item.submittedCost !== undefined &&
          item.submittedCost.toString() !==
            (item.confirmedCost ? item.confirmedCost.toString() : '') &&
          item.isDirty
      ).length > 0
        ? true
        : false;
    let monthlyCostsChanged =
      formData.monthlyCosts &&
      formData.monthlyCosts.filter(
        item =>
          item.submittedCost !== undefined &&
          item.submittedCost.toString() !==
            (item.confirmedCost ? item.confirmedCost.toString() : '') &&
          item.isDirty
      ).length > 0
        ? true
        : false;

    let finalCostsChanged = formData.finalPayment?.submittedCost !== formData.finalPayment?.confirmedCost;

    if (currentPaymentsChanged || monthlyCostsChanged || (isFinalPayment && finalCostsChanged)) {
      showSavePaymentRequestModal();
    } else {
      saveCosts();
    }
  };

  return (
    <>
      <PaymentRequestCostsHeaderSection
        paymentRequestTitle={paymentRequestTitle}
      />
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Payment Request will be lost."
        />

        <form onSubmit={handleSubmit(onCostsSubmit)}>
          <AlertForQueryErrorOrNull
            isError={editPaymentRequestCostsStatus.isError}
            error={editPaymentRequestCostsStatus.error}
          />

          <AlertForChangedData isDirty={isDirty} />

          {!isFinalPayment && (
            <>
              <ColumnContainer
                sx={{
                  padding: 0,
                  border: '1px solid lightgray',
                  borderRadius: 2,
                  mt: 1,
                  gap: 1,
                }}
              >
                <PaymentRequestCostsCurrentPaymentsSection
                  currentPaymentsFields={currentPaymentsFields}
                  form={form}
                  control={control}
                  readOnly={
                    readOnly || 
                    (!isFinalPayment && !hasLiveprojectPaymentsProcess) ||
                    (isFinalPayment && !hasLiveprojectFinalpaymentProcess)
                  }
                />
              </ColumnContainer>
              <ColumnContainer
                sx={{
                  padding: 0,
                  border: '1px solid lightgray',
                  borderRadius: 2,
                  mt: 2,
                  gap: 1,
                }}
              >
                <PaymentRequestCostsMonthlyCostsSection
                  monthlyCostsFields={monthlyCostsFields}
                  form={form}
                  readOnly={
                    readOnly || 
                    (!isFinalPayment && !hasLiveprojectPaymentsProcess) ||
                    (isFinalPayment && !hasLiveprojectFinalpaymentProcess)
                  }
                />
              </ColumnContainer>
            </>
          )}
          <ColumnContainer
            sx={{
              padding: 0,
              border: '1px solid lightgray',
              borderRadius: 2,
              mt: 2,
              gap: 1,
            }}
          >
            <PaymentRequestCostsFinalPaymentSection
              finalPayment={currentData?.finalPayment}
              control={control}
              isFinalPayment={isFinalPayment}
              totalGrantFunding={data ? data?.totalGrantFunding : 0}
              readOnly={
                readOnly || 
                (!isFinalPayment && !hasLiveprojectPaymentsProcess) ||
                (isFinalPayment && !hasLiveprojectFinalpaymentProcess)
              }
            />
          </ColumnContainer>
          <PaymentRequestCostsFooterSection
            totalGrantFunding={data ? data?.totalGrantFunding : 0}
            totalScheduledCosts={
              Number(scheduledCurrentPaymentsTotals) +
              Number(scheduledMonthlyCostsTotals) +
              Number(scheduledFinalCostsTotals) +
              Number(totalPaidToDate)
            }
            totalConfirmedCosts={totalCosts}
            totalPaidCosts={totalPaidToDate}
            readOnly={
              readOnly || 
              (!isFinalPayment && !hasLiveprojectPaymentsProcess) ||
              (isFinalPayment && !hasLiveprojectFinalpaymentProcess)
            }
            hasProcessPermssion={hasProcessPermssion}
          />
        </form>
        {isSavePaymentRequestModalShowing && (
          <PaymentRequestSaveModal
            onCancel={() => hideSavePaymentRequestModal()}
            onConfirm={() => saveCosts()}
            control={control}
          />
        )}
      </FormProvider>
    </>
  );
};

const PaymentsPaidToDateContainer = ({ children }: { children: ReactNode }) => {
  return (
    <ColumnContainer
      sx={{
        padding: 0,
        borderRadius: 2,
        mt: 2,
        gap: 1,
        mb: 2,
      }}
    >
      {children}
    </ColumnContainer>
  );
};
