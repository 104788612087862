import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Controller, useFormContext } from 'react-hook-form';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EReferralType } from 'enums/EReferralType';
import { useFileUpload } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { ReferApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplicationNotificationBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import { IApplicationFRAEWChecks } from 'types/applications/ApplicationEligibilityTypes';
import { nameof } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationFRAEWChecks) =>
  nameof<IApplicationFRAEWChecks>(fieldName);

interface IFRAEWAssuranceProps {
  data?: IApplicationFRAEWChecks;
  readOnly?: boolean;
  assuranceEvidenceDocumentFileInvalid: boolean;
}
export const FRAEWAssurance = ({
  data,
  readOnly,
  assuranceEvidenceDocumentFileInvalid,
}: IFRAEWAssuranceProps) => {
  const { onReferralQuestion } = useApplicationEligibilityContext();
  const { uploadFile, uploadFileStatus } = useFileUpload();

  const { watch, setValue } = useFormContext<IApplicationFRAEWChecks>();

  const watchAssuranceEvidenceAvailable = watch('assuranceEvidenceAvailable');

  const watchAssuranceEvidenceDocumentFileId = watch(
    'assuranceEvidenceDocumentFileId'
  );

  const handleReferralQuestion = (
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => {
    onReferralQuestion(
      EEligibilitySection.Fraew,
      question,
      referralType,
      refer
    );
  };

  const uploadAssuranceEvidenceFile = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'EligibilityFRAEW',
      onSuccess: payload => {
        setValue(`assuranceEvidenceDocumentFileId`, payload.id);
      },
    });
  };

  const deleteAssuranceEvidenceFile = async () => {
    setValue(`assuranceEvidenceDocumentFileId`, '');
  };

  return (
    <Grid container spacing={1}>
      <Grid xs={9} item>
        <Grid container spacing={1}>
          <Box p={2}>
            <Grid xs={12} item>
              <Typography variant="h2" fontWeight={600}>
                Assurance
              </Typography>
            </Grid>
            <Box p={2}>
              <Grid xs={12} item>
                <Grid item mb={2}>
                  <Typography variant="h3" fontWeight={600} fontSize="1em">
                    Is the assurance evidence available now?
                  </Typography>
                  <Controller
                    defaultValue={''}
                    name={getName('assuranceEvidenceAvailable')}
                    render={({
                      fieldState,
                      field: { onChange, ...fieldProps },
                    }) => (
                      <FormControl>
                        <ToggleButtonGroup
                          {...fieldProps}
                          onChange={(_, val) => {
                            if (val !== null) {
                              onChange(val);
                            }
                            handleReferralQuestion(
                              EEligibilityQuestion.FRAEW_AssuranceEvidenceAvailable,
                              EReferralType.HomesEngland,
                              val === false
                            );
                          }}
                          exclusive
                          disabled={readOnly}
                        >
                          <ToggleButton data-automation-id="AssuranceEvidenceAvailable_Yes" value={true}>Yes</ToggleButton>
                          <ToggleButton data-automation-id="AssuranceEvidenceAvailable_No" value={false}>No</ToggleButton>
                        </ToggleButtonGroup>
                        {fieldState.error?.message ? (
                          <FormHelperText error>
                            {fieldState.error?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
              {watchAssuranceEvidenceAvailable === true && (
                <Grid item mb={2} data-automation-id="AssuranceEvidenceDocument">
                  <Typography variant="h3" fontWeight={600} fontSize="1em">
                    Provide assurance evidence
                  </Typography>
                  {readOnly ||
                  watchAssuranceEvidenceDocumentFileId ===
                    data?.assuranceEvidenceDocument?.id ? (
                    <Box display="flex" flexDirection="row">
                      <ApplicationFileDownloadWrapper
                        fileName={data?.assuranceEvidenceDocument?.name ?? ''}
                        fileId={data?.assuranceEvidenceDocument?.id ?? ''}
                      />
                      {!readOnly && (
                        <IconButton
                          size="small"
                          onClick={deleteAssuranceEvidenceFile}
                          aria-label="Delete File"
                          name="Delete File"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      )}
                    </Box>
                  ) : (
                    <>
                      <FileUploadInputSelect
                        id="assuranceEvidenceDocument"
                        accept=".pdf"
                        inputName="assuranceEvidenceDocumentFileId"
                        onFileUpload={file => uploadAssuranceEvidenceFile(file)}
                        onFileDelete={deleteAssuranceEvidenceFile}
                        isUploading={uploadFileStatus.isLoading}
                        uploaded={uploadFileStatus.isSuccess}
                        label="Click to upload or drag and drop PDF (max. 100MB)"
                      />
                      {assuranceEvidenceDocumentFileInvalid && (
                        <FormHelperText error>
                          Assurance evidence document is required.
                        </FormHelperText>
                      )}
                    </>
                  )}
                </Grid>
              )}
              {watchAssuranceEvidenceAvailable === false && (
                <ReferApplicationNotificationBanner
                  question={
                    EEligibilityQuestion.FRAEW_AssuranceEvidenceAvailable
                  }
                  referralType={EReferralType.HomesEngland}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid xs={3} item></Grid>
    </Grid>
  );
};
