import { EScheduleOfWorksReferralStatus } from 'enums/EScheduleOfWorksReferralStatus';
import { EScheduleOfWorksStatus } from 'enums/EScheduleOfWorksStatus';
import {
  EScheduleOfWorksChecksAnswer,
  EScheuduleOfWorksStatus,
  IScheduleOfWorksChecks,
  IScheduleOfWorksChecksDetails,
  IScheduleOfWorksCosts,
  IScheduleOfWorksOverview,
  IScheduleOfWorksReferral,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';
import { getGuid } from 'util/AppUtils';

export const applicationScheduleOfWorksOverviewData: IScheduleOfWorksOverview =
  {
    totalGrantFunding: 1300000,
    totalGrantPaidToDate: 1300000,
    totalUnClaimedGrant: 1300000,
    projectDurationInMonths: 13,
    startDate: '2023-04-01',
    endDate: '2024-07-31',
    dateSubmitted: '2023-08-01',
    status: EScheuduleOfWorksStatus.InProgress,
    worksContractDocuments: [
      {
        fileId: '1',
        filename: 'Works Contract Document 1',
        uploadDate: '"2023-09-25T05:47:55.1697423Z"',
      },
      {
        fileId: '2',
        filename: 'Works Contract Document 2',
        uploadDate: '"2023-09-24T05:47:55.1697423Z"',
      },
    ],
    buildingControlDocuments: [
      {
        fileId: '1',
        filename: 'Works Contract Document 3',
        uploadDate: '"2023-09-25T05:47:55.1697423Z"',
      },
      {
        fileId: '2',
        filename: 'Works Contract Document 4',
        uploadDate: '"2023-09-24T05:47:55.1697423Z"',
      },
    ],
    leaseholderAgreementDocuments: [
      {
        fileId: '1',
        filename: 'Works Contract Document 5',
        uploadDate: '"2023-09-25T05:47:55.1697423Z"',
      },
      {
        fileId: '2',
        filename: 'Works Contract Document 6',
        uploadDate: '"2023-09-24T05:47:55.1697423Z"',
      },
    ],
    approvedDate: new Date(),
    approvedBy: 'Alan Rogers',
    latestPaymentRequestId: getGuid(),
    isFinalPayment: false,
  };

export const applicationScheduleOfWorksCostsData: IScheduleOfWorksCosts = {
  id: getGuid(),
  grantPaidToDate: [
    {
      itemName: 'PTFS Payment',
      amount: 50000,
      paid: true,
    },
    {
      itemName: 'July 2023',
      amount: 50000,
      paid: true,
    },
  ],
  showUpfrontPercentage: true,
  applyUpfrontPercentage: true,
  upfrontPercentage: 10,
  initialPayments: [
    {
      id: '1',
      itemName: 'August 2023',
      submittedCost: 125000,
      confirmedCost: 125000,
    },
  ],
  monthlyCosts: [
    {
      id: '1',
      itemName: 'September 2023',
      submittedCost: 100000,
      confirmedCost: 100000,
    },
    {
      id: '2',
      itemName: 'October 2023',
      submittedCost: 75000,
      confirmedCost: 75000,
    },
    {
      id: '3',
      itemName: 'November 2023',
      submittedCost: 75000,
      confirmedCost: 75000,
    },
    {
      id: '4',
      itemName: 'December 2023',
      submittedCost: 62500,
      confirmedCost: 62500,
    },
    {
      id: '5',
      itemName: 'January 2024',
      submittedCost: 82000,
      confirmedCost: 82000,
    },
    {
      id: '6',
      itemName: 'February 2024',
      submittedCost: 75000,
      confirmedCost: 75000,
    },
    {
      id: '7',
      itemName: 'March 2024',
      submittedCost: 75000,
      confirmedCost: 75000,
    },
    {
      id: '8',
      itemName: 'April 2024',
      submittedCost: 75000,
      confirmedCost: 75000,
    },
    {
      id: '9',
      itemName: 'May 2024',
      submittedCost: 62500,
      confirmedCost: 5500,
    },
    {
      id: '10',
      itemName: 'June 2024',
      submittedCost: 62500,
      confirmedCost: 62500,
    },
    {
      id: '11',
      itemName: 'July 2024',
      submittedCost: 63500,
      confirmedCost: 17000,
    },
  ],
  finalPayment: {
    itemName: '5% of overall project costs to be paid on completion',
    confirmedCost: 50000,
  },
};

export const applicationScheduleOfWorksChecksDetailData: IScheduleOfWorksChecksDetails =
  {
    startDate: '2023-04-01',
    endDate: '2024-07-31',
    buildingHeightMetres: 15,
    eligibleCosts: 1300000,
    otherCosts: 1300000,
    totalCosts: 2600000,
    thirdPartyContribution: 4000,
    leadContractorCompany: 'Lead Contractor Company',
    worksContractDocuments: [
      {
        fileId: '1',
        filename: 'Works Contract Document 1',
        uploadDate: '"2023-09-25T05:47:55.1697423Z"',
      },
      {
        fileId: '2',
        filename: 'Works Contract Document 2',
        uploadDate: '"2023-09-24T05:47:55.1697423Z"',
      },
    ],
  };

export const applicationScheduleOfWorksChecksData: IScheduleOfWorksChecks = {
  id: '1',
  siteStartDateMatchesContract: true,
  siteStartDateMatchesContractReason: 'Site Start Date Matches Contract Reason',
  contractProvidesSufficientTime: true,
  contractProvidesSufficientTimeReason:
    'Contract Provides Sufficient Time Reason',
  workPackageCostsMatchSubmittedCosts: true,
  workPackageCostsMatchSubmittedCostsReason:
    'Works Package Costs Match Submitted Costs Reason',
  hasFullPlansApproval: EScheduleOfWorksChecksAnswer.Yes,
  hasFullPlansApprovalReason: 'Has Full Plans Approval Reason',
  companyNameMatchLeadContractor: true,
  companyNameMatchLeadContractorReason:
    'Company Name Match Lead Contractor Reason',
  monthlyPaymentsGreaterThanMaximumPermitted: true,
  monthlyPaymentsGreaterThanMaximumPermittedReason:
    'Monthly Payments Greater Than Maximum Permitted Reason',
  status: EScheduleOfWorksStatus.Submitted,
  isScheduleOfWorksCostsConfirmed: true,
};

export const applicationScheduleOfWorksReferralsData: Array<IScheduleOfWorksReferral> =
  [
    {
      id: getGuid(),
      reason: 'Schedule Of Works - Referral 1',
      status: EScheduleOfWorksReferralStatus.InProgress,
      completeReason: null,
      referredByUserId: getGuid(),
    },
    {
      id: getGuid(),
      reason: 'Schedule Of Works - Referral 2',
      status: EScheduleOfWorksReferralStatus.Complete,
      completeReason: 'Some information here about why this was completed',
      referredByUserId: getGuid(),
    },
    {
      id: getGuid(),
      reason: 'Schedule Of Works - Referral 3',
      status: EScheduleOfWorksReferralStatus.Overdue,
      completeReason: 'Some information here about why this was completed',
      referredByUserId: getGuid(),
    },
    {
      id: getGuid(),
      reason: 'Schedule Of Works - Referral 4',
      status: EScheduleOfWorksReferralStatus.Overdue,
      completeReason: null,
      referredByUserId: 'a90ca444-db7b-4a4b-a468-3dce205b888b',
    },
  ];
