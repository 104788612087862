import { Chip } from '@mui/material';
import { useGetPullInRagRatingQuery } from 'api/pullInProcess';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';

export const PullInRagRating = () => {
  const { pullInProcessId } = usePullInProcessContext();
  const { data: pullInRagRating } = useGetPullInRagRatingQuery(pullInProcessId);

  const ragRatingBackgroundColor = (ratingName: string): string => {
    switch (ratingName) {
      case 'High':
        return '#8b0000';
      case 'Medium':
        return '#ff8c00';
      case 'Low':
        return '#2e7d32';
      default:
        return '#D3D3D3';
    }
  };

  const ragRatingForegroundColor = (ratingName: string): string => {
    switch (ratingName) {
      case 'High':
        return '#FFFFFF';
      case 'Medium':
        return '#000000';
      case 'Low':
        return '#FFFFFF';
      default:
        return '#000000';
    }
  };

  return (
    <>
      {pullInRagRating?.ratingName ? (
        <Chip
          sx={{
            backgroundColor: ragRatingBackgroundColor(
              pullInRagRating?.ratingName
            ),
            color: ragRatingForegroundColor(pullInRagRating?.ratingName),
          }}
          label={pullInRagRating?.ratingName}
        />
      ) : null}
    </>
  );
};
