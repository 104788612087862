import { ReactNode, useState } from 'react';
import { Box, Tabs, useTheme } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  useGetApplicationSummaryQuery,
  useGetConfirmEligibilityChecksQuery,
} from 'api/application';
import { CustomTab } from 'common/components/customTab';
import { CustomTabPanel } from 'common/components/customTabPanel';
import { EApplicationStatusLanguage } from 'enums/EApplicationStatus';
import { EEligiblityCheckStatus } from 'enums/EEligiblityCheckStatus';
import { useCurrentUserPermissions } from 'hooks/authHooks/useCurrentUserPermissions';
import { PermissionDeniedToTab } from 'pages/applicationPage/common/components/PermissionDeniedToTab';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { EApplicationTabType } from 'pages/applicationPage/common/types';
import {
  ApplicationDetails,
  ApplicationEligibility,
  ApplicationSignatories,
  ApplicationTaskList,
  Documents,
  ProjectPrep,
  Support,
} from 'pages/applicationPage/content';
import { ApplicationGrantFunding } from 'pages/applicationPage/content/applicationGrantFunding';
import { useGridQueryAndState as useApplicationTaskListGridQuery } from 'pages/applicationPage/content/applicationTasklist/utils';
import { Communications } from 'pages/applicationPage/content/communications';
import { DutyOfCare } from 'pages/applicationPage/content/dutyOfCare';
import { LiveProject } from 'pages/applicationPage/content/liveProject';
import { Payments } from 'pages/applicationPage/content/payments';

type TabItem = {
  label: string;
  tabType: EApplicationTabType;
  content: ReactNode;
  isDisabled?: boolean;
  isHidden?: boolean;
};

export const ApplicationTabs = () => {
  const { applicationId } = useApplicationContext();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasCommunicationsView = doesUserHaveSinglePermission(
    'communications.view'
  );
  const hasApplicationsDetailsView = doesUserHaveSinglePermission(
    'applications.details.view'
  );
  const hasApplicationsEligibilityView = doesUserHaveSinglePermission(
    'applications.eligibility.view'
  );
  const hasApplicationsGrantFundingView = doesUserHaveSinglePermission(
    'applications.grantfunding.view'
  );
  const hasApplicationsSignatoriesView = doesUserHaveSinglePermission(
    'applications.signatory.view'
  );
  const hasLiveprojectView = doesUserHaveSinglePermission('liveproject.view');
  const hasPaymentPermission = doesUserHaveSinglePermission(
    'applications.payments'
  );
  const hasApplicationsTasklistView = doesUserHaveSinglePermission(
    'applications.tasklist.view'
  );
  const hasApplicationTasklistViewown = doesUserHaveSinglePermission(
    'application.tasklist.view-own'
  );
  const hasViewDocuments = doesUserHaveSinglePermission('documents.view');
  const hasProjectsetupViewPermission =
    doesUserHaveSinglePermission('projectsetup.view');
  const hasApplicationDutyofcareView = doesUserHaveSinglePermission('applications.dutyofcare.view');
  const hasSupportView = doesUserHaveSinglePermission('support.view');

  const summaryQuery = useGetApplicationSummaryQuery(
    applicationId ?? skipToken
  );
  const applicationStatus = summaryQuery?.data?.status;
  const applicationProgressArray = ['Not Started', 'In Progress'];
  const isApplicationInProgress =
    applicationStatus &&
    applicationProgressArray.includes(
      EApplicationStatusLanguage[applicationStatus]
    );

  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState<EApplicationTabType>(
    EApplicationTabType.Application
  );

  const handleChange = (newValue: string) => {
    setSelectedTab(newValue as EApplicationTabType);
  };

  const eligibilityQuery = useGetConfirmEligibilityChecksQuery(applicationId);
  const isEligibilityApproved =
    eligibilityQuery.data?.status === EEligiblityCheckStatus.Approved;
  const isGrantCertifyingOfficerCaptured =
    summaryQuery?.data?.isGrantCertifyingOfficerCaptured;
  const isVendorPaymentApprovalWaiting =
    summaryQuery?.data?.isVendorPaymentApprovalWaiting;

  const tabItems: TabItem[] = [
    {
      label: 'Application Details',
      tabType: EApplicationTabType.Application,
      content: hasApplicationsDetailsView ? <ApplicationDetails /> : <PermissionDeniedToTab />,
    },
    {
      label: 'Task List',
      tabType: EApplicationTabType.TaskList,
      content: (hasApplicationsTasklistView || hasApplicationTasklistViewown) ? <ApplicationTaskList /> : <PermissionDeniedToTab />,
    },
    {
      label: 'Eligibility',
      tabType: EApplicationTabType.Eligibility,
      content: <ApplicationEligibility />,
      isDisabled: !hasApplicationsEligibilityView || isApplicationInProgress,
    },
    {
      label: 'Grant Funding',
      tabType: EApplicationTabType.GrantFunding,
      content: <ApplicationGrantFunding />,
      isDisabled:
        !hasApplicationsGrantFundingView ||
        !isEligibilityApproved ||
        isApplicationInProgress,
    },
    {
      label: 'Payments',
      tabType: EApplicationTabType.Payment,
      content: <Payments />,
      isHidden:
        !isEligibilityApproved ||
        !hasPaymentPermission ||
        isApplicationInProgress,
    },
    {
      label: 'Project Set-up',
      tabType: EApplicationTabType.ProjectPrep,
      content: hasProjectsetupViewPermission ? (
        <ProjectPrep />
      ) : (
        <PermissionDeniedToTab />
      ),
    },
    {
      label: 'Live Project',
      tabType: EApplicationTabType.LiveProject,
      content: hasLiveprojectView ? <LiveProject /> : <PermissionDeniedToTab />,
    },
    {
      label: 'Signatories',
      tabType: EApplicationTabType.Signatories,
      content: hasApplicationsSignatoriesView ? <ApplicationSignatories /> : <PermissionDeniedToTab />,
    },
    {
      label: 'Documents',
      tabType: EApplicationTabType.Documents,
      content: hasViewDocuments ? <Documents /> : <PermissionDeniedToTab />,
    },
    {
      label: 'Communication',
      tabType: EApplicationTabType.Communication,
      content: hasCommunicationsView ? (
        <Communications />
      ) : (
        <PermissionDeniedToTab />
      ),
    },
    {
      label: 'Support',
      tabType: EApplicationTabType.Support,
      content: hasSupportView ? <Support /> : <PermissionDeniedToTab />,
    },
    {
      label: 'Duty of Care',
      tabType: EApplicationTabType.DutyOfCare,
      content: hasApplicationDutyofcareView ? <DutyOfCare /> : <PermissionDeniedToTab />,
      isDisabled: !isGrantCertifyingOfficerCaptured,
    },
  ];

  const { query } = useApplicationTaskListGridQuery();

  function displayWarningIcon(tabName: string) {
    switch (tabName) {
      case 'Payments':
        return isVendorPaymentApprovalWaiting;
      default:
        return false;
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ position: 'relative', top: 1 }}>
        <nav>
          <Tabs
            onChange={(_, value) => {
              if (value === EApplicationTabType.TaskList) {
                // Trigger a refetch of the tasks whenever we enter the tasks tab
                query.refetch();
              }

              handleChange(value);
            }}
            aria-label="Application Tabs"
            value={selectedTab}
            sx={{ px: 3 }}
          >
            {tabItems.map((item, index) => {
              if (item.isHidden) {
                return null;
              }

              return (
                <CustomTab
                  disabled={item.isDisabled}
                  key={item.tabType}
                  label={item.label}
                  currentSelectedTab={selectedTab}
                  value={item.tabType}
                  showWarningIcon={displayWarningIcon(item.label)}
                />
              );
            })}
          </Tabs>
        </nav>
      </Box>
      <Box sx={{ borderTop: 1, borderColor: theme.palette.divider }}>
        {/* 
          The reason for using a map with <Box> elements here and showing/hiding with display/block CSS instead of using a series of `<TabPanel />` components
          is because we want to keep the nodes in the DOM to take advantage of the page guards within eligibility.
          I.e. if a change is made within the eligibility, but not saved, then the user can navigate to a different tab,
          then go back to the eligibility tab and the forms will be in the same state. If we use `<TabPanel />` components then each tab is mounted/unmounted
          when navigating between the tabs, therefore losing the state of each tab at the same time.
          With the manual hide/show approach, if they attempt to navigate away from the page, the existing eligibility page guards will kick in and ask if they're sure they want to leave without saving.
          */}
        {tabItems.map((item, index) => {
          if (item.isHidden || item.isDisabled) {
            return null;
          }

          return (
            <CustomTabPanel
              key={item.tabType}
              currentSelectedTab={selectedTab}
              value={item.tabType}
              sx={{ p: 3 }}
            >
              {item.content}
            </CustomTabPanel>
          );
        })}
      </Box>
    </Box>
  );
};
