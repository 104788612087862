import { Box, Stack, useTheme, Typography } from '@mui/material';
import {
  EVariationType,
  IVariationOverview,
  ThirdPartyContributionTypeVariation,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import {
  Row,
  VariationOverviewBox,
} from 'pages/applicationPage/content/liveProject/sections/VariationSection/components/common';
import { getGbpAmount, isNumeric } from 'util/AppUtils';

const WarningCard = () => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        borderLeft: `5px solid  ${palette.error.main}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}
        bgcolor={'#f6d7d2'}
      >
        <Typography fontSize={'0.9em'} color={'grey.600'}>
          Greater than amount of grant available
        </Typography>
      </Box>
    </Box>
  );
};

export function ThirdPartyContributionBox({
  data,
}: {
  data: IVariationOverview;
}) {
  const thirdPartyVariationType = data.variations.find(
    v => v.type === EVariationType.ThirdPartyContribution
  ) as ThirdPartyContributionTypeVariation;

  const bothValidNums =
    isNumeric(thirdPartyVariationType?.amount) &&
    isNumeric(thirdPartyVariationType?.amountGrantAvailable);

  const isExceeding =
    bothValidNums &&
    thirdPartyVariationType?.amount >
      thirdPartyVariationType?.amountGrantAvailable;

  return (
    <Box>
      <Stack gap={2}>
        <VariationOverviewBox title="Third Party Contribution">
          <Stack gap={2}>
            {isExceeding ? <WarningCard /> : null}
            <Box sx={{ bgcolor: 'white', p: 2 }}>
              <Stack gap={2}>
                <Row
                  label="Third Party Contribution"
                  value={getGbpAmount({
                    value: thirdPartyVariationType?.amount,
                    showCurrencySymbol: true,
                    showIfValueNullOrUndefined: '-',
                  })}
                  valueSx={{
                    fontWeight: 600,
                    color: isExceeding ? 'red' : 'inherit',
                  }}
                />
                <Row
                  label="Amount Grant Available"
                  value={getGbpAmount({
                    value: thirdPartyVariationType?.amountGrantAvailable,
                    showCurrencySymbol: true,
                    showIfValueNullOrUndefined: '-',
                  })}
                />
              </Stack>
            </Box>
          </Stack>
        </VariationOverviewBox>
      </Stack>
    </Box>
  );
}
