import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useAddOrganisationMutation,
  useEditOrganisationMutation,
  useLazyGetOrganisationQuery,
} from 'api/administration/organisations';
import {
  useGetTeamListQuery
} from 'api/administration/teams';
import { Loading } from 'common/components/loading';
import { useLocalSnackbar } from 'hooks';
import { StyledDrawer, StyledDrawerActions } from 'styles/globalStyles/drawer';
import { IOrganisationForEdit } from 'types/administration/AdministrationOrganisationTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IOrganisationForEdit) =>
  nameof<IOrganisationForEdit>(fieldName);

interface IAddEditOrganisationDialogProps {
  organisationId?: number;
  onSuccess: () => void;
  onClose: () => void;
}

const defaultSystemNotification: IOrganisationForEdit = {
  id: 0,
  name: '',
  needsToAgreeTermsAndConditions: false,
  teams: []
};

export function AddEditOrgDrawer({
  organisationId,
  onSuccess,
  onClose,
}: IAddEditOrganisationDialogProps) {
  return (
    <StyledDrawer
      anchor="right"
      open
      onClose={() => {
        onClose();
      }}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h1" component="span">
            {organisationId ? 'Edit' : 'Add'} Organisation
          </Typography>
        </DialogTitle>
        <AddEditSystemNotificationForm
          organisationId={organisationId}
          onSuccess={onSuccess}
          onClose={onClose}
        />
      </Box>
    </StyledDrawer>
  );
}

interface IAddEditOrganisationFormProps {
  organisationId?: number;
  onSuccess: () => void;
  onClose: () => void;
}

export const AddEditSystemNotificationForm = ({
  organisationId,
  onSuccess,
  onClose,
}: IAddEditOrganisationFormProps) => {

  const [getOrganisation, getOrganisationStatus] = useLazyGetOrganisationQuery();
  const [addOrganisation, addOrganisationResult] = useAddOrganisationMutation();
  const [editOrganisation, editOrganisationResult] = useEditOrganisationMutation();
  const {
      data: teamListData,
      isError: teamListIsError,
      error: teamListError,
      isLoading: teamListIsLoading,
    } = useGetTeamListQuery();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } = useLocalSnackbar();

  const requiredHelpText = 'Please fill out this field';

  const form = useForm<IOrganisationForEdit>({
    defaultValues: defaultSystemNotification,
  });
  const { control, handleSubmit, reset } = form;

  // if is Edit then retrieve data
  useEffect(() => {
    if (organisationId) {
      getOrganisation(organisationId).then(result => {
        if (result?.data) {
          reset(result.data);
        }
      });
    }
  }, [reset, organisationId, getOrganisation]);

  // errors
  useEffect(() => {
    if (teamListIsError && teamListError)
      createErrorSnackbar('An error occurred while fetching Team data.');
  }, [teamListIsError, teamListError, createErrorSnackbar]);

  const onSubmit = async (formData: IOrganisationForEdit) => {
    if (organisationId === undefined) {
      try {
        await addOrganisation(formData)
          .unwrap()
          .then(_ => {
            createSuccessSnackbar(`New organisation added`);
            onSuccess();
          })
          .catch(error => {
            if (error?.data?.generalError?.errorMessage) {
              createErrorSnackbar(error.data.generalError.errorMessage);
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(`Failed to add organisation`);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to add organisation`);
      }
    } else
      try {
        formData.id = organisationId;
        await editOrganisation(formData)
          .unwrap()
          .then(_ => {
            createSuccessSnackbar(`Organisation updated successfully`);
            onSuccess();
          })
          .catch(error => {
            if (error?.data?.generalError?.errorMessage) {
              createErrorSnackbar(error.data.generalError.errorMessage);
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(`Failed to edit organisation`);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to edit organisation`);
      }
  };

  const isFormLoading = getOrganisationStatus.isLoading || teamListIsLoading;
  const isSubmitting = addOrganisationResult.isLoading || editOrganisationResult.isLoading;

  return isFormLoading ? (
    <Loading isOpen />
  ) : (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>

              <Typography variant="h2" mb={3}>
                Detail
              </Typography>
              <Controller
                control={control}
                name={getName('name')}
                rules={{ required: requiredHelpText }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    label="Name"
                    helperText={fieldState.error?.message}
                    multiline
                    InputProps={{
                      inputProps: {
                        maxLength: 1000,
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>

              <Typography variant="h2" mb={3}>
                Terms and Conditions
              </Typography>

              <p>
                Are users assigned to this organisation required to agree Terms &amp; Conditions?
              </p>

              <Controller
                  name={`needsToAgreeTermsAndConditions`}
                  render={({ field: { onChange, ...fieldProps } }) => (
                    <ToggleButtonGroup
                      {...fieldProps}
                      onChange={(_, val) => {
                        if (val !== null) onChange(val);
                      }}
                      exclusive
                    >
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
            </Grid>
            
            <Grid item xs={12}>
                <Typography variant="h2" mb={3}>
                  Team view
                </Typography>
                <Controller
                  control={control}
                  name="teams"
                  rules={{
                    validate: value => 
                      (value && value.length > 0) || requiredHelpText
                  }}
                  render={({
                    fieldState,
                    field: { onChange, value, ...fieldProps },
                  }) => (
                    <Autocomplete
                      {...fieldProps}
                      onChange={(_, val) => {
                        const selectedAuthorities = val.map(id => ({
                          id: id,
                          name:
                          teamListData?.find(o => o.id === id)?.name || '',
                        }));
                        onChange(selectedAuthorities);
                      }}
                      value={value ? value.map(item => item.id) : []}
                      options={teamListData?.map(({ id }) => id) || []}
                      getOptionLabel={opt =>
                        teamListData?.find(o => o.id === opt)?.name || ''
                      }
                      multiple
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!fieldState.error}
                            label="Team"
                            helperText={fieldState.error?.message || 
                              (!value || value.length === 0 ? requiredHelpText : "")}
                          />
                        );
                      }}
                    />
                  )}
                />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDrawerActions>
          <Grid container justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
            >
              {organisationId ? 'Update' : 'Add'} Organisation
            </LoadingButton>
          </Grid>
        </StyledDrawerActions>
      </form>
    </FormProvider>
  );
};