import { useState, useEffect } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@mui/material';
import { TextFieldWithStartIcon } from 'common/components/textFieldWithStartIcon';
import { useDebounce } from 'hooks';

interface IGridSearchFieldProps {
  isLoading: boolean;
  defaultValue: Nullable<string> | undefined;
  handleSearch: (searchValue: string) => void;
}

export const GridSearchField = ({
  isLoading,
  defaultValue,
  handleSearch,
}: IGridSearchFieldProps) => {
  const [searchValue, setSearchValue] = useState<string | null>(
    defaultValue ?? null
  );
  const debouncedValue = useDebounce(searchValue, 500);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    // Need the following check to avoid an infinite re-rendering loop
    if (debouncedValue !== null && defaultValue !== debouncedValue) {
      // We have a new value so call the func passed in from the parent component
      handleSearch(debouncedValue);
    }
  }, [debouncedValue, defaultValue, handleSearch]);

  return (
    <TextFieldWithStartIcon
      data-testid="searchValue"
      disabled={isLoading}
      onChange={e => {
        handleSearchChange(e.target.value);
      }}
      label="Type to search"
      defaultValue={defaultValue ?? ''}
      sx={{ fontSize: '15px' }}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FontAwesomeIcon icon={faSearch} size="sm" />
          </InputAdornment>
        ),
      }}
    />
  );
};
