import { useState } from 'react';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Box,
  Card,
  CardContent,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { EQuestionType } from 'enums/EQuestionType';
import {
  useCurrentUserPermissions,
  useEllipsisMenu,
  useModalState,
} from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import {
  ArchivePaymentRequestCostEvidenceDocumentDialog,
  ViewPaymentRequestCostEvidenceAuditHistoryDialog,
  PaymentRequestCostEvidenceEditedIndicator,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/Monthly/sections/PaymentRequestCostEvidenceSection';
import { IPaymentRequestCostEvidenceDocument } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';

export function PaymentRequestCostEvidenceDocumentList({
  title,
  documents = [],
  docType,
  onUploadClick,
  refetch,
}: IPaymentRequestCostEvidenceDocument) {
  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const [selectedFileId, setselectedFileId] = useState<string | null>(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    'costReport' | 'invoice'
  >('costReport');

  const onArchiveDocumentClick = (
    fileId: string,
    docType: 'costReport' | 'invoice'
  ) => {
    setselectedFileId(fileId);
    setSelectedDocumentType(docType);
    showArchiveDocumentDialog(true);
  };
  const [questionType, setQuestionType] = useState<EQuestionType | null>(null);
  const {
    isShowing: isArchiveDocumentDialogShowing,
    showModal: showArchiveDocumentDialog,
    hideModal: hideArchiveDocumentDialog,
  } = useModalState();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasLiveprojectPaymentProcess = doesUserHaveSinglePermission(
    'liveproject.payments.process'
  );

  const {
    isShowing: isViewPaymentRequestCostEvidenceAuditHistoryDialogShowing,
    showModal: showPaymentRequestCostEvidenceAuditHistoryDialog,
    hideModal: hidePaymentRequestCostEvidenceAuditHistoryDialog,
  } = useModalState();

  const onHistoryClick = (docType: 'costReport' | 'invoice') => {
    const type =
      docType === 'costReport'
        ? EQuestionType.PaymentRequestCostReport
        : EQuestionType.PaymentRequestInvoice;
    setQuestionType(type);
    showPaymentRequestCostEvidenceAuditHistoryDialog();
  };

  return (
    <RoundBorderBox>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderBottom: '1px solid lightgray',
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Button
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
            onClick={onUploadClick}
            disabled={!hasLiveprojectPaymentProcess}
          >
            Upload Document
          </Button>
          <PaymentRequestCostEvidenceEditedIndicator
            questionType={
              docType === 'costReport'
                ? EQuestionType.PaymentRequestCostReport
                : EQuestionType.PaymentRequestInvoice
            }
          />
          <IconButton
            aria-label="edit"
            name="edit"
            onClick={event => handleEllipsisClick(docType, event)}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>

          {docType === 'invoice' || docType === 'costReport' ? (
            <Menu
              id={`document-row-item-invoice`}
              anchorEl={ellipsisMenuAnchor}
              open={Boolean(ellipsisMenuAnchor)}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => onHistoryClick(docType)}>
                History
              </MenuItem>
            </Menu>
          ) : null}
        </Box>
      </Box>

      <Grid container spacing={2} p={2}>
        {documents.map((file, index) => (
          <Grid item xs={12} sm={6} key={file.id}>
            <Card variant="outlined">
              <CardContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 1,
                  '&:last-child': { pb: 0 },
                }}
              >
                <Stack spacing={1} sx={{ flexGrow: 1 }}>
                  <ApplicationFileDownloadWrapper
                    fileName={file.name}
                    fileId={file.id}
                    fileWrapperStyle={{
                      marginTop: '1rem',
                      border: 'none',
                    }}
                  />
                </Stack>

                <Box sx={{ ml: 'auto' }}>
                  <IconButton
                    aria-label="edit"
                    name="edit"
                    onClick={event => handleEllipsisClick(file.id, event)}
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                  {selectedId === file.id ? (
                    <Menu
                      id={`document-row-item-${file.id}`}
                      anchorEl={ellipsisMenuAnchor}
                      open={Boolean(ellipsisMenuAnchor)}
                      onClose={handleMenuClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          onArchiveDocumentClick(
                            file.id,
                            title === 'Cost Report' ? 'costReport' : 'invoice'
                          )
                        }
                        disabled={!hasLiveprojectPaymentProcess}
                      >
                        Archive
                      </MenuItem>
                    </Menu>
                  ) : null}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {isArchiveDocumentDialogShowing && selectedFileId && (
        <ArchivePaymentRequestCostEvidenceDocumentDialog
          documentId={selectedFileId}
          documentType={selectedDocumentType}
          onConfirm={() => {
            setselectedFileId(null);
            hideArchiveDocumentDialog();
            handleMenuClose();
            refetch();
          }}
          onCancel={() => {
            setselectedFileId(null);
            hideArchiveDocumentDialog();
            handleMenuClose();
          }}
        />
      )}
      {isViewPaymentRequestCostEvidenceAuditHistoryDialogShowing &&
        questionType !== null && (
          <ViewPaymentRequestCostEvidenceAuditHistoryDialog
            questionType={questionType as EQuestionType}
            onClose={() => {
              hidePaymentRequestCostEvidenceAuditHistoryDialog();
            }}
          />
        )}
    </RoundBorderBox>
  );
}
