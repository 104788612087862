import { Button, DialogContent, TextField } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useRejectPaymentRequestMutation } from 'api/application/paymentRequestApi';
import { useCurrentUserPermissions, useLocalSnackbar } from 'hooks';
import { IPaymentRequestRejectForm } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

interface IRejectPaymentRequestModalProps {
  applicationId: string;
  paymentRequestId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const getName = (fieldName: keyof IPaymentRequestRejectForm) =>
  nameof<IPaymentRequestRejectForm>(fieldName);

export function RejectPaymentRequestModal({
  applicationId,
  paymentRequestId,
  onConfirm,
  onCancel,
}: IRejectPaymentRequestModalProps) {
  const [rejectPaymentRequest] = useRejectPaymentRequestMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasPaymentReleaseReject = doesUserHaveSinglePermission(
    'payment-release.reject'
  );

  const form = useForm<IPaymentRequestRejectForm>();
  const { handleSubmit, reset } = form;

  const handleRejectPaymentRequest = async (
    formData: IPaymentRequestRejectForm
  ) => {
    formData.applicationId = applicationId;
    formData.paymentRequestId = paymentRequestId;

    if (!hasPaymentReleaseReject) {
      createErrorSnackbar('You do not have permission');
      onCancel();
      return;
    }

    try {
      await rejectPaymentRequest(formData)
        .unwrap()
        .then(() => {
          createSuccessSnackbar('Payment Request Rejected');
          onConfirm();
        })
        .catch(error => {
          if (error?.data?.generalError?.errorMessage) {
            createErrorSnackbar(error.data.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            error.data.propertyErrors.forEach(
              (item: { errorMessage: string }) => {
                setServerSideFormErrors(form, error.data);
                createWarningSnackbar(item.errorMessage);
              }
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to reject payment request`);
    }
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        Are you sure you want to reject this payment request?
      </StyledDialogTitle>
      <DialogContent>
        <Controller
          control={form.control}
          name={getName('reason')}
          rules={{ required: 'Required' }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              multiline
              fullWidth
              label="Provide reason"
              error={!!fieldState.error}
              rows={3}
              helperText={fieldState.error?.message}
              sx={{ mb: 0 }}
              inputProps={{ maxLength: 1000 }}
            />
          )}
        />
      </DialogContent>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleRejectPaymentRequest)}>
          <StyledDialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                reset();
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Reject Payment
            </Button>
          </StyledDialogActions>
        </form>
      </FormProvider>
    </StyledDialog>
  );
}
