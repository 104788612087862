import {
  Box,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useGetPullInEditHistoryQuery } from 'api/pullInProcess';
import { Loading } from 'common/components/loading';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { getUkDateTimeStringFromJsonDateString } from 'util/AppUtils';

interface IPullInEditHistoryModalProps {
  onClose: () => void;
  type: number;
  noHistoryText: string;
}

export function PullInEditHistoryModal({
  onClose,
  type,
  noHistoryText
}: IPullInEditHistoryModalProps) {
  const { pullInProcessId } = usePullInProcessContext();

  const { data, isLoading } = useGetPullInEditHistoryQuery({
    buildingId: pullInProcessId,
    historyType: type,
  });

  return isLoading ? (
    <Loading isOpen />
  ) : (
    <StyledDialog open fullWidth maxWidth="lg">
      <StyledDialogTitle sx={{ textAlign: 'left' }}>History</StyledDialogTitle>
      <StyledDialogContent>
        {data && data.length > 0 ? (
          <StyledGenericTable>
            <TableHead>
              <TableRow>
                <TableCell>Original Value</TableCell>
                <TableCell>New Value</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Date/Time</TableCell>
                <TableCell>Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(item => {
                return (
                  <TableRow key={uuidv4()}>
                    <TableCell>{item.oldValue}</TableCell>
                    <TableCell>{item.newValue}</TableCell>
                    <TableCell>{item.changedByUser}</TableCell>
                    <TableCell>
                      {getUkDateTimeStringFromJsonDateString(item.dateChanged)}
                    </TableCell>
                    <TableCell>{item.changeReason}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledGenericTable>
        ) : (
          <NoEditHistoryComponent noHistoryText={noHistoryText} />
        )}
      </StyledDialogContent>
      <StyledDialogActions
        sx={{ justifyContent: 'right', paddingRight: '2rem' }}
      >
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}

export function NoEditHistoryComponent({ noHistoryText }: { noHistoryText: string }) {
  const theme = useTheme();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={2}
      pl={0}
      borderColor={theme.palette.divider}
    >
      <Box padding="5px">
        <Typography variant="h1" component="span">
          {noHistoryText}
        </Typography>
      </Box>
    </Grid>
  );
}
