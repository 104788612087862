import {
  Box,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useVariationRejectMutation } from 'api/application/variationApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { useLocalSnackbar, useModalState } from 'hooks';
import { EVariationStatus } from 'pages/applicationPage/content/liveProject/sections/VariationSection/VariationTypes';
import { useApplicationAndVariationIdOrNull } from 'pages/applicationPage/content/liveProject/sections/VariationSection/hooks';

export const useRejectModal = () => {
  const { isShowing, showModal, hideModal } = useModalState();

  const renderRejectModal = () => {
    return <RejectModal isShowing={isShowing} hideModal={hideModal} />;
  };

  return {
    showRejectModal: showModal,
    hideRejectModal: hideModal,
    renderRejectModal,
  };
};

export const RejectModal = ({
  isShowing,
  hideModal,
}: {
  isShowing: boolean;
  hideModal: () => void;
}) => {
  const queryParams = useApplicationAndVariationIdOrNull();
  const [reject, status] = useVariationRejectMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const form = useForm<{ reason: string }>({
    defaultValues: { reason: '' },
  });

  const handleConfirmClick = async () => {
    const isValid = await form.trigger();
    if (!isValid) {
      return;
    }

    if (!queryParams) {
      createErrorSnackbar('Could not get variation data');
      return;
    }

    const { reason } = form.getValues();
    reject({
      ...queryParams,
      status: EVariationStatus.Rejected,
      reason,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Variation has been successfully rejected');
        hideModal();
        form.reset();
      });
  };

  if (!isShowing) {
    return null;
  }

  return (
    <ConfirmationModal
      isShowing={isShowing}
      confirmButtonText="Reject Variation"
      cancelButtonText="Cancel"
      onConfirm={handleConfirmClick}
      onCancel={hideModal}
      dialogActionsSx={{ m: 2 }}
      areButtonsLoading={status.isLoading}
      showActionsDivider
      fullWidth
      maxWidth="md"
    >
      <Box p={3}>
        {status.isLoading ? <LinearProgress /> : null}
        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={'1.2em'}>
            Reject variation request
          </Typography>

          <FormProvider {...form}>
            <Controller
              control={form.control}
              name={`reason`}
              rules={{ required: 'Required' }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  multiline
                  fullWidth
                  label="Explain reason"
                  error={!!fieldState.error}
                  rows={3}
                  helperText={fieldState.error?.message}
                  sx={{ mb: 0 }}
                />
              )}
            />
          </FormProvider>

          <AlertForQueryErrorOrNull
            isError={status.isError}
            error={status.error}
          />
        </Stack>
      </Box>
    </ConfirmationModal>
  );
};
