export const enum EAdministrationTabType {
  Teams = '1',
  Organisation = '2',
  Roles = '3',
  Users = '4',
  PTFS = '5',
  SystemNotifications = '6',
  AssessorPanelList = '7',
  BenchmarkFigures = '8'
}
