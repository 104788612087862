import { ComponentProps, ReactNode, createContext, useContext } from 'react';
import {
  faAdd,
  faArrowDown,
  faArrowUp,
  faClock,
  faEnvelope,
  faFile,
  faPen,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import {
  useDeletePullInProcessCommunicationByIdMutation,
  useGetPullInProcessCommunicationsQuery,
} from 'api/pullInProcess';
import { ConfirmationModal } from 'common/components/confirmationModal';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { EPullinProcessDocumentType } from 'enums/EPullinProcessDocumentTypes';
import { useCurrentUserPermissions, useEllipsisMenu, useLocalSnackbar, useModalState } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { AddEditCommunications } from 'pages/pullInProcessPage/components/communications';
import { PullInFileDownloadWrapper } from 'pages/pullInProcessPage/components/pullInFileDownloadWrapper';
import {
  EPullInCommunicationType,
  EPullInCommunicationTypeLanguage,
  IPullinProcessCommunication,
} from 'types/pullInProcess/communicationsTypes';
import { getPrettyDateStringFromJsonDateString } from 'util/AppUtils';

export const communicationContext = createContext<
  ReturnType<typeof useModalState<string | null>> | undefined
>(undefined);

export function useCommunicationContext() {
  const communicationModalContext = useContext(communicationContext);
  if (communicationModalContext === undefined) {
    throw new Error('communicationContext is undefined');
  }
  return communicationModalContext;
}

export function CommunicationDetails() {
  const { pullInProcessId } = usePullInProcessContext();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasPullinprocessCommunicationsAdd = doesUserHaveSinglePermission(
    'pullinprocess.communications.add'
  );

  const { isLoading, data, isSuccess } =
    useGetPullInProcessCommunicationsQuery(pullInProcessId);

  const modalState = useModalState<string | null>();
  
  const { showModal } = modalState;

  return (
    <communicationContext.Provider value={modalState}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={'1rem 0'}
      >
        <Typography component="h3" fontSize={'1.1rem'} fontWeight={900}>
          Communications
        </Typography>
        <Button
          onClick={() => showModal()}
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faAdd} />}
          disabled={!hasPullinprocessCommunicationsAdd}
        >
          Add communication
        </Button>
      </Box>
      <Box>
        {isLoading ? <FormSkeleton /> : null}

        {isSuccess ? <CommunicationListView data={data} /> : null}
        <AddEditCommunications />
      </Box>
    </communicationContext.Provider>
  );
}

const CommunicationListView = ({
  data,
}: {
  data: IPullinProcessCommunication[];
}) => {
  return (
    <Stack rowGap={2}>
      {data.map(item => (
        <CardRenderer key={`parent-${item.communicationId}`} item={item} />
      ))}
    </Stack>
  );
};

const CardRenderer = ({ item }: { item: IPullinProcessCommunication }) => {
  return item.communicationTypeId === EPullInCommunicationType.Note ? (
    <NoteCard key={item.communicationId} item={item} />
  ) : (
    <DetailedCard key={item.communicationId} item={item} />
  );
};

const DetailedCard = ({
  item,
}: {
  item: Omit<IPullinProcessCommunication, 'communicationTypeId'> & {
    communicationTypeId: EPullInCommunicationType;
  };
}) => {
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasPullinprocessCommunicationsEdit = doesUserHaveSinglePermission(
    'pullinprocess.communications.edit'
  );
  const hasPullinprocessCommunicationsDelete = doesUserHaveSinglePermission(
    'pullinprocess.communications.delete'
  );

  const { showModal } = useCommunicationContext();

  const { pullInProcessId } = usePullInProcessContext();
  const [deleteCommunicationRequest] =
    useDeletePullInProcessCommunicationByIdMutation();
  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const {
    isShowing: deleteModalIsShowing,
    hideModal: hideDeleteModal,
    showModal: showDeleteModal,
    modalData: deleteModalData,
  } = useModalState<string | null>();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  function editCommunication(id: string) {
    showModal(id);
    handleMenuClose();
  }

  function deleteCommunication(id: string) {
    showDeleteModal(id);
    handleMenuClose();
  }

  function deleteSelectedDocument() {
    deleteModalData &&
      deleteCommunicationRequest({
        buildingId: pullInProcessId,
        communicationId: deleteModalData,
      })
        .then(_ => createSuccessSnackbar('Communication successfully deleted'))
        .catch(e =>
          createErrorSnackbar(
            `Something went wrong - ${e instanceof Error ? e.message : e}`
          )
        )
        .finally(() => hideDeleteModal());
  }

  return (
    <ListViewBox>
      <Box p={2}>
        <Grid
          container
          justifyContent={'space-between'}
          gap={1.5}
          flexWrap="nowrap"
        >
          <Grid item xs={6}>
            <Box display={'flex'} alignItems={'center'}>
              {communicationsIcon(item.communicationTypeId)}

              <Typography
                variant="h2"
                fontSize={'0.9em'}
                fontWeight={700}
                mr={3}
                ml={1}
              >
                {EPullInCommunicationTypeLanguage[item.communicationTypeId]}
              </Typography>

              <Chip
                label={item.isAutomated ? 'Automated' : 'Manual'}
                size="small"
                color={'default'}
              />
              {item?.isInbound ? (
                <Box display={'flex'} ml={3}>
                  <Icon icon={faArrowUp} color={_labelColor} />
                  <Typography
                    variant="body1"
                    fontSize={'0.7em'}
                    fontWeight={600}
                    mr={2}
                    ml={1}
                  >
                    INBOUND
                  </Typography>
                </Box>
              ) : (
                <Box display={'flex'} ml={2}>
                  <Icon icon={faArrowDown} color={_labelColor} />
                  <Typography
                    variant="body1"
                    fontSize={'0.7em'}
                    fontWeight={600}
                    mr={2}
                    ml={1}
                  >
                    OUTBOUND
                  </Typography>
                </Box>
              )}
           {item.attachmentFile && (
            <PullInFileDownloadWrapper
                                      showIconOnly = {true}
                                      fileName={item.attachmentFile?.name ?? "Communication"}
                                      pullInDocumentTypeId={item.communicationId}
                                      pullInDocumentType={EPullinProcessDocumentType.Communication}
                                    />
                                  )}
            </Box>
            <Box mt={3} display={'flex'}>
              <Typography
                variant="body1"
                fontSize={'0.9em'}
                fontWeight={500}
              >
                {item?.text}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            display={'flex'}
            justifyContent={'flex-end'}
            gap={2}
          >
            <Box>
              <Typography
                variant="body1"
                fontSize={'0.9em'}
                fontWeight={600}
                mb={1}
                color={'grey.500'}
              >
                {item?.isAutomated ? 'Recipient' : 'Added by'}
              </Typography>
              <Typography variant="body1" fontSize={'0.9em'} fontWeight={600}>
                {item?.isAutomated ? item?.recipientName : item?.sentByName}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                fontSize={'0.9em'}
                fontWeight={600}
                color={'grey.500'}
                mb={1}
              >
                {item?.isAutomated ? 'Sent' : 'Date'}
              </Typography>
              <Typography variant="body1" fontSize={'0.9em'} fontWeight={600}>
                {item?.sentOnDateTime &&
                  getPrettyDateStringFromJsonDateString(
                    item.sentOnDateTime.toString()
                  )}
              </Typography>
            </Box>
            <Box textAlign={'right'}>
              <Chip
                label={`Stage ${item.communicationStageTypeId}`}
                size="small"
                color={'default'}
              />
            </Box>
            <IconButton
              aria-label="edit"
              name="edit"
              onClick={event =>
                handleEllipsisClick(item.communicationId, event)
              }
              sx={{ flexShrink: 1, flexGrow: 0, m: 0 }}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
            {selectedId === item.communicationId ? (
              <Menu
                id={`pull-in-process-communication-menu-${item.communicationId}`}
                anchorEl={ellipsisMenuAnchor}
                open={Boolean(ellipsisMenuAnchor)}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  key="edit"
                  onClick={() => editCommunication(item.communicationId)}
                  disabled={!hasPullinprocessCommunicationsEdit}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  key="delete"
                  onClick={() => deleteCommunication(item.communicationId)}
                  disabled={!hasPullinprocessCommunicationsDelete}
                >
                  Delete
                </MenuItem>
              </Menu>
            ) : null}
          </Grid>
        </Grid>
      </Box>
      <ConfirmationModal
        isShowing={deleteModalIsShowing}
        onCancel={hideDeleteModal}
        confirmButtonType="button"
        onConfirm={deleteSelectedDocument}
      >
        Are you sure you want to delete this communication?
      </ConfirmationModal>
    </ListViewBox>
  );
};

const NoteCard = ({
  item,
}: {
  item: Omit<IPullinProcessCommunication, 'communicationTypeId'> & {
    communicationTypeId: EPullInCommunicationType;
  };
}) => {
  const { showModal } = useCommunicationContext();

  const { pullInProcessId } = usePullInProcessContext();
  const [deleteCommunicationRequest] =
    useDeletePullInProcessCommunicationByIdMutation();

  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const {
    isShowing: deleteModalIsShowing,
    hideModal: hideDeleteModal,
    showModal: showDeleteModal,
    modalData: deleteModalData,
  } = useModalState<string | null>();

  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  function editCommunication(id: string) {
    showModal(id);
    handleMenuClose();
  }

  function deleteCommunication(id: string) {
    showDeleteModal(id);
    handleMenuClose();
  }

  function deleteSelectedDocument() {
    deleteModalData &&
      deleteCommunicationRequest({
        buildingId: pullInProcessId,
        communicationId: deleteModalData,
      })
        .then(_ => createSuccessSnackbar('Communication successfully deleted'))
        .catch(e =>
          createErrorSnackbar(
            `Something went wrong - ${e instanceof Error ? e.message : e}`
          )
        )
        .finally(() => hideDeleteModal());
  }

  return (
    <ListViewBox>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        justifyContent={'space-between'}
        pr={2}
        pt={1}
        pb={1}
        pl={2}
        borderBottom={'1px solid rgba(0, 0, 0, 0.08)'}
      >
        <Box display={'flex'} alignItems={'center'}>
          {communicationsIcon(item.communicationTypeId)}

          <Typography
            variant="h2"
            fontSize={'0.9em'}
            fontWeight={700}
            mr={3}
            ml={1}
          >
            {EPullInCommunicationTypeLanguage[item.communicationTypeId]}
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Icon icon={faClock} color={_labelColor} />
          <Typography
            variant="body1"
            color={_labelColor}
            fontSize={'0.9em'}
            ml={2}
            sx={{ fontWeight: 600 }}
          >
            {item.addedOnDateTime &&
              getPrettyDateStringFromJsonDateString(
                item.addedOnDateTime.toString()
              )}
          </Typography>
        </Box>
        <Box>
          <IconButton
            aria-label="edit"
            name="edit"
            onClick={event => handleEllipsisClick(item.communicationId, event)}
            sx={{ flexShrink: 1, flexGrow: 0, m: 0 }}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
          {selectedId === item.communicationId ? (
            <Menu
              id={`pull-in-process-communication-menu-${item.communicationId}`}
              anchorEl={ellipsisMenuAnchor}
              open={Boolean(ellipsisMenuAnchor)}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                key="edit"
                onClick={() => editCommunication(item.communicationId)}
              >
                Edit
              </MenuItem>
              <MenuItem
                key="delete"
                onClick={() => deleteCommunication(item.communicationId)}
              >
                Delete
              </MenuItem>
            </Menu>
          ) : null}
        </Box>
      </Stack>
      <Box p={2}>
        <Typography
          variant="body1"
          fontSize={'0.9em'}
          ml={2}
          sx={{ fontWeight: 500 }}
        >
          {item.text}
        </Typography>
      </Box>
      <ConfirmationModal
        isShowing={deleteModalIsShowing}
        onCancel={hideDeleteModal}
        confirmButtonType="button"
        onConfirm={deleteSelectedDocument}
      >
        Are you sure you want to delete this communication?
      </ConfirmationModal>
    </ListViewBox>
  );
};

const _labelColor = 'grey.700';

const communicationsIcon = (commsType: EPullInCommunicationType) => {
  if (commsType === EPullInCommunicationType.Phone) {
    return <Icon icon={faPhone} size="sm" color={_labelColor} />;
  } else if (commsType === EPullInCommunicationType.Letter) {
    return <Icon icon={faFile} size="sm" color={_labelColor} />;
  } else if (commsType === EPullInCommunicationType.Email) {
    return <Icon icon={faEnvelope} size="sm" color={_labelColor} />;
  } else {
    return <Icon icon={faPen} size="sm" color={_labelColor} />;
  }
};

const ListViewBox = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) => {
  return (
    <RoundBorderBox
      sx={{
        ...sx,
        transition: 'all 150ms linear',
        cursor: 'pointer',
        ':hover': {
          boxShadow: '0 3px 15px rgba(0, 0, 0, 0.15)',
        },
      }}
      border={2}
      borderRadius={2}
      onClick={() => {}}
    >
      {children}
    </RoundBorderBox>
  );
};

const Icon = (props: ComponentProps<typeof FontAwesomeIcon>) => {
  return <FontAwesomeIcon {...props} color={'#999'} />;
};
