import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { organisationEndpoints } from 'api/administration/organisations';
import { ESortDirection } from 'enums/ESortDirection';
import { IGetOrgsListQuery, IOrgsGridListItem } from 'types/administration/AdministrationOrganisationTypes';
import { nameof } from 'util/formUtils';

export const administrationSortByTypes = [
  nameof<IOrgsGridListItem>('name'),
] as const;
export type AdministrationSortByTypes =
  (typeof administrationSortByTypes)[number];

export interface IAdministrationOrgsState {
  isGridLoading: boolean;
  gridState: IGetOrgsListQuery;
}

const initialState: IAdministrationOrgsState = {
  isGridLoading: true,
  gridState: {
    skip: 0,
    take: 25,
    name: [],
    searchValue: null,
    sortBy: nameof<IOrgsGridListItem>('name'),
    sortDirection: ESortDirection.Asc,
  },
};

const administrationOrganisationsSlice = createSlice({
  name: 'administrationOrganisations',
  initialState,
  reducers: {
    gridStateChanged: (
      state,
      action: PayloadAction<Partial<IGetOrgsListQuery>>
    ) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
    userLeftAdministrationTeamsPage: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        organisationEndpoints.endpoints.getPaginatedOrgsList.matchFulfilled,
        state => {
          state.isGridLoading = false;
        }
      )
      .addMatcher(
        organisationEndpoints.endpoints.getPaginatedOrgsList.matchRejected,
        state => {
          state.isGridLoading = false;
        }
      );
  },
});

export const administrationOrganisationsReducer = administrationOrganisationsSlice.reducer;
export const { gridStateChanged, userLeftAdministrationTeamsPage } = administrationOrganisationsSlice.actions;
