import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Collapse,  Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
  import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetPullInProcessSummaryQuery } from 'api/pullInProcess';
import {
    EPullInProcessStatus,
    EPullInProcessStatusLanguage,
  } from 'enums/EPullInProcessStatus';
  import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';

export const PullInStatus = () => {
    const { pullInProcessId } = usePullInProcessContext();
  const { data, isSuccess } = useGetPullInProcessSummaryQuery(
    pullInProcessId ?? skipToken
  );

  const { palette } = useTheme();

    return (
        <Collapse
            in={
              data &&
              isSuccess &&
              [
                EPullInProcessStatus.NotEligible,
                EPullInProcessStatus.ProgressedToApplication,
                EPullInProcessStatus.Duplicate,
              ].includes(data?.status || 0)
            }
          >
            <Box m="1rem" bgcolor={'#f5f5f5'} p="1rem 1rem 3rem">
              <Box gap="1rem" display="flex" alignItems="center">
                <FontAwesomeIcon
                  icon={
                    data?.status ===
                    EPullInProcessStatus.ProgressedToApplication
                      ? faCircleCheck
                      : faXmarkCircle
                  }
                  color={
                    data?.status ===
                    EPullInProcessStatus.ProgressedToApplication
                      ? palette.success.light
                      : palette.error.light
                  }
                  size="2x"
                />
                <Typography fontWeight={600}>
                  {
                    EPullInProcessStatusLanguage[
                      data?.status as
                        | EPullInProcessStatus.NotEligible
                        | EPullInProcessStatus.ProgressedToApplication
                        | EPullInProcessStatus.Duplicate
                    ]
                  }
                </Typography>
              </Box>

              {data?.statusDescription
                ? data.statusDescription?.map?.((val, idx) => (
                    <Typography
                      m={'1rem 0 0 3rem'}
                      key={idx}
                      sx={{ '&+&': { mt: '0' } }}
                    >
                      {val}
                    </Typography>
                  )) ?? 'No description provided'
                : 'No description provided'}
            </Box>
          </Collapse>
    )
}