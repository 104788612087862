import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  useGetAdditionalPaymentRecommendationDetailsQuery,
  useUpdateAdditionalPaymentRecommendationMutation,
} from 'api/application/paymentApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect';
import {
  useCurrentUserPermissions,
  useFileUpload,
  useLocalSnackbar,
} from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { IAdditionalPaymentRecommendationUpdate } from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

const getName = (fieldName: keyof IAdditionalPaymentRecommendationUpdate) =>
  nameof<IAdditionalPaymentRecommendationUpdate>(fieldName);

export const hasDecimal = (numberToCheck: number) =>
  numberToCheck - Math.floor(numberToCheck) !== 0;

export const SendAdditionalRecommendationBanner = ({
  recommendationSentAndReceived,
}: {
  recommendationSentAndReceived?: boolean;
}) => {
  const { applicationId, isVendorPaymentApprovalWaiting } =
    useApplicationContext();
  const { uploadFile } = useFileUpload();
  const [updatePaymentApproval, { isLoading, isError, error }] =
    useUpdateAdditionalPaymentRecommendationMutation();
  const additionalPaymentDetails =
    useGetAdditionalPaymentRecommendationDetailsQuery(applicationId);
  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasPaymentRecommendEdit = doesUserHaveSinglePermission(
    'payment-recommend.edit'
  );

  const form = useForm<IAdditionalPaymentRecommendationUpdate>({
    defaultValues: { paymentAmount: 0, evidenceFileId: '' },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(zAdditionalPaymentSchema),
  });
  const { handleSubmit, watch } = form;

  const watchEvidenceFileId = watch('evidenceFileId');

  const onSubmit = async (formData: IAdditionalPaymentRecommendationUpdate) => {
    if (!hasPaymentRecommendEdit) {
      createErrorSnackbar('You do not have permission');
      return;
    }

    try {
      formData.applicationId = applicationId;
      await updatePaymentApproval(formData)
        .unwrap()
        .then(payload => {
          createSuccessSnackbar(`Payment recommended`);
        })
        .catch(error => {
          if (error?.data?.generalError) {
            createErrorSnackbar(error?.data?.generalError.errorMessage);
          } else if (error.data.propertyErrors) {
            setServerSideFormErrors(form, error.data);
            createWarningSnackbar(
              'Please correct any form validation errors shown, and then try again.'
            );
          } else {
            createErrorSnackbar(error);
          }
        });
    } catch (err) {
      createErrorSnackbar(`Failed to recommend payment`);
    }
  };

  const handleOnFileUpload = async (file: File) => {
    await uploadFile({
      file,
      uploadSection: 'AdditionalPayment',
      onSuccess: payload => {
        form.setValue('evidenceFileId', payload.id);
      },
    });
  };

  const handleOnDeleteFileUpload = async () => {
    form.setValue('evidenceFileId', '');
  };

  return (
    <Box
      sx={{
        padding: 2,
        paddingTop: 3,
        border: '1px solid lightgray',
        borderRadius: 1,
        borderLeft: '5px solid black',
        borderLeftColor: 'black',
        bgcolor: grey[100],
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Typography variant="h3" fontWeight={800} fontSize="0.8em">
            {recommendationSentAndReceived
              ? 'Payment Details'
              : 'Send Payment Recommendation to Homes England'}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography
            variant="body1"
            color={'grey.600'}
            fontSize="0.8em"
            fontWeight={600}
          >
            How much is this payment ?
          </Typography>
        </Grid>

        {additionalPaymentDetails?.data?.recommendationSent ? (
          <>
            <Grid xs={12} item>
              <Typography variant="h4" fontWeight={600} fontSize="1em">
                {`£${additionalPaymentDetails?.data?.paymentAmount?.toLocaleString()}`}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              {additionalPaymentDetails?.data?.evidenceFile && (
                <ApplicationFileDownloadWrapper
                  fileId={additionalPaymentDetails?.data?.evidenceFile.id}
                  fileName={additionalPaymentDetails?.data?.evidenceFile.name}
                />
              )}
            </Grid>
          </>
        ) : (
          <>
            <FormProvider {...form}>
              <form
                noValidate
                onSubmit={event => {
                  event.stopPropagation();
                  handleSubmit(onSubmit)(event);
                }}
                style={{ width: '100%' }}
              >
                <Grid
                  xs={12}
                  item
                  sx={{
                    paddingRight: 2,
                    paddingLeft: 2,
                  }}
                >
                  <Controller
                    name={getName('paymentAmount')}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="number"
                        fullWidth
                        error={!!fieldState.error}
                        required
                        helperText={fieldState.error?.message}
                        onChange={e => {
                          if (hasDecimal(Number(e.target.value))) return;
                          return field.onChange(e);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">£</InputAdornment>
                          ),
                          inputProps: {
                            min: 1,
                          },
                        }}
                        disabled={
                          additionalPaymentDetails?.data?.recommendationSent
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  xs={12}
                  item
                  sx={{
                    paddingRight: 2,
                    paddingLeft: 2,
                  }}
                >
                  <Typography variant="h3" fontWeight={600} fontSize="1em">
                    Upload evidence
                  </Typography>
                  {watchEvidenceFileId !== undefined &&
                  watchEvidenceFileId ===
                    additionalPaymentDetails?.data?.evidenceFile?.id ? (
                    <Box display="flex" flexDirection="row">
                      <ApplicationFileDownloadWrapper
                        fileName={
                          additionalPaymentDetails?.data?.evidenceFile?.name ??
                          ''
                        }
                        fileId={
                          additionalPaymentDetails?.data?.evidenceFile?.id ?? ''
                        }
                      />
                      <IconButton
                        size="small"
                        onClick={() => handleOnDeleteFileUpload()}
                        aria-label="Delete File"
                        name="Delete File"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <FileUploadInputSelect
                        id="evidenceFileDocument"
                        accept=".pdf"
                        inputName="evidenceFileId"
                        onFileUpload={file => handleOnFileUpload(file)}
                        onFileDelete={() => handleOnDeleteFileUpload()}
                      />
                    </>
                  )}
                </Grid>
                <Grid
                  xs={12}
                  item
                  sx={{
                    padding: 2,
                  }}
                >
                  <Button
                    disabled={
                      isVendorPaymentApprovalWaiting ||
                      isLoading ||
                      !hasPaymentRecommendEdit
                    }
                    sx={{
                      color: 'black',
                      fontWeight: '600',
                      fontSize: '0.8em',
                      bgcolor: grey[100],
                    }}
                    variant="outlined"
                    type="submit"
                    data-automation-id="button-recommend-payment"
                  >
                    Recommend Payment
                  </Button>
                  <AlertForQueryErrorOrNull isError={isError} error={error} />
                </Grid>
              </form>
            </FormProvider>
          </>
        )}
        <Grid xs={12} item>
          {additionalPaymentDetails?.data?.recommendationSent ||
          recommendationSentAndReceived ? (
            <Box display="flex" alignItems="center">
              <FontAwesomeIcon
                icon={solid('check-circle')}
                color="black"
                size="lg"
              />
              <Box
                sx={{
                  marginLeft: 2,
                }}
              >
                <Typography fontSize="0.8em" fontWeight={600} color="black">
                  Recommendation Sent
                </Typography>

                <Typography fontSize="0.6em" fontWeight={600} color="grey.700">
                  {additionalPaymentDetails?.data?.dateRecommendationSent
                    ? getUkDateStringFromJsonDateString(
                        additionalPaymentDetails?.data?.dateRecommendationSent.toString()
                      )
                    : getUkDateStringFromJsonDateString(new Date().toString())}
                </Typography>
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export const zAdditionalPaymentSchema = z.object({
  paymentAmount: z.coerce
    .number()
    .gt(0, { message: 'Payment amount must be greater than 0' }),
  evidenceFileId: z.string().optional(),
});
