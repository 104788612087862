import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Menu,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Chip
} from '@mui/material';
import { SortDirection } from '@mui/material/TableCell';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetPaginatedTeamsListQuery } from 'api/administration/teams';
import { Loading } from 'common/components/loading';
import { NoResultsFoundText } from 'common/components/noResultsfoundText/NoResultsFoundText';
import { ESortDirection } from 'enums/ESortDirection';
import { ETeam } from 'enums/ETeam';
import { useCurrentUserPermissions, useModalState, useEllipsisMenu } from 'hooks';
import { DeleteTeamDialog } from 'pages/administrationPage/components/administrationTeamsTab/components/deleteTeamDialog/DeleteTeamDialog';
import { EAdminTeamsMenuType } from 'pages/administrationPage/components/administrationTeamsTab/types/EAdminTeamsMenuType';
import { useAppDispatch } from 'state';
import { selectAdministrationTeamsGridState, selectIsAdministrationTeamsGridLoading } from 'state/selectors/administration/administrationTeamsSelectors';
import { gridStateChanged } from 'state/slices/administration/administrationTeamsReducer';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import { ITeamsGridListItem } from 'types/administration/AdministrationTeamsTypes';
import { getMenuItemConfig, getMenuItems } from 'util/AppUtils';

export function AdministrationTeamsGrid() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const administrationTeamsGridState = useSelector(
    selectAdministrationTeamsGridState
  );

  const teamsListQuery = useGetPaginatedTeamsListQuery(
    administrationTeamsGridState
  );

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasEditPermission = doesUserHaveSinglePermission('admin.team.edit');
  const hasDeletePermission = doesUserHaveSinglePermission('admin.team.delete');

  const { skip, take, sortBy, sortDirection } = administrationTeamsGridState;
  const isAdministrationTeamsGridLoading = useSelector(
     selectIsAdministrationTeamsGridLoading
  );
  
  const {
    isShowing: isDeleteDialogShowing,
    showModal: showDeleteDialog,
    hideModal: hideDeleteDialog,
    modalData: idToDelete
  } = useModalState<string>();

  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const handleMenuItemClick = (menuType: EAdminTeamsMenuType) => {

    switch (menuType) {
      case EAdminTeamsMenuType.ViewEditTeam:
        navigate(`/admin/addEditTeam/${selectedId}`);
        break;
      case EAdminTeamsMenuType.DeleteTeam:
        showDeleteDialog(selectedId);
        break;
      }

    handleMenuClose();
  };

  const noResultsFound =
    teamsListQuery.data?.count === 0 &&
    !!teamsListQuery.originalArgs?.searchValue;

  const currentPage = skip / take;

  const sortHandler = (property: keyof ITeamsGridListItem): void => {
    dispatch(
      gridStateChanged({
        sortBy: property,
        sortDirection:
          sortDirection === ESortDirection.Asc
            ? ESortDirection.Desc
            : ESortDirection.Asc,
      })
    );
  };

  const getAriaSortDirection = (
    property: keyof ITeamsGridListItem,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getCurrentSortDirection = (
    property: keyof ITeamsGridListItem,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderTableCell = (
    property: keyof ITeamsGridListItem,
    columnLabel: string
  ): JSX.Element => {
    if (property === 'name') {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          active={sortBy === property}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => sortHandler(property)}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
      )}
       else {
        return <TableCell>{columnLabel}</TableCell>
      }
  };

  return (
    <>
      <Loading isOpen={isAdministrationTeamsGridLoading} />
      <NoResultsFoundText show={noResultsFound} />
      <StyledGenericTable data-testid="Administration-Teams-Table">
        <TableHead>
          <TableRow>
            {getHeaderTableCell('name', 'Team')}
            {getHeaderTableCell('canViewAbove18metres', 'Can view 18m+')}
            {getHeaderTableCell('canViewBelow18metres', 'Can view <18m')}
            {getHeaderTableCell('localAuthoritiesCostCentres', 'Local Authorities')}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {teamsListQuery.data?.results?.map(row => (
            <TableRow key={row.teamId}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.canViewAbove18metres ? "Yes" : "No"}</TableCell>
              <TableCell>{row.canViewBelow18metres ? "Yes" : "No"}</TableCell>
              <TableCell>
                {
                  !row.containsAllLocalAuthoritesCostCentres ? (
                    row.localAuthoritiesCostCentres && (
                      row.localAuthoritiesCostCentres.map(localAuthorityCostCentre => 
                      <Chip key={localAuthorityCostCentre.id} size="small" label={localAuthorityCostCentre.name} sx={{ mx: 1, my: 1 }} />
                      )
                    )
                  ) : (
                    <Chip size="small" label="All" sx={{ mx: 1, my: 1 }} />
                  )
              }
              </TableCell>

              <TableCell align="right">

              { (hasEditPermission || hasDeletePermission) && 
              <>
                    <IconButton
                      aria-label="edit"
                      name="edit"
                      onClick={event => handleEllipsisClick(row.teamId, event)}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                    {selectedId === row.teamId ? (
                      <Menu
                        id={`admin-teams-menu-${row.teamId}`}
                        anchorEl={ellipsisMenuAnchor}
                        open={Boolean(ellipsisMenuAnchor)}
                        onClose={handleMenuClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {getMenuItems(
                          [
                            getMenuItemConfig(
                              'Edit Team',
                              EAdminTeamsMenuType.ViewEditTeam,
                              undefined,
                              !hasEditPermission
                            ),
                            getMenuItemConfig(
                              'Delete Team',
                              EAdminTeamsMenuType.DeleteTeam,
                              undefined,
                              !hasDeletePermission || row.teamId in ETeam
                            ),
                          ],
                          handleMenuItemClick
                        )}
                      </Menu>
                    ) : null}
                  </> }

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={teamsListQuery.data?.count || 0}
              rowsPerPage={take}
              rowsPerPageOptions={[take]}
              page={currentPage}
              onPageChange={(e, newPage) => {
                const newSkip = newPage * take;
                dispatch(gridStateChanged({ skip: newSkip }));
              }}
              onRowsPerPageChange={e => {
                dispatch(
                  gridStateChanged({
                    take: +e.target.value,
                  })
                );
              }}
            />
          </TableRow>
        </TableFooter>
      </StyledGenericTable>


      <DeleteTeamDialog
              isShowing={isDeleteDialogShowing}
              onClose={hideDeleteDialog}
              onCancel={hideDeleteDialog}
              selectedId={idToDelete}
            />
    </>
  );
}
