import { ReactNode } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { CardTitle } from 'common/components/cardTitle';
import { useCloseReferralToHomesEnglandDrawer } from 'common/components/referrals';
import {
  useCloseReferralToThirdPartyDrawer,
  useReferToThirdPartyDrawer,
  useViewReferralToThirdPartyDrawer,
} from 'common/components/referrals/thirdPartyReferrals';
import { useThirdPartyReferralQuery } from 'common/components/referrals/thirdPartyReferrals/hooks';
import {
  ReferralDataResponse,
  ReferralsGridProps,
  getReferralsArray,
  getReferredToString,
  useReferralsList,
} from 'common/components/referrals/utils';
import {
  useCurrentUserPermissions,
  useEllipsisMenu,
  useLocalSnackbar,
} from 'hooks';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import {
  EReferralStatus,
  EReferralStatusLanguage,
  EReferralType,
  EReferralRecordType,
  EReferralMenuType,
} from 'types/applications/ReferralTypes';
import { getGbpAmount, getUkDateStringFromJsonDateString } from 'util/AppUtils';

const useDrawers = (props: ReferralsGridProps) => {
  const viewThirdPartyReferralDrawer = useViewReferralToThirdPartyDrawer(props);
  const referToThirdPartyDrawer = useReferToThirdPartyDrawer();
  const closeThirdPartyDrawer = useCloseReferralToThirdPartyDrawer();
  const closeHomesEnglandDrawer = useCloseReferralToHomesEnglandDrawer();

  return {
    viewThirdPartyReferralDrawer,
    referToThirdPartyDrawer,
    closeThirdPartyDrawer,
    closeHomesEnglandDrawer,
  };
};

const getReferral = (data: ReferralDataResponse, selectedId: string) => {
  if (!data) {
    return null;
  }

  var array = getReferralsArray(data);
  return array.find(item => item.id === selectedId);
};

const getSelectedType = (data: ReferralDataResponse, selectedId: string) => {
  const selectedType = getReferral(data, selectedId)?.type;
  return selectedType;
};

const useGetThirdPartyReferral = (
  selectedId: string,
  selectedType: ReturnType<typeof getSelectedType>,
  params: Parameters<typeof useThirdPartyReferralQuery>[2]
) => {
  const { query } = useThirdPartyReferralQuery(
    selectedId,
    selectedType,
    params
  );
  return { tpReferral: query.data };
};

export const ReferralsGrid = (
  props: { title?: string | ReactNode } & ReferralsGridProps
) => {
  const { createErrorSnackbar } = useLocalSnackbar();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasLiveprojectPaymentsProcess = doesUserHaveSinglePermission(
    'liveproject.payments.process'
  );
  const hasWorkpackageProcess = doesUserHaveSinglePermission(
    'workpackage.process'
  );
  const hasApplicationsReferProcess = doesUserHaveSinglePermission(
    'applications.refer.process'
  );

  const {
    selectedId,
    ellipsisMenuAnchor,
    handleEllipsisClick,
    handleMenuClose,
  } = useEllipsisMenu();

  const { query } = useReferralsList(props);
  const { data: response } = query;
  const getData = getReferralsArray(response);
  const data =
    props.referralRecordType === EReferralRecordType.PaymentRequest &&
    props.heReferrals
      ? [...getData, ...props.heReferrals]
      : getData;
  const selectedType = getSelectedType(data, selectedId);

  const { tpReferral } = useGetThirdPartyReferral(
    selectedId,
    selectedType,
    props
  );

  const {
    closeHomesEnglandDrawer,
    closeThirdPartyDrawer,
    referToThirdPartyDrawer,
    viewThirdPartyReferralDrawer,
  } = useDrawers(props);

  const handleMenuItemClick = (menuType: EReferralMenuType) => {
    switch (menuType) {
      case EReferralMenuType.ViewReferral:
        viewThirdPartyReferralDrawer.showViewReferralDrawer({
          referralId: selectedId,
          referralType: selectedType,
        });
        break;

      case EReferralMenuType.EditReferral:
        if (!tpReferral) {
          return createErrorSnackbar(
            'Cannot load third party referral data, please try again.'
          );
        }

        const getConfig = (): Parameters<
          typeof referToThirdPartyDrawer.showReferDrawer
        >[0] => {
          const base = {
            referralType: 'editReferral',
            referral: tpReferral,
          } as const;
          switch (props.referralRecordType) {
            case EReferralRecordType.WorksPackage:
              return {
                ...base,
                referralRecordType: EReferralRecordType.WorksPackage,
              };

            case EReferralRecordType.PaymentRequest:
              return {
                ...base,
                referralRecordType: EReferralRecordType.PaymentRequest,
                paymentRequestId: props.paymentRequestId,
              };

            case EReferralRecordType.Variation:
              return {
                ...base,
                referralRecordType: EReferralRecordType.Variation,
                variationId: props.variationId,
              };
          }
        };

        referToThirdPartyDrawer.showReferDrawer(getConfig());
        break;

      case EReferralMenuType.CloseReferral:
        const referral = data?.find(item => item.id === selectedId);
        if (!referral) {
          createErrorSnackbar('Could not find referral. Please try again.');
          return;
        }

        switch (referral.type) {
          case EReferralType.ThirdParty:
            const getTpConfig = (): Parameters<
              typeof closeThirdPartyDrawer.showCloseReferralDrawer
            >[0] => {
              const base = {
                thirdPartyReferralId: selectedId,
              } as const;

              switch (props.referralRecordType) {
                case EReferralRecordType.WorksPackage:
                  return {
                    ...base,
                    referralRecordType: EReferralRecordType.WorksPackage,
                  };

                case EReferralRecordType.PaymentRequest:
                  return {
                    ...base,
                    referralRecordType: EReferralRecordType.PaymentRequest,
                    paymentId: props.paymentRequestId,
                  };

                case EReferralRecordType.Variation:
                  return {
                    ...base,
                    referralRecordType: EReferralRecordType.Variation,
                    variationId: props.variationId,
                  };
              }
            };

            closeThirdPartyDrawer.showCloseReferralDrawer(getTpConfig());
            break;

          case EReferralType.HomesEngland:
            const getHeConfig = (): Parameters<
              typeof closeHomesEnglandDrawer.showCloseReferralDrawer
            >[0] => {
              const base = {
                referralId: selectedId,
              } as const;

              switch (props.referralRecordType) {
                case EReferralRecordType.WorksPackage:
                  return {
                    ...base,
                    referralRecordType: EReferralRecordType.WorksPackage,
                  };
                case EReferralRecordType.PaymentRequest:
                  return {
                    ...base,
                    referralRecordType: EReferralRecordType.PaymentRequest,
                    paymentId: props.paymentRequestId,
                    onSuccess: props.onCompleteHeReferralSuccess,
                  };

                case EReferralRecordType.Variation:
                  return {
                    ...base,
                    referralRecordType: EReferralRecordType.Variation,
                    variationId: props.variationId,
                  };
              }
            };

            closeHomesEnglandDrawer.showCloseReferralDrawer(getHeConfig());
            break;

          default:
            break;
        }

        break;
    }

    handleMenuClose();
  };

  if (data.length) {
    return (
      <>
        <Box
          sx={{
            bgcolor: 'grey.200',
            p: 3,
            borderRadius: 1,
            ...props.sx,
          }}
        >
          {props.title ? (
            <>
              {typeof props.title === 'string' ? (
                <CardTitle title={props.title ?? 'Referrals'} />
              ) : (
                props.title
              )}
            </>
          ) : (
            <CardTitle title={'Referrals'} />
          )}

          <StyledGenericTable
            sx={{
              '& thead': {
                '& th': {
                  fontSize: 12,
                  fontWeight: 600,
                  color: 'grey.700',
                  textTransform: 'none',
                  pb: 2,
                },
              },
              '& tbody tr': {
                '& td': {
                  fontSize: 13,
                  fontWeight: 600,
                  textTransform: 'none',
                  borderTop: '0',
                  py: 0,
                },
              },
              '& thead th:first-of-type, & tbody td:first-of-type': {
                pl: 0,
              },

              '& thead th:last-of-type, & tbody td:last-of-type': {
                pr: 0,
              },
              bgcolor: 'unset',
              mb: 0,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell>Cost (£)</TableCell>
                <TableCell>Response</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => {
                const isThirdPartyReferral =
                  item.type === EReferralType.ThirdParty;
                const isHEReferral = item.type === EReferralType.HomesEngland;
                const isComplete = item.status === EReferralStatus.Complete;

                return (
                  <TableRow key={item.id}>
                    <TableCell>
                      {item.companyName ?? (isHEReferral ? 'N/A' : '-')}
                    </TableCell>
                    <TableCell>
                      {isThirdPartyReferral
                        ? getReferredToString(item)
                        : 'Homes England'}
                    </TableCell>
                    <TableCell>
                      {item.startDate
                        ? getUkDateStringFromJsonDateString(item.startDate)
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {item.endDate
                        ? getUkDateStringFromJsonDateString(item.endDate)
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {item.cost
                        ? getGbpAmount({
                            value: item.cost,
                            showCurrencySymbol: false,
                          })
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {item.status ? (
                        <Chip
                          label={
                            item.thirdPartyReferrerType &&
                            item.status === EReferralStatus.InProgress
                              ? 'Awaiting Response'
                              : EReferralStatusLanguage[item.status]
                          }
                          size="small"
                          sx={{ fontSize: '0.9em' }}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell aria-label="Actions">
                      <IconButton
                        onClick={e => {
                          handleEllipsisClick(item.id, e);
                        }}
                        sx={{
                          pointerEvents: 'auto',
                          flexGrow: 1,
                          backgroundColor: 'grey.200',
                          p: 1,
                          m: 0,
                        }}
                        aria-label="Actions"
                        name="Actions"
                      >
                        <FontAwesomeIcon
                          icon={solid('ellipsis')}
                          size="xs"
                          fixedWidth
                        />
                      </IconButton>

                      {selectedId === item.id ? (
                        <Menu
                          id={`referral-menu-${item.id}`}
                          anchorEl={ellipsisMenuAnchor}
                          open={Boolean(ellipsisMenuAnchor)}
                          onClose={handleMenuClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <MenuItem
                            onClick={() =>
                              handleMenuItemClick(
                                EReferralMenuType.ViewReferral
                              )
                            }
                            disabled={!hasWorkpackageProcess}
                          >
                            View Referral
                          </MenuItem>

                          {hasApplicationsReferProcess && !isComplete && (
                            <div>
                              <MenuItem
                                onClick={() =>
                                  handleMenuItemClick(
                                    EReferralMenuType.EditReferral
                                  )
                                }
                                disabled={!hasWorkpackageProcess}
                              >
                                Edit Referral
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleMenuItemClick(
                                    EReferralMenuType.CloseReferral
                                  )
                                }
                                disabled={
                                  !(
                                    hasLiveprojectPaymentsProcess ||
                                    hasWorkpackageProcess
                                  )
                                }
                              >
                                Close Referral
                              </MenuItem>
                            </div>
                          )}
                        </Menu>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledGenericTable>
        </Box>

        {viewThirdPartyReferralDrawer.renderViewReferralDrawer()}

        {referToThirdPartyDrawer.renderReferDrawer()}

        {closeThirdPartyDrawer.renderCloseReferralDrawer()}

        {closeHomesEnglandDrawer.renderCloseReferralDrawer()}
      </>
    );
  }

  return null;
};
