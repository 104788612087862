import { DataTableResponse } from 'common/types/DataTableResponse';
import { EPermissionGroup } from 'enums/EPermissionGroup';
import {
  IAddEditRole,
  IRole,
  IRoleGridListItem,
} from 'types/administration/AdministrationRolesTypes';

export const rolesList: Array<IRole> = [
  {
    id: '1',
    name: 'Mock Role 1',
  },
  {
    id: '2',
    name: 'Mock Role 2',
  },
  {
    id: '3',
    name: 'Mock Role 3',
  },
];

export const rolesGrid: DataTableResponse<IRoleGridListItem> = {
  count: 3,
  results: [
    {
      id: '1',
      name: 'Mock Role 1',
      activeUsers: 5,
    },
    {
      id: '2',
      name: 'Mock Role 2',
      activeUsers: 10,
    },
    {
      id: '3',
      name: 'Mock Role 3',
      activeUsers: 0,
    },
  ],
};

export const roleData: IAddEditRole = {
  id: '1',
  name: 'Mock Role 1',
  paymentApprovalLevel: 1000,
  permissions: [
    {
      name: 'tasklist.edit',
      permissionGroup: EPermissionGroup.ApplicationTaskList,
      granted: true,
    },
    {
      name: 'tasklist.view-own',
      permissionGroup: EPermissionGroup.TaskManagement,
      granted: true,
    },
    {
      name: 'tasklist.view-all',
      permissionGroup: EPermissionGroup.TaskManagement,
      granted: false,
    },
    {
      name: 'applications.view',
      permissionGroup: EPermissionGroup.ApplicationGeneral,
      granted: true,
    }
  ],
};
