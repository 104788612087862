import { LoadingButton } from '@mui/lab';
import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import {
  useApprovePaymentReleaseMutation,
  useGetAdditionalPaymentRecommendationDetailsQuery,
  useGetPaymentRecommendationApplicationDetailsQuery,
  useRejectPaymentReleaseMutation,
} from 'api/application/paymentApi';
import { AlertForQueryErrorOrNull, ErrorAlert } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import {
  useCurrentUser,
  useCurrentUserPermissions,
  useLocalSnackbar,
  useModalState,
} from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  EPaymentSection,
  EPaymentStatusLanguage,
} from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';
import { VendorPaymentApprovalWaitingBanner } from 'pages/applicationPage/content/payments/common/VendorPaymentApprovalWaitingBanner';
import { useApplicationPaymentsContext } from 'pages/applicationPage/content/payments/context';
import { SendAdditionalRecommendationBanner } from 'pages/applicationPage/content/payments/sections/AdditionalRecommendPaymentSection/SendAdditionalRecommendationBanner';
import { ReleaseAdditionalPaymentStatusBanner } from 'pages/applicationPage/content/payments/sections/AdditionalReleasePaymentSection/ReleaseAdditionalPaymentStatusBanner';
import {
  ApplicationOverviewSection,
  ApprovePaymentModal,
  FundingSection,
  RejectPaymentModal,
} from 'pages/applicationPage/content/payments/sections/common';

export const AdditionalReleasePaymentSection = () => {
  const { doesUserHaveSomeOfThesePermissions } = useCurrentUserPermissions();
  const canUserViewReleaseSection = doesUserHaveSomeOfThesePermissions([
    'payment-release.view',
    'payment-release.edit',
  ]);

  if (!canUserViewReleaseSection) {
    return <ErrorAlert msg="Unauthorised" />;
  }

  return <AdditionalReleasePaymentSectionMain />;
};

const AdditionalReleasePaymentSectionMain = () => {
  const { paymentApprovalLevel } = useCurrentUser();
  const { applicationId, isVendorPaymentApprovalWaiting } =
    useApplicationContext();
  const { setSelectedItemId } = useApplicationPaymentsContext();
  const paymentAppQuery =
    useGetPaymentRecommendationApplicationDetailsQuery(applicationId);
  const paymentDetailsQuery =
    useGetAdditionalPaymentRecommendationDetailsQuery(applicationId);
  const [updatePaymentApproval, updatePaymentApprovalStatus] =
    useApprovePaymentReleaseMutation();
  const [updatePaymentReject, updatePaymentRejectStatus] =
    useRejectPaymentReleaseMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();
  const paymentRecommendationId =
    paymentDetailsQuery?.data?.paymentRecommendationId ?? '';

  const hasPaymentApprovalLevel =
    paymentApprovalLevel === undefined ||
    (paymentApprovalLevel !== undefined &&
      paymentDetailsQuery.data &&
      paymentDetailsQuery.data.paymentAmount &&
      paymentDetailsQuery.data.paymentAmount <= paymentApprovalLevel);

  const {
    isShowing: isApprovePaymentModalShowing,
    showModal: showApprovePaymentModal,
    hideModal: hideApprovePaymentModal,
  } = useModalState();

  const {
    isShowing: isRejectPaymentModalShowing,
    showModal: showRejectPaymentModal,
    hideModal: hideRejectPaymentModal,
  } = useModalState();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasPaymentReleaseEdit = doesUserHaveSinglePermission(
    'payment-release.edit'
  );

  const approvePayment = async () => {
    if (!hasPaymentReleaseEdit) {
      createErrorSnackbar('You do not have permission');
      return;
    }
    hideApprovePaymentModal();
    await updatePaymentApproval({
      applicationId,
      paymentRecommendationId,
    })
      .unwrap()
      .then(() => {
        createSuccessSnackbar('Payment Approved');
      });
  };

  const rejectPayment = async (reason: string) => {
    if (!hasPaymentReleaseEdit) {
      createErrorSnackbar('You do not have permission');
      return;
    }

    hideRejectPaymentModal();
    await updatePaymentReject({
      applicationId,
      paymentRecommendationId,
      reason,
    })
      .unwrap()
      .then(() => {
        createErrorSnackbar('Payment Rejected');
        setSelectedItemId(EPaymentSection.RecommendPayment);
      });
  };

  // isApproved starts out as undefined
  // When approve is clicked, it gets set to true
  // When reject is clicked, it gets set to false
  // When reject is clicked, and the payment get re-recommended, it gets set back to undefined
  const isApprovedValue = paymentDetailsQuery?.data?.isApproved;
  const isPaymentApprovalHandled =
    isApprovedValue === true || isApprovedValue === false;

  return (
    <>
      <RoundBorderBox>
        <Header />
        <Divider />
        <Box p={3}>
          {isVendorPaymentApprovalWaiting && (
            <VendorPaymentApprovalWaitingBanner />
          )}
          <Grid container columnSpacing={4}>
            <Grid item xs={8} display={'flex'} flexDirection={'column'} gap={3}>
              {isPaymentApprovalHandled && (
                <ReleaseAdditionalPaymentStatusBanner />
              )}

              <FundingSection />

              {!isPaymentApprovalHandled && (
                <SendAdditionalRecommendationBanner recommendationSentAndReceived />
              )}
              {paymentDetailsQuery?.data?.paymentStatus && (
                <Typography fontSize="0.9em" fontWeight={600} color="black">
                  Payment Status:{' '}
                  {
                    EPaymentStatusLanguage[
                      paymentDetailsQuery?.data?.paymentStatus
                    ]
                  }
                </Typography>
              )}
              <AlertForQueryErrorOrNull
                isError={paymentAppQuery.isError}
                error={paymentAppQuery.error}
              />
              <AlertForQueryErrorOrNull
                isError={paymentDetailsQuery.isError}
                error={paymentDetailsQuery.error}
              />
              <AlertForQueryErrorOrNull
                isError={updatePaymentApprovalStatus.isError}
                error={updatePaymentApprovalStatus.error}
              />
              <AlertForQueryErrorOrNull
                isError={updatePaymentRejectStatus.isError}
                error={updatePaymentRejectStatus.error}
              />
            </Grid>
            <Grid item xs={4}>
              <ApplicationOverviewSection />
            </Grid>
          </Grid>
        </Box>
        {!isPaymentApprovalHandled && !isVendorPaymentApprovalWaiting && (
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <Box
                display="flex"
                justifyContent="flex-end"
                p={3}
                bgcolor="grey.100"
              >
                <Box gap={2} display="flex">
                  <LoadingButton
                    loading={updatePaymentRejectStatus.isLoading}
                    variant="outlined"
                    onClick={() => showRejectPaymentModal(true)}
                  >
                    Reject
                  </LoadingButton>

                  <LoadingButton
                    loading={updatePaymentApprovalStatus.isLoading}
                    variant="contained"
                    disabled={!hasPaymentApprovalLevel}
                    type="submit"
                    onClick={() => showApprovePaymentModal(true)}
                  >
                    Approve Payment
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} item>
              <Box
                display="flex"
                justifyContent="center"
                p={3}
                bgcolor="grey.100"
              >
                {!hasPaymentApprovalLevel && (
                  <Typography
                    fontWeight={600}
                    fontSize="1em"
                    color={({ palette }) => palette.warning.main}
                  >
                    You do not have permission to approve this payment
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </RoundBorderBox>
      {isRejectPaymentModalShowing && (
        <RejectPaymentModal
          onConfirm={rejectPayment}
          onCancel={() => hideRejectPaymentModal()}
        />
      )}
      {isApprovePaymentModalShowing && (
        <ApprovePaymentModal
          onConfirm={() => approvePayment()}
          onCancel={() => hideApprovePaymentModal()}
        />
      )}
    </>
  );
};

const Header = () => {
  const { applicationId } = useApplicationContext();
  const paymentDetailsQuery =
    useGetAdditionalPaymentRecommendationDetailsQuery(applicationId);

  const statusValue = paymentDetailsQuery.data?.isApproved
    ? 'Approved'
    : 'Not Started';
  const statusColour = paymentDetailsQuery.data?.isApproved
    ? 'success'
    : 'warning';

  return (
    <Box bgcolor="grey.100" p={3}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" fontSize="1.2em" fontWeight={600}>
          Release Additional Payment
        </Typography>
        {paymentDetailsQuery.isSuccess ? (
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <Typography variant="body1" color="grey.700" fontSize="0.9em">
              Status
            </Typography>
            <Chip label={statusValue} color={statusColour} variant={'filled'} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
