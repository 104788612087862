import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetPaymentRequestCostEvidenceDocumentHistoryQuery } from 'api/application/paymentRequestApi';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import { IPaymentRequestCostEvidenceEditedIndicatorProps } from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';

export function PaymentRequestCostEvidenceEditedIndicator({
  questionType,
}: IPaymentRequestCostEvidenceEditedIndicatorProps) {
  const { applicationId, selectedItem } = useApplicationLiveProjectContext();

  const request = selectedItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: selectedItem.id,
        questionType: questionType,
      }
    : skipToken;

  const { data, isLoading } =
    useGetPaymentRequestCostEvidenceDocumentHistoryQuery(request);

  return (
    <Box>
      {data !== undefined && !isLoading && data.length > 0 ? (
        <FontAwesomeIcon icon={faUserPen} />
      ) : null}
    </Box>
  );
}
