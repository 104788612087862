import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { GetPaymentRequestReferralsQueryResult } from 'api/application/applicationApi';
import {
  useGetPaymentRequestOverviewQuery,
  useGetPaymentRequestReferralsQuery,
} from 'api/application/paymentRequestApi';
import { useCurrentUserPermissions } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import {
  EPaymentRecommendationStatus,
  EPaymentRequestSection,
  IPaymentRequestReferral,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';

interface IPaymentRequestContext {
  applicationId: string;
  selectedItemId: EPaymentRequestSection;
  setSelectedItemId: (itemId: EPaymentRequestSection) => void;
  readOnly: boolean;
  currentStatus?: EPaymentRecommendationStatus;
  paymentRequestReferralsQuery: GetPaymentRequestReferralsQueryResult;
  referrals: IPaymentRequestReferral[];
  showReferApplicationDialog: boolean;
  handleShowReferApplicationDialog: (show: boolean) => void;
  showReferralsSection: () => void;
  isReferralsSectionShowing: boolean;
  paymentRequestId: string;
}

const PaymentRequestContext = createContext<IPaymentRequestContext | undefined>(
  undefined
);

interface IPaymentRequestContextProviderProps {
  children: ReactNode;
}

export const PaymentRequestContextProvider = ({
  children,
}: IPaymentRequestContextProviderProps) => {
  const { applicationId, closed } = useApplicationContext();
  const { selectedItem: paymentRequestItem } =
    useApplicationLiveProjectContext();

  const [selectedItemId, setSelectedItemId] = useState<EPaymentRequestSection>(
    EPaymentRequestSection.Overview
  );

  const handleSetSelectedItemId = (itemId: EPaymentRequestSection) => {
    setIsReferralsSectionShowing(false);
    setSelectedItemId(itemId);
  };

  const paymentRequestId = paymentRequestItem?.id ?? '';

  const request = paymentRequestItem?.id
    ? {
        applicationId: applicationId,
        paymentRequestId: paymentRequestItem.id,
      }
    : skipToken;

  const paymentRequestReferralsQuery =
    useGetPaymentRequestReferralsQuery(request);

  const paymentRequestOverviewQuery =
    useGetPaymentRequestOverviewQuery(request);

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasLiveprojectPaymentsProcess = doesUserHaveSinglePermission(
    'liveproject.payments.process'
  );

  const readOnly =
    paymentRequestOverviewQuery.data?.paymentRecommendation ===
      EPaymentRecommendationStatus.Approved ||
    paymentRequestOverviewQuery.data?.paymentRecommendation ===
      EPaymentRecommendationStatus.Rejected ||
    paymentRequestOverviewQuery.data?.paymentRecommendation ===
      EPaymentRecommendationStatus.Expired ||
    paymentRequestOverviewQuery.data?.paymentRecommendation ===
      EPaymentRecommendationStatus.Paid ||
    paymentRequestOverviewQuery.data?.paymentRecommendation ===
      EPaymentRecommendationStatus.Sent ||
    closed ||
    !hasLiveprojectPaymentsProcess;

  const currentStatus = paymentRequestOverviewQuery.data?.paymentRecommendation;

  const [isReferralsSectionShowing, setIsReferralsSectionShowing] =
    useState(false);

  const [showReferApplicationDialog, setShowReferApplicationDialog] =
    useState<boolean>(false);

  const handleShowReferApplicationDialog = useCallback((show: boolean) => {
    setShowReferApplicationDialog(show);
  }, []);

  const showReferralsSection = () => {
    setIsReferralsSectionShowing(true);
  };

  const value = useMemo(
    () => ({
      applicationId,
      selectedItemId,
      setSelectedItemId: handleSetSelectedItemId,
      readOnly,
      currentStatus,
      referrals: paymentRequestReferralsQuery.data?.results ?? [],
      paymentRequestReferralsQuery,
      showReferApplicationDialog,
      handleShowReferApplicationDialog,
      showReferralsSection,
      isReferralsSectionShowing,
      paymentRequestId,
    }),
    [
      applicationId,
      selectedItemId,
      readOnly,
      currentStatus,
      paymentRequestReferralsQuery,
      showReferApplicationDialog,
      handleShowReferApplicationDialog,
      isReferralsSectionShowing,
      paymentRequestId,
    ]
  );

  return (
    <PaymentRequestContext.Provider value={value}>
      {children}
    </PaymentRequestContext.Provider>
  );
};

export const usePaymentRequestContext = () => {
  const context = useContext(PaymentRequestContext);
  if (context === undefined) {
    throw new Error(
      'usePaymentRequestContext must be used within an PaymentRequestContextProvider'
    );
  }
  return context;
};
