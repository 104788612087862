import { useState } from 'react';
import { Button, TextField, Dialog, Box } from '@mui/material';
import {
  StyledDialogActions,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IRejectPaymentModalProps {
  onConfirm: (reason: string) => void;
  onCancel: () => void;
}

export function RejectPaymentModal({
  onConfirm,
  onCancel,
}: IRejectPaymentModalProps) {
  const [reason, setReason] = useState<string>('');
  return (
    <Dialog open fullWidth maxWidth="sm">
      <StyledDialogTitle>Reject payment recommendation</StyledDialogTitle>
      <Box sx={{ p: 2 }}>
        <TextField
          label={'Reason for Rejection'}
          fullWidth
          value={reason}
          multiline
          rows={4}
          onChange={e => setReason(e.target.value)}
          data-automation-id="reject-payment-reason"
        />
      </Box>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel} data-automation-id="reject-payment-cancel" >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => onConfirm(reason)}
          disabled={reason === ''}
          data-automation-id="reject-payment-confirm"
        >
          Reject
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}
