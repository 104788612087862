import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { GetScheduleOfWorksReferralsQueryResult } from 'api/application/applicationApi';
import {
  useGetScheduleOfWorksOverviewQuery,
  useGetScheduleOfWorksReferralsQuery,
} from 'api/application/scheduleOfWorksApi';
import { useCurrentUserPermissions } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { useApplicationLiveProjectContext } from 'pages/applicationPage/content/liveProject/context/ApplicationLiveProjectContext';
import {
  EScheduleOfWorksSection,
  EScheuduleOfWorksStatus,
  IScheduleOfWorksReferral,
} from 'pages/applicationPage/content/liveProject/sections/ScheduleOfWorksSection/ScheduleOfWorksTypes';

interface IScheduleOfWorksContext {
  applicationId: string;
  selectedItemId: EScheduleOfWorksSection;
  setSelectedItemId: (itemId: EScheduleOfWorksSection) => void;
  readOnly: boolean;
  currentStatus?: EScheuduleOfWorksStatus;
  scheduleOfWorksReferralsQuery: GetScheduleOfWorksReferralsQueryResult;
  referrals: IScheduleOfWorksReferral[];
  showReferApplicationDialog: boolean;
  handleShowReferApplicationDialog: (show: boolean) => void;
  showReferralsSection: () => void;
  isReferralsSectionShowing: boolean;
}

const ScheduleOfWorksContext = createContext<
  IScheduleOfWorksContext | undefined
>(undefined);

interface IScheduleOfWorksContextProviderProps {
  children: ReactNode;
}

export const ScheduleOfWorksContextProvider = ({
  children,
}: IScheduleOfWorksContextProviderProps) => {
  const { applicationId, closed } = useApplicationContext();
  const { selectedItem } = useApplicationLiveProjectContext();

  const [selectedItemId, setSelectedItemId] = useState<EScheduleOfWorksSection>(
    EScheduleOfWorksSection.Overview
  );

  const handleSetSelectedItemId = (itemId: EScheduleOfWorksSection) => {
    setIsReferralsSectionShowing(false);
    setSelectedItemId(itemId);
  };

  var request = selectedItem?.id
    ? {
        applicationId: applicationId,
        scheduleOfWorksId: selectedItem.id,
      }
    : skipToken;

  const scheduleOfWorksReferralsQuery =
    useGetScheduleOfWorksReferralsQuery(request);

  const scheduleOfWorksOverviewQuery =
    useGetScheduleOfWorksOverviewQuery(request);

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasLiveprojectScheduleofworksProcess = doesUserHaveSinglePermission(
    'liveproject.scheduleofworks.process'
  );

  const readOnly =
    scheduleOfWorksOverviewQuery.data?.status ===
      EScheuduleOfWorksStatus.Approved ||
    closed ||
    !hasLiveprojectScheduleofworksProcess;

  const currentStatus = scheduleOfWorksOverviewQuery.data?.status;

  useEffect(() => {
    if (scheduleOfWorksReferralsQuery.data?.results) {
      const newReferrals = scheduleOfWorksReferralsQuery.data.results.map(
        d => ({
          ...d,
        })
      );

      setReferrals(newReferrals);
    }
  }, [scheduleOfWorksReferralsQuery.data?.results]);

  const [referrals, setReferrals] = useState<IScheduleOfWorksReferral[]>([]);

  const [isReferralsSectionShowing, setIsReferralsSectionShowing] =
    useState(false);

  const [showReferApplicationDialog, setShowReferApplicationDialog] =
    useState<boolean>(false);

  const handleShowReferApplicationDialog = useCallback((show: boolean) => {
    setShowReferApplicationDialog(show);
  }, []);

  const showReferralsSection = () => {
    setIsReferralsSectionShowing(true);
  };

  const value = useMemo(
    () => ({
      applicationId,
      selectedItemId,
      setSelectedItemId: handleSetSelectedItemId,
      readOnly,
      currentStatus,
      referrals,
      scheduleOfWorksReferralsQuery,
      showReferApplicationDialog,
      handleShowReferApplicationDialog,
      showReferralsSection,
      isReferralsSectionShowing,
    }),
    [
      scheduleOfWorksReferralsQuery,
      applicationId,
      handleShowReferApplicationDialog,
      isReferralsSectionShowing,
      readOnly,
      currentStatus,
      referrals,
      selectedItemId,
      showReferApplicationDialog,
    ]
  );

  return (
    <ScheduleOfWorksContext.Provider value={value}>
      {children}
    </ScheduleOfWorksContext.Provider>
  );
};

export const useScheduleOfWorksContext = () => {
  const context = useContext(ScheduleOfWorksContext);
  if (context === undefined) {
    throw new Error(
      'useScheduleOfWorksContext must be used within an ScheduleOfWorksContextProvider'
    );
  }
  return context;
};
