import { ETeam } from 'enums/ETeam';
import { UserPermissions } from 'types/UserPermissions';
import { IMyUserProfile } from 'types/administration/AdministrationUsersTypes';

export const userProfileData: IMyUserProfile = {
  firstName: 'Joe',
  lastName: 'Bloggs',
  emailAddress: 'jbloggs@aol.com',
  role: 'System Admin',
  organisation: 'Davies Group',
  teamId: ETeam.HomesEnglandOperations,
  paymentApprovalLevel: undefined,
};

export const permissionsForUserData: UserPermissions[] = [
  'admin.access',
  'admin.roles',
  'admin.users',
  'admin.system-notification',
  'admin.application.govdata.edit',
  'admin.fraew-assessor-list',
  'admin.ptfs.view',
  'admin.ptfs.update',
  'admin.benchmark-figures.view',
  'admin.benchmark-figures.edit',
  'admin.team-management.view',
  'admin.team-management.edit',
  'admin.team.edit',
  'admin.team.delete',
  'admin.organisation-management.view',
  'admin.organisation-management.edit',
  'admin.organisation.edit',
  'admin.organisation.delete',
  'applications.admin.signatory',
  'applications.close',
  'applications.eligibility.view',
  'applications.eligibility.edit',
  'applications.eligibility.confirm',
  'applications.grantfunding.view',
  'applications.grantfunding.edit',
  'applications.payments',
  'payment-recommend.edit',
  'payment-recommend.view',
  'payment-release.edit',
  'payment-release.view',
  'payment-release.unlock',
  'applications.view',
  'communications.view',
  'communications.add',
  'documents.view',
  'documents.upload',
  'documents.delete',
  'tasklist.edit',
  'tasklist.view-all',
  'tasklist.view-own',
  'workpackage.costschedule.edit',
  'workpackage.cladding.edit',
  'workpackage.grantpercentage.edit',
  'pullinprocess.view'
];
