import { v4 as uuidv4 } from 'uuid';
import { DataTableResponse } from 'common/types/DataTableResponse';
import { EDocumentCategory, EDocumentType, IApplicationDocument, IApplicationDocumentCategories, IApplicationDocumentTypes } from 'types/applications/ApplicationDocumentTypes';

export const applicationDocumentCategoriesData: Array<IApplicationDocumentCategories> =
  [
    {
      id: '1',
      name: 'Submit Application',
    },
    {
      id: '2',
      name: 'Eligibility checks',
    },
    {
      id: '3',
      name: 'Miscellaneous',
    },
  ];

  export const applicationDocumentTypesData: Array<IApplicationDocumentTypes> =
  [
    {
      id: '1',
      name: 'FRAEW Report',
      categoryId: 1
    },
    {
      id: '2',
      name: 'Counter signed GFA',
      categoryId: 2
    },
    {
      id: '3',
      name: 'Signed Deed of Trust',
      categoryId: 2
    },
  ];

export const applicationDocumentsData: DataTableResponse<IApplicationDocument> = {
  results:[{
    fileId: uuidv4(),
    filename: 'Mock Document 1.pdf',
    documentCategoryId: EDocumentCategory.ApplyForGrant,
    categoryName: 'Deed of Trust',
    documentTypeId: EDocumentType.DeedOfTrustSigned,
    documentType: 'Deed of Trust Signed',
    uploadDate: '2023-03-02 10:00:00',
    uploadedBy: 'Homer Simpson',
    isKeyDocument : false,
    canDelete : true,
    isArchived: false,

  },
  {
    fileId: uuidv4(),
    filename: 'Mock Document 2.pdf',
    documentCategoryId: EDocumentCategory.ApplyForGrant,
    categoryName: 'Apply for Grant',
    documentTypeId: EDocumentType.AssuranceEvidence,
    documentType: 'Assurance Evidence',
    uploadDate: '2023-03-10 16:00:00',
    uploadedBy: 'Bart Simpson',
    isKeyDocument: true,
    canDelete : false,
    isArchived: false,
  },
  {
    fileId: uuidv4(),
    filename: 'Mock Document 2.pdf',
    documentCategoryId: EDocumentCategory.Payments,
    categoryName: 'Payment',
    documentTypeId: EDocumentType.AssuranceEvidence,
    documentType: 'Assurance Evidence',
    uploadDate: '2023-03-10 16:00:00',
    uploadedBy: 'Bart Simpson',
    isKeyDocument: true,
    canDelete : false,
    isArchived: false,
  }],
  count: 2
};
