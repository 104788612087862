import { Button } from '@mui/material';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';

interface IApprovePaymentModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function ApprovePaymentModal({
  onConfirm,
  onCancel,
}: IApprovePaymentModalProps) {
  return (
    <StyledDialog open>
      <StyledDialogTitle>
        Are you sure you want to approve payment recommendation?
      </StyledDialogTitle>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onCancel} data-automation-id="button-approve-payment-cancel">
          Cancel
        </Button>
        <Button variant="contained" onClick={onConfirm} data-automation-id="button-approve-payment-confirm">
          Yes, Approve
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
