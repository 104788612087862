import { Autocomplete, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useGetCostCentreListQuery } from 'api/application';
import { ICostCentreItem } from 'types/applications/ApplicationEligibilityTypes';
import { sortByValueAsc } from 'util/AppUtils';

export const useCostCentreOptions = () => {
  const costCentreListQuery = useGetCostCentreListQuery();
  const costCentreOptions =
    costCentreListQuery.data
      ?.map((item: { name: string; id: string; isInEngland: boolean }) => ({
        value: item.name,
        id: item.id,
        isInEngland: item.isInEngland,
      }))
      .sort(sortByValueAsc) ?? [];

  return costCentreOptions;
};

interface ICostCentreFieldProps {
  onChange?: (item?: ICostCentreItem) => void;
  readOnly?: boolean;
}

export const CostCentreField = ({
  onChange: parentOnChange,
  readOnly = false,
}: ICostCentreFieldProps) => {
  const { control, getValues } = useFormContext();
  const costCentreOptions = useCostCentreOptions();
  const valueObj = costCentreOptions.find(
    costCentreOption => costCentreOption.id === getValues('costCentreId')
  );

  return costCentreOptions.length > 0 ? (
    <Controller
      control={control}
      name="costCentreId"
      render={({ field, formState: { errors } }) => (
        <Autocomplete
          data-automation-id="CostCentre"
          value={valueObj || null}
          options={costCentreOptions}
          getOptionLabel={option => option.value}
          onChange={(_, selectedValue) => {
            if (parentOnChange) {
              if (selectedValue != null) {
                field.onChange(selectedValue.id);
                parentOnChange({
                  id: selectedValue.id,
                  name: selectedValue.value,
                  isInEngland: selectedValue.isInEngland,
                });
              } else {
                field.onChange(undefined);
                parentOnChange(undefined);
              }
            }
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.value}
              </li>
            );
          }}
          renderInput={params => (
            <TextField
              {...field}
              {...params}
              label="Local Authority"
              error={!!errors.costCentreId}
              helperText={errors.costCentreId?.message}
            />
          )}
          disabled={readOnly}
        />
      )}
    />
  ) : null;
};
