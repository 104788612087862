import { useState } from 'react';
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import {
  useGetPaymentRecommendationDetailsQuery,
  useGetVendorAccountSetupSummaryQuery,
  useGetVendorPaymentDetailsQuery,
} from 'api/application/paymentApi';
import { AlertForQueryErrorOrNull, ErrorAlert } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useCurrentUserPermissions } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { PaymentHasAlreadyBeenMadeSection } from 'pages/applicationPage/content/payments/sections/RecommendPaymentSection/PaymentHasAlreadyBeenMadeSection';
import { RepresentativeFileSection } from 'pages/applicationPage/content/payments/sections/RecommendPaymentSection/RepresentativeFileSection';
import { SendRecommendationBanner } from 'pages/applicationPage/content/payments/sections/RecommendPaymentSection/SendRecommendationBanner';
import {
  ApplicationOverviewSection,
  FundingSection,
  PaymentDocumentsSection,
} from 'pages/applicationPage/content/payments/sections/common';
import { usePaymentRepresentativeLogic } from 'pages/applicationPage/content/payments/utils/paymentUtils';

export const RecommendPaymentSection = () => {
  const { doesUserHaveSomeOfThesePermissions } = useCurrentUserPermissions();
  const canUserViewRecommendSection = doesUserHaveSomeOfThesePermissions([
    'payment-recommend.view',
    'payment-recommend.edit',
  ]);

  if (!canUserViewRecommendSection) {
    return <ErrorAlert msg="Unauthorised" />;
  }

  return <RecommendPaymentSectionMain />;
};

const RecommendPaymentSectionMain = () => {
  const { applicationId } = useApplicationContext();

  const [paymentAlreadyMade, setPaymentAlreadyMade] = useState<boolean>(false);

  const paymentRepresentativeLogic = usePaymentRepresentativeLogic();
  const paymentDetailsQuery =
    useGetPaymentRecommendationDetailsQuery(applicationId);

  const isLoading =
    paymentRepresentativeLogic.isLoading || paymentDetailsQuery.isLoading;
  const isSuccess =
    paymentRepresentativeLogic.isSuccess && paymentDetailsQuery.isSuccess;

  const { isRepresentativeAndPaymentGoingToRepresentative } =
    paymentRepresentativeLogic;

  const hasFile = Boolean(
    paymentDetailsQuery.data?.moneyProtectionSchemeEvidenceFile
  );

  return (
    <RoundBorderBox>
      <Header />
      <Divider />
      <Box p={3}>
        <Grid container columnSpacing={4}>
          <Grid item xs={8} display={'flex'} flexDirection={'column'} gap={3}>
            <FundingSection />

            {isLoading ? <FormSkeleton /> : null}

            {isSuccess ? (
              <>
                {!paymentAlreadyMade &&
                  (isRepresentativeAndPaymentGoingToRepresentative ? (
                    <>
                      <RepresentativeFileSection />
                      {hasFile ? <SendRecommendationBanner /> : null}
                    </>
                  ) : (
                    <SendRecommendationBanner />
                  ))}

                {paymentDetailsQuery.data.recommendationSent !== true && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(_, val) => {
                          if (val !== null) {
                            setPaymentAlreadyMade(val);
                          }
                        }}
                      />
                    }
                    label="Payment has already been made"
                    data-automation-id="checkbox-payment-already-made"
                  />
                )}

                {paymentAlreadyMade &&
                  paymentDetailsQuery.data.recommendationSent !== true && (
                    <PaymentHasAlreadyBeenMadeSection />
                  )}

                {paymentAlreadyMade &&
                  paymentDetailsQuery.data.recommendationSent === true && (
                    <SendRecommendationBanner />
                  )}
              </>
            ) : null}

            <AlertForQueryErrorOrNull
              isError={paymentRepresentativeLogic.isError}
              error={paymentRepresentativeLogic.error}
            />
            <AlertForQueryErrorOrNull
              isError={paymentDetailsQuery.isError}
              error={paymentDetailsQuery.error}
            />
          </Grid>
          <Grid item xs={4}>
            <ApplicationOverviewSection />
            <PaymentDocumentsSection />
          </Grid>
        </Grid>
      </Box>
    </RoundBorderBox>
  );
};

const Header = () => {
  const { applicationId } = useApplicationContext();

  const paymentDetailsQuery =
    useGetPaymentRecommendationDetailsQuery(applicationId);

  const summaryQuery = useGetVendorAccountSetupSummaryQuery(applicationId);
  const statusQuery = useGetVendorPaymentDetailsQuery(applicationId);
  const isSuccess = summaryQuery.isSuccess && statusQuery.isSuccess;

  const statusValue = paymentDetailsQuery.data?.recommendationSent
    ? 'Recommendation Sent'
    : 'Not Started';
  const statusColour = paymentDetailsQuery.data?.recommendationSent
    ? 'success'
    : 'warning';

  return (
    <Box bgcolor="grey.100" p={3}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" fontSize="1.2em" fontWeight={600}>
          Recommend Payment
        </Typography>
        {isSuccess ? (
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <Typography variant="body1" color="grey.700" fontSize="0.9em">
              Status
            </Typography>
            <Chip label={statusValue} color={statusColour} variant={'filled'} data-automation-id="chip-status" />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
