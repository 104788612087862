import { IPaginationWithSortQuery } from "types/PaginationQuery";
import { nameof } from "util/formUtils";

export interface IApplicationDocumentCategories {
  id: string;
  name: string;
}

export interface IApplicationDocumentTypes {
  id: string;
  name: string;
  categoryId: number
}

export enum EDocumentCategory {
    SubmitApplication = 1,
    EligibilityChecks = 2,
    Miscellaneous = 3,
    DataChangeEvidence = 4,
    ArchivedDocument = 5,
    ApplyForGrant = 6,
    GrantFundingAgreement = 7,
    Payments = 8,
    WorksPackage = 9,
    DutyOfCare = 10,
    WorksDeliveryPaymentRequest = 11,
    WorksDeliveryVariation = 12,
    WorksComplete = 13,
    Cssp = 14,
    Enforcement = 15
}
export enum EDocumentType {
    LeaseholderEvidence = 1,
    FRAEWSummary = 2,
    FRAEWReport = 3,
    GrantFundingAgreementInitial = 4,
    GrantFundingAgreementSigned = 5,
    GrantFundingAgreementCounterSigned = 6,
    DeedOfTrustInitial = 7,
    DeedOfTrustSigned = 8,
    VendorSetUpDetailsEvidence = 9,
    VendorSetUpApproval = 10,
    AdditionalPaymentEvidence = 11,
    ProgressReportCommunicationEvidence = 12,
    ScheduleOfWorksContract = 13,
    WorksPackageDeedInitial = 14,
    WorksPackageDeedSigned = 15,
    WorksPackageDeedCountersigned = 16,
    DutyOfCareInitial = 17,
    DutyOfCareSigned = 18,
    DutyOfCareCountersigned = 19,
    CostReport = 20,
    VariationRequestEvidence = 21,
    ExitFRAEW = 22,
    PracticalCompletionCertificate = 23,
    CommunicationEvidence = 24,
    FinalCostReport = 25,
    ClientMoneyProtectionScheme = 26,
    PaymentRequestThirdPartyReferralResponse = 27,
    SignedOffBuildingRegulationsEvidence = 28,
    ResponsibleEntityRepresentation = 29,
    S151Evidence = 30,
    ChiefExecutiveEvidence = 31,
    WorkPackageThirdPartyReferralResponse = 32,
    VariationRequestThirdPartyReferralResponse = 33,
    VeriphyReport = 34,
    DevelopersPledge = 35,
    AssuranceEvidence = 36,
    BuildingHeightEvidence = 37,
    QualifyingLeaseDocument = 38,
    FreeholderAndPlanFromLandRegistry = 39,
    CertificateOfIncorporation = 40,
    EligibilityLetter = 41,
    ArchivedDocument = 42,
    BeneficialOwnershipChecks = 43,
    KYCEvidence = 44,
    DeedOfVariation = 45,
    Miscellaneous = 46,
    DataChangeEvidence = 47,
    CSSP = 48,
    SubmitApplication = 49,
    ScheduleOfWorksBuildingControl = 50,
    ScheduleOfWorksLeaseholderEngagement = 51,
    Enforcement = 52,
    ArchivedKeyDocument = 53,
    PaymentRequestInvoice = 54
}

export interface IApplicationDocument {
  fileId: string;
  filename: string;
  documentCategoryId: EDocumentCategory;
  documentTypeId : EDocumentType;
  categoryName: string;
  documentType : string;
  uploadDate: string;
  uploadedBy: string;
  isKeyDocument: boolean;
  canDelete: boolean;
  isArchived: boolean;
}

export interface IAddApplicationDocument {
  applicationId: string;
  fileId: string;
  categoryId: string;
  typeId: string;
}

export interface IApplicationDocumentActionsBase {
  applicationId: string;
  fileId: string;
}

export interface IDeleteApplicationDocument extends IApplicationDocumentActionsBase {};
export interface IArchiveApplicationDocument extends IApplicationDocumentActionsBase {};

export const applicationDocumentsGridSortByTypes = [
  nameof<IApplicationDocument>('filename'),
  nameof<IApplicationDocument>('categoryName'),
  nameof<IApplicationDocument>('documentType'),
  nameof<IApplicationDocument>('uploadDate'),
  nameof<IApplicationDocument>('uploadedBy'),
  nameof<IApplicationDocument>('isKeyDocument'),
] as const;

export type ApplicationDocumentsSortByTypes =
  (typeof applicationDocumentsGridSortByTypes)[number];

export interface IGetApplicationDocumentsPaginatedQuery
  extends IPaginationWithSortQuery<ApplicationDocumentsSortByTypes> {
  applicationId: string;
  categories: string[];
  types: string[];
  keyDocumentsOnly: boolean;
}
