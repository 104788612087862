import { DataTableResponse } from 'common/types/DataTableResponse';
import {
  IOrganisation,
  IOrganisationUser,
  IOrgsGridListItem,
  IOrganisationForEdit
} from 'types/administration/AdministrationOrganisationTypes';

export const administrationOrganisationData: Array<IOrganisation> = [
  {
    id: 1,
    name: 'Mock Organisation 1',
  },
  {
    id: 2,
    name: 'Mock Organisation 2',
  },
  {
    id: 3,
    name: 'Mock Organisation 3',
  },
];

export const organisationUsersData: IOrganisationUser[] = [
  { id: '556c270b-b09f-4953-a351-47b8c0efabc0', fullName: 'Clem Fandango' },
  { id: '556c270b-b09f-4953-a351-47b8c0efabc1', fullName: 'Ray Purchase' },
  { id: '556c270b-b09f-4953-a351-47b8c0efabc2', fullName: 'Steven Toast' },
];

export const administrationOrganisationsForAdminData: DataTableResponse<IOrgsGridListItem> = {
  results: [
    {
      id: '1',
      name: 'Homes England',
      teams: [
        { id: '1', name: 'Homes England Operations' }
      ]
    },
    {
      id: '2',
      name: 'Davies Group',
      teams: [
        { id: '1', name: 'Davies Ops' }
      ]
    },
    {
      id: '3',
      name: 'Greater London Authority',
      teams: [
        { id: '1', name: 'Barnet & Camden' },
        { id: '2', name: 'Bexley & Bromley' },
        { id: '3', name: 'Brent & Harrow' },
        { id: '4', name: 'City & East' },
        { id: '5', name: 'Croyden & Sutton' },
        { id: '6', name: 'Ealing & Hillingdon' },
        { id: '7', name: 'Enfield & Haringey' },
        { id: '8', name: 'Greenwich & Lewisham' },
        { id: '9', name: 'Havering & Redbridge' },
        { id: '10', name: 'Lambeth & Southwark' },
        { id: '11', name: 'Merton & Wansworth' },
        { id: '12', name: 'North East (London)' },
      ]
    }
  ],
  count: 3,
};

export const administrationEditOrganisationData: IOrganisationForEdit = 
  {
    id: 1,
    name: 'Mock Organisation 1',
    needsToAgreeTermsAndConditions: true,
    teams: [ 
      { id: 1, name: 'Team 1' }
    ]
  };
