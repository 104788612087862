import { useState } from 'react';
import { faAdd, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SortDirection,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetApplicationDocumentsQuery } from 'api/application/documentsApi';
import { NoResultsFoundText } from 'common/components/noResultsfoundText/NoResultsFoundText';
import { ESortDirection } from 'enums/ESortDirection';
import {
  useCurrentUserPermissions,
  useEllipsisMenu,
  useModalState,
} from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { ArchiveDocumentDialog } from 'pages/applicationPage/content/documents/ArchiveDocumentDialog';
import { DeleteDocumentDialog } from 'pages/applicationPage/content/documents/DeleteDocumentDialog';
import { DocumentsFilters } from 'pages/applicationPage/content/documents/DocumentsFilters';
import { UploadDocumentDialog } from 'pages/applicationPage/content/documents/UploadDocumentDialog';
import { useAppDispatch } from 'state';
import { selectApplicationDocumentsGridState } from 'state/selectors/applications/applicationsSelectors';
import { documentsGridStateChanged } from 'state/slices/applications';
import { StyledGenericTable } from 'styles/globalStyles/tables';
import {
  EDocumentType,
  EDocumentCategory,
  IApplicationDocument,
} from 'types/applications/ApplicationDocumentTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';

export const Documents = () => {
  const dispatch = useAppDispatch();

  const {
    selectedId,
    handleEllipsisClick,
    handleMenuClose,
    ellipsisMenuAnchor,
  } = useEllipsisMenu();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasUploadDocuments = doesUserHaveSinglePermission('documents.upload');
  const hasDeleteDocuments = doesUserHaveSinglePermission('documents.delete');
  const hasArchiveDocuments = doesUserHaveSinglePermission('documents.archive');
  const hasApplicationsBankdetailsView = doesUserHaveSinglePermission(
    'applications.bank-details.view'
  );

  const applicationDocumentsGridState = useSelector(
    selectApplicationDocumentsGridState
  );

  const { data, isLoading, refetch } = useGetApplicationDocumentsQuery(
    applicationDocumentsGridState
  );

  const noResultsFound =
    data?.count === 0 && !!applicationDocumentsGridState.searchValue;

  const { skip, take, sortBy, sortDirection } = applicationDocumentsGridState;

  const currentPage = skip / take;

  const sortHandler = (property: keyof IApplicationDocument): void => {
    dispatch(
      documentsGridStateChanged({
        sortBy: property,
        sortDirection:
          sortDirection === ESortDirection.Asc
            ? ESortDirection.Desc
            : ESortDirection.Asc,
      })
    );
  };

  const getAriaSortDirection = (
    property: keyof IApplicationDocument,
    direction: ESortDirection
  ): SortDirection => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'asc'
      : 'desc';
  };

  const getButtonAriaSortDirection = (
    property: keyof IApplicationDocument,
    direction: ESortDirection
  ): 'ascending' | 'descending' => {
    return sortBy === property && direction === ESortDirection.Asc
      ? 'ascending'
      : 'descending';
  };

  const getCurrentSortDirection = (
    property: keyof IApplicationDocument,
    direction: ESortDirection
  ): 'asc' | 'desc' => {
    return sortBy === property
      ? direction === ESortDirection.Asc
        ? 'asc'
        : 'desc'
      : 'asc';
  };

  const getHeaderTableCell = (
    property: keyof IApplicationDocument,
    columnLabel: string
  ): JSX.Element => {
    return (
      <TableCell sortDirection={getAriaSortDirection(property, sortDirection)}>
        <TableSortLabel
          aria-sort={getButtonAriaSortDirection(property, sortDirection)}
          active={sortBy === property}
          direction={getCurrentSortDirection(property, sortDirection)}
          onClick={() => sortHandler(property)}
        >
          {columnLabel}
        </TableSortLabel>
      </TableCell>
    );
  };

  const {
    isShowing: isUploadDocumentDialogShowing,
    showModal: showUploadDocumentDialog,
    hideModal: hideUploadDocumentDialog,
  } = useModalState();

  const {
    isShowing: isDeleteDocumentDialogShowing,
    showModal: showDeleteDocumentDialog,
    hideModal: hideDeleteDocumentDialog,
  } = useModalState();

  const {
    isShowing: isArchiveDocumentDialogShowing,
    showModal: showArchiveDocumentDialog,
    hideModal: hideArchiveDocumentDialog,
  } = useModalState();

  const [selectedRowDocumentId, setSelectedRowDocumentId] = useState<
    string | null
  >(null);

  const onDeleteDocumentClick = (rowId: string) => {
    setSelectedRowDocumentId(rowId);
    showDeleteDocumentDialog(true);
  };

  const onArchiveDocumentClick = (rowId: string) => {
    setSelectedRowDocumentId(rowId);
    showArchiveDocumentDialog(true);
  };

  const canViewDocument = (row: IApplicationDocument): boolean => {
    const isRestrictedDocument =
      row.documentCategoryId === EDocumentCategory.Payments ||
      row.documentTypeId === EDocumentType.DeedOfTrustSigned ||
      row.documentTypeId === EDocumentType.DeedOfTrustInitial;

    return isRestrictedDocument ? hasApplicationsBankdetailsView : true;
  };

  const restrictedDocumentsDisplay: { [key: number]: boolean } = {
    [EDocumentType.DeedOfTrustInitial]: hasApplicationsBankdetailsView,
  };

  return (
    <>
      {data && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" fontWeight={900}>
                Documents ({data?.count})
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                onClick={showUploadDocumentDialog}
                startIcon={<FontAwesomeIcon icon={faAdd} />}
                disabled={!hasUploadDocuments}
              >
                Upload Document
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12}>
                <DocumentsFilters isLoading={isLoading} />
              </Grid>
              <NoResultsFoundText show={noResultsFound} text="No Documents." />
              <StyledGenericTable data-testid="Application-Documents-Table">
                <TableHead>
                  <TableRow>
                    {getHeaderTableCell('isKeyDocument', 'Key Document')}
                    {getHeaderTableCell('filename', 'File Name')}
                    {getHeaderTableCell('documentType', 'Document Type')}
                    {getHeaderTableCell('categoryName', 'Category Name')}
                    {getHeaderTableCell('uploadDate', 'Upload Date')}
                    {getHeaderTableCell('uploadedBy', 'Uploaded By')}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.results
                    ?.filter((row: IApplicationDocument) => {
                      return (
                        restrictedDocumentsDisplay[row.documentTypeId] ?? true
                      );
                    })
                    .map((row: IApplicationDocument) => (
                      <TableRow key={row.fileId}>
                        <TableCell style={{ width: '3rem' }}>
                          {row.isKeyDocument ? (
                            <FontAwesomeIcon icon={faStar} />
                          ) : null}
                        </TableCell>
                        <TableCell>{row.filename}</TableCell>
                        <TableCell>{row.documentType}</TableCell>
                        <TableCell>{row.categoryName}</TableCell>
                        <TableCell>
                          {row.uploadDate
                            ? getUkDateStringFromJsonDateString(row.uploadDate)
                            : ''}
                        </TableCell>
                        <TableCell>{row.uploadedBy}</TableCell>
                        <TableCell>
                          {canViewDocument(row) && (
                            <ApplicationFileDownloadWrapper
                              showIconOnly
                              fileName={row.filename}
                              fileId={row.fileId}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <>
                            <IconButton
                              aria-label="edit"
                              name="edit"
                              onClick={event =>
                                handleEllipsisClick(row.fileId, event)
                              }
                            >
                              <MoreVertIcon fontSize="small" />
                            </IconButton>
                            {selectedId === row.fileId ? (
                              <Menu
                                id={`document-row-item-${row.fileId}`}
                                anchorEl={ellipsisMenuAnchor}
                                open={Boolean(ellipsisMenuAnchor)}
                                onClose={handleMenuClose}
                                MenuListProps={{
                                  'aria-labelledby': 'basic-button',
                                }}
                              >
                                <MenuItem
                                  onClick={event =>
                                    onArchiveDocumentClick(row.fileId)
                                  }
                                  disabled={
                                    !hasArchiveDocuments || row.isArchived
                                  }
                                >
                                  Archive
                                </MenuItem>
                                {/* Remove delete button */}
                                {false && (
                                  <MenuItem
                                    onClick={event =>
                                      onDeleteDocumentClick(row.fileId)
                                    }
                                    disabled={
                                      !hasDeleteDocuments || !row.canDelete
                                    }
                                  >
                                    Delete
                                  </MenuItem>
                                )}
                              </Menu>
                            ) : null}
                          </>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={data?.count || 0}
                      rowsPerPage={take}
                      rowsPerPageOptions={[take]}
                      page={currentPage}
                      onPageChange={(e, newPage) => {
                        const newSkip = newPage * take;
                        dispatch(documentsGridStateChanged({ skip: newSkip }));
                      }}
                      onRowsPerPageChange={e => {
                        dispatch(
                          documentsGridStateChanged({
                            take: +e.target.value,
                          })
                        );
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </StyledGenericTable>
            </Grid>
          </Grid>

          {isUploadDocumentDialogShowing && (
            <UploadDocumentDialog
              onConfirm={() => {
                hideUploadDocumentDialog();
                refetch();
              }}
              onCancel={() => {
                hideUploadDocumentDialog();
              }}
            />
          )}
          {isDeleteDocumentDialogShowing && selectedRowDocumentId && (
            <DeleteDocumentDialog
              documentId={selectedRowDocumentId}
              onConfirm={() => {
                setSelectedRowDocumentId(null);
                hideDeleteDocumentDialog();
                handleMenuClose();
                refetch();
              }}
              onCancel={() => {
                setSelectedRowDocumentId(null);
                hideDeleteDocumentDialog();
                handleMenuClose();
              }}
            />
          )}
          {isArchiveDocumentDialogShowing && selectedRowDocumentId && (
            <ArchiveDocumentDialog
              documentId={selectedRowDocumentId}
              onConfirm={() => {
                setSelectedRowDocumentId(null);
                hideArchiveDocumentDialog();
                handleMenuClose();
                refetch();
              }}
              onCancel={() => {
                setSelectedRowDocumentId(null);
                hideArchiveDocumentDialog();
                handleMenuClose();
              }}
            />
          )}
        </>
      )}
    </>
  );
};
