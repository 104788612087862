import { DataTableResponse } from 'common/types/DataTableResponse';
import { ITeamDetails, ITeamRule, ITeamsGridListItem, ITeamListing } from 'types/administration/AdministrationTeamsTypes';
import {
} from 'types/administration/AdministrationUsersTypes';

export const administrationTeamsData: DataTableResponse<ITeamsGridListItem> = {
  results: [
    {
      teamId: '1',
      name: 'Mock Team 1',
      canViewAbove18metres: true,
      canViewBelow18metres: false,
      localAuthoritiesCostCentres: [
        { id: "111111111", name: 'Mock Local Auth 1'},
        { id: "222222222", name: 'Mock Local Auth 2'}
      ],
      containsAllLocalAuthoritesCostCentres: false
    },
    {
      teamId: '2',
      name: 'Mock Team 2',
      canViewAbove18metres: true,
      canViewBelow18metres: false,
      localAuthoritiesCostCentres: [
        { id: "111111111", name: 'Mock Local Auth 1'}
      ],
      containsAllLocalAuthoritesCostCentres: false
    },
    {
      teamId: '3',
      name: 'Mock Team 3',
      canViewAbove18metres: true,
      canViewBelow18metres: false,
      localAuthoritiesCostCentres: [
      ],
      containsAllLocalAuthoritesCostCentres: false
    },
    {
      teamId: '4',
      name: 'Mock Team 4',
      canViewAbove18metres: true,
      canViewBelow18metres: false,
      localAuthoritiesCostCentres: [
        { id: "111111111", name: 'Mock Local Auth 1'},
        { id: "222222222", name: 'Mock Local Auth 2'},
        { id: "333333333", name: 'Mock Local Auth 3'},
        { id: "444444444", name: 'Mock Local Auth 4'}
      ],
      containsAllLocalAuthoritesCostCentres: true
    },
    {
      teamId: '5',
      name: 'Mock Team 5',
      canViewAbove18metres: true,
      canViewBelow18metres: false,
      localAuthoritiesCostCentres: [
        { id: "111111111", name: 'Mock Local Auth 1'},
        { id: "222222222", name: 'Mock Local Auth 2'}
      ],
      containsAllLocalAuthoritesCostCentres: false
    },
  ],
  count: 5,
};

export const administrationTeamData: ITeamDetails = {
  teamId: 1,
  name: "Test Team",
  containsAllLocalAuthoritesCostCentres: false,
  canTasksBeAssignedToThisTeam: true,
  localAuthoritiesCostCentres: [
    { id: "1", name: "Cost Centre England 1" },
    { id: "4", name: "Cost Centre Wales 1" }
  ],
  rules: [
    { id: 1, ruleName: "View Cases", granted: true, description: "Can view all cases" },
    { id: 2, ruleName: "Edit Cases", granted: false, description: "Can edit case details" },
    { id: 3, ruleName: "Process Cases", granted: true, description: "Can process case details" }
  ]
};

export const administrationRules: ITeamRule[] = [
  { id: 1, ruleName: "View Cases", granted: false, description: "Can view all cases" },
  { id: 2, ruleName: "Edit Cases", granted: false, description: "Can edit case details" },
  { id: 3, ruleName: "Process Cases", granted: false, description: "Can process case details" }
];

export const administrationRulesDefault: Omit<ITeamRule, 'granted'>[] = [
  { id: 1, ruleName: "View Cases", description: "Can view all cases" },
  { id: 2, ruleName: "Edit Cases", description: "Can edit case details" },
  { id: 3, ruleName: "Process Cases", description: "Can process case details" }
];

export const administrationTeamList: ITeamListing[] = [
  {
    id: 1,
    name: 'Team 1',
    canTasksBeAssignedToThisTeam: true
  },
  {
    id: 2,
    name: 'Team 2',
    canTasksBeAssignedToThisTeam: true
  },
  {
    id: 3,
    name: 'Team 3',
    canTasksBeAssignedToThisTeam: true
  }
];