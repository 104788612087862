import { applicationApi } from 'api/application/applicationApi';
import {
  IAddPaymentRequestReferral,
  IClosingPaymentRequestChecks,
  IClosingPaymentRequestChecksDetails,
  IClosingPaymentRequestOverview,
  IGetPaymentRequestReferralsResponse,
  IPaymentRequestChecks,
  IPaymentRequestChecksDetails,
  IPaymentRequestCompleteReferralRequest,
  IPaymentRequestCosts,
  IPaymentRequestOverview,
  IPaymentRequestProjectTeam,
  IPaymentRequestReferralRequest,
  IPaymentRequestRejectForm,
  IPaymentRequestRequest,
  IPaymentRequestVariations,
  IPaymentRequestClosingChecks,
  IPaymentRequestCostEvidenceDocumentList,
  IAddPaymentRequestCostEvidenceCostReportDocument,
  IAddPaymentRequestCostEvidenceInvoiceDocument,
  IArchivePaymentRequestCostReportDocument,
  IArchivePaymentRequestInvoiceDocument,
  IPaymentRequestCostEvidenceDocumentHistoryRequest,
  IPaymentRequestCostEvidenceDocumentHistory,
} from 'pages/applicationPage/content/liveProject/sections/PaymentRequest/PaymentRequestTypes';
import { QueryTags } from 'util/ApiUtils';

export const paymentRequestEndpoints = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getPaymentRequestOverview: builder.query<
      IPaymentRequestOverview,
      IPaymentRequestRequest
    >({
      providesTags: [QueryTags.ApplicationPaymentRequestOverview],
      query: params =>
        `${params.applicationId}/paymentRequest/${params.paymentRequestId}/overview`,
      keepUnusedDataFor: 0,
    }),
    getClosingPaymentRequestOverview: builder.query<
      IClosingPaymentRequestOverview,
      IPaymentRequestRequest
    >({
      providesTags: [QueryTags.ApplicationClosingPaymentRequestOverview],
      query: params =>
        `${params.applicationId}/paymentRequest/${params.paymentRequestId}/closing/overview`,
      keepUnusedDataFor: 0,
    }),
    getClosingPaymentRequestClosingChecks: builder.query<
      IPaymentRequestClosingChecks,
      IPaymentRequestRequest
    >({
      providesTags: [QueryTags.ApplicationClosingPaymentRequestOverview],
      query: params =>
        `${params.applicationId}/paymentRequest/${params.paymentRequestId}/applicationclosingchecks`,
    }),
    getPaymentRequestChecksDetails: builder.query<
      IPaymentRequestChecksDetails,
      IPaymentRequestRequest
    >({
      providesTags: [QueryTags.ApplicationPaymentRequest],
      query: params =>
        `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/checks/details`,
    }),
    getClosingPaymentRequestChecksDetails: builder.query<
      IClosingPaymentRequestChecksDetails,
      IPaymentRequestRequest
    >({
      providesTags: [QueryTags.ApplicationPaymentRequest],
      query: params =>
        `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/closing/checks/details`,
    }),
    getPaymentRequestCosts: builder.query<
      IPaymentRequestCosts,
      IPaymentRequestRequest
    >({
      providesTags: [QueryTags.ApplicationPaymentRequest],
      query: params =>
        `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/costs`,
    }),
    editPaymentRequestCosts: builder.mutation<void, IPaymentRequestCosts>({
      invalidatesTags: [
        QueryTags.ApplicationPaymentRequest,
        QueryTags.ApplicationPaymentRequestOverview,
        QueryTags.ApplicationClosingPaymentRequestOverview,
        QueryTags.ApplicationStatus,
      ],
      query: payload => ({
        url: `/${payload.id}/paymentRequest/${payload.paymentRequestId}/costs`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getPaymentRequestProjectTeam: builder.query<
      IPaymentRequestProjectTeam[],
      string
    >({
      query: applicationId => `${applicationId}/paymentRequest/projectteam`,
    }),
    getPaymentRequestChecks: builder.query<
      IPaymentRequestChecks,
      IPaymentRequestRequest
    >({
      providesTags: [QueryTags.ApplicationPaymentRequest],
      query: params =>
        `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/checks`,
    }),
    editPaymentRequestChecks: builder.mutation<any, IPaymentRequestChecks>({
      invalidatesTags: [
        QueryTags.ApplicationPaymentRequest,
        QueryTags.ApplicationStatus,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/paymentRequest/${payload.paymentRequestId}/checks`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getClosingPaymentRequestChecks: builder.query<
      IClosingPaymentRequestChecks,
      IPaymentRequestRequest
    >({
      providesTags: [QueryTags.ApplicationPaymentRequest],
      query: params =>
        `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/closing/checks`,
    }),
    editClosingPaymentRequestChecks: builder.mutation<
      any,
      IClosingPaymentRequestChecks
    >({
      invalidatesTags: [
        QueryTags.ApplicationPaymentRequest,
        QueryTags.ApplicationStatus,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/paymentRequest/${payload.paymentRequestId}/closing/checks`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getPaymentRequestReferrals: builder.query<
      IGetPaymentRequestReferralsResponse,
      IPaymentRequestReferralRequest
    >({
      providesTags: [QueryTags.ApplicationPaymentRequest],
      query: params =>
        `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/referrals`,
    }),
    addPaymentRequestReferral: builder.mutation<
      any,
      IAddPaymentRequestReferral
    >({
      invalidatesTags: [
        QueryTags.ApplicationPaymentRequest,
        QueryTags.ApplicationStatus,
      ],
      query: payload => ({
        url: `/${payload.applicationId}/paymentRequest/${payload.paymentRequestId}/refer`,
        method: 'PUT',
        body: payload,
      }),
    }),
    completePaymentRequestReferral: builder.mutation<
      void,
      IPaymentRequestCompleteReferralRequest
    >({
      invalidatesTags: [
        QueryTags.ApplicationPaymentRequest,
        QueryTags.ApplicationStatus,
      ],
      query: params => ({
        url: `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/refer/${params.referralId}/complete`,
        method: 'PUT',
        body: { completeReason: params.completeReason },
      }),
    }),
    approvePaymentRequest: builder.mutation<void, IPaymentRequestRequest>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationLiveProjectList,
        QueryTags.ApplicationPaymentRequestOverview,
        QueryTags.ApplicationClosingPaymentRequestOverview,
        QueryTags.ApplicationPaymentRequest,
      ],
      query: params => ({
        url: `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/approve`,
        method: 'POST',
        body: { isApproved: true },
      }),
    }),
    rejectPaymentRequest: builder.mutation<void, IPaymentRequestRejectForm>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationLiveProjectList,
        QueryTags.ApplicationPaymentRequestOverview,
        QueryTags.ApplicationClosingPaymentRequestOverview,
        QueryTags.ApplicationPaymentRequest,
      ],
      query: params => ({
        url: `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/reject`,
        method: 'POST',
        body: { reason: params.reason },
      }),
    }),
    getPaymentRequestVariation: builder.query<
      IPaymentRequestVariations,
      IPaymentRequestRequest
    >({
      providesTags: [QueryTags.ApplicationPaymentRequest],
      query: params =>
        `${params.applicationId}/paymentRequest/${params.paymentRequestId}/variation`,
    }),
    recommendPaymentRequest: builder.mutation<void, IPaymentRequestRequest>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationPaymentRequestOverview,
        QueryTags.ApplicationClosingPaymentRequestOverview,
        QueryTags.ApplicationPaymentRequest,
      ],
      query: params => ({
        url: `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/recommend`,
        method: 'POST',
      }),
    }),
    unlockPaymentRequest: builder.mutation<void, IPaymentRequestRejectForm>({
      invalidatesTags: [
        QueryTags.ApplicationStatus,
        QueryTags.ApplicationLiveProjectList,
        QueryTags.ApplicationPaymentRequestOverview,
        QueryTags.ApplicationClosingPaymentRequestOverview,
        QueryTags.ApplicationPaymentRequest,
      ],
      query: params => ({
        url: `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/unlock`,
        method: 'POST',
        body: { reason: params.reason },
      }),
    }),
    GetPaymentRequestCostEvidenceDocumentList: builder.query<
      IPaymentRequestCostEvidenceDocumentList,
      IPaymentRequestRequest
    >({
      providesTags: [QueryTags.ApplicationClosingPaymentRequestOverview],
      query: params =>
        `${params.applicationId}/paymentRequest/${params.paymentRequestId}/costEvidence`,
    }),
    AddPaymentRequestCostEvidenceCostReportDocument: builder.mutation<
      void,
      IAddPaymentRequestCostEvidenceCostReportDocument
    >({
      invalidatesTags: [QueryTags.ApplicationPaymentRequest],
      query: payload => ({
        url: `/${payload.applicationId}/paymentRequest/${payload.paymentRequestId}/costEvidence/costReport`,
        method: 'POST',
        body: payload,
      }),
    }),
    AddPaymentRequestCostEvidenceInvoiceDocument: builder.mutation<
      void,
      IAddPaymentRequestCostEvidenceInvoiceDocument
    >({
      invalidatesTags: [QueryTags.ApplicationPaymentRequest],
      query: payload => ({
        url: `/${payload.applicationId}/paymentRequest/${payload.paymentRequestId}/costEvidence/invoice`,
        method: 'POST',
        body: payload,
      }),
    }),
    ArchivePaymentRequestCostReportDocument: builder.mutation<
      void,
      IArchivePaymentRequestCostReportDocument
    >({
      invalidatesTags: [QueryTags.ApplicationPaymentRequest],
      query: payload => ({
        url: `/${payload.applicationId}/paymentRequest/${payload.paymentRequestId}/costEvidence/costReport/${payload.fileId}`,
        method: 'DELETE',
      }),
    }),
    ArchivePaymentRequestInvoiceDocument: builder.mutation<
      void,
      IArchivePaymentRequestInvoiceDocument
    >({
      invalidatesTags: [QueryTags.ApplicationPaymentRequest],
      query: payload => ({
        url: `/${payload.applicationId}/paymentRequest/${payload.paymentRequestId}/costEvidence/invoice/${payload.fileId}`,
        method: 'DELETE',
      }),
    }),
    GetPaymentRequestCostEvidenceDocumentHistory: builder.query<
      IPaymentRequestCostEvidenceDocumentHistory[],
      IPaymentRequestCostEvidenceDocumentHistoryRequest
    >({
      providesTags: [QueryTags.ApplicationPaymentRequest],
      query: params =>
        `/${params.applicationId}/paymentRequest/${params.paymentRequestId}/history/${params.questionType}`,
    }),
  }),
});

export const {
  useGetPaymentRequestOverviewQuery,
  useGetClosingPaymentRequestOverviewQuery,
  useGetPaymentRequestCostsQuery,
  useEditPaymentRequestCostsMutation,
  useGetPaymentRequestChecksDetailsQuery,
  useGetClosingPaymentRequestChecksDetailsQuery,
  useGetPaymentRequestProjectTeamQuery,
  useGetPaymentRequestChecksQuery,
  useEditPaymentRequestChecksMutation,
  useGetClosingPaymentRequestChecksQuery,
  useEditClosingPaymentRequestChecksMutation,
  useGetPaymentRequestReferralsQuery,
  useAddPaymentRequestReferralMutation,
  useCompletePaymentRequestReferralMutation,
  useApprovePaymentRequestMutation,
  useRejectPaymentRequestMutation,
  useGetPaymentRequestVariationQuery,
  useRecommendPaymentRequestMutation,
  useUnlockPaymentRequestMutation,
  useGetClosingPaymentRequestClosingChecksQuery,
  useGetPaymentRequestCostEvidenceDocumentListQuery,
  useAddPaymentRequestCostEvidenceCostReportDocumentMutation,
  useAddPaymentRequestCostEvidenceInvoiceDocumentMutation,
  useArchivePaymentRequestCostReportDocumentMutation,
  useArchivePaymentRequestInvoiceDocumentMutation,
  useGetPaymentRequestCostEvidenceDocumentHistoryQuery,
} = paymentRequestEndpoints;
