import { useEffect, useState } from 'react';
import { useGetTeamListQuery } from 'api/administration/teams';
import { FilterSelect } from 'common/components/taskGrid/TasksGridFilterSelect';
import { useLocalSnackbar } from 'hooks';
import { numToStr } from 'util/AppUtils';

interface GridState {
  team: number[];
  skip: number;
}

interface TeamFilterSelectProps {
  gridState: GridState;
  gridStateChanged: (newState: Partial<GridState>) => void;
}

export const TeamFilterSelect = ({ gridState, gridStateChanged }: TeamFilterSelectProps) => {

  const { createErrorSnackbar } = useLocalSnackbar();

  const {
    data: teamsData,
    isError: teamsIsError
  } = useGetTeamListQuery();

  const [teamOptions, setTeamOptions] = useState<{ label: string, value: string }[]>([]);

  useEffect(() => {
    if (teamsData) {
      setTeamOptions(
        teamsData.map(team => ({
          label: team.name,
          value: team.id.toString(),
        }))
      );
    }
  }, [teamsData]);

  useEffect(() => {
    if (teamsIsError) {
      createErrorSnackbar('An error occurred while fetching filter Teams.');
    }
  }, [teamsIsError, createErrorSnackbar]);

  return (
    <FilterSelect
      name="team"
      label="Team"
      value={gridState.team.map(numToStr)}
      multiple
      onChange={e => {
        if (Array.isArray(e.target.value)) {
          gridStateChanged({
            team: e.target.value,
            skip: 0,
          });
        }
      }}
      options={teamOptions}
    />
  );
};
