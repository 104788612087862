export enum EPermissionGroup {
  TaskManagement = 1,
  ApplicationGeneral = 2,
  ApplicationDetails = 3,
  ApplicationTaskList = 4,
  ApplicationEligibility = 5,
  ApplicationGrantFunding = 6,
  ApplicationPayments = 7,
  ApplicationProjectSetup = 8,
  ApplicationLiveProject = 9,
  ApplicationSignatory = 10,
  ApplicationCommunications = 11,
  ApplicationDocuments = 12,
  ApplicationSupport = 13,
  ApplicationDutyOfCare = 14,
  PullinGeneral = 15,
  PullinTasklist = 16,
  PullinCommunications = 17,
  PullinDocuments = 18,
  AdminGeneral = 19,
  AdminTeamManagement = 20,
  AdminOrganisationManagement = 21,
  AdminUserManagement = 22,
  AdminRoleManagement = 23,
  AdminPTFS = 24,
  AdminSystemNotifications = 25,
  AdminAssessorPanelList = 26,
  AdminBenchmarkFigures = 27
}

export const EPermissionGroupLanguage: {
  [key in EPermissionGroup]: string;
} = {
  [EPermissionGroup.TaskManagement]: 'Task Management',
  [EPermissionGroup.ApplicationGeneral]: 'Applications - General',
  [EPermissionGroup.ApplicationDetails]: 'Applications - Details',
  [EPermissionGroup.ApplicationTaskList]: 'Applications - Task List',
  [EPermissionGroup.ApplicationEligibility]: 'Applications - Eligibility',
  [EPermissionGroup.ApplicationGrantFunding]: 'Applications - Grant Funding',
  [EPermissionGroup.ApplicationPayments]: 'Applications - Payments',
  [EPermissionGroup.ApplicationProjectSetup]: 'Applications - Project Set-up',
  [EPermissionGroup.ApplicationLiveProject]: 'Applications - Live Project',
  [EPermissionGroup.ApplicationSignatory]: 'Applications - Signatory',
  [EPermissionGroup.ApplicationCommunications]: 'Applications - Communications',
  [EPermissionGroup.ApplicationDocuments]: 'Applications - Documents',
  [EPermissionGroup.ApplicationSupport]: 'Applications - Support',
  [EPermissionGroup.ApplicationDutyOfCare]: 'Applications - Duty of Care',
  [EPermissionGroup.PullinGeneral]: 'Pull In - General',
  [EPermissionGroup.PullinTasklist]: 'Pull In - Task List',
  [EPermissionGroup.PullinCommunications]: 'Pull In - Communications',
  [EPermissionGroup.PullinDocuments]: 'Pull In - Documents',
  [EPermissionGroup.AdminGeneral]: 'Admin - General',
  [EPermissionGroup.AdminTeamManagement]: 'Admin - Team Management',
  [EPermissionGroup.AdminOrganisationManagement]: 'Admin - Organisation Management',
  [EPermissionGroup.AdminUserManagement]: 'Admin - Users',
  [EPermissionGroup.AdminRoleManagement]: 'Admin - Role Management',
  [EPermissionGroup.AdminPTFS]: 'Admin - PTFS Benchmark Figures',
  [EPermissionGroup.AdminSystemNotifications]: 'Admin - System Notifications',
  [EPermissionGroup.AdminAssessorPanelList]: 'Admin - Assessor Panel List',
  [EPermissionGroup.AdminBenchmarkFigures]: 'Admin - Benchmark Figures'
} as const;

export const EPermissionGroupOrder: EPermissionGroup[] = [
  EPermissionGroup.TaskManagement,
  EPermissionGroup.ApplicationGeneral,
  EPermissionGroup.ApplicationDetails,
  EPermissionGroup.ApplicationTaskList,
  EPermissionGroup.ApplicationEligibility,
  EPermissionGroup.ApplicationGrantFunding,
  EPermissionGroup.ApplicationPayments,
  EPermissionGroup.ApplicationProjectSetup,
  EPermissionGroup.ApplicationLiveProject,
  EPermissionGroup.ApplicationSignatory,
  EPermissionGroup.ApplicationCommunications,
  EPermissionGroup.ApplicationDocuments,
  EPermissionGroup.ApplicationSupport,
  EPermissionGroup.ApplicationDutyOfCare,
  EPermissionGroup.PullinGeneral,
  EPermissionGroup.PullinTasklist,
  EPermissionGroup.PullinCommunications,
  EPermissionGroup.PullinDocuments,
  EPermissionGroup.AdminGeneral,
  EPermissionGroup.AdminTeamManagement,
  EPermissionGroup.AdminOrganisationManagement,
  EPermissionGroup.AdminUserManagement,
  EPermissionGroup.AdminRoleManagement,
  EPermissionGroup.AdminPTFS,
  EPermissionGroup.AdminSystemNotifications,
  EPermissionGroup.AdminAssessorPanelList,
  EPermissionGroup.AdminBenchmarkFigures
];
