import { TasksGrid } from 'common/components/taskGrid';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context/pullInProcessContext';
import { PullinTasksDataGridNavigation } from 'pages/pullInProcessPage/components/pullinTaskLists/PullinTasksDataGridNavigation';
import {
  PullinTasksGridContextProvider,
  usePullinTasksGridContext,
} from 'pages/pullInProcessPage/components/pullinTaskLists/PullinTasksGridContext';

export const PullinTasksDataGrid = () => {
  return (
    <PullinTasksGridContextProvider>
      <TasksGridMain />
    </PullinTasksGridContextProvider>
  );
};

const TasksGridMain = () => {
  const { pullInProcessId } = usePullInProcessContext();
  const {
    query,
    state: {
      gridState: { take, skip, ...gridStateProps },
    },
    gridStateChanged,
  } = usePullinTasksGridContext();
  return (
    <TasksGrid
      config={{
        type: 'PullinTasks',
        query,
        navigationComponent: <PullinTasksDataGridNavigation />,
        pagination: {
          gridStateChanged,
          take,
          skip,
        },
        filters: { take, skip, ...gridStateProps },
      }}
      applicationId={pullInProcessId}
    />
  );
};
