import { ComponentProps, ReactNode, useState } from 'react';
import {
  faEllipsis,
  faExclamation,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  IconButton,
  Menu,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useTasksGridContext } from 'common/components/taskGrid/TasksGridContext';
import { ViewTaskNoteContent } from 'common/components/taskNote';
import { useCurrentUserPermissions } from 'hooks';
import {
  ECmsTaskStatus,
  ECmsTaskStatusLanguage,
  ETaskMenuType,
  IAdminTaskListItem,
  TaskListItemDefault,
} from 'types/tasks/CmsTaskTypes';
import {
  getMenuItemConfig,
  getMenuItems,
  getUkDateStringFromJsonDateString,
} from 'util/AppUtils';

// Please note, the various `minWidth: 0` or `zeroMinWidth` settings within this component are there to ensure the task description can be properly condensed down to one line when the value is longer than one line
// If you take the minWidths away then the paragraph horribly overflows the containing flex items and containers
// See here for more info on minWidth within flex: https://www.joshwcomeau.com/css/interactive-guide-to-flexbox/#the-minimum-size-gotcha-11
type TaskGridType =
  | 'CurrentUserTasks'
  | 'AdminTasks'
  | 'ApplicationTasks'
  | 'PullinTasks';

export const TaskAccordion = (props: {
  type: TaskGridType;
  task: TaskListItemDefault | IAdminTaskListItem;
  selectedRowId: string;
  ellipsisMenuAnchor: HTMLElement | null;
  handleEllipsisClick: (
    rowId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  handleMenuItemClick: (menuType: ETaskMenuType) => void;
  handleMenuClose: () => void;
}) => {
  const { type, task } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Accordion
      expanded={isExpanded}
      aria-label={`Accordion for application ${type}`}
    >
      <AccordionSummary
        id={`accordion-header-${task.taskId}`}
        sx={{
          pointerEvents: 'none',
          '& .MuiAccordionSummary-content': {
            minWidth: 0,
          },
          userSelect: 'text',
        }}
        role="region"
      >
        <Grid container alignItems="center" columnSpacing={2} wrap="nowrap">
          <Grid
            item
            flexBasis="30px"
            sx={isExpanded ? { alignSelf: 'flex-start' } : null}
          >
            <IconButton
              onClick={e => {
                setIsExpanded(isExpandedVal => !isExpandedVal);
              }}
              sx={{
                pointerEvents: 'auto',
                flexGrow: 1,
                background: 'grey.100',
                p: 1,
                m: 0,
                padding: 0,
              }}
              aria-label={isExpanded ? 'Shrink task item' : 'Open task item'}
              name={isExpanded ? 'Shrink task item' : 'Open task item'}
              aria-expanded={isExpanded ? true : false}
            >
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
          <Grid item justifyContent="space-between" flexGrow={1} zeroMinWidth>
            <Grid container columnSpacing={1}>
              <GridColumn
                label={task.taskType}
                value={`${task.taskSubtype}${
                  task.topic ? ` - ${task.topic}` : ''
                }`}
                flexBasis="30%"
                flexGrow={1}
                hideContentOverflow={!isExpanded}
              />

              {type !== 'ApplicationTasks' ? (
                <GridColumn
                  label="Reference"
                  value={task.referenceNumber}
                  flexBasis="140px"
                />
              ) : null}

              {type !== 'ApplicationTasks' &&
              type !== 'AdminTasks' &&
              type !== 'PullinTasks' ? (
                <GridColumn
                  label="Assignee"
                  value={task.assignedTo}
                  flexBasis="220px"
                />
              ) : null}

              {type !== 'CurrentUserTasks' ? (
                <GridColumn
                  label="Team"
                  value={task.assignedToTeamName}
                  flexBasis="220px"
                />
              ) : null}

              {type !== 'CurrentUserTasks' ? (
                <GridColumn
                  label="Assignee"
                  value={(task as IAdminTaskListItem).assignedTo}
                  flexBasis="220px"
                />
              ) : null}

              <GridColumn
                label="Due"
                value={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {getUkDateStringFromJsonDateString(task.dueDate)}
                    {new Date() > new Date(task.dueDate) &&
                      task.status !== ECmsTaskStatus.Done && (
                        <FontAwesomeIcon
                          icon={faExclamation}
                          size="xs"
                          fixedWidth
                          style={{
                            marginLeft: '0.3rem',
                            background: 'rgba(255, 0, 0, 0.22)',
                            borderRadius: '22px',
                            padding: '3px',
                            color: '#d32f2f',
                            width: '10px',
                            height: '10px',
                          }}
                        />
                      )}
                  </Box>
                }
                flexBasis="13%"
              />

              <GridColumn
                label="Status"
                value={
                  <Chip
                    size="small"
                    label={ECmsTaskStatusLanguage[task.status]}
                  />
                }
                flexBasis="150px"
              />

              <GridColumn
                label=""
                value={
                  <>
                    {task?.noteCount > 0 && (
                      <Chip
                        sx={{
                          marginTop: '1rem',
                          marginLeft: '0.25rem',
                          p: 0,
                        }}
                        size="small"
                        label={
                          <Box>
                            <FontAwesomeIcon
                              icon={faPen}
                              size="sm"
                              style={{ marginRight: '0.2rem' }}
                              fixedWidth
                            />
                            <strong> {task?.noteCount}</strong>
                          </Box>
                        }
                      />
                    )}
                  </>
                }
                flexBasis="75px"
              />
            </Grid>
          </Grid>
          <EllipsisButtonColumn {...props} isExpanded={isExpanded} />
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <ViewTaskNoteContent
          taskId={task.taskId}
          isDisplayed={isExpanded}
          description={task.description}
          buildingName={task.buildingName}
          applicant={task.applicant}
          createdBy={task.createdBy}
          createdDate={task.createdDate}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const GridColumn = ({
  label,
  value,
  flexBasis,
  flexGrow,
  hideContentOverflow = true,
}: {
  label: string;
  value: ReactNode;
  flexBasis: string;
  flexGrow?: number;
  hideContentOverflow?: boolean;
}) => {
  return (
    <Grid
      item
      flexBasis={flexBasis}
      flexGrow={flexGrow}
      sx={{ wordWrap: 'break-word' }}
      zeroMinWidth
    >
      <Box>
        <Typography
          sx={{ fontSize: '0.9em', fontWeight: 600, color: 'grey.800' }}
        >
          {label}
        </Typography>
      </Box>
      <Box>
        {typeof value === 'string' ? (
          <Typography
            sx={
              hideContentOverflow
                ? {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    mr: 2,
                  }
                : null
            }
          >
            {value}
          </Typography>
        ) : (
          value
        )}
      </Box>
    </Grid>
  );
};

type EllipsisButtonColumnProps = ComponentProps<typeof TaskAccordion> & {
  isExpanded: boolean;
};

const EllipsisButtonColumn = ({
  isExpanded,
  handleEllipsisClick,
  ellipsisMenuAnchor,
  task,
  selectedRowId,
  handleMenuClose,
  type,
  handleMenuItemClick,
}: EllipsisButtonColumnProps) => {
  const isEllipsisMenuOpen = Boolean(ellipsisMenuAnchor);
  const { canUserCreateTasks, canViewApplications } = useTasksGridContext();

  const menuItemArray = [
    getMenuItemConfig('Edit Task', ETaskMenuType.EditTask, canUserCreateTasks),
    getMenuItemConfig(
      'Change Status',
      ETaskMenuType.ChangeStatus,
      canUserCreateTasks
    ),
    getMenuItemConfig('Add Note', ETaskMenuType.AddNote, canUserCreateTasks),
    getMenuItemConfig(
      'Go to Application',
      ETaskMenuType.OpenApplication,
      type !== 'ApplicationTasks' && canViewApplications
    ),
  ].filter(Boolean);

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasApplicationTasklistEdit = doesUserHaveSinglePermission(
    'application.tasklist.edit'
  );
  const hasPullinprocessTaskProcess = doesUserHaveSinglePermission(
    'pullinprocess.tasklist.process'
  );
  const canProcessTasks =
    (type !== 'PullinTasks' && hasApplicationTasklistEdit) ||
    (type === 'PullinTasks' && hasPullinprocessTaskProcess);

  return (
    <Grid
      item
      flexBasis="30px"
      sx={isExpanded ? { alignSelf: 'flex-start' } : null}
    >
      {menuItemArray.length > 0 ? (
        <>
          <IconButton
            onClick={e => {
              handleEllipsisClick(task.taskId, e);
            }}
            sx={{
              pointerEvents: 'auto',
              flexGrow: 1,
              background: 'grey.100',
              p: 1,
              m: 0,
            }}
            aria-label="Task actions"
            name="Task actions"
          >
            <FontAwesomeIcon icon={faEllipsis} size="xs" fixedWidth />
          </IconButton>
          {selectedRowId === task.taskId ? (
            <Menu
              id={`task-menu-${task.taskId}`}
              anchorEl={ellipsisMenuAnchor}
              open={isEllipsisMenuOpen}
              onClose={handleMenuClose}
            >
              {getMenuItems(
                [
                  getMenuItemConfig(
                    'Edit Task',
                    ETaskMenuType.EditTask,
                    canUserCreateTasks,
                    !canProcessTasks
                  ),
                  getMenuItemConfig(
                    'Change Status',
                    ETaskMenuType.ChangeStatus,
                    canUserCreateTasks,
                    !canProcessTasks
                  ),
                  getMenuItemConfig(
                    'Add Note',
                    ETaskMenuType.AddNote,
                    canUserCreateTasks,
                    !canProcessTasks
                  ),
                  getMenuItemConfig('History', ETaskMenuType.History),
                  getMenuItemConfig(
                    'Go to Application',
                    ETaskMenuType.OpenApplication,
                    type !== 'ApplicationTasks' && canViewApplications
                  ),
                ],
                handleMenuItemClick
              )}
            </Menu>
          ) : null}
        </>
      ) : null}
    </Grid>
  );
};
