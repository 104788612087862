import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { EPullInNotEligibleReason } from 'enums/EPullInNotEligibleReason';
import {
  EPullinProcessDocumentType,
  EPullinProcessDocumentTypeLanguage,
} from 'enums/EPullinProcessDocumentTypes';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  TPostPutBuildingRecord,
  TPullInProcessOverview,
  TPullInProcessSummary,
  TPullInProcesscontacts,
  TSourceOfInformationList,
  TLocalAuthorityList,
  TAddBuildingRecord,
} from 'types/pullInProcess/addBuildingRecordTypes';
import {
  IPullinProcessCommunication,
  TCommunication,
  TCommunicationsForm,
} from 'types/pullInProcess/communicationsTypes';
import { TContactDetails } from 'types/pullInProcess/contactDetailsTypes';
import {
  TBuildingDocument,
  TBuildingDocumentForm,
} from 'types/pullInProcess/documentDetailsTypes';
import {
  GetPullInEditHistoryResponse,
  IGetPullInProcessesPaginatedQuery,
  TPullInProcessGridListItem,
  TPullInProcessKpis,
  IPullInRagRating,
  IPullInRagRatingRequest,
  IPullInRagRatings,
} from 'types/pullInProcess/pullInProcessTypes';
import { pullInProcessQueryTags } from 'util/ApiUtils';
import { handleFileDownloadFromWithinRtkQuery } from 'util/FileUtils';
import {
  customPrepareHeaders,
  getUrlSearchParamFromQueryData,
} from 'util/QueryUtils';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/pullin`;

const getPaginatedPullInProcessesString = (
  query: IGetPullInProcessesPaginatedQuery
) => {
  const queryParamsStr = getUrlSearchParamFromQueryData({
    skip: query.skip,
    take: query.take,
    sortBy: query.sortBy.toString(),
    sortDirection: query.sortDirection.toString(),
    searchValue: query.searchValue,
  });

  if (query.stage.length > 0) {
    for (const stage of query.stage) {
      queryParamsStr.append('stage', `${stage}`);
    }
  }

  if (query.statuses.length > 0) {
    for (const status of query.statuses) {
      queryParamsStr.append('statuses', `${status}`);
    }
  }

  return `${
    process.env.REACT_APP_API_URL
  }/api/pullin/grid?${queryParamsStr.toString()}`;
};

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: customPrepareHeaders,
  credentials: 'include',
});

export const pullInProcessesApi = createApi({
  reducerPath: 'pullInProcessesApi',
  baseQuery,
  tagTypes: [
    pullInProcessQueryTags.PullInProcessGrid,
    pullInProcessQueryTags.PullInProcessKPI,
    pullInProcessQueryTags.PullInProcessSourceOfInformation,
    pullInProcessQueryTags.AddBuildingRecord,
    pullInProcessQueryTags.PullInProcessSummary,
    pullInProcessQueryTags.PullInProcessOverview,
    pullInProcessQueryTags.PullInProcessContacts,
    pullInProcessQueryTags.PullInProcessContactDetails,
    pullInProcessQueryTags.AddPullInProcessContactDetails,
    pullInProcessQueryTags.UpdatePullInProcessContactDetails,
  ],
  endpoints: builder => ({
    getPaginatedPullInProcesses: builder.query<
      IPaginationResponse<TPullInProcessGridListItem>,
      IGetPullInProcessesPaginatedQuery
    >({
      providesTags: [pullInProcessQueryTags.PullInProcessGrid],
      query: query => {
        return getPaginatedPullInProcessesString(query);
      },
    }),
    getPullInProcessesKpis: builder.query<TPullInProcessKpis, void>({
      providesTags: [pullInProcessQueryTags.PullInProcessKPI],
      query: _ => 'kpis',
    }),
    getPullInProcessesSourceOfInformation: builder.query<
      TSourceOfInformationList,
      void
    >({
      providesTags: [pullInProcessQueryTags.PullInProcessKPI],
      query: _ => 'sourceofinformation',
    }),
    getPullInProcessesLocalAuthorities: builder.query<
      TLocalAuthorityList,
      void
    >({
      providesTags: [pullInProcessQueryTags.PullInProcessLocalAuthorities],
      query: _ => 'localauthorities',
    }),
    addBuildingRecord: builder.mutation<unknown, TPostPutBuildingRecord>({
      invalidatesTags: [
        pullInProcessQueryTags.PullInProcessGrid,
        pullInProcessQueryTags.PullInProcessKPI,
      ],
      query: payload => ({
        url: `/building`,
        method: 'POST',
        body: payload,
      }),
    }),

    /** OVERVIEW */

    updatePullInProcessBuildingDetails: builder.mutation<
      unknown,
      { buildingId: string } & TPostPutBuildingRecord
    >({
      invalidatesTags: [
        pullInProcessQueryTags.PullInProcessBuildingDetails,
        pullInProcessQueryTags.PullInProcessOverview,
        pullInProcessQueryTags.PullInProcessSummary,
      ],
      query: ({ buildingId, ...payload }) => ({
        url: `/building/${buildingId}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getPullInProcessBuildingDetails: builder.query<TAddBuildingRecord, string>({
      providesTags: [pullInProcessQueryTags.PullInProcessBuildingDetails],
      query: buildingId => `/building/${buildingId}`,
    }),
    getPullInProcessSummary: builder.query<TPullInProcessSummary, string>({
      providesTags: [pullInProcessQueryTags.PullInProcessSummary],
      query: buildingId => `building/${buildingId}/summary`,
    }),
    getPullInProcessOverview: builder.query<TPullInProcessOverview, string>({
      providesTags: [pullInProcessQueryTags.PullInProcessOverview],
      query: buildingId => `building/${buildingId}/overview`,
    }),

    GetPullInBuildingResponsiveFlag: builder.query<
      { isResponsive: boolean },
      string
    >({
      providesTags: [pullInProcessQueryTags.PullInProcessBuildingDetails],
      query: buildingId => `/building/${buildingId}/responsive`,
    }),

    updatePullInBuildingResponsiveFlag: builder.mutation<
      void,
      { buildingId: string; comment: string }
    >({
      invalidatesTags: [pullInProcessQueryTags.PullInProcessBuildingDetails],
      query: ({ buildingId, comment }) => ({
        url: `/building/${buildingId}/responsive`,
        method: 'POST',
        body: { comment },
      }),
    }),

    getRagRatings: builder.query<Array<IPullInRagRatings>, void>({
      query: () => `${process.env.REACT_APP_API_URL}/api/ragRatings`,
    }),
    getPullInRagRating: builder.query<IPullInRagRating, unknown>({
      providesTags: [pullInProcessQueryTags.PullInProcessBuildingDetails],
      query: buildingId => `/building/${buildingId}/ragRating`,
    }),
    updatePullInRagRating: builder.mutation<void, IPullInRagRatingRequest>({
      invalidatesTags: [pullInProcessQueryTags.PullInProcessBuildingDetails],
      query: payload => ({
        url: `/building/${payload.buildingId}/ragRating`,
        method: 'PUT',
        body: { ...payload },
      }),
    }),
    getPullInEditHistory: builder.query<
    GetPullInEditHistoryResponse,
      { buildingId: string; historyType: number }
    >({
      providesTags: [pullInProcessQueryTags.PullInProcessBuildingDetails],
      query: ({ buildingId, historyType }) => `/building/${buildingId}/edit/history/${historyType}`,
    }),
    

    /** STATUSES */

    updateBuildingToApplication: builder.mutation<
      unknown,
      { buildingId: string; applicationRef: string }
    >({
      invalidatesTags: [
        pullInProcessQueryTags.PullInProcessBuildingDetails,
        pullInProcessQueryTags.PullInProcessOverview,
        pullInProcessQueryTags.PullInProcessSummary,
      ],
      query: ({ buildingId, applicationRef }) => ({
        url: `/building/${buildingId}/application`,
        method: 'PUT',
        body: { applicationRef },
      }),
    }),
    updateBuildingToNotEligible: builder.mutation<
      unknown,
      {
        buildingId: string;
        notEligibleReasonId: EPullInNotEligibleReason;
        description: string;
      }
    >({
      invalidatesTags: [
        pullInProcessQueryTags.PullInProcessBuildingDetails,
        pullInProcessQueryTags.PullInProcessOverview,
        pullInProcessQueryTags.PullInProcessSummary,
      ],
      query: ({ buildingId, ...payload }) => ({
        url: `/building/${buildingId}/eligibility`,
        method: 'PUT',
        body: payload,
      }),
    }),
    updateBuildingToDuplicate: builder.mutation<
      unknown,
      {
        buildingId: string;
        reason: string;
      }
    >({
      invalidatesTags: [
        pullInProcessQueryTags.PullInProcessBuildingDetails,
        pullInProcessQueryTags.PullInProcessOverview,
        pullInProcessQueryTags.PullInProcessSummary,
      ],
      query: ({ buildingId, ...payload }) => ({
        url: `/building/${buildingId}/duplicate`,
        method: 'PUT',
        body: payload,
      }),
    }),

    /** CONTACTS */

    getPullInProcessContacts: builder.query<TPullInProcesscontacts, string>({
      providesTags: [pullInProcessQueryTags.PullInProcessContacts],
      query: buildingId => `building/${buildingId}/contact`,
    }),
    getPullInProcessContactDetails: builder.query<
      TContactDetails,
      { buildingId: string; contactDetailsId: string }
    >({
      providesTags: [pullInProcessQueryTags.PullInProcessContactDetails],
      query: ({ buildingId, contactDetailsId }) =>
        `/building/${buildingId}/contact/${contactDetailsId}`,
    }),
    addPullInProcessContactDetails: builder.mutation<
      unknown,
      { buildingId: string } & TContactDetails
    >({
      invalidatesTags: [
        pullInProcessQueryTags.PullInProcessBuildingDetails,
        pullInProcessQueryTags.PullInProcessOverview,
        pullInProcessQueryTags.PullInProcessSummary,
        pullInProcessQueryTags.PullInProcessContacts,
      ],
      query: ({ buildingId, ...payload }) => ({
        url: `/building/${buildingId}/contact`,
        method: 'POST',
        body: payload,
      }),
    }),
    updatePullInProcessContactDetails: builder.mutation<
      unknown,
      { buildingId: string; contactDetailsId: string } & TContactDetails
    >({
      invalidatesTags: [
        pullInProcessQueryTags.PullInProcessBuildingDetails,
        pullInProcessQueryTags.PullInProcessOverview,
        pullInProcessQueryTags.PullInProcessSummary,
        pullInProcessQueryTags.PullInProcessContacts,
      ],
      query: ({ buildingId, contactDetailsId, ...payload }) => ({
        url: `/building/${buildingId}/contact/${contactDetailsId}`,
        method: 'PUT',
        body: payload,
      }),
    }),

    /** COMMUNICATIONS */

    getPullInProcessCommunications: builder.query<
      IPullinProcessCommunication[],
      string
    >({
      providesTags: [pullInProcessQueryTags.PullInProcessCommunications],
      query: buildingId => `/building/${buildingId}/communication`,
    }),
    addPullInProcessCommunications: builder.mutation<
      unknown,
      { buildingId: string } & TCommunicationsForm
    >({
      invalidatesTags: [
        pullInProcessQueryTags.PullInProcessCommunications,
        pullInProcessQueryTags.PullInProcessSummary,
      ],
      query: ({ buildingId, ...payload }) => ({
        url: `/building/${buildingId}/communication`,
        method: 'POST',
        body: payload,
      }),
    }),
    updatePullInProcessCommunicationById: builder.mutation<
      unknown,
      {
        buildingId: string;
        communicationId: string;
        payload: TCommunicationsForm;
      }
    >({
      invalidatesTags: [
        pullInProcessQueryTags.PullInProcessCommunications,
        pullInProcessQueryTags.PullInProcessSummary,
      ],
      query: ({ buildingId, communicationId, payload }) => ({
        url: `/building/${buildingId}/communication/${communicationId}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getPullInProcessCommunicationById: builder.query<
      TCommunication,
      { buildingId: string; communicationId: string }
    >({
      providesTags: [pullInProcessQueryTags.PullInProcessCommunications],
      query: ({ buildingId, communicationId }) =>
        `/building/${buildingId}/communication/${communicationId}`,
    }),
    deletePullInProcessCommunicationById: builder.mutation<
      unknown,
      { buildingId: string; communicationId: string }
    >({
      invalidatesTags: [pullInProcessQueryTags.PullInProcessCommunications],
      query: ({ buildingId, communicationId }) => ({
        url: `/building/${buildingId}/communication/${communicationId}`,
        method: 'DELETE',
      }),
    }),

    /** DOCUMENTS */

    getPullInProcessDocuments: builder.query<TBuildingDocument[], string>({
      providesTags: [pullInProcessQueryTags.PullInProcessDocuments],
      query: buildingId => `/building/${buildingId}/document`,
    }),
    getPullInProcessDocumentById: builder.query<
      TBuildingDocument,
      { buildingId: string; documentId: string }
    >({
      providesTags: [pullInProcessQueryTags.PullInProcessDocuments],
      query: ({ buildingId, documentId }) =>
        `/building/${buildingId}/document/${documentId}`,
    }),
    deletePullInProcessDocumentById: builder.mutation<
      unknown,
      { buildingId: string; documentId: string }
    >({
      invalidatesTags: [pullInProcessQueryTags.PullInProcessDocuments],
      query: ({ buildingId, documentId }) => ({
        url: `/building/${buildingId}/document/${documentId}`,
        method: 'DELETE',
      }),
    }),
    updatePullInProcessDocumentById: builder.mutation<
      unknown,
      { buildingId: string; documentId: string; payload: TBuildingDocumentForm }
    >({
      invalidatesTags: [pullInProcessQueryTags.PullInProcessDocuments],
      query: ({ buildingId, documentId, payload }) => ({
        url: `/building/${buildingId}/document/${documentId}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    addPullInProcessDocuments: builder.mutation<
      unknown,
      { buildingId: string } & TBuildingDocumentForm
    >({
      invalidatesTags: [pullInProcessQueryTags.PullInProcessDocuments],
      query: ({ buildingId, ...payload }) => ({
        url: `/building/${buildingId}/document`,
        method: 'POST',
        body: payload,
      }),
    }),
    // download document endpoint
    getDownloadPullInProcessDocumentById: builder.query<
      null,
      {
        buildingId: string;
        pullInDocumentTypeId: string;
        fileName: string;
        pullInDocumentType: EPullinProcessDocumentType;
      }
    >({
      queryFn: async (
        { buildingId, pullInDocumentTypeId, fileName, pullInDocumentType },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `/building/${buildingId}/${EPullinProcessDocumentTypeLanguage[pullInDocumentType]}/${pullInDocumentTypeId}/file`,
          responseHandler: response => response.blob(),
        });

        if (result.error) {
          return { error: result.error };
        }

        return handleFileDownloadFromWithinRtkQuery(
          fileName,
          result.data as Blob
        );
      },
    }),
  }),
});

export const {
  useGetPaginatedPullInProcessesQuery,
  useGetPullInProcessesKpisQuery,
  useGetPullInProcessesSourceOfInformationQuery,
  useGetPullInProcessesLocalAuthoritiesQuery,
  useAddBuildingRecordMutation,
  useUpdatePullInProcessBuildingDetailsMutation,
  useGetPullInProcessBuildingDetailsQuery,
  useLazyGetPullInProcessBuildingDetailsQuery,
  // OVERVIEW
  useGetPullInProcessSummaryQuery,
  useGetPullInProcessOverviewQuery,
  // STATUSES
  useUpdateBuildingToApplicationMutation,
  useUpdateBuildingToDuplicateMutation,
  useUpdateBuildingToNotEligibleMutation,
  // DOCUMENTS
  useGetPullInProcessDocumentsQuery,
  useAddPullInProcessDocumentsMutation,
  useDeletePullInProcessDocumentByIdMutation,
  useUpdatePullInProcessDocumentByIdMutation,
  useLazyGetPullInProcessDocumentByIdQuery,
  // CONTACTS
  useLazyGetPullInProcessContactDetailsQuery,
  useAddPullInProcessContactDetailsMutation,
  useUpdatePullInProcessContactDetailsMutation,
  useGetPullInProcessContactsQuery,
  // COMMUNICATIONS
  useGetPullInProcessCommunicationsQuery,
  useAddPullInProcessCommunicationsMutation,
  useUpdatePullInProcessCommunicationByIdMutation,
  useDeletePullInProcessCommunicationByIdMutation,
  useLazyGetPullInProcessCommunicationByIdQuery,
  // DOWNLOAD DOCUMENT ENDPOINT
  useLazyGetDownloadPullInProcessDocumentByIdQuery,
  // Unresponsive flag
  useGetPullInBuildingResponsiveFlagQuery,
  useUpdatePullInBuildingResponsiveFlagMutation,
  // Rag rating
  useGetRagRatingsQuery,
  useGetPullInRagRatingQuery,
  useUpdatePullInRagRatingMutation,
  useGetPullInEditHistoryQuery,
} = pullInProcessesApi;
