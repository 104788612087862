import { administrationApi } from 'api/administration';
import { IPaginationResponse } from 'types/PaginationResponse';
import {
  IGetTeamsListQuery,
  ITeamDetails,
  ITeamRules,
  ITeamsGridListItem,
  ITeamListing
} from 'types/administration/AdministrationTeamsTypes';
import { QueryTags } from 'util/ApiUtils';
import { getUrlSearchParamFromQueryData } from 'util/QueryUtils';

const getPaginatedTeamsQueryString = (query: IGetTeamsListQuery) => {
  const queryParamsStr = getUrlSearchParamFromQueryData({
    skip: query.skip,
    take: query.take,
    sortBy: query.sortBy.toString(),
    sortDirection: query.sortDirection.toString(),
    searchValue: query.searchValue,
  });

  return `/teams?${queryParamsStr.toString()}`;
};

export const teamsEndpoints = administrationApi.injectEndpoints({
  endpoints: builder => ({
    getPaginatedTeamsList: builder.query<
      IPaginationResponse<ITeamsGridListItem>,
      IGetTeamsListQuery
    >({
      providesTags: [QueryTags.Teams],
      query: query => {
        return getPaginatedTeamsQueryString(query);
      },
    }),
    getTeam: builder.query<ITeamDetails, string>({
      providesTags: [QueryTags.Team],
      query: query => `/team/${query}`,
    }),
    addTeam: builder.mutation<void, ITeamDetails>({
      invalidatesTags: [QueryTags.Team, QueryTags.Teams, QueryTags.Teamlist],
      query: payload => ({
        url: `/team`,
        method: 'POST',
        body: payload,
      }),
    }),
    editTeam: builder.mutation<void, ITeamDetails>({
      invalidatesTags: [QueryTags.Team, QueryTags.Teams, QueryTags.Teamlist],
      query: payload => ({
        url: `/team/${payload.teamId}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getTeamRules: builder.query<ITeamRules, void>({
      providesTags: [QueryTags.Teamrules],
      query: () => '/teamrules',
    }),
    deleteTeam: builder.mutation<void, string>({
      invalidatesTags: [QueryTags.Teams, QueryTags.Teamlist],
      query: payload => ({
        url: `/team/${payload}`,
        method: 'DELETE',
        body: payload,
      }),
    }),
    getTeamList: builder.query<ITeamListing[], void>({
      providesTags: [QueryTags.Teamlist],
      query: () => `${process.env.REACT_APP_API_URL}/api/teams`,
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetPaginatedTeamsListQuery,
  useGetTeamQuery,
  useLazyGetTeamQuery,
  useAddTeamMutation,
  useEditTeamMutation,
  useGetTeamRulesQuery,
  useLazyGetTeamRulesQuery,
  useDeleteTeamMutation,
  useGetTeamListQuery
} = teamsEndpoints;
