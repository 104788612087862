import { Box, Divider, Grid, Typography } from '@mui/material';
import { useGetProjectTeamQuery } from 'api/application/worksPackageApi';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  ProjectTeamAccordionInfo,
  ProjectTeamValue,
  RegulatoryComplianceCard,
  ProjectPlanCard,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ProjectTeamSection';
import { GrantCertifyingOfficerAuthorisedSignatoriesCard } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/ProjectTeamSection/GrantCertifyingOfficerAuthorisedSignatoriesCard';

export const ProjectTeamSection = () => {
  const { applicationId } = useApplicationContext();
  const { isSuccess, isLoading, data } = useGetProjectTeamQuery(applicationId);

  return (
    <>
      <RoundBorderBox>
        <Box
          bgcolor={'grey.100'}
          sx={{ padding: 2, borderBottom: '1px solid lightgray' }}
        >
          <Typography variant="h3" fontWeight={800} fontSize="1.3em">
            Project team
          </Typography>
        </Box>

        {isLoading ? <FormSkeleton sx={{ m: 2 }} /> : null}

        {isSuccess ? (
          <Box>
            <Grid
              container
              alignItems="center"
              columnSpacing={2}
              padding={2}
              wrap="nowrap"
            >
              <Grid item flexBasis={'48px'} />
              <Grid item xs={3}>
                <ProjectTeamValue>Role</ProjectTeamValue>
              </Grid>
              <Grid item xs={3}>
                <ProjectTeamValue>Name</ProjectTeamValue>
              </Grid>
              <Grid item xs={3}>
                <ProjectTeamValue>Company</ProjectTeamValue>
              </Grid>
              <Grid item xs={3}>
                <ProjectTeamValue>Company Registration</ProjectTeamValue>
              </Grid>
            </Grid>
            <Divider />
            <ProjectTeamAccordionInfo data={data} />
          </Box>
        ) : null}
      </RoundBorderBox>

      <GrantCertifyingOfficerAuthorisedSignatoriesCard />

      <RegulatoryComplianceCard />

      <ProjectPlanCard />
    </>
  );
};
