export const holdReasonsData = [
  {
    id: 1,
    name: 'FRAEW audit Fail',
    isOther: false,
  },
  {
    id: 2,
    name: 'FRAEW summary mismatch',
    isOther: false,
  },
  {
    id: 3,
    name: 'Pending LR Title applications',
    isOther: false,
  },
  {
    id: 4,
    name: 'Lease investigation',
    isOther: false,
  },
  {
    id: 5,
    name: 'Legal advice being sought',
    isOther: false,
  },
  {
    id: 6,
    name: 'Company status not active',
    isOther: false,
  },
  {
    id: 7,
    name: 'Signatory clarification required',
    isOther: false,
  },
  {
    id: 8,
    name: 'Application info missing',
    isOther: false,  
  },
  {
    id: 9,
    name: 'Bank details query',
    isOther: false,
  },
  {
    id: 10,
    name: 'Change to Representative',
    isOther: false,
  },
  {
    id: 11,
    name: 'Signatories update',
    isOther: false,
  },
  {
    id: 12,
    name: 'Pending Policy Decision',
    isOther: false,
  },
  {
    id: 13,
    name: 'Complaint Received',
    isOther: false,
  },
  {
    id: 14,
    name: 'Other',
    isOther: true,
  },
];