import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useGetPullInProcessOverviewQuery } from 'api/pullInProcess';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { EPullInBuildingHeightTypeLanguage } from 'enums/EPullInBuildingHeightType';
import { useCurrentUserPermissions } from 'hooks/authHooks/useCurrentUserPermissions';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import { stringAppendWithValueOrEmpty } from 'util/stringAppendWithValueOrEmpty';

export function OverviewSummaryPanel({
  onEditClick,
}: {
  onEditClick: () => void;
}) {
  const { pullInProcessId } = usePullInProcessContext();
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasPullinprocessBuildingdetailsProcess = doesUserHaveSinglePermission(
    'pullinprocess.buildingdetails.process'
  );
  const { isLoading, data, isSuccess } =
    useGetPullInProcessOverviewQuery(pullInProcessId);
  if (isLoading) return <div>loading data</div>;
  if (!isSuccess) return <div>Couldn't resolve api request.</div>;
  return (
    <RoundBorderBox>
      <Grid
        container
        sx={{
          width: 'inherit',
        }}
      >
        <Grid
          item
          xs={12}
          bgcolor="#f5f5f5"
          p={4}
          borderBottom="1px solid lightgrey"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography component="h2" variant="h2" fontWeight="600" m={0}>
              Building Details
            </Typography>

            <Button
              variant="outlined"
              onClick={onEditClick}
              disabled={!hasPullinprocessBuildingdetailsProcess}
            >
              Edit
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} p={4}>
          <Box
            display="flex"
            justifyContent="space-between"
            p="0.5rem 4rem 0.5rem 0"
          >
            <Typography fontWeight={600}>Building address</Typography>
            <Box display="flex" flexDirection="column">
              <Typography>
                {stringAppendWithValueOrEmpty(data.address.buildingNameNumber)}
              </Typography>
              <Typography>
                {stringAppendWithValueOrEmpty(data.address.line1)}
              </Typography>
              <Typography>
                {stringAppendWithValueOrEmpty(data.address.line2)}
              </Typography>
              <Typography>
                {stringAppendWithValueOrEmpty(data.address.townCity)}
              </Typography>
              <Typography>
                {stringAppendWithValueOrEmpty(data.address.country?.name)}
              </Typography>
              <Typography>
                {stringAppendWithValueOrEmpty(data.address.postCode)}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} p={4}>
          <Divider sx={{ mb: 2 }} />
          <Box
            display="flex"
            justifyContent="space-between"
            p="0.5rem 4rem 0.5rem 0"
          >
            <Typography fontWeight={600}>Local Authority</Typography>
            <Box display="flex" flexDirection="column">
              <Typography>{data.address?.localAuthority ?? ''}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} p={4}>
          <Divider sx={{ mb: 2 }} />
          <Box
            display="flex"
            justifyContent="space-between"
            p="0.5rem 4rem 0.5rem 0"
          >
            <Typography fontWeight={600}>Source of Information</Typography>
            <Box display="flex" flexDirection="column">
              <Typography>{data.sourceOfInformation?.name ?? ''}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} p={4}>
          <Divider sx={{ mb: 2 }} />
          <Box
            display="flex"
            justifyContent="space-between"
            p="0.5rem 4rem 0.5rem 0"
          >
            <Typography fontWeight={600}>Building height</Typography>
            <Box display="flex" flexDirection="column">
              <Typography>
                {EPullInBuildingHeightTypeLanguage[data.buildingHeight]}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </RoundBorderBox>
  );
}