import { Box, Button, Grid, Typography } from '@mui/material';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { FormSkeleton } from 'common/components/skeletons';
import {
  useCurrentUserPermissions,
  useLocalSnackbar,
  useModalState,
} from 'hooks';
import { ColumnContainer } from 'pages/applicationPage/common/components';
import {
  ESupportTicketDetailType,
  ESupportTicketStatus,
} from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { useApplicationSupportContext } from 'pages/applicationPage/content/support/context';
import {
  SupportOverviewBox,
  SupportTicketDetailItem,
  SupportTicketDetails,
  SupportTicketItem,
} from 'pages/applicationPage/content/support/sections';
import {
  DataTitle,
  useReferSupportRequestModal,
  useResolveSupportRequestModal,
} from 'pages/applicationPage/content/support/sections/SupportTicketSection/common';
import { ReferToRemediationAdviserDrawer } from 'pages/applicationPage/content/support/sections/SupportTicketSection/common/ReferToRemediationAdviserDrawer';
import { useGetSupportTicketInfo } from 'pages/applicationPage/content/support/supportHooks';

export const SupportTicketSection = () => {
  const { selectedItemId } = useApplicationSupportContext();
  const { createErrorSnackbar } = useLocalSnackbar();
  const {
    query: { isError, error, data, isLoading },
  } = useGetSupportTicketInfo();

  const {
    isShowing: isReferToRemediationAdviserDrawerShowing,
    showModal: showReferToRemediationAdviserDrawer,
    hideModal: hideReferToRemediationAdviserDrawer,
  } = useModalState();

  const { showReferModal, renderReferModal } = useReferSupportRequestModal();

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasSupportReferralsProcess = doesUserHaveSinglePermission(
    'support.referrals.process'
  );
  const hasSupportReferralsRefer = doesUserHaveSinglePermission(
    'support.referrals.refer'
  );

  const handleShowReferModal = () => {
    if (!selectedItemId) {
      createErrorSnackbar('Could not get support ticket id');
      return;
    }

    showReferModal({
      config: {
        type: 'existingTicket',
        supportTicketId: selectedItemId,
      },
    });
  };

  const { showResolveModal, renderResolveModal } =
    useResolveSupportRequestModal();

  const handleShowResolveModal = () => {
    if (!selectedItemId) {
      createErrorSnackbar('Could not get support ticket id');
      return;
    }

    showResolveModal({
      config: { type: 'existingTicket', supportTicketId: selectedItemId },
    });
  };

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isError || !data) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  const showReferButton = [ESupportTicketStatus.ToDo].includes(data.status);

  const isReferredToHomesEngland = [
    ESupportTicketStatus.ReferredToHomesEngland,
  ].includes(data.status);

  const isReferredToThirdParty = [
    ESupportTicketStatus.AppointedRemediationAdviser,
  ].includes(data.status);

  const showResolveButton = [
    ESupportTicketStatus.ToDo,
    ESupportTicketStatus.AppointedRemediationAdviser,
    ESupportTicketStatus.ReferredToHomesEngland,
  ].includes(data.status);

  const showTicketActions =
    showReferButton || isReferredToHomesEngland || showResolveButton;

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={4}>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <SupportOverviewBox />
          </ColumnContainer>
          <ColumnContainer sx={{ marginBottom: '10px' }}>
            <SupportTicketDetails />
          </ColumnContainer>
        </Grid>
        <Grid item xs={8}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            mb={2}
          >
            <Typography variant="h2" fontWeight={900} fontSize={'1.3em'}>
              Support Communication
            </Typography>
          </Box>

          {showTicketActions ? (
            <Box
              sx={{
                p: 3,
                mb: 1,
                backgroundColor: 'grey.100',
                borderLeft: '1px solid black',
              }}
            >
              <DataTitle title="Choose an action" />
              <Box display={'flex'} gap={1}>
                {showReferButton ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleShowReferModal}
                    disabled={!hasSupportReferralsRefer}
                  >
                    Refer request
                  </Button>
                ) : null}

                {isReferredToHomesEngland &&
                  (data.raisedByHomesEngland ? (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={showReferToRemediationAdviserDrawer}
                      disabled={!hasSupportReferralsProcess}
                    >
                      Refer to Third Party
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={showReferToRemediationAdviserDrawer}
                      disabled={!hasSupportReferralsProcess}
                    >
                      Refer to Remediation Adviser
                    </Button>
                  ))}

                {showResolveButton ? (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleShowResolveModal}
                    disabled={
                      ((isReferredToHomesEngland || isReferredToThirdParty) && !hasSupportReferralsProcess) ||
                      (!isReferredToHomesEngland && !isReferredToThirdParty && !hasSupportReferralsRefer)
                    }
                  >
                    Resolve support
                  </Button>
                ) : null}
              </Box>
            </Box>
          ) : null}

          {data?.details.map(detailItem => {
            return (
              <ColumnContainer
                key={detailItem.timestamp.toString()}
                sx={{ marginBottom: '10px' }}
              >
                {detailItem.supportDetailType ===
                ESupportTicketDetailType.Ticket ? (
                  <SupportTicketItem
                    supportDetailItem={detailItem}
                    data={data}
                    user={detailItem.user}
                    timestamp={detailItem.timestamp}
                  />
                ) : (
                  <SupportTicketDetailItem supportDetailItem={detailItem} />
                )}
              </ColumnContainer>
            );
          })}
        </Grid>
      </Grid>

      {isReferToRemediationAdviserDrawerShowing && (
        <ReferToRemediationAdviserDrawer
          editMode={false}
          isShowingRemediationAdviserDrawer={
            isReferToRemediationAdviserDrawerShowing
          }
          hideRemediationAdviserDrawer={hideReferToRemediationAdviserDrawer}
        />
      )}

      {showReferButton ? renderReferModal() : null}
      {showResolveButton ? renderResolveModal() : null}
    </>
  );
};
