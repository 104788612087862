import { Box, Divider, Grid, Typography } from '@mui/material';
import { useGetVendorPaymentDetailsQuery } from 'api/application/paymentApi';
import { ErrorAlert } from 'common/components/alerts';
import { BankDetails } from 'common/components/bankDetails';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { EQuestionType } from 'enums/EQuestionType';
import { useCurrentUserPermissions } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ApplicationDetailsAnswerEditedIndicator } from 'pages/applicationPage/content/applicationDetails/ApplicationDetailsAnswerEditedIndicator';
import { ApplicationInfoPanelContextMenu } from 'pages/applicationPage/content/applicationDetails/components/ApplicationInfoPanelContextMenu';
import { IVendorPaymentMain } from 'pages/applicationPage/content/payments/ApplicationPaymentTypes';
import { extractErrorMessages } from 'util/ApiUtils';

const keys = [
  'supplierName',
  'supplierNumber',
  'supplierAddress',
  'contactTelephoneNumber',
  'vatRegNumber',
  'companyRegNumber',
  'email',
] as const;

type StackedDataKey = (typeof keys)[number];
type StackedData = {
  title: string;
  value: string;
  questionType?: EQuestionType;
};

export const PaymentOverviewSection = () => {
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasApplicationsBankdetailsView = doesUserHaveSinglePermission(
    'applications.bank-details.view'
  );
  
  return (
    <RoundBorderBox sx={{ p: 2 }}>
      <Box mb={2}>
        <Typography variant="h3" fontWeight={900} fontSize={'1.05em'}>
          Payment Details
        </Typography>
      </Box>
      <PaymentOverviewSectionContent />
      {hasApplicationsBankdetailsView && <BankDetails />}
    </RoundBorderBox>
  );
};

const PaymentOverviewSectionContent = () => {
  const { applicationId } = useApplicationContext();
  const { isLoading, isError, error, isSuccess, data } =
    useGetVendorPaymentDetailsQuery(applicationId);

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isError) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (isSuccess) {
    return <PaymentOverviewSectionData data={data.vendor} />;
  }

  return null;
};

export const PaymentOverviewSectionData = ({
  data,
}: {
  data: IVendorPaymentMain['vendor'];
}) => {
  const stackedData: Record<StackedDataKey, StackedData> = {
    supplierName: {
      title: 'Supplier Name',
      value: data.name,
    },
    supplierNumber: {
      title: 'Supplier Number',
      value: data.reference,
    },
    supplierAddress: {
      title: 'Supplier Address',
      value: data.address.join(', '),
    },
    contactTelephoneNumber: {
      title: 'Contact Telephone Number',
      value: data.telephoneNumber,
    },
    vatRegNumber: {
      title: 'VAT Registration Number',
      value: data.vatRegistrationNumber,
      questionType: EQuestionType.VatNumber,
    },
    companyRegNumber: {
      title: 'Company Registration Number',
      value: data.companyRegistrationNumber,
    },
    email: {
      title: 'Email address for remittance',
      value: data.email,
    },
  };

  return (
    <>
      {keys.map(stackedDataKey => {
        return (
          <PaymentDetailsSectionStacked
            key={stackedDataKey}
            {...stackedData[stackedDataKey]}
          />
        );
      })}
    </>
  );
};

const PaymentDetailsSectionStacked = ({
  title,
  value,
  questionType,
}: {
  title: string;
  value: string;
  questionType?: EQuestionType; //If specified question is editable
}) => {
  return (
    <>
      <Box>
        {questionType ? (
          <Grid container gap={1} justifyContent={'space-between'}>
            <Grid item>
              <DataTitle title={title} />
            </Grid>
            <Grid item gap={1}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px',
                }}
              >
                <ApplicationDetailsAnswerEditedIndicator
                  questionType={questionType}
                />
                <ApplicationInfoPanelContextMenu
                  questionType={questionType}
                  currentValue={value}
                />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <DataTitle title={title} />
        )}
      </Box>
      <Box>
        <Typography variant="body1" fontWeight={600}>
          {value}
        </Typography>
      </Box>
      <Divider sx={{ my: 1.5 }} />
    </>
  );
};

const DataTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      variant="body1"
      color="grey.700"
      fontWeight={'600'}
      fontSize={'0.95em'}
    >
      {title}
    </Typography>
  );
};
