import { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useEditFRAEWChecksMutation,
  useGetFRAEWChecksQuery,
} from 'api/application';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { LeavePageGuard } from 'common/components/leavePageGuard';
import { Loading } from 'common/components/loading';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { useLocalSnackbar } from 'hooks';
import { FRAEWAssurance } from 'pages/applicationPage/content/applicationEligibility/components/FRAEWChecks/FRAEWAssurance';
import { FRAEWBuildingHeight } from 'pages/applicationPage/content/applicationEligibility/components/FRAEWChecks/FRAEWBuildingHeight';
import { FRAEWLifeSafetyRiskStatus } from 'pages/applicationPage/content/applicationEligibility/components/FRAEWChecks/FRAEWLifeSafetyRiskStatus';
import { FRAEWReportSummary } from 'pages/applicationPage/content/applicationEligibility/components/FRAEWChecks/FRAEWReportSummary';
import { ReferApplicationButton } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplication';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import {
  IApplicationEligibilityReferral,
  IApplicationFRAEWChecks,
} from 'types/applications/ApplicationEligibilityTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { setServerSideFormErrors } from 'util/formUtils';

export const FRAEWChecksForm = () => {
  const { applicationId } = useApplicationEligibilityContext();
  const query = useGetFRAEWChecksQuery(applicationId);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData: IApplicationFRAEWChecks = {
      ...data,
      assuranceEvidenceDocumentFileId:
        data?.assuranceEvidenceDocument?.id ?? '',
      buildingHeightDocumentFileId: data?.buildingHeightDocument?.id ?? '',
    };

    return <FRAEWChecksFormMain initialData={initialData} />;
  }

  return null;
};

const FRAEWChecksFormMain = ({
  initialData,
}: {
  initialData: IApplicationFRAEWChecks;
}) => {
  const { applicationId, readOnly, handleAutoShowReferApplicationDialog } =
    useApplicationEligibilityContext();

  const { referrals } = useApplicationEligibilityContext();

  const [editFRAEWChecks, editFRAEWChecksStatus] = useEditFRAEWChecksMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationFRAEWChecks>({ defaultValues: initialData });
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty, isSubmitting },
    watch,
  } = form;

  const [data] = useState<IApplicationFRAEWChecks | undefined>(initialData);
  const [
    assuranceEvidenceDocumentFileInvalid,
    setAssuranceEvidenceDocumentFileInvalid,
  ] = useState<boolean>(false);

  const [, setBuildingHeightDocumentFileInvalid] = useState<boolean>(false);

  const watchOffPanelFRAEWReport = watch('offPanelFRAEWReport');

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Eligibility,
  });

  useEffect(() => {
    referrals
      .filter(item => item.section === EEligibilitySection.Fraew)
      .forEach((referral: IApplicationEligibilityReferral) => {
        form.setValue(`referrals.${referral.question}.reason`, referral.reason);
      });
  }, [form, referrals]);

  const validForm = (formData: IApplicationFRAEWChecks) => {
    let valid = true;
    setAssuranceEvidenceDocumentFileInvalid(false);
    setBuildingHeightDocumentFileInvalid(false);

    if (
      formData.offPanelFRAEWReport &&
      formData.assuranceEvidenceAvailable === true &&
      (formData.assuranceEvidenceDocumentFileId === undefined ||
        formData.assuranceEvidenceDocumentFileId === '')
    ) {
      setAssuranceEvidenceDocumentFileInvalid(true);
      valid = false;
    }

    if (
      formData.validReasonForIncorrectBuildingHeight &&
      (formData.buildingHeightDocumentFileId === undefined ||
        formData.buildingHeightDocumentFileId === '')
    ) {
      setBuildingHeightDocumentFileInvalid(true);
      valid = false;
    }

    if (!valid) {
      createWarningSnackbar(
        'Please correct any form validation errors shown, and then try again.'
      );
    }

    return valid;
  };

  const getFraewReferrals = (formData: IApplicationFRAEWChecks) => {
    const frReferrals = referrals.filter(
      item => item.section === EEligibilitySection.Fraew
    );

    frReferrals.forEach((referral: IApplicationEligibilityReferral) => {
      let referralReason = formData.referrals?.[referral.question];
      if (referralReason) {
        referral.reason = referralReason.reason;
      }
    });

    return frReferrals;
  };

  const onSubmit = async (rejectTriggered: boolean) => {
    const formData = getValues();
    const fraewReferrals = getFraewReferrals(formData);
    if (fraewReferrals.length > 0 || validForm(formData)) {
      try {
        formData.id = applicationId;
        formData.referrals = fraewReferrals;
        formData.rejectTriggered = rejectTriggered;
        await editFRAEWChecks(formData as IApplicationFRAEWChecks)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`FRAEW Checks updated successfully`);
            reset({}, { keepValues: true });
            handleAutoShowReferApplicationDialog();
          })
          .catch(error => {
            if (error?.data?.generalError?.errorMessage) {
              createErrorSnackbar(error.data.generalError.errorMessage);
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to edit FRAEW checks`);
      }
    }
  };

  return (
    <>
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the FRAEW Checks will be lost."
        />

        {isOnHoldForThisType && (
          <OnHoldBanner holdType={EOnHoldType.Eligibility} />
        )}

        <form onSubmit={handleSubmit(() => onSubmit(false))}>
          <AlertForQueryErrorOrNull
            isError={editFRAEWChecksStatus.isError}
            error={editFRAEWChecksStatus.error}
          />

          <AlertForChangedData isDirty={isDirty} />

          <Grid container spacing={1}>
            <Grid xs={12} item>
              <Box p={3}>
                {watchOffPanelFRAEWReport && (
                  <>
                    <Grid xs={12} item>
                      <FRAEWAssurance
                        data={data}
                        readOnly={readOnly || isOnHoldForThisType}
                        assuranceEvidenceDocumentFileInvalid={
                          assuranceEvidenceDocumentFileInvalid
                        }
                      />
                    </Grid>
                    <hr />
                  </>
                )}
                <Grid xs={12} item>
                  <FRAEWReportSummary
                    data={data}
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                </Grid>
                <hr />
                <Grid xs={12} item>
                  <FRAEWBuildingHeight
                    data={data}
                    readOnly={readOnly || isOnHoldForThisType}
                    onSubmit={onSubmit}
                  />
                </Grid>
                <hr />
                <Grid xs={12} item>
                  <FRAEWLifeSafetyRiskStatus
                    readOnly={readOnly || isOnHoldForThisType}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {!readOnly && (
            <Grid container spacing={1}>
              <Grid xs={12} item>
                <Box>
                  <Grid xs={12} item>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      p={3}
                      bgcolor="grey.100"
                    >
                      <ReferApplicationButton
                        sectionType={EEligibilitySection.Fraew}
                        disabled={isOnHoldForThisType}
                      />
                      <OnOffHold holdType={EOnHoldType.Eligibility} />
                      <Box gap={2} display="flex">
                        <Button
                          data-automation-id="FRAEWChecks_Cancel"
                          variant="outlined"
                          disabled={isOnHoldForThisType}
                          onClick={() => {
                            reset();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          data-automation-id="FRAEWChecks_Confirm"
                          variant="contained"
                          type="submit"
                          disabled={
                            editFRAEWChecksStatus.isLoading ||
                            isOnHoldForThisType
                          }
                        >
                          Confirm Details
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
        </form>
      </FormProvider>
    </>
  );
};
