import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Box, Grid, Stack, Typography } from '@mui/material';
import { SupportList } from '.';
import { useGetSupportRequestListQuery } from 'api/application/applicationSupportApi';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useCurrentUserPermissions, useModalState } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import { ESupportTicketStatus } from 'pages/applicationPage/content/support/ApplicationSupportTypes';
import { AddSupportTicketDrawer } from 'pages/applicationPage/content/support/sections/SupportTicketSection';
import { getGbpAmount } from 'util/AppUtils';

export const OverviewSection = () => {
  const {
    isShowing: isAddSupportTicketDrawerShowing,
    showModal: showAddSupportTicketDrawer,
    hideModal: hideAddSupportTicketDrawer,
  } = useModalState();

  const handleAddSupportClick = () => {
    showAddSupportTicketDrawer();
  };

  const { applicationId } = useApplicationContext();
  const { isLoading, isSuccess, isError, error, data } =
    useGetSupportRequestListQuery(applicationId);

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  const totalSupportCost =
    data?.reduce((sum, item) => {
      sum = sum + (item?.cost ?? 0);
      return sum;
    }, 0) ?? 0;

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={3}>
          <RoundBorderBox sx={{ p: 2 }}>
            <CardTitle title="Support Overview" />

            {isLoading ? <FormSkeleton /> : null}

            {isSuccess ? (
              <Stack gap={2}>
                <OverviewSummaryItem
                  title="Total number of requests"
                  value={data.length.toString()}
                />
                <OverviewSummaryItem
                  title="Number of open support requests"
                  value={data
                    .filter(i =>
                      [
                        ESupportTicketStatus.ToDo,
                        ESupportTicketStatus.AppointedRemediationAdviser,
                        ESupportTicketStatus.ReferredToHomesEngland,
                      ].includes(i.status)
                    )
                    .length.toString()}
                />
                <OverviewSummaryItem
                  title="Total support cost"
                  value={getGbpAmount({ value: totalSupportCost })}
                />
              </Stack>
            ) : null}
          </RoundBorderBox>
        </Grid>
        <Grid item xs={9}>
          <RoundBorderBox>
            <Header handleAddSupportClick={handleAddSupportClick} />

            {isLoading ? <FormSkeleton /> : null}

            {isSuccess ? <SupportList /> : null}
          </RoundBorderBox>
        </Grid>
      </Grid>

      {isAddSupportTicketDrawerShowing && (
        <>
          <AddSupportTicketDrawer
            onClose={() => {
              hideAddSupportTicketDrawer();
            }}
          />
        </>
      )}
    </>
  );
};

const Header = ({
  handleAddSupportClick,
}: {
  handleAddSupportClick: () => void;
}) => {
  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
  const hasSupportReferralsRefer = doesUserHaveSinglePermission(
    'support.referrals.refer'
  );

  return (
    <Box bgcolor="grey.100" p={2}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" fontSize="1.2em" fontWeight={600}>
          Support Items
        </Typography>

        <Box>
          <Button
            startIcon={<FontAwesomeIcon icon={faAdd} />}
            variant="outlined"
            size="small"
            onClick={handleAddSupportClick}
            disabled={!hasSupportReferralsRefer}
          >
            Add Support Request
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const CardTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant="h3" fontWeight={600} fontSize="1em">
      {title}
    </Typography>
  );
};

const OverviewSummaryItem = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  return (
    <Box>
      <Typography variant="body1" color={'grey.700'}>
        {title}
      </Typography>
      <Typography variant="body1" fontWeight={600}>
        {value}
      </Typography>
    </Box>
  );
};
