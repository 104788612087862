import { ComponentProps, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  SxProps,
  Typography,
  accordionClasses,
} from '@mui/material';
import { useGetWorksPackageCostsScheduleQuery } from 'api/application/worksPackageApi';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import { AlertForQueryErrorOrNull } from 'common/components/alerts';
import {
  GridItemRight,
  HeaderLabel,
  VersionAndLabel,
  getAmountFromCostsSchedule,
  getDiffBetweenPreviousAndCurrent,
} from 'common/components/costSchedule/common';
import { RoundBorderBox } from 'common/components/roundBorderBox';
import { FormSkeleton } from 'common/components/skeletons';
import { useCurrentUserPermissions } from 'hooks/authHooks/useCurrentUserPermissions';
import { useApplicationProjectPrepContext } from 'pages/applicationPage/content/projectPrep/context';
import { useWorksPackageContext } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/context';
import { EditDrawer } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/CostsScheduleSection/EditDrawer';
import {
  CostsScheduleContextProvider,
  useCostsScheduleContext,
} from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/sections/CostsScheduleSection/context';
import {
  ECostsScheduleSection,
  FieldLabels,
  ICostsSchedule,
  SectionTitles,
} from 'types/applications/ApplicationCostScheduleTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { getGbpAmount } from 'util/AppUtils';

export const CostsScheduleSection = () => {
  return (
    <CostsScheduleContextProvider>
      <CostsScheduleSectionMain />
    </CostsScheduleContextProvider>
  );
};

const CostsScheduleSectionMain = () => {
  const { applicationId } = useApplicationProjectPrepContext();
  const { isSuccess, data, isError, error, isLoading } =
    useGetWorksPackageCostsScheduleQuery(applicationId);
  const { hasPrevious } = useCostsScheduleContext();

  if (isLoading) {
    return <FormSkeleton />;
  }

  if (isError) {
    return <AlertForQueryErrorOrNull isError={isError} error={error} />;
  }

  if (isSuccess && !data.isAvailable) {
    return <Alert severity="info">Cost Schedule not available yet.</Alert>;
  }

  if (isSuccess && data.isAvailable) {
    return (
      <>
        <Stack rowGap={2}>
          <Grid container px={3}>
            <Grid item xs={hasPrevious ? 6 : 9} />
            {hasPrevious ? (
              <>
                <GridItemRight xs={2}>
                  <VersionAndLabel version={1} label={'Submitted'} />
                </GridItemRight>
                <GridItemRight xs={2}>
                  <HeaderLabel label={'Amendments'} />
                </GridItemRight>
                <GridItemRight item xs={2}>
                  <VersionAndLabel version={2} label={'Total Cost'} />
                </GridItemRight>
              </>
            ) : (
              <GridItemRight item xs={3}>
                <VersionAndLabel
                  version={
                    data.currentVersion
                      ? data.currentVersion
                      : hasPrevious
                      ? 2
                      : 1
                  }
                  label={data.isDraft ? 'Draft' : 'Submitted'}
                />
              </GridItemRight>
            )}
          </Grid>

          <Section
            section={ECostsScheduleSection.RemovalOfUnsafeCladding}
            rows={[
              {
                title:
                  FieldLabels[ECostsScheduleSection.RemovalOfUnsafeCladding]
                    .removal,
                descriptionFieldName: 'unsafeCladdingRemovalDescription',
                amountFieldName: 'unsafeCladdingRemovalAmount',
              },
            ]}
            totalAmount={data.current.unsafeCladdingTotal}
          />

          <Section
            section={ECostsScheduleSection.InstallationOfNewCladding}
            rows={[
              {
                title:
                  FieldLabels[ECostsScheduleSection.InstallationOfNewCladding]
                    .newCladding,
                descriptionFieldName: 'newCladdingDescription',
                amountFieldName: 'newCladdingAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.InstallationOfNewCladding]
                    .external,
                descriptionFieldName: 'externalWorksDescription',
                amountFieldName: 'externalWorksAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.InstallationOfNewCladding]
                    .internal,
                descriptionFieldName: 'internalWorksDescription',
                amountFieldName: 'internalWorksAmount',
              },
            ]}
            totalAmount={data.current.installationTotal}
          />

          <Section
            section={ECostsScheduleSection.Preliminaries}
            rows={[
              {
                title:
                  FieldLabels[ECostsScheduleSection.Preliminaries]
                    .mainContractor,
                descriptionFieldName: 'mainContractorPreliminariesDescription',
                amountFieldName: 'mainContractorPreliminariesAmount',
              },
              {
                title: FieldLabels[ECostsScheduleSection.Preliminaries].access,
                descriptionFieldName: 'accessDescription',
                amountFieldName: 'accessAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.Preliminaries].overheads,
                descriptionFieldName: 'mainContractorOverheadDescription',
                amountFieldName: 'mainContractorOverheadAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.Preliminaries]
                    .contingencies,
                descriptionFieldName: 'contractorContingenciesDescription',
                amountFieldName: 'contractorContingenciesAmount',
              },
            ]}
            totalAmount={data.current.preliminariesTotal}
          />

          <Section
            section={ECostsScheduleSection.OtherCosts}
            rows={[
              {
                title: FieldLabels[ECostsScheduleSection.OtherCosts].fraew,
                amountFieldName: 'fraewSurveyAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.OtherCosts].feasibilityFees,
                descriptionFieldName: 'feasibilityStageDescription',
                amountFieldName: 'feasibilityStageAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.OtherCosts].postTenderFees,
                descriptionFieldName: 'postTenderStageDescription',
                amountFieldName: 'postTenderStageAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.OtherCosts].liaisonCosts,
                descriptionFieldName: 'propertyManagerDescription',
                amountFieldName: 'propertyManagerAmount',
              },
              {
                title:
                  FieldLabels[ECostsScheduleSection.OtherCosts]
                    .irrecoverableVatCosts,
                descriptionFieldName: 'irrecoverableVatDescription',
                amountFieldName: 'irrecoverableVatAmount',
              },
            ]}
            totalAmount={data.current.otherCostsTotal}
          />

          <Section
            section={ECostsScheduleSection.InelgibleCosts}
            rows={[
              {
                title: FieldLabels[ECostsScheduleSection.InelgibleCosts].costs,
                amountFieldName: 'ineligibleAmount',
                descriptionFieldName: 'ineligibleDescription',
              },
            ]}
            totalLabel="Total ineligible cost"
            totalAmount={data.current.ineligibleAmount ?? 0}
            sx={{ backgroundColor: 'grey.200' }}
          />
        </Stack>

        <EditDrawer />
      </>
    );
  }

  return null;
};

type SectionProps = {
  section: ECostsScheduleSection;
  rows: SectionRow[];
  totalLabel?: string;
  totalAmountOriginal?: number;
  totalAmount: number;
  sx?: SxProps;
};
type SectionRow = {
  title: string;
  amountFieldName: keyof ICostsSchedule;
  descriptionFieldName?: keyof ICostsSchedule;
};

const Section = ({
  section,
  rows,
  totalLabel = 'Total eligible cost',
  totalAmount,
  sx,
}: SectionProps) => {
  const { setSectionToEdit } = useCostsScheduleContext();
  return (
    <RoundBorderBox>
      <Stack sx={{ ...sx, p: 3, pb: 1 }}>
        <Grid container mb={1}>
          <Grid item xs={9}>
            <SectionTitle title={SectionTitles[section]} />
          </Grid>
          <GridItemRight xs={3}>
            <EditButton onClick={() => setSectionToEdit(section)} />
          </GridItemRight>
        </Grid>
        {rows.map(item => (
          <CostsSchduleItemRow key={item.title} {...item} />
        ))}
      </Stack>
      <Divider />
      <Grid container sx={{ px: 3, py: 2 }}>
        <Grid item xs={9}>
          <Typography
            variant="body1"
            sx={{ color: 'grey.700', fontSize: '0.9em' }}
          >
            {totalLabel}
          </Typography>
        </Grid>
        <GridItemRight xs={3}>
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, fontSize: '0.95em' }}
          >
            {getGbpAmount({ value: totalAmount })}
          </Typography>
        </GridItemRight>
      </Grid>
    </RoundBorderBox>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant="body1" sx={{ fontSize: '0.95em', fontWeight: 600 }}>
      {title}
    </Typography>
  );
};

const EditButton = ({
  onClick,
}: {
  onClick: ComponentProps<typeof Button>['onClick'];
}) => {
  const { readOnly } = useWorksPackageContext();

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.WorksPackage,
  });

  const { doesUserHaveSinglePermission } = useCurrentUserPermissions();
    const hasWorkpackageCostscheduleEdit = doesUserHaveSinglePermission(
      'workpackage.costschedule.edit'
    );

  if (readOnly || isOnHoldForThisType) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      size="small"
      sx={{ py: 0.25, px: 1.5, minWidth: 'unset', height: 'unset' }}
      onClick={onClick}
      disabled={!hasWorkpackageCostscheduleEdit}
    >
      Edit
    </Button>
  );
};

const CostsSchduleItemRow = ({
  title,
  amountFieldName,
  descriptionFieldName,
}: SectionRow) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data, hasPrevious } = useCostsScheduleContext();

  if (!data) {
    return null;
  }

  const diff = getDiffBetweenPreviousAndCurrent(
    data.previous,
    data.current,
    amountFieldName
  );

  return (
    <Accordion
      expanded={isExpanded}
      elevation={0}
      sx={{
        [`&.${accordionClasses.root}:before`]: {
          background: 'none',
        },
        background: 'none',
      }}
    >
      <AccordionSummary
        sx={{
          p: 0,
          pointerEvents: 'none',
          '& .MuiAccordionSummary-content': {
            minWidth: 0,
            my: 0,
          },
        }}
      >
        <Grid container alignItems="center" columnSpacing={2} wrap="nowrap">
          <Grid
            item
            flexBasis={'30px'}
            sx={isExpanded ? { alignSelf: 'flex-start' } : null}
          >
            <IconButton
              onClick={e => {
                setIsExpanded(isExpandedVal => !isExpandedVal);
              }}
              sx={{
                pointerEvents: 'auto',
                flexGrow: 1,
                background: 'grey.100',
                p: 1,
                m: 0,
                padding: 0,
              }}
            >
              {isExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
          <Grid item xs={hasPrevious ? 6 : 9}>
            <Typography fontSize={'0.9em'}>{title}</Typography>
          </Grid>

          {hasPrevious ? (
            <>
              <Grid item xs={2} textAlign={'right'}>
                {getAmountFromCostsSchedule(data.previous, amountFieldName)}
              </Grid>
              <Grid item xs={2} textAlign={'right'}>
                {diff !== null ? (
                  <Typography sx={diff < 0 ? { color: 'red' } : null}>
                    {getGbpAmount({ value: diff })}
                  </Typography>
                ) : null}
              </Grid>
            </>
          ) : null}

          <Grid item xs={hasPrevious ? 2 : 3} textAlign={'right'}>
            {getAmountFromCostsSchedule(data.current, amountFieldName)}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography fontSize={'0.9em'}>
          {descriptionFieldName ? data.current[descriptionFieldName] : '-'}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
