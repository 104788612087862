import { Button } from '@mui/material';
import { useArchiveDocumentMutation } from 'api/application/documentsApi';
import { useLocalSnackbar } from 'hooks';
import { useApplicationContext } from 'pages/applicationPage/common/context';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
} from 'styles/globalStyles/dialog';
import { IArchiveApplicationDocument } from 'types/applications/ApplicationDocumentTypes';

interface IArchiveDocumentDialogProps {
  documentId: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ArchiveDocumentDialog({
  documentId,
  onConfirm,
  onCancel,
}: IArchiveDocumentDialogProps) {
  const { applicationId } = useApplicationContext();
  const [archiveDocument] = useArchiveDocumentMutation();
  const { createSuccessSnackbar, createErrorSnackbar } = useLocalSnackbar();

  const onArchiveDocument = async () => {
    const archiveApplicationDocument: IArchiveApplicationDocument = {
      fileId: documentId,
      applicationId: applicationId,
    };

    try {
      await archiveDocument(archiveApplicationDocument);
      createSuccessSnackbar('Successfully archived document');
      onConfirm();
    } catch (err) {
      createErrorSnackbar(`Failed to archive document`);
    }
  };

  return (
    <StyledDialog open>
      <StyledDialogTitle>
        {`Are you sure you wish to archive this file?`}
      </StyledDialogTitle>
      <StyledDialogActions>
        <Button aria-label="cancel" variant="outlined" onClick={onCancel}>
          No
        </Button>
        <Button
          aria-label="Archive File"
          variant="contained"
          onClick={onArchiveDocument}
        >
          Yes
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}
