import { taskApi } from 'api/task';
import { TaskNote, TaskNoteView } from 'types/tasks/CmsTaskTypes';
import { QueryTags } from 'util/ApiUtils';

export const noteEndpoints = taskApi.injectEndpoints({
  endpoints: builder => ({
    addNote: builder.mutation<void, { taskId: string; note: TaskNote }>({
      invalidatesTags: [QueryTags.Notes, QueryTags.Tasks],
      query: ({ taskId, note }) => ({
        url: `${taskId}/subtask`,
        method: 'POST',
        body: note,
      }),
    }),
    editNote: builder.mutation<
      void,
      { taskId: string; noteId: string; note: TaskNote }
    >({
      invalidatesTags: [QueryTags.Notes, QueryTags.Tasks],
      query: ({ taskId, noteId, note }) => ({
        url: `${taskId}/subtask/${noteId}`,
        method: 'PUT',
        body: note,
      }),
    }),
    getNotes: builder.query<TaskNoteView[], string>({
      providesTags: [QueryTags.Notes],
      query: taskId => `/${taskId}/subtask`,
      keepUnusedDataFor: 0,
    }),
    getNote: builder.query<TaskNote, { taskId: string; noteId: string }>({
      providesTags: [QueryTags.Notes],
      query: ({ taskId, noteId }) => `/${taskId}/subtask/${noteId}`,
    }),
  }),
});

export const {
  useGetNoteQuery,
  useGetNotesQuery,
  useAddNoteMutation,
  useEditNoteMutation,
} = noteEndpoints;
