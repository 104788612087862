import { ReactNode } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  CircularProgress,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ErrorAlert } from 'common/components/alerts';
import { extractErrorMessages } from 'util/ApiUtils';

interface IFileDownloadProps {
  handleClick: () => void;
  isLoading: boolean;
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  displayValue?: ReactNode;
  showIconOnly?: boolean;
  fileWrapperStyle?: SxProps<Theme>;
  fileName?: string;
}

const downloadFocus = {
  '&:focus': {
    backgroundColor: '#eceff1',
  },
};

export const FileDownload = ({
  handleClick,
  isLoading,
  isError,
  error,
  displayValue,
  showIconOnly,
  fileWrapperStyle,
  fileName,
}: IFileDownloadProps) => {
  if (isLoading) {
    return (
      <FileWrapper fileWrapperStyle={fileWrapperStyle}>
        <CircularProgress size={20} />
        <Typography>Loading...</Typography>
      </FileWrapper>
    );
  }

  if (isError) {
    return (
      <ErrorAlert
        sx={{ mb: 2, alignItems: 'center' }}
        msg={extractErrorMessages(error)}
        action={
          <Button color="inherit" onClick={() => handleClick()}>
            Try again
          </Button>
        }
      />
    );
  }

  return (
    <FileWrapper
      handleClick={handleClick}
      hideBorder={showIconOnly}
      fileWrapperStyle={fileWrapperStyle}
      fileName={fileName}
    >
      {showIconOnly ? (
        <Box sx={downloadFocus}>
          <FontAwesomeIcon
            icon={solid('file-download')}
            color={grey[600]}
            size="lg"
          />
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center" sx={downloadFocus}>
            <FontAwesomeIcon
              icon={solid('file-download')}
              color={grey[600]}
              size="lg"
              role="button"
            />
          </Box>
          <Box display="flex" alignItems="center" role="button">
            {displayValue}
          </Box>
        </>
      )}
    </FileWrapper>
  );
};

const FileWrapper = ({
  hideBorder,
  children,
  handleClick,
  fileWrapperStyle,
  fileName,
}: {
  hideBorder?: boolean;
  children: ReactNode;
  handleClick?: IFileDownloadProps['handleClick'];
  fileWrapperStyle?: IFileDownloadProps['fileWrapperStyle'];
  fileName?: string;
}) => {
  return (
    // data-automation-id: logic is so that we dont have multiple download buttons with the same automation id on one page
    // by default 'Download' is added if there is no fileName in multiple instances
    // will fallback to no data-automation-id if fileName is 'Download' or empty
    <Box
      display="flex"
      role="button"
      aria-label="Download File"
      data-automation-id={fileName && fileName !== 'Download' ? `file_download_${fileName}` : null}
      onClick={() => (handleClick ? handleClick() : null)}
      gap={4}
      sx={{
        border: hideBorder ? 0 : 1,
        borderRadius: 1,
        borderColor: 'divider',
        p: 2,
        mt: '5px',
        mb: 2,
        width: '100%',
        transition: 'all 250ms linear',
        ':hover': {
          bgcolor: 'grey.50',
          borderColor: grey[600],
          cursor: 'pointer',
        },
        ...fileWrapperStyle,
      }}
    >
      {children}
    </Box>
  );
};
