import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

interface TeamRuleItemProps {
  controllerName: string;
  description: string;
}

export const CustomToggle = ({ controllerName, description }: TeamRuleItemProps) => {
  const { control } = useFormContext();
  
  return (
    <Grid container mb={1}>
      <Grid item xs={9}>
        {description || 'Unnamed Rule'}
      </Grid>
      <Grid
        item
        xs={3}
        display="flex"
        justifyContent="flex-start"
      >
        <Controller
          control={control}
          name={controllerName}
          render={({ field: { onChange, value, ...fieldProps } }) => (
            <ToggleButtonGroup
              {...fieldProps}
              onChange={(_, val) => {
                if (val !== null) onChange(val);
              }}
              value={value}
              exclusive
            >
              <ToggleButton value={true}>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </ToggleButtonGroup>
          )}
        />
      </Grid>
    </Grid>
  );
};