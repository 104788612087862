import { ReactNode } from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Controller, useFormContext } from 'react-hook-form';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EReferralType } from 'enums/EReferralType';
import {
  FRAEWChecksApplicationDetailsPanel,
  FRAEWChecksDocumentsPanel,
  FRAEWChecksSummaryPanel,
} from 'pages/applicationPage/content/applicationEligibility/components/FRAEWChecks/InfoPanels';
import { ReferApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplicationNotificationBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context/ApplicationEligibilityContext';
import { IApplicationFRAEWChecks } from 'types/applications/ApplicationEligibilityTypes';
import { nameof } from 'util/formUtils';

const getName = (fieldName: keyof IApplicationFRAEWChecks) =>
  nameof<IApplicationFRAEWChecks>(fieldName);

interface IFRAEWReportSummaryProps {
  data?: IApplicationFRAEWChecks;
  readOnly?: boolean;
}

export const FRAEWReportSummary = ({
  data,
  readOnly,
}: IFRAEWReportSummaryProps) => {
  const { onReferralQuestion } = useApplicationEligibilityContext();
  const { watch } = useFormContext<IApplicationFRAEWChecks>();

  const [
    watchIsBothFilesOfCorrectType,
    watchIsFRAEWReportsLinkedToCorrectApplication,
    watchDoesBuildingAddressMatchApplication,
    watchDoesSummaryDetailsMatchTheApplication,
    watchDoesRecommendedCourseOfActionMakeSense,
  ] = watch([
    'isBothFilesOfCorrectType',
    'isFRAEWReportsLinkedToCorrectApplication',
    'doesBuildingAddressMatchApplication',
    'doesSummaryDetailsMatchTheApplication',
    'doesRecommendedActionMakeSense',
  ]);

  const handleReferralQuestion = (
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => {
    onReferralQuestion(
      EEligibilitySection.Fraew,
      question,
      referralType,
      refer
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid xs={9} item>
        <Grid container spacing={1}>
          <Box p={2}>
            <Grid xs={12} item>
              <Typography variant="h2" fontWeight={600}>
                FRAEW Report/Summary
              </Typography>
            </Grid>
            <Stack p={2} spacing={4}>
              <FraewQuestionContainer>
                <Box>
                  <FraewQuestion question="Are both files uploaded and the correct type of document?" />
                  <Controller
                    defaultValue={''}
                    name={getName('isBothFilesOfCorrectType')}
                    render={({
                      fieldState,
                      field: { onChange, ...fieldProps },
                    }) => (
                      <FormControl>
                        <ToggleButtonGroup
                          {...fieldProps}
                          onChange={(_, val) => {
                            if (val !== null) {
                              onChange(val);
                            }
                            handleReferralQuestion(
                              EEligibilityQuestion.FRAEW_IsBothFilesOfCorrectType,
                              EReferralType.Applicant,
                              val === false
                            );
                          }}
                          exclusive
                          disabled={readOnly}
                        >
                          <ToggleButton data-automation-id="IsBothFilesOfCorrectType_Pass" value={true}>Pass</ToggleButton>
                          <ToggleButton data-automation-id="IsBothFilesOfCorrectType_Fail" value={false}>Fail</ToggleButton>
                        </ToggleButtonGroup>
                        {fieldState.error?.message ? (
                          <FormHelperText error>
                            {fieldState.error?.message}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Box>
                {watchIsBothFilesOfCorrectType === false && (
                  <ReferApplicationNotificationBanner
                    question={
                      EEligibilityQuestion.FRAEW_IsBothFilesOfCorrectType
                    }
                    referralType={EReferralType.Applicant}
                  />
                )}
              </FraewQuestionContainer>

              {watchIsBothFilesOfCorrectType &&
              watchIsBothFilesOfCorrectType !== undefined ? (
                <>
                  <FraewQuestionContainer>
                    <Box>
                      <FraewQuestion
                        question="Are both the FRAEW Report/Summary linked to the correct
                        application number?"
                      />

                      <Controller
                        defaultValue={''}
                        name={getName(
                          'isFRAEWReportsLinkedToCorrectApplication'
                        )}
                        render={({
                          fieldState,
                          field: { onChange, ...fieldProps },
                        }) => (
                          <FormControl>
                            <ToggleButtonGroup
                              {...fieldProps}
                              onChange={(_, val) => {
                                if (val !== null) {
                                  onChange(val);
                                }
                                handleReferralQuestion(
                                  EEligibilityQuestion.FRAEW_IsFRAEWReportsLinkedToCorrectApplication,
                                  EReferralType.Applicant,
                                  val === false
                                );
                              }}
                              exclusive
                              disabled={readOnly}
                            >
                              <ToggleButton data-automation-id="IsFRAEWReportsLinkedToCorrectApplication_Pass" value={true}>Pass</ToggleButton>
                              <ToggleButton data-automation-id="IsFRAEWReportsLinkedToCorrectApplication_Fail" value={false}>Fail</ToggleButton>
                            </ToggleButtonGroup>
                            {fieldState.error?.message ? (
                              <FormHelperText error>
                                {fieldState.error?.message}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        )}
                      />
                    </Box>
                    {watchIsFRAEWReportsLinkedToCorrectApplication ===
                      false && (
                      <ReferApplicationNotificationBanner
                        question={
                          EEligibilityQuestion.FRAEW_IsFRAEWReportsLinkedToCorrectApplication
                        }
                        referralType={EReferralType.Applicant}
                      />
                    )}
                  </FraewQuestionContainer>

                  <FraewQuestionContainer>
                    <Box>
                      <FraewQuestion question="Does the building address match the application?" />
                      <Controller
                        defaultValue={''}
                        name={getName('doesBuildingAddressMatchApplication')}
                        render={({
                          fieldState,
                          field: { onChange, ...fieldProps },
                        }) => (
                          <FormControl>
                            <ToggleButtonGroup
                              {...fieldProps}
                              onChange={(_, val) => {
                                if (val !== null) {
                                  onChange(val);
                                }
                                handleReferralQuestion(
                                  EEligibilityQuestion.FRAEW_DoesBuildingAddressMatchApplication,
                                  EReferralType.Applicant,
                                  val === false
                                );
                              }}
                              exclusive
                              disabled={readOnly}
                            >
                              <ToggleButton data-automation-id="DoesBuildingAddressMatchApplication_Pass" value={true}>Pass</ToggleButton>
                              <ToggleButton data-automation-id="DoesBuildingAddressMatchApplication_Fail" value={false}>Fail</ToggleButton>
                            </ToggleButtonGroup>
                            {fieldState.error?.message ? (
                              <FormHelperText error>
                                {fieldState.error?.message}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        )}
                      />
                    </Box>
                    {watchDoesBuildingAddressMatchApplication === false && (
                      <ReferApplicationNotificationBanner
                        question={
                          EEligibilityQuestion.FRAEW_DoesBuildingAddressMatchApplication
                        }
                        referralType={EReferralType.HomesEngland}
                      />
                    )}
                  </FraewQuestionContainer>

                  <FraewQuestionContainer>
                    <Box>
                      <FraewQuestion question="Do all the details in the summary match the application?" />
                      <Controller
                        defaultValue={''}
                        name={getName('doesSummaryDetailsMatchTheApplication')}
                        render={({
                          fieldState,
                          field: { onChange, ...fieldProps },
                        }) => (
                          <FormControl>
                            <ToggleButtonGroup
                              {...fieldProps}
                              onChange={(_, val) => {
                                if (val !== null) {
                                  onChange(val);
                                }
                                handleReferralQuestion(
                                  EEligibilityQuestion.FRAEW_DoesSummaryDetailsMatchTheApplication,
                                  EReferralType.HomesEngland,
                                  val === false
                                );
                              }}
                              exclusive
                              disabled={readOnly}
                            >
                              <ToggleButton data-automation-id="DoesSummaryDetailsMatchTheApplication_Pass" value={true}>Pass</ToggleButton>
                              <ToggleButton data-automation-id="DoesSummaryDetailsMatchTheApplication_Fail" value={false}>Fail</ToggleButton>
                            </ToggleButtonGroup>
                            {fieldState.error?.message ? (
                              <FormHelperText error>
                                {fieldState.error?.message}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        )}
                      />
                    </Box>
                    {watchDoesSummaryDetailsMatchTheApplication === false && (
                      <ReferApplicationNotificationBanner
                        question={
                          EEligibilityQuestion.FRAEW_DoesSummaryDetailsMatchTheApplication
                        }
                        referralType={EReferralType.HomesEngland}
                      />
                    )}
                  </FraewQuestionContainer>

                  <FraewQuestionContainer>
                    <Box>
                      <FraewQuestion question="Does the recommended course of action make sense?" />
                      <Controller
                        defaultValue={''}
                        name={getName('doesRecommendedActionMakeSense')}
                        render={({
                          fieldState,
                          field: { onChange, ...fieldProps },
                        }) => (
                          <FormControl>
                            <ToggleButtonGroup
                              {...fieldProps}
                              onChange={(_, val) => {
                                if (val !== null) {
                                  onChange(val);
                                }
                                handleReferralQuestion(
                                  EEligibilityQuestion.FRAEW_DoesRecommendedActionMakeSense,
                                  EReferralType.HomesEngland,
                                  val === false
                                );
                              }}
                              exclusive
                              disabled={readOnly}
                            >
                              <ToggleButton data-automation-id="DoesRecommendedActionMakeSense_Pass" value={true}>Pass</ToggleButton>
                              <ToggleButton data-automation-id="DoesRecommendedActionMakeSense_Fail" value={false}>Fail</ToggleButton>
                            </ToggleButtonGroup>
                            {fieldState.error?.message ? (
                              <FormHelperText error>
                                {fieldState.error?.message}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        )}
                      />
                    </Box>
                    {watchDoesRecommendedCourseOfActionMakeSense === false && (
                      <ReferApplicationNotificationBanner
                        question={
                          EEligibilityQuestion.FRAEW_DoesRecommendedActionMakeSense
                        }
                        referralType={EReferralType.HomesEngland}
                      />
                    )}
                  </FraewQuestionContainer>
                </>
              ) : null}
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid xs={3} item>
        <FRAEWChecksDocumentsPanel />
        <FRAEWChecksApplicationDetailsPanel />
        <FRAEWChecksSummaryPanel />
      </Grid>
    </Grid>
  );
};

const FraewQuestionContainer = ({ children }: { children: ReactNode }) => {
  return <Stack spacing={1}>{children}</Stack>;
};

const FraewQuestion = ({ question }: { question: string }) => {
  return (
    <Typography variant="h3" fontWeight={600} fontSize="1em">
      {question}
    </Typography>
  );
};
