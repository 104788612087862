import { administrationAssessorPanelListHandlers } from 'mocks/handlers/administration/administrationAssessorPanelListHandlers';
import { administrationBenchmarkFiguresServiceHandlers } from 'mocks/handlers/administration/administrationBenchmarkFiguresServiceHandlers';
import { administrationOrganisationHandlers } from 'mocks/handlers/administration/administrationOrganisationHandlers';
import { administrationPermissionsHandlers } from 'mocks/handlers/administration/administrationPermissionsHandlers';
import { administrationRolesServiceHandlers } from 'mocks/handlers/administration/administrationRolesServiceHandlers';
import { administrationSystemNotificationsServiceHandlers } from 'mocks/handlers/administration/administrationSystemNotificationsServiceHandlers';
import { administrationTeamsHandlers } from 'mocks/handlers/administration/administrationTeamsHandlers';
import { administrationUsersHandlers } from 'mocks/handlers/administration/administrationUsersHandlers';
import { applicationDetailsEditHandlers } from 'mocks/handlers/application/applicationDetailsEditHandlers';
import { applicationHandlers } from 'mocks/handlers/application/applicationHandlers';
import { communicationsHandlers } from 'mocks/handlers/application/communicationsHandlers';
import { documentsHandlers } from 'mocks/handlers/application/documentsHandlers';
import { dutyOfCareHandlers } from 'mocks/handlers/application/dutyOfCareHandlers';
import { eligibilityHandlers } from 'mocks/handlers/application/eligibilityHandlers';
import { grantFundingHandlers } from 'mocks/handlers/application/grantFundingHandlers';
import { liveProjectHandlers } from 'mocks/handlers/application/liveProjectHandlers';
import { paymentHandlers } from 'mocks/handlers/application/paymentHandlers';
import { paymentRequestHandlers } from 'mocks/handlers/application/paymentRequestHandlers';
import { projectPrepHandlers } from 'mocks/handlers/application/projectPrepHandlers';
import { scheduleOfWorksHandlers } from 'mocks/handlers/application/scheduleOfWorksHandlers';
import { signatoriesHandlers } from 'mocks/handlers/application/signatoriesHandlers';
import { supportHandlers } from 'mocks/handlers/application/supportHandlers';
import { variationHandlers } from 'mocks/handlers/application/variationHandlers';
import { worksPackageDeedHandlers } from 'mocks/handlers/application/worksPackageDeedHandlers';
import { worksPackageHandlers } from 'mocks/handlers/application/worksPackageHandlers';
import { countryHandlers } from 'mocks/handlers/common/countryHandler';
import { holdReasonsHandlers } from 'mocks/handlers/common/holdReasonsHandlers';
import { fileHandlers } from 'mocks/handlers/file/fileHandler';
import { localHostHandlers } from 'mocks/handlers/localHostHandlers';
import { profileHandlers } from 'mocks/handlers/profile/profileHandlers';
import { addBuildingRecordHandlers } from 'mocks/handlers/pullInProcess/addBuildingRecord';
import { pullInProcessHandlers } from 'mocks/handlers/pullInProcess/pullInProcessHandlers';
import { taskHandlers } from 'mocks/handlers/task/taskHandlers';

export const handlers = [
  ...profileHandlers,
  ...fileHandlers,
  ...administrationPermissionsHandlers,
  ...administrationRolesServiceHandlers,
  ...administrationSystemNotificationsServiceHandlers,
  ...localHostHandlers,
  ...administrationUsersHandlers,
  ...administrationTeamsHandlers,
  ...administrationOrganisationHandlers,
  ...administrationAssessorPanelListHandlers,
  ...administrationBenchmarkFiguresServiceHandlers,
  ...applicationHandlers,
  ...applicationDetailsEditHandlers,
  ...paymentHandlers,
  ...dutyOfCareHandlers,
  ...supportHandlers,
  ...projectPrepHandlers,
  ...worksPackageHandlers,
  ...eligibilityHandlers,
  ...grantFundingHandlers,
  ...signatoriesHandlers,
  ...documentsHandlers,
  ...communicationsHandlers,
  ...taskHandlers,
  ...liveProjectHandlers,
  ...scheduleOfWorksHandlers,
  ...worksPackageDeedHandlers,
  ...pullInProcessHandlers,
  ...paymentRequestHandlers,
  ...variationHandlers,
  ...addBuildingRecordHandlers,
  ...countryHandlers,
  ...holdReasonsHandlers,
];
