import { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useEditCoreInformationChecksMutation,
  useGetCoreInformationChecksQuery,
} from 'api/application';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { LeavePageGuard } from 'common/components/leavePageGuard/LeavePageGuard';
import { Loading } from 'common/components/loading';
import { ECheckpointResponses } from 'enums/ECheckpointResponses';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { useLocalSnackbar } from 'hooks';
import { CoreInformationDevelopersPledge } from 'pages/applicationPage/content/applicationEligibility/components/CoreInformationChecks/CoreInformationDevelopersPledge';
import { CoreInformationExistingApplications } from 'pages/applicationPage/content/applicationEligibility/components/CoreInformationChecks/CoreInformationExistingApplications';
import { CoreInformationLeaseHolderEngagement } from 'pages/applicationPage/content/applicationEligibility/components/CoreInformationChecks/CoreInformationLeaseHolderEngagement';
import { ReferApplicationButton } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplication';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import {
  IApplicationCoreInformationChecks,
  IApplicationEligibilityReferral,
} from 'types/applications/ApplicationEligibilityTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { setServerSideFormErrors } from 'util/formUtils';

export const CoreInformationChecksForm = () => {
  const { applicationId, referrals } = useApplicationEligibilityContext();
  const query = useGetCoreInformationChecksQuery(applicationId);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData: IApplicationCoreInformationChecks = {
      ...data,
      developersPledgeDocumentFileId: data?.developersPledgeDocument?.id ?? '',
      referrals: referrals.filter(
        item => item.section === EEligibilitySection.CoreInformation
      ),
    };

    return <CoreInformationChecksFormMain initialData={initialData} />;
  }

  return null;
};

const CoreInformationChecksFormMain = ({
  initialData,
}: {
  initialData: IApplicationCoreInformationChecks;
}) => {
  const { applicationId, readOnly, handleAutoShowReferApplicationDialog } =
    useApplicationEligibilityContext();

  const { referrals } = useApplicationEligibilityContext();

  const [editCoreInformationChecks, editCoreInformationChecksStatus] =
    useEditCoreInformationChecksMutation();

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationCoreInformationChecks>({
    defaultValues: initialData,
  });

  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, isSubmitting },
  } = form;

  const [data] = useState<IApplicationCoreInformationChecks | undefined>(
    initialData
  );

  const [
    developersPledgeDocumentFileInvalid,
    setDevelopersPledgeDocumentFileInvalid,
  ] = useState<boolean>(false);

  useEffect(() => {
    referrals
      .filter(item => item.section === EEligibilitySection.CoreInformation)
      .forEach((referral: IApplicationEligibilityReferral) => {
        form.setValue(`referrals.${referral.question}.reason`, referral.reason);
      });
  }, [form, referrals]);

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Eligibility,
  });

  const validForm = (formData: IApplicationCoreInformationChecks) => {
    let valid = true;
    setDevelopersPledgeDocumentFileInvalid(false);

    if (
      (formData.hasDevelopersPledge === ECheckpointResponses.Yes ||
        formData.hasDevelopersPledge === ECheckpointResponses.NotSure) &&
      (formData.developersPledgeDocumentFileId === undefined ||
        formData.developersPledgeDocumentFileId === '')
    ) {
      setDevelopersPledgeDocumentFileInvalid(true);
      valid = false;
    }

    if (!valid) {
      createWarningSnackbar(
        'Please correct any form validation errors shown, and then try again.'
      );
    }

    return valid;
  };

  const getCoreReferrals = (formData: IApplicationCoreInformationChecks) => {
    const coreReferrals = referrals.filter(
      item => item.section === EEligibilitySection.CoreInformation
    );

    coreReferrals.forEach((referral: IApplicationEligibilityReferral) => {
      let referralReason = formData.referrals?.[referral.question];
      if (referralReason) {
        referral.reason = referralReason.reason;
      }
    });

    return coreReferrals;
  };

  const onSubmit = async () => {
    const formData = getValues();
    const coreReferrals = getCoreReferrals(formData);
    if (coreReferrals.length > 0 || validForm(formData)) {
      try {
        formData.id = applicationId;
        formData.referrals = getCoreReferrals(formData);
        await editCoreInformationChecks(
          formData as IApplicationCoreInformationChecks
        )
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(
              `Core Information Checks updated successfully`
            );
            reset({}, { keepValues: true });
            handleAutoShowReferApplicationDialog();
          })
          .catch(error => {
            if (error?.data?.generalError?.errorMessage) {
              createErrorSnackbar(error.data.generalError.errorMessage);
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to edit core information checks`);
      }
    }
  };

  return (
    <>
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Core Information Checks will be lost."
        />

        {isOnHoldForThisType && (
          <OnHoldBanner holdType={EOnHoldType.Eligibility} />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertForQueryErrorOrNull
            isError={editCoreInformationChecksStatus.isError}
            error={editCoreInformationChecksStatus.error}
          />

          <AlertForChangedData isDirty={isDirty} />

          <Grid container spacing={1}>
            <Grid xs={12} item>
              <Box p={3}>
                <CoreInformationExistingApplications
                  readOnly={readOnly || isOnHoldForThisType}
                  watch={watch}
                />

                <Divider />

                <CoreInformationDevelopersPledge
                  data={data}
                  readOnly={readOnly || isOnHoldForThisType}
                  developersPledgeDocumentFileInvalid={
                    developersPledgeDocumentFileInvalid
                  }
                />

                <Divider />

                <CoreInformationLeaseHolderEngagement
                  readOnly={readOnly || isOnHoldForThisType}
                />
              </Box>
            </Grid>
          </Grid>
          {!readOnly && (
            <Grid container spacing={1}>
              <Grid xs={12} item>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  p={3}
                  bgcolor="grey.100"
                >
                  <ReferApplicationButton
                    sectionType={EEligibilitySection.CoreInformation}
                    disabled={isOnHoldForThisType}
                  />
                  <OnOffHold holdType={EOnHoldType.Eligibility} />
                  <Box gap={2} display="flex">
                    <Button
                      data-automation-id="CoreInformationChecks_Cancel"
                      variant="outlined"
                      disabled={isOnHoldForThisType}
                      onClick={() => {
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      data-automation-id="CoreInformationChecks_Confirm"
                      variant="contained"
                      disabled={isOnHoldForThisType}
                      type="submit"
                    >
                      Confirm Details
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </form>
      </FormProvider>
    </>
  );
};
