import { useEffect, useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  useEditLandRegistryChecksMutation,
  useGetApplicationSummaryQuery,
  useGetLandRegistryChecksQuery,
} from 'api/application';
import { OnHoldBanner } from 'common/components/OnOffHold/OnHoldBanner';
import { OnOffHold } from 'common/components/OnOffHold/OnOffHold';
import { useOnOffHold } from 'common/components/OnOffHold/useOnOffHold';
import {
  AlertForChangedData,
  AlertForQueryErrorOrNull,
} from 'common/components/alerts';
import { FileUploadInputSelect } from 'common/components/fileUploadInputSelect/FileUploadInputSelect';
import { LeavePageGuard } from 'common/components/leavePageGuard/LeavePageGuard';
import { Loading } from 'common/components/loading';
import { ECheckpointResponses } from 'enums/ECheckpointResponses';
import { EEligibilityQuestion } from 'enums/EEligibilityQuestion';
import { EEligibilitySection } from 'enums/EEligibilitySection';
import { EReferralType } from 'enums/EReferralType';
import { useFileUpload, useLocalSnackbar } from 'hooks';
import { ApplicationFileDownloadWrapper } from 'pages/applicationPage/common/components';
import { LandRegistryChecksInfoPanel } from 'pages/applicationPage/content/applicationEligibility/components/LandRegistryChecks/LandRegistryChecksInfoPanel';
import { ReferApplicationButton } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplication';
import { ReferApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/ReferApplicationNotificationBanner';
import { RejectApplicationNotificationBanner } from 'pages/applicationPage/content/applicationEligibility/components/RejectApplicationNotificationBanner';
import { useApplicationEligibilityContext } from 'pages/applicationPage/content/applicationEligibility/context';
import { EResponsibleEntityOrganisationType } from 'pages/applicationPage/content/projectPrep/sections/WorksPackageSection/WorksPackageTypes';
import {
  IApplicationEligibilityReferral,
  IApplicationLandRegistryChecks,
} from 'types/applications/ApplicationEligibilityTypes';
import { EOnHoldType } from 'types/applications/ApplicationHoldTypes';
import { nameof, setServerSideFormErrors } from 'util/formUtils';

type FileInputName =
  | 'landRegistryDocument'
  | 'leaseDocument'
  | 'certificateDocument';

const getName = (fieldName: keyof IApplicationLandRegistryChecks) =>
  nameof<IApplicationLandRegistryChecks>(fieldName);

export const LandRegistryChecksForm = () => {
  const { applicationId } = useApplicationEligibilityContext();
  const query = useGetLandRegistryChecksQuery(applicationId);

  if (query.isLoading) {
    return <Loading isOpen />;
  }

  if (query.isSuccess) {
    const data = query.data;
    const initialData: IApplicationLandRegistryChecks = {
      ...data,
      referrals: data.referrals?.map(r => ({ ...r })) ?? [],
      landRegistryDocumentId: data.landRegistryDocument?.id ?? '',
      leaseDocumentId: data.leaseDocument?.id ?? '',
      certificateDocumentId: data.certificateDocument?.id ?? '',
    };

    return <LandRegistryChecksFormMain initialData={initialData} />;
  }

  return null;
};

const LandRegistryChecksFormMain = ({
  initialData,
}: {
  initialData: IApplicationLandRegistryChecks;
}) => {
  const { applicationId, readOnly, rejectedQuestion } =
    useApplicationEligibilityContext();

  const applicationSummary = useGetApplicationSummaryQuery(applicationId);

  const {
    referrals,
    onReferralQuestion,
    onRejectedQuestion,
    handleAutoShowReferApplicationDialog,
  } = useApplicationEligibilityContext();

  const [editLandRegistryChecks, editLandRegistryChecksStatus] =
    useEditLandRegistryChecksMutation();
  const { uploadFile } = useFileUpload();

  const [data] = useState<IApplicationLandRegistryChecks | undefined>(
    initialData
  );

  const [landRegistryDocumentFileInvalid, setLandRegistryDocumentFileInvalid] =
    useState<boolean>(false);
  const [leaseDocumentFileInvalid, setLeaseDocumentFileInvalid] =
    useState<boolean>(false);
  const [certificateDocumentFileInvalid, setCertificateDocumentFileInvalid] =
    useState<boolean>(false);

  const rightToManageCompany =
    applicationSummary.data?.responsibleEntityOrganisationType ===
    EResponsibleEntityOrganisationType.RightToManageCompany;

  const { createSuccessSnackbar, createErrorSnackbar, createWarningSnackbar } =
    useLocalSnackbar();

  const form = useForm<IApplicationLandRegistryChecks>({
    defaultValues: initialData,
  });
  const {
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, isSubmitting },
  } = form;

  const watchLandRegistryDocumentId = watch('landRegistryDocumentId');
  const watchLeaseDocumentId = watch('leaseDocumentId');
  const watchCertificateDocumentId = watch('certificateDocumentId');
  const watchIsResidentialLeaseLongTerm = watch('isResidentialLeaseLongTerm');
  const watchHasNoPendingApplications = watch('hasNoPendingApplications');
  const watchIsCorrectResponsibleEntity = watch('isCorrectResponsibleEntity');
  const watchHasAbilityToMakeChanges = watch('hasAbilityToMakeChanges');

  const { isOnHoldForThisType } = useOnOffHold({
    holdType: EOnHoldType.Eligibility,
  });

  const validForm = (formData: IApplicationLandRegistryChecks) => {
    let valid = true;
    setLandRegistryDocumentFileInvalid(false);
    setLeaseDocumentFileInvalid(false);
    setCertificateDocumentFileInvalid(false);

    if (
      formData.landRegistryDocumentId === undefined ||
      formData.landRegistryDocumentId === ''
    ) {
      setLandRegistryDocumentFileInvalid(true);
      valid = false;
    }

    if (
      formData.leaseDocumentId === undefined ||
      formData.leaseDocumentId === ''
    ) {
      setLeaseDocumentFileInvalid(true);
      valid = false;
    }

    if (
      !rightToManageCompany &&
      (formData.certificateDocumentId === undefined ||
        formData.certificateDocumentId === '')
    ) {
      setCertificateDocumentFileInvalid(true);
      valid = false;
    }

    if (!valid) {
      createWarningSnackbar(
        'Please correct any form validation errors shown, and then try again.'
      );
    }

    return valid;
  };

  const handleReferralQuestion = (
    question: EEligibilityQuestion,
    referralType: EReferralType,
    refer: boolean
  ) => {
    onReferralQuestion(
      EEligibilitySection.LandRegistry,
      question,
      referralType,
      refer
    );
  };

  useEffect(() => {
    referrals
      .filter(item => item.section === EEligibilitySection.LandRegistry)
      .forEach((referral: IApplicationEligibilityReferral) => {
        form.setValue(`referrals.${referral.question}.reason`, referral.reason);
      });
  }, [form, referrals]);

  const getLandRegistryReferrals = () => {
    return referrals.filter(
      item => item.section === EEligibilitySection.LandRegistry
    );
  };

  const onSubmit = async (rejectTriggered: boolean) => {
    const formData = getValues();
    if (rejectTriggered || validForm(formData)) {
      try {
        formData.id = applicationId;
        formData.referrals = getLandRegistryReferrals();
        formData.rejectTriggered = rejectTriggered;
        await editLandRegistryChecks(formData as IApplicationLandRegistryChecks)
          .unwrap()
          .then(payload => {
            createSuccessSnackbar(`Land Registry Checks updated successfully`);
            reset({}, { keepValues: true });
            handleAutoShowReferApplicationDialog();
          })
          .catch(error => {
            if (error?.data?.generalError?.errorMessage) {
              createErrorSnackbar(error.data.generalError.errorMessage);
            } else if (error.data.propertyErrors) {
              setServerSideFormErrors(form, error.data);
              createWarningSnackbar(
                'Please correct any form validation errors shown, and then try again.'
              );
            } else {
              createErrorSnackbar(error);
            }
          });
      } catch (err) {
        createErrorSnackbar(`Failed to edit Land Registry checks`);
      }
    }
  };

  const handleOnFileUpload = async (
    file: File,
    fileInputName: FileInputName
  ) => {
    await uploadFile({
      file,
      uploadSection: 'EligibilityLandRegistry',
      onSuccess: payload => {
        form.setValue(`${fileInputName}Id`, payload.id);
      },
    });
  };

  const handleOnDeleteFileUpload = async (fileInputName: FileInputName) => {
    form.setValue(`${fileInputName}Id`, '');
  };

  const handleRejectedQuestion = (question: EEligibilityQuestion | null) => {
    onRejectedQuestion(question);
  };

  return (
    <>
      <FormProvider {...form}>
        <LeavePageGuard
          blockNavigationIf={isDirty && !isSubmitting}
          message="Are you sure you want to leave? Any unsaved changes to the Land Registry Checks will be lost."
        />

        {isOnHoldForThisType && (
          <OnHoldBanner holdType={EOnHoldType.Eligibility} />
        )}

        <form onSubmit={handleSubmit(async () => await onSubmit(false))}>
          <AlertForQueryErrorOrNull
            isError={editLandRegistryChecksStatus.isError}
            error={editLandRegistryChecksStatus.error}
          />

          <AlertForChangedData isDirty={isDirty} />

          <Grid container spacing={1}>
            <Grid xs={9} item>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Box p={3}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item mb={2} data-automation-id="LandRegistryDocument">
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Upload freehold register and plan from land registry
                          document
                        </Typography>
                        {watchLandRegistryDocumentId !== undefined &&
                        watchLandRegistryDocumentId ===
                          data?.landRegistryDocument?.id ? (
                          <Box display="flex" flexDirection="row">
                            <ApplicationFileDownloadWrapper
                              fileName={data?.landRegistryDocument?.name ?? ''}
                              fileId={data?.landRegistryDocument?.id ?? ''}
                            />
                            <IconButton
                              size="small"
                              disabled={isOnHoldForThisType}
                              onClick={() =>
                                handleOnDeleteFileUpload('landRegistryDocument')
                              }
                              aria-label="Delete File"
                              name="Delete File"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          </Box>
                        ) : (
                          <>
                            <FileUploadInputSelect
                              id="landRegistryDocument"
                              accept=".pdf"
                              inputName="landRegistryDocumentId"
                              onFileUpload={file =>
                                handleOnFileUpload(file, 'landRegistryDocument')
                              }
                              onFileDelete={() =>
                                handleOnDeleteFileUpload('landRegistryDocument')
                              }
                              disabled={isOnHoldForThisType}
                            />
                            {landRegistryDocumentFileInvalid && (
                              <FormHelperText error>
                                Land registry document is required.
                              </FormHelperText>
                            )}
                          </>
                        )}
                      </Grid>
                      <Grid item mb={2} data-automation-id="LeaseDocument">
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Upload qualifying lease document
                        </Typography>
                        {watchLeaseDocumentId !== undefined &&
                        watchLeaseDocumentId === data?.leaseDocument?.id ? (
                          <Box display="flex" flexDirection="row">
                            <ApplicationFileDownloadWrapper
                              fileName={data?.leaseDocument?.name ?? ''}
                              fileId={data?.leaseDocument?.id ?? ''}
                            />
                            <IconButton
                              size="small"
                              disabled={isOnHoldForThisType}
                              onClick={() =>
                                handleOnDeleteFileUpload('leaseDocument')
                              }
                              aria-label="Delete File"
                              name="Delete File"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          </Box>
                        ) : (
                          <>
                            <FileUploadInputSelect
                              id="leaseDocument"
                              accept=".pdf"
                              inputName="leaseDocumentId"
                              onFileUpload={file =>
                                handleOnFileUpload(file, 'leaseDocument')
                              }
                              onFileDelete={() =>
                                handleOnDeleteFileUpload('leaseDocument')
                              }
                              disabled={isOnHoldForThisType}
                            />
                            {leaseDocumentFileInvalid && (
                              <FormHelperText error>
                                Qualifying lease document is required.
                              </FormHelperText>
                            )}
                          </>
                        )}
                      </Grid>
                      <Grid item mb={2} data-automation-id="CertificateDocument">
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Upload certificate of incorporation document
                        </Typography>
                        {watchCertificateDocumentId !== undefined &&
                        watchCertificateDocumentId ===
                          data?.certificateDocument?.id ? (
                          <Box display="flex" flexDirection="row">
                            pending
                            <ApplicationFileDownloadWrapper
                              fileName={data?.certificateDocument?.name ?? ''}
                              fileId={data?.certificateDocument?.id ?? ''}
                            />
                            <IconButton
                              size="small"
                              disabled={isOnHoldForThisType}
                              onClick={() =>
                                handleOnDeleteFileUpload('certificateDocument')
                              }
                              aria-label="Delete File"
                              name="Delete File"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          </Box>
                        ) : (
                          <>
                            <FileUploadInputSelect
                              id="certificateDocument"
                              accept=".pdf"
                              inputName="certificateDocumentId"
                              onFileUpload={file =>
                                handleOnFileUpload(file, 'certificateDocument')
                              }
                              onFileDelete={() =>
                                handleOnDeleteFileUpload('certificateDocument')
                              }
                              disabled={isOnHoldForThisType}
                            />
                            {certificateDocumentFileInvalid && (
                              <FormHelperText error>
                                Certificate document is required.
                              </FormHelperText>
                            )}
                          </>
                        )}
                      </Grid>
                      <Grid xs={12} item>
                        <Grid item mb={2}>
                          <Typography
                            variant="h3"
                            fontWeight={600}
                            fontSize="1em"
                          >
                            Is the residential lease long term (over 21 years)?
                          </Typography>
                          <Controller
                            defaultValue={''}
                            name={getName('isResidentialLeaseLongTerm')}
                            render={({
                              fieldState,
                              field: { onChange, ...fieldProps },
                            }) => (
                              <FormControl>
                                <ToggleButtonGroup
                                  {...fieldProps}
                                  onChange={(_, val) => {
                                    if (val !== null) {
                                      onChange(val);
                                    }
                                    handleRejectedQuestion(
                                      val === ECheckpointResponses.No
                                        ? EEligibilityQuestion.LandRegistry_IsResidentialLeaseLongTerm
                                        : null
                                    );
                                    handleReferralQuestion(
                                      EEligibilityQuestion.LandRegistry_IsResidentialLeaseLongTerm,
                                      EReferralType.HomesEngland,
                                      val === ECheckpointResponses.Complex
                                    );
                                  }}
                                  exclusive
                                  disabled={
                                    isOnHoldForThisType ||
                                    (readOnly &&
                                      rejectedQuestion !==
                                        EEligibilityQuestion.LandRegistry_IsResidentialLeaseLongTerm)
                                  }
                                >
                                  <ToggleButton
                                    data-automation-id="IsResidentialLeaseLongTerm_Pass"
                                    key={ECheckpointResponses.Yes}
                                    value={ECheckpointResponses.Yes}
                                  >
                                    Pass
                                  </ToggleButton>
                                  <ToggleButton
                                    data-automation-id="IsResidentialLeaseLongTerm_Fail"
                                    key={ECheckpointResponses.No}
                                    value={ECheckpointResponses.No}
                                  >
                                    Fail
                                  </ToggleButton>
                                  <ToggleButton
                                    data-automation-id="IsResidentialLeaseLongTerm_ComplexCase"
                                    key={ECheckpointResponses.Complex}
                                    value={ECheckpointResponses.Complex}
                                  >
                                    Complex Case
                                  </ToggleButton>
                                </ToggleButtonGroup>
                                {fieldState.error?.message ? (
                                  <FormHelperText error>
                                    {fieldState.error?.message}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {watchIsResidentialLeaseLongTerm ===
                        ECheckpointResponses.Yes && (
                        <Grid item mb={2}>
                          <Typography variant="h3" fontSize="1em">
                            Record the Page Number in Document
                          </Typography>
                          <Controller
                            name={getName('leaseLongTermPageNumber')}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                data-automation-id="LeaseLongTermPageNumber"
                                type="number"
                                fullWidth
                                error={!!fieldState.error}
                                required
                                label="Page Number"
                                helperText={fieldState.error?.message}
                                style={{ width: '12rem' }}
                                disabled={readOnly || isOnHoldForThisType}
                                InputProps={{ inputProps: { min: 1 } }}
                              />
                            )}
                          />
                          <strong style={{ paddingLeft: '1rem' }}>
                            Page Number
                          </strong>
                        </Grid>
                      )}
                      {watchIsResidentialLeaseLongTerm ===
                        ECheckpointResponses.No && (
                        <RejectApplicationNotificationBanner
                          question={
                            EEligibilityQuestion.LandRegistry_IsResidentialLeaseLongTerm
                          }
                          onClick={() => onSubmit(true)}
                          disabled={isOnHoldForThisType}
                        />
                      )}
                      {watchIsResidentialLeaseLongTerm ===
                        ECheckpointResponses.Complex && (
                        <ReferApplicationNotificationBanner
                          question={
                            EEligibilityQuestion.LandRegistry_IsResidentialLeaseLongTerm
                          }
                          referralType={EReferralType.HomesEngland}
                          disabled={isOnHoldForThisType}
                        />
                      )}
                      <Grid xs={12} item>
                        <Grid item mb={2}>
                          <Typography
                            variant="h3"
                            fontWeight={600}
                            fontSize="1em"
                          >
                            Are there any pending applications on the
                            buildings's title?
                          </Typography>
                          <Controller
                            defaultValue={''}
                            name={getName('hasNoPendingApplications')}
                            render={({
                              fieldState,
                              field: { onChange, ...fieldProps },
                            }) => (
                              <FormControl>
                                <ToggleButtonGroup
                                  {...fieldProps}
                                  onChange={(_, val) => {
                                    if (val !== null) {
                                      onChange(val);
                                    }
                                    handleRejectedQuestion(
                                      val === ECheckpointResponses.Yes
                                        ? EEligibilityQuestion.LandRegistry_HasNoPendingApplications
                                        : null
                                    );
                                    handleReferralQuestion(
                                      EEligibilityQuestion.LandRegistry_HasNoPendingApplications,
                                      EReferralType.HomesEngland,
                                      val === ECheckpointResponses.Complex
                                    );
                                  }}
                                  exclusive
                                  disabled={
                                    isOnHoldForThisType ||
                                    (readOnly &&
                                      rejectedQuestion !==
                                        EEligibilityQuestion.LandRegistry_HasNoPendingApplications)
                                  }
                                >
                                  <ToggleButton
                                    data-automation-id="HasNoPendingApplications_Yes"
                                    key={ECheckpointResponses.Yes}
                                    value={ECheckpointResponses.Yes}
                                  >
                                    Yes
                                  </ToggleButton>
                                  <ToggleButton
                                    data-automation-id="HasNoPendingApplications_No"
                                    key={ECheckpointResponses.No}
                                    value={ECheckpointResponses.No}
                                  >
                                    No
                                  </ToggleButton>
                                  <ToggleButton
                                    data-automation-id="HasNoPendingApplications_ComplexCase"
                                    key={ECheckpointResponses.Complex}
                                    value={ECheckpointResponses.Complex}
                                  >
                                    Complex Case
                                  </ToggleButton>
                                </ToggleButtonGroup>
                                {fieldState.error?.message ? (
                                  <FormHelperText error>
                                    {fieldState.error?.message}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {watchHasNoPendingApplications ===
                        ECheckpointResponses.Yes && (
                        <RejectApplicationNotificationBanner
                          question={
                            EEligibilityQuestion.LandRegistry_HasNoPendingApplications
                          }
                          onClick={() => onSubmit(true)}
                          disabled={isOnHoldForThisType}
                        />
                      )}
                      {watchHasNoPendingApplications ===
                        ECheckpointResponses.Complex && (
                        <ReferApplicationNotificationBanner
                          question={
                            EEligibilityQuestion.LandRegistry_HasNoPendingApplications
                          }
                          referralType={EReferralType.HomesEngland}
                          disabled={isOnHoldForThisType}
                        />
                      )}
                      <Grid xs={12} item>
                        <Grid item mb={2}>
                          <Typography
                            variant="h3"
                            fontWeight={600}
                            fontSize="1em"
                          >
                            Is the applicant the correct responsible entity and
                            has a qualifying legal interest in the building?
                          </Typography>
                          <Controller
                            defaultValue={''}
                            name={getName('isCorrectResponsibleEntity')}
                            render={({
                              fieldState,
                              field: { onChange, ...fieldProps },
                            }) => (
                              <FormControl>
                                <ToggleButtonGroup
                                  {...fieldProps}
                                  onChange={(_, val) => {
                                    if (val !== null) {
                                      onChange(val);
                                    }
                                    handleRejectedQuestion(
                                      val === ECheckpointResponses.No
                                        ? EEligibilityQuestion.LandRegistry_IsCorrectResponsibleEntity
                                        : null
                                    );
                                    handleReferralQuestion(
                                      EEligibilityQuestion.LandRegistry_IsCorrectResponsibleEntity,
                                      EReferralType.HomesEngland,
                                      val === ECheckpointResponses.Complex
                                    );
                                  }}
                                  exclusive
                                  disabled={
                                    isOnHoldForThisType ||
                                    (readOnly &&
                                      rejectedQuestion !==
                                        EEligibilityQuestion.LandRegistry_IsCorrectResponsibleEntity)
                                  }
                                >
                                  <ToggleButton
                                    data-automation-id="IsCorrectResponsibleEntity_Pass"
                                    key={ECheckpointResponses.Yes}
                                    value={ECheckpointResponses.Yes}
                                  >
                                    Pass
                                  </ToggleButton>
                                  <ToggleButton
                                    data-automation-id="IsCorrectResponsibleEntity_Fail"
                                    key={ECheckpointResponses.No}
                                    value={ECheckpointResponses.No}
                                  >
                                    Fail
                                  </ToggleButton>
                                  <ToggleButton
                                    data-automation-id="IsCorrectResponsibleEntity_ComplexCase"
                                    key={ECheckpointResponses.Complex}
                                    value={ECheckpointResponses.Complex}
                                  >
                                    Complex Case
                                  </ToggleButton>
                                </ToggleButtonGroup>
                                {fieldState.error?.message ? (
                                  <FormHelperText error>
                                    {fieldState.error?.message}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {watchIsCorrectResponsibleEntity ===
                        ECheckpointResponses.No && (
                        <RejectApplicationNotificationBanner
                          question={
                            EEligibilityQuestion.LandRegistry_IsCorrectResponsibleEntity
                          }
                          onClick={() => onSubmit(true)}
                          disabled={isOnHoldForThisType}
                        />
                      )}
                      {watchIsCorrectResponsibleEntity ===
                        ECheckpointResponses.Complex && (
                        <ReferApplicationNotificationBanner
                          question={
                            EEligibilityQuestion.LandRegistry_IsCorrectResponsibleEntity
                          }
                          referralType={EReferralType.HomesEngland}
                          disabled={isOnHoldForThisType}
                        />
                      )}
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Title Reference
                        </Typography>
                        <Controller
                          name={getName('landRegistryTitle')}
                          rules={{
                            required: 'Required',
                            maxLength: {
                              value: 250,
                              message: '250 characters is the maximum allowed.',
                            },
                            pattern: {
                              value: /^[a-zA-Z]{2,3}[0-9]*$/,
                              message: 'Please enter a valid title reference',
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              data-automation-id="LandRegistryTitle"
                              fullWidth
                              error={!!fieldState.error}
                              required
                              label="Title Reference"
                              helperText={fieldState.error?.message}
                              style={{ width: '16rem' }}
                              disabled={readOnly || isOnHoldForThisType}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item mb={2}>
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          fontSize="1em"
                        >
                          Title Reference Note
                        </Typography>
                        <Controller
                          name={getName('landRegistryTitleNote')}
                          rules={{
                            maxLength: {
                              value: 1000,
                              message:
                                '1000 characters is the maximum allowed.',
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              data-automation-id="LandRegistryTitleNote"
                              fullWidth
                              multiline
                              error={!!fieldState.error}
                              label="Title Reference Notes"
                              helperText={fieldState.error?.message}
                              disabled={readOnly || isOnHoldForThisType}
                            />
                          )}
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <Grid item mb={2}>
                          <Typography
                            variant="h3"
                            fontWeight={600}
                            fontSize="1em"
                          >
                            Does the Responsible Entity have practical and legal
                            ability to make changes to the building?
                          </Typography>
                          <Controller
                            defaultValue={''}
                            name={getName('hasAbilityToMakeChanges')}
                            render={({
                              fieldState,
                              field: { onChange, ...fieldProps },
                            }) => (
                              <FormControl>
                                <ToggleButtonGroup
                                  {...fieldProps}
                                  onChange={(_, val) => {
                                    if (val !== null) {
                                      onChange(val);
                                    }
                                    handleRejectedQuestion(
                                      val === ECheckpointResponses.No
                                        ? EEligibilityQuestion.LandRegistry_HasAbilityToMakeChanges
                                        : null
                                    );
                                    handleReferralQuestion(
                                      EEligibilityQuestion.LandRegistry_HasAbilityToMakeChanges,
                                      EReferralType.HomesEngland,
                                      val === ECheckpointResponses.Complex
                                    );
                                  }}
                                  exclusive
                                  disabled={
                                    isOnHoldForThisType ||
                                    (readOnly &&
                                      rejectedQuestion !==
                                        EEligibilityQuestion.LandRegistry_HasAbilityToMakeChanges)
                                  }
                                >
                                  <ToggleButton
                                    data-automation-id="HasAbilityToMakeChanges_Pass"
                                    key={ECheckpointResponses.Yes}
                                    value={ECheckpointResponses.Yes}
                                  >
                                    Pass
                                  </ToggleButton>
                                  <ToggleButton
                                    data-automation-id="HasAbilityToMakeChanges_Fail"
                                    key={ECheckpointResponses.No}
                                    value={ECheckpointResponses.No}
                                  >
                                    Fail
                                  </ToggleButton>
                                  <ToggleButton
                                    data-automation-id="HasAbilityToMakeChanges_ComplexCase"
                                    key={ECheckpointResponses.Complex}
                                    value={ECheckpointResponses.Complex}
                                    onChange={(_, val) => {
                                      if (val !== null) {
                                        onChange(val);
                                      }
                                    }}
                                  >
                                    Complex Case
                                  </ToggleButton>
                                </ToggleButtonGroup>
                                {fieldState.error?.message ? (
                                  <FormHelperText error>
                                    {fieldState.error?.message}
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {watchHasAbilityToMakeChanges ===
                        ECheckpointResponses.Yes && (
                        <Grid item mb={2}>
                          <Typography variant="h3" fontSize="1em">
                            Record the Page Number in Document
                          </Typography>
                          <Controller
                            name={getName('abilityToMakeChangesPageNumber')}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                data-automation-id="AbilityToMakeChangesPageNumber"
                                type="number"
                                fullWidth
                                error={!!fieldState.error}
                                required
                                label="Page Number"
                                helperText={fieldState.error?.message}
                                style={{ width: '12rem' }}
                                disabled={readOnly || isOnHoldForThisType}
                                InputProps={{ inputProps: { min: 1 } }}
                              />
                            )}
                          />
                          <strong style={{ paddingLeft: '1rem' }}>
                            Page Number
                          </strong>
                        </Grid>
                      )}
                      {watchHasAbilityToMakeChanges ===
                        ECheckpointResponses.No && (
                        <RejectApplicationNotificationBanner
                          question={
                            EEligibilityQuestion.LandRegistry_HasAbilityToMakeChanges
                          }
                          onClick={() => onSubmit(true)}
                          disabled={isOnHoldForThisType}
                        />
                      )}
                      {watchHasAbilityToMakeChanges ===
                        ECheckpointResponses.Complex && (
                        <ReferApplicationNotificationBanner
                          question={
                            EEligibilityQuestion.LandRegistry_HasAbilityToMakeChanges
                          }
                          referralType={EReferralType.HomesEngland}
                          disabled={isOnHoldForThisType}
                        />
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={3} item>
              <Box p={3}>
                <LandRegistryChecksInfoPanel />
              </Box>
            </Grid>
            {!readOnly && (
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    p={3}
                    bgcolor="grey.100"
                  >
                    <ReferApplicationButton
                      sectionType={EEligibilitySection.LandRegistry}
                      disabled={isOnHoldForThisType}
                    />
                    <OnOffHold holdType={EOnHoldType.Eligibility} />
                    <Box gap={2} display="flex">
                      <Button
                        data-automation-id="LandRegsitrChecksForm_Cancel"
                        variant="outlined"
                        onClick={() => reset()}
                        disabled={isOnHoldForThisType}
                      >
                        Cancel
                      </Button>

                      <LoadingButton
                        data-automation-id="LandRegsitrChecksForm_Confirm"
                        variant="contained"
                        type="submit"
                        disabled={isOnHoldForThisType}
                        loading={editLandRegistryChecksStatus.isLoading}
                      >
                        Confirm Details
                      </LoadingButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
