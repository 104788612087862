import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Chip, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetPullInBuildingResponsiveFlagQuery } from 'api/pullInProcess';
import { PageTitle } from 'common/components/pageTitle';
import { SummaryItem } from 'common/components/summaryItem';
import { EPullInEditHistoryTypes } from 'enums/EPullInEditHistoryTypes';
import {
  EPullInProcessStatus,
  EPullInProcessStatusLanguage,
} from 'enums/EPullInProcessStatus';
import { useEllipsisMenu, useModalState } from 'hooks';
import { usePullInProcessContext } from 'pages/pullInProcessPage/common/context';
import {
  PullInUnresponsiveFlagModal,
  PullInUnResponsiveNotificationBanner,
  PullInRagRatingModal,
} from 'pages/pullInProcessPage/components';
import { PullInEditHistoryModal } from 'pages/pullInProcessPage/components/pullInEditHistoryModal';
import { Statuses } from 'pages/pullInProcessPage/components/statuses/Statuses';
import { TPullInProcessSummary } from 'types/pullInProcess/addBuildingRecordTypes';
import { getUkDateStringFromJsonDateString } from 'util/AppUtils';
import { stringAppendWithValueOrEmpty } from 'util/stringAppendWithValueOrEmpty';

export const TitleAndSummarySection = ({
  data,
}: {
  data: TPullInProcessSummary;
}) => {
  const navigate = useNavigate();

  const { pullInProcessId } = usePullInProcessContext();

  const { data: pullInResponsiveData, isLoading } =
    useGetPullInBuildingResponsiveFlagQuery(pullInProcessId);
  const isResponsive = pullInResponsiveData?.isResponsive;

  const modalState = useModalState<string | null>();

  const {
    isShowing: isPullInUnresponsiveFlagModalShowing,
    showModal: showPullInUnresponsiveFlagModal,
    hideModal: hidePullInUnresponsiveFlagModal,
  } = useModalState();

  const {
    isShowing: isPullInUnresponsiveFlagHistoryModalShowing,
    showModal: showPullInUnresponsiveHistoryFlagModal,
    hideModal: hidePullInUnresponsiveHistoryFlagModal,
  } = useModalState();

  const {
    isShowing: isPullInRagRatingModalShowing,
    showModal: showPullInRagRatingModal,
    hideModal: hidePullInRagRatingModal,
  } = useModalState();

  const {
    isShowing: isPullInRAGRatingHistoryModalShowing,
    showModal: showRAGRatingHistoryFlagModal,
    hideModal: hideRAGRatingHistoryFlagModal,
  } = useModalState();

  const {
    ellipsisMenuAnchor,
    handleEllipsisClick,
    handleMenuClose,
    selectedId,
  } = useEllipsisMenu();

  const handleMenuItemClick = (menuType: string) => {
    switch (menuType) {
      case 'edit':
        modalState.showModal();
        break;
      case 'unresponsiveFlag':
        showPullInUnresponsiveFlagModal();
        break;
      case 'unresponsiveFlagHistory':
        showPullInUnresponsiveHistoryFlagModal();
        break;
      case 'ragRating':
        showPullInRagRatingModal();
        break;
      case 'ragRatingHistory':
        showRAGRatingHistoryFlagModal();
        break;
      default:
        break;
    }

    handleMenuClose();
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mb={3}>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton
                size="medium"
                color="inherit"
                onClick={() => {
                  navigate(-1);
                }}
                sx={{
                  m: 0,
                  boxShadow: theme => theme.shadows[3],
                }}
                aria-label="Back to pull in processes"
                name="Back to pull in processes"
              >
                <FontAwesomeIcon icon={faChevronLeft} size="xs" fixedWidth />
              </IconButton>
            </Grid>
            <Grid item ml={2}>
              <PageTitle
                title={`${stringAppendWithValueOrEmpty(
                  data.address.buildingNameNumber,
                  ','
                )} ${stringAppendWithValueOrEmpty(
                  data.address.line1,
                  ','
                )} ${stringAppendWithValueOrEmpty(data.address.postCode)}`}
                gridMarginBottom={0}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ ml: { xs: 3, lg: 0 } }}>
          <Grid container display="flex" alignItems="center" spacing={2}>
            <SummaryItem label="Stage" value={data.stage} />
            <SummaryItem label="Unique ID" value={data.uniqueId} />
            <SummaryItem
              label="Date Created"
              value={getUkDateStringFromJsonDateString(data.creationDate)}
            />
            <SummaryItem
              label="Last Communication"
              value={
                data.lastCommunication
                  ? getUkDateStringFromJsonDateString(data.lastCommunication)
                  : ''
              }
            />
            <Box p={2}>
              <Chip
                size="medium"
                label={
                  data?.status ? EPullInProcessStatusLanguage[data?.status] : ''
                }
                sx={theme => ({
                  backgroundColor:
                    data?.status === EPullInProcessStatus.NotEligible
                      ? theme.palette.error.light
                      : theme.palette.chipPrimary?.light,
                })}
              />
            </Box>
            <Box>
              <IconButton
                aria-label="edit"
                name="edit"
                onClick={event => handleEllipsisClick(data.uniqueId, event)}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
              {selectedId ? (
                <Menu
                  anchorEl={ellipsisMenuAnchor}
                  open={Boolean(ellipsisMenuAnchor)}
                  onClose={handleMenuItemClick}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    disabled={[
                      EPullInProcessStatus.Duplicate,
                      EPullInProcessStatus.NotEligible,
                      EPullInProcessStatus.ProgressedToApplication,
                    ].includes(data?.status as EPullInProcessStatus)}
                    onClick={() => handleMenuItemClick('edit')}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleMenuItemClick('unresponsiveFlag')}
                  >
                    Unresponsive Flag
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleMenuItemClick('unresponsiveFlagHistory')
                    }
                  >
                    Unresponsive Flag History
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('ragRating')}>
                    RAG Rating
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleMenuItemClick('ragRatingHistory')}
                  >
                    RAG Rating History
                  </MenuItem>
                </Menu>
              ) : null}{' '}
              <Statuses modalState={modalState} />
            </Box>
          </Grid>
        </Grid>
        {!isResponsive && !isLoading && (
          <Grid container>
            <Grid item xs={12}>
              <PullInUnResponsiveNotificationBanner />
            </Grid>
          </Grid>
        )}
      </Grid>
      {isPullInUnresponsiveFlagModalShowing && (
        <PullInUnresponsiveFlagModal
          onClose={hidePullInUnresponsiveFlagModal}
        />
      )}
      {isPullInUnresponsiveFlagHistoryModalShowing && (
        <PullInEditHistoryModal
          onClose={hidePullInUnresponsiveHistoryFlagModal}
          type={EPullInEditHistoryTypes.Unresponsive}
          noHistoryText="At the moment no unresponsive flag has been set"
        />
      )}

      {isPullInRagRatingModalShowing && (
        <PullInRagRatingModal onClose={() => hidePullInRagRatingModal()} />
      )}
      {isPullInRAGRatingHistoryModalShowing && (
        <PullInEditHistoryModal
          onClose={hideRAGRatingHistoryFlagModal}
          type={EPullInEditHistoryTypes.RagRating}
          noHistoryText="At the moment no RAG ratings has been set"
        />
      )}
    </>
  );
};
